import React from 'react';

import { Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

import { LocationOn, Mail, Phone, Language } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

function About(props) {
    const { t } = useTranslation();

  return (
    <>
        <Typography variant="body1" fontWeight={'bold'}>
            {t(`helpHeaderFive`)}
        </Typography>
        <List dense={true} sx={{paddingLeft: "1vw", paddingRight: "1vw", paddingTop: "2.5vh"}}>
            <ListItem
                sx={{paddingTop: "1vh", paddingBottom: "1vh"}}
            >
                <ListItemIcon
                    sx={{minWidth: "30px"}}
                >
                    <LocationOn fontSize="medium"/>
                </ListItemIcon>
                <ListItemText 
                    primary="Paris Street, 7A, Bucharest"
                />
            </ListItem>
            <ListItem
                sx={{paddingTop: "1vh", paddingBottom: "1vh"}}
            >
                <ListItemIcon
                    sx={{minWidth: "30px"}}
                >
                    <Mail fontSize="medium"/>
                </ListItemIcon>
                <ListItemText 
                    primary="hi@esenca.app"
                />
            </ListItem>
            <ListItem
                sx={{paddingTop: "1vh", paddingBottom: "1vh"}}
            >
                <ListItemIcon
                    sx={{minWidth: "30px"}}
                >
                    <Language fontSize="medium"/>
                </ListItemIcon>
                <ListItemText 
                    primary="https://esenca.app"
                />
            </ListItem>
        </List>
    </>
  );
}

export default About;
