import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { initializeAppCheck, ReCaptchaV3Provider, ReCaptchaEnterpriseProvider } from 'firebase/app-check';


const env_type = process.env.REACT_APP_ENV_TYPE;


const firebaseConfigProd = {
  apiKey: "AIzaSyAlrkxodV6_qRufhfnSk6PB2hpwYlURH5Y",
  // authDomain: "auth.dashboard.esenca.app",
  authDomain: "esenca-b9053.firebaseapp.com",
  databaseURL: "https://esenca-b9053.firebaseio.com",
  projectId: "esenca-b9053",
  storageBucket: "esenca-b9053.appspot.com",
  messagingSenderId: "333199250987",
  appId: "1:333199250987:web:528abc9f58d5e63c92bc6e",
  measurementId: "G-L45ZHGMJS0"
};

const firebaseConfigTest = {
  apiKey: "AIzaSyClTiZGww9KfT78n1n6x35d-ITTFcC0j8U",
  authDomain: "database-development-5f35a.firebaseapp.com",
  projectId: "database-development-5f35a",
  storageBucket: "database-development-5f35a.appspot.com",
  messagingSenderId: "732035346513",
  appId: "1:732035346513:web:87f3cb20e565f55c198870"
};

const firebaseConfig = env_type === 'production' ? firebaseConfigProd : firebaseConfigTest;

// Initialize Firebase
const app = initializeApp(firebaseConfig);

if (env_type === 'production') {
  const captchaSiteKey = '6Lcwc0EoAAAAAJDNTxFlMy4NygN-fao9zHziW3LU';
  // const captchaSiteKey = '6LcCQnEpAAAAAEixXaPi8ok83oMm7zk0roc-Fn-s';
  // const captchaSiteKey = '6Lcwc0EoAAAAAPwpkGpLgvS0MOZeA5f_NDMreVBu';
  // window.FIREBASE_APPCHECK_DEBUG_TOKEN=true;
  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(captchaSiteKey),
    isTokenAutoRefreshEnabled: true
  })
}
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const database = getDatabase(app);

// if (env_type === 'development') {
//   console.log("connecting to emulator")
//   connectAuthEmulator(auth, "http://127.0.0.1:9099");
// }
