import { useState, useEffect } from 'react';
import { Box, Toolbar, Tab, Card } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import { MeasurementsTable } from './components/MeasurementsTable';
import { RecommendationTable } from './components/RecommendationTable';

import { useTranslation } from "react-i18next";
import { useAuth } from '../../AuthContext';
import { useParams, useNavigate } from 'react-router-dom';

const drawerWidth = 245;

function MeasurementsPage() {

  const { permissions } = useAuth();
  const { t } = useTranslation();
  const { tab } = useParams(); 
  const navigate = useNavigate();

  const [canViewMeasurements, setCanViewMeasurements] = useState(false)
  const [canViewRecommendations, setCanViewRecommendations] = useState(false);

  const [value, setValue] = useState('measurements');

  useEffect(() => {
    if (tab) {
      setValue(tab);
    } else {
      setValue("measurements"); 
    }
  }, [tab]);

  useEffect(()=>{
    if(permissions && permissions.includes('view_measurements')) {
      setCanViewMeasurements(true)
    } else {
      setValue('recommendations')
    }
    if(permissions && permissions.includes('view_recommendations')) {
      setCanViewRecommendations(true)
    }
  },[permissions])

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`/measurements/${newValue}`);
  };


  return (
    <>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, ml: { sm: `${drawerWidth}px` }, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />

        <Card>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                {canViewMeasurements && <Tab label={t(`Measurements`)} value="measurements" data-cy="measurements-table"/> }
                {canViewRecommendations && <Tab label={t(`Recommendations`)} value="recommendations" data-cy="recommendations-table"/>}
              </TabList>
            </Box>
            {canViewMeasurements && 
            <TabPanel value="measurements">
              <MeasurementsTable />
            </TabPanel>
            }
            {canViewRecommendations &&
            <TabPanel value="recommendations">
              <RecommendationTable />
            </TabPanel>
            }
          </TabContext>
        </Card>
      </Box>
    </>
  );
}

export default MeasurementsPage;
