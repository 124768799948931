import { useEffect, useState } from "react";

import {
  Box, Button, Card, Checkbox, Grid, MenuItem, Paper, Table, TableCell, TableRow, TableBody, TableContainer, TableHead,
  TextField, Toolbar, Typography, Select
} from "@mui/material";

import {
  Add,
  Delete,
  InfoOutlined,
  ReportProblemOutlined
} from "@mui/icons-material";


import { areKeysUnique, isNumberKey, convertObjectToFloat, parseData, isValidManual, convertArraysToDictionaries, isValidFirestoreFieldName } from '../utils';
import { useAuth } from "../../../../AuthContext";

const database_url = process.env.REACT_APP_DATABASE_URL;

export default function AddChartManualMain(props) {

  const { userId, companyId, standard, handleSnackbar, currentUser } = useAuth()

  const [chartId, setChartId] = useState('my_chart');
  const [selectedMeasurement, setSelectedMeasurement] = useState('');
  const [measurements, setMeasurements] = useState([]);
  const [data, setData] = useState([]);
  const [availableMeasurements, setAvailableMeasurements] = useState([]);

  const [errorChartId, setErrorChartId] = useState(false)
  const [errorChart, setErrorChart] = useState(false)

  useEffect(() => {
    if (standard) {
      if (Object.keys(standard).length > 0) {
        let dataArray = Object.entries(standard);
        dataArray.sort((a, b) => {
          let nameA = a[1].name.toLowerCase();
          let nameB = b[1].name.toLowerCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });
        let sortedData = {};
        for (let [key, value] of dataArray) {
          sortedData[key] = value;
        }

        setAvailableMeasurements(Object.keys(sortedData))
        setSelectedMeasurement(Object.keys(sortedData)[0])
      }
    }
  }, [standard]);

  const handleAddRow = () => {
    const newRow = { sizes: '', ...measurements.reduce((acc, name) => ({ ...acc, [name]: '' }), {}) };
    setData([...data, newRow]);
  };

  const handleAddColumn = () => {
    if (selectedMeasurement && !measurements.includes(selectedMeasurement)) {
      setMeasurements([...measurements, selectedMeasurement]);
      setData(data.map((row) => ({ ...row, [selectedMeasurement]: '' })));
      const newAvailableMeasurements = availableMeasurements.filter(
        (measurement) => measurement !== selectedMeasurement
      );
      setAvailableMeasurements(newAvailableMeasurements);
      setSelectedMeasurement(newAvailableMeasurements[0]);
    }
  };

  const handleDeleteRow = (index) => {
    const newData = [...data];
    newData.splice(index, 1);
    setData(newData);
  };

  const handleDeleteMeasurement = (measurement) => {
    const updatedMeasurements = measurements.filter((m) => m !== measurement);
    setMeasurements(updatedMeasurements);

    const updatedData = data.map((row) => {
      const newRow = { ...row };
      delete newRow[measurement];
      return newRow;
    });

    setData(updatedData);
    setAvailableMeasurements([...availableMeasurements, measurement]);
    setSelectedMeasurement(measurement);
  };

  const saveSizeChart = async () => {

    const accessToken = await currentUser.getIdToken(true);
    if (chartId === '') {
      setErrorChartId(true)
      handleSnackbar('error', "The size chart id is not valid. Please check and try again.");
    } else {
      setErrorChartId(false)
      if (data.length > 0) {
        if (isValidManual(data)) {
          setErrorChart(false)

          let parsed = parseData(data)
          convertObjectToFloat(parsed)

          const sizeChart = {};
          sizeChart[chartId] = parsed;

          const outputObject = Object.fromEntries(
            Object.entries(sizeChart).map(([key, value]) => [
              key,
              convertArraysToDictionaries(value)
            ])
          );

          // for (let key in outputObject) {
          //   outputObject['sc_' + key] = outputObject[key];
          //   delete outputObject[key];
          // }

          for (let key in outputObject) {
            let new_key = 'sc_' + key
            outputObject[new_key] = outputObject[key];
            delete outputObject[key];
            if(!areKeysUnique(Object.values(outputObject[new_key]["sizes"]))){
              handleSnackbar('error', "The size chart id is not valid. Please check and try again.");
            }else if (!isValidFirestoreFieldName(new_key)) {
              setErrorChartId(true)
              handleSnackbar('error', "The size chart id is not valid. Please check and try again.");
            } else {
              if (companyId) {
                let send_data = { size_charts: outputObject };
    
                fetch(database_url + "/add_company_size_chart?user_id=" + userId + '&company_id=' + companyId, {
                  method: "POST",
                  headers: { "Accept": "application/json", "Content-Type": 'application/json', 'x-access-token': accessToken },
                  body: JSON.stringify(send_data)
                }).then(() => {
                  props.handleCloseDialog()
                  handleSnackbar('success', "Size Chart Saved to Database");
                  window.location.reload()
                });
              }
            }
          }

        } else {
          setErrorChart(true)
          handleSnackbar('error', "The size chart is not valid. Please check and try again.");
        }
      } else {
        setErrorChart(true)
        handleSnackbar('error', "The size chart is not valid. Please check and try again.");
      }
    }
  }


  return (
    <Grid container>
      <Grid item xs={12}>
        <Toolbar />
      </Grid>
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12}>
          {errorChart && <Typography variant="caption" sx={{ fontWeight: 'bold' }} color="error">The size chart is not valid. Please check and try again.</Typography>}
        </Grid>
        <Grid item xs={12} md={7} mt={2}>
          <Card sx={{ px: 5, py: 2 }}>
            <Typography variant="body1" fontWeight={'bold'} mb={2}>
              Size Chart ID
            </Typography>
            <Box display={'flex'} justifyContent={'space-between'}>
              <TextField
                variant="outlined"
                sx={{ width: '300px' }}
                value={chartId}
                onChange={(event) => setChartId(event.target.value)}
                error={errorChartId}
                helperText={errorChartId ? "Please enter a valid chart id." : ""}
              />
              {/* <Box>
                <IconButton>
                  <InfoOutlined />
                  <Typography variant="body2">
                    What is this?
                  </Typography>
                </IconButton>
              </Box> */}
            </Box>
          </Card>
        </Grid>
        {/* <Grid item xs={12} md={5} mt={2}>
          <Card sx={{ px: 5, py: 2 }}>
            <Typography variant="body1" fontWeight={'bold'}>
              Measurements Standard
            </Typography>
            <Typography variant="body2" mb={2}>
              View a more detailed description of where we take our measurements
            </Typography>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Button
                variant="contained"
              >
                Show
              </Button>
            </Box>
          </Card>
        </Grid> */}
        <Grid item xs={12} mt={5} display={'flex'}>
          <Select
            variant="standard"
            sx={{ mt: 3, width: '300px', ml: 5 }}
            value={selectedMeasurement}
            onChange={(e) => setSelectedMeasurement(e.target.value)}
          >
            {availableMeasurements.map((measurement, index) => (
              <MenuItem key={index} value={measurement}>
                {standard[measurement].name}
              </MenuItem>
            ))}
          </Select>
          <Button
            variant="outlined"
            onClick={handleAddColumn}
            sx={{ mt: 3, width: '15%', ml: 5 }}
            startIcon={
              <Add />
            }
          >
            Add Measurement
          </Button>
          <Button
            variant="outlined"
            onClick={handleAddRow}
            sx={{ mt: 3, width: '15%', marginLeft: 'auto' }}
            disabled={!(measurements.length > 0)}
            startIcon={
              <Add />
            }
          >
            New Row
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <ReportProblemOutlined /> Please input the body measurements in <b>centimeters</b>.
          </Typography>
        </Grid>
        {
          measurements.length > 0 ? (
            <>
              <Grid item xs={12}>
                <TableContainer component={Paper} sx={{ mt: 2 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">sizes</TableCell>
                        {measurements.map((measurement, index) => (
                          <TableCell align="center" key={index}>
                            {standard[measurement].name}
                            <Checkbox
                              onChange={() => handleDeleteMeasurement(measurement)}
                              checked={measurements.includes(measurement)}
                              checkedIcon={<Delete />}
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((row, rowIndex) => (
                        <TableRow key={rowIndex}>
                          <TableCell align="center">
                            <TextField
                              value={row.sizes}
                              onChange={(e) => {
                                const newData = [...data];
                                newData[rowIndex].sizes = e.target.value;
                                setData(newData);
                              }}
                            />
                          </TableCell>
                          {measurements.map((measurement, measurementIndex) => (
                            <TableCell align="center" key={measurementIndex}>
                              <TextField
                                type="number"
                                value={row[measurement]}
                                onChange={(e) => {
                                  const newData = [...data];
                                  newData[rowIndex][measurement] = e.target.value;
                                  setData(newData);
                                }}
                                onKeyDown={(e) => {
                                  if (!isNumberKey(e)) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </TableCell>
                          ))}
                          <TableCell align="center">
                            <Button onClick={() => handleDeleteRow(rowIndex)}>Delete</Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Card
                sx={{
                  width: '100%',
                  minHeight: '20vh',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <Typography variant="body2" alignSelf={'center'}>
                  Add a Measurement to start.
                </Typography>
              </Card>
            </Grid>
          )
        }
        <Grid item xs={12} align="right">
          <Button
            onClick={saveSizeChart}
            variant="contained"
            sx={{ mt: 3, width: '15%' }}
          >
            Save to database
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}