import React from 'react';
import { Box, Toolbar } from '@mui/material';

import SettingsComponent from './components/SettingsComponent';

const drawerWidth = 240;

function SettingsPage(props) {
  return (
    <>
        <Box
            component="main"
            sx={{ flexGrow: 1, p: 3, ml: { sm: `${drawerWidth + 8}px` },width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >
            <Toolbar />
            <SettingsComponent/>
        </Box>
    </>
  );
}

export default SettingsPage;
