import { useState, useEffect } from "react";

import { Typography, Grid, Card, Chip, LinearProgress, Divider, Button, Box } from "@mui/material";

import { useAuth } from "../../../AuthContext";

const database_url = process.env.REACT_APP_DATABASE_URL;

export default function SettingsComponent(props) {

    const { currentUser, companyConfig, handleSnackbar } = useAuth()

    const [userId, setUserId] = useState("");

    useEffect(()=>{
        if(currentUser){
            setUserId(currentUser.uid)
        }
    },[currentUser])

    const saveConfig = () => {
        let data = "";
        
        if (userId) {
            data = {user: userId, config: companyConfig};
        } 
        fetch(database_url+"/update_config_test", {
            method: "POST",
            headers: {"Accept": "application/json", "Content-Type": 'application/json' },
            body: JSON.stringify(data)
        }).then(() => {
            handleSnackbar('success', "Config saved successfully!");
        });
    }

    return (
        <>
            <Card sx={{ padding: "2%", backgroundColor: "#f8f8f8" }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Button onClick={saveConfig} sx={{mt:2}} variant="outlined">Save Config</Button>
                    </Grid>
                    <Grid item xs={6} sx={{mt:1}}>
                        <Typography variant="h6">Plan</Typography>
                        <Chip label="Business Plan" sx={{mt:2}} color="info"/>
                        <Typography variant="body2" sx={{mt:2}}>~EUR / month + tax</Typography>
                        <Button sx={{mt:2}} variant="outlined">Upgrade Plan</Button>
                    </Grid>
                    <Grid item xs={6} sx={{mt:1}}>
                        <Typography variant="h6">Billing Period</Typography>
                        <Typography variant="body2" sx={{mt:2}}>Payment date</Typography>
                        <Chip label="20.10.2023" sx={{mt:2}} color="info"/>
                        <br/>
                        <Button sx={{mt:2}} variant="outlined">Change billing period</Button>
                    </Grid>
                    <Grid item xs={12} sx={{mt:3}}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={12} sx={{mt:3}}>
                        <Typography variant="h6">Measurements</Typography>
                        <Chip label="20/100 measurements used" sx={{mt:2}} color="info"/>
                        <Box sx={{ width: '100%', mt:2 }}>
                            <LinearProgress variant="determinate" value={20} color="info" />
                        </Box>
                        <Button sx={{mt:2}} color="primary" variant="outlined">Manage Measurements</Button>
                    </Grid>
                    <Grid item xs={12} sx={{mt:3}}>
                        <Divider/>
                    </Grid>
                    <Grid item container xs={12} sx={{mt:3}}>
                        <Grid item xs={12} mb={3}>
                            <Typography variant="h6">Add-ons</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2" sx={{fontWeight:'bold'}}>3D Avatar</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2">10.10.2023</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2">~ EUR</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Chip label="Active" color="info"/>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} sx={{mt:3}}>
                        <Grid item xs={3}>
                            <Typography variant="body2" sx={{fontWeight:'bold'}}>White Label</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2">15.10.2023</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2">~ EUR</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Chip label="Active" color="info"/>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} sx={{mt:3}}>
                        <Grid item xs={3}>
                            <Typography variant="body2" sx={{fontWeight:'bold'}}>Widget Customisation</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2">15.10.2023</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2">~ EUR</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Chip label="Active" color="info"/>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{mt:3}}>
                        <Divider/>
                    </Grid>
                    <Grid item container xs={12} sx={{mt:3}}>
                        <Grid item xs={12} mb={3}>
                            <Typography variant="h6">Account</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2" sx={{fontWeight:'bold'}}>Timezone</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Chip label="CET" color="info"/>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} sx={{mt:3}}>
                        <Grid item xs={3}>
                            <Typography variant="body2" sx={{fontWeight:'bold'}}>Measurements List</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2">List with measurements</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} sx={{mt:3}}>
                        <Grid item xs={3}>
                            <Typography variant="body2" sx={{fontWeight:'bold'}}>Measurements Requests</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Chip label="Request a measurement" color="info"/>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{mt:3}}>
                        <Divider/>
                    </Grid>
                    <Grid item container xs={12} sx={{mt:3}}>
                        <Grid item xs={12} mb={3}>
                            <Typography variant="h6">Widget Customisation</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2" sx={{fontWeight:'bold'}}>Logo</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Chip label="Upload" color="info"/>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} sx={{mt:3}}>
                        <Grid item xs={3}>
                            <Typography variant="body2" sx={{fontWeight:'bold'}}>Colors</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Chip label="Color one" color="info"/>
                        </Grid>
                        <Grid item xs={3}>
                            <Chip label="Color two" color="primary"/>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} sx={{mt:3}}>
                        <Grid item xs={3}>
                            <Typography variant="body2" sx={{fontWeight:'bold'}}>Language - audio</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2">English</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} sx={{mt:3}}>
                        <Grid item xs={3}>
                            <Typography variant="body2" sx={{fontWeight:'bold'}}>Texts</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2">List with texts</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </>
    );
}