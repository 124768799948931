import { useState, useEffect } from 'react';

import { TableContainer, TableHead, Table, TableCell, TableRow, TableBody, Button, IconButton, Dialog, DialogContent,
  DialogContentText, DialogTitle, DialogActions } from '@mui/material';
import { ReadMore, Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/material/styles";

import { useAuth } from '../../../../AuthContext';
import { colors } from '../../../../defaultTheme';
import { useTranslation } from "react-i18next";
import { parseTimestamp } from '../../../Measurements/utils/utils';

import { useParams, useNavigate, useLocation } from 'react-router-dom';

import ProductDetailsDialog from './ProductDetailsDialog';

const database_url = process.env.REACT_APP_DATABASE_URL;

function createData(id, createdAt, updatedAt, name, collection, variations, sku, clientGivenNumber) {
  return { id, createdAt, updatedAt, name, collection, variations, sku, clientGivenNumber };
}

function ProductsTable(props) {
  const { companyProducts, companyCollections, permissions, companyId, currentUser, handleSnackbar, setCompanyProducts, 
    userId, companyConfig  } = useAuth()
  const { t } = useTranslation();

  const [rows, setRows] = useState([]);
  const [currentProduct, setCurrentProduct] = useState([]);

  const [buttonLoader, setButtonLoader] = useState(false);

  const [openProductDialog, setOpenProductDialog] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  const [canDeleteProduct, setCanDeleteProduct] = useState(false);

  const theme = useTheme();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const productId = params.get('id');

  const { tab, dialog } = useParams();
  const navigate = useNavigate();

  useEffect(()=>{
    if(openProductDialog || openDeleteDialog ){
      window?.tidioChatApi?.display(false);
    } else {
      window?.tidioChatApi?.display(true);
    }
  },[openProductDialog,openDeleteDialog])

  useEffect(() => {
    switch (dialog) {
      case 'details':
        setOpenProductDialog(true);
        break;
      case 'delete':
        setOpenDeleteDialog(true);
        break;
      default:
        setOpenProductDialog(false);
        setOpenDeleteDialog(false);
        break;
    }
  }, [dialog]);

  useEffect(() => {
    if (companyProducts) {
      let items = companyProducts
      let rows = []
      for (const key in items) {
        let name = ""
        let collection = ""
        let variations = ""

        let sku = ""
        let clientGivenNumber = ""

        let timezone = companyConfig.timezone
        let createdAt = items[key].createdAt ?  parseTimestamp(items[key].createdAt, timezone) : ""
        let updatedAt = items[key].updatedAt ?  parseTimestamp(items[key].updatedAt, timezone) : ""

        if (items[key].hasOwnProperty("name")) {
          name = items[key].name
        } else {
          name = String(key).split("_").join(" ")
        }

        if (items[key].hasOwnProperty("sku")) {
          sku = items[key].sku
        } 

        if (items[key].hasOwnProperty("clientGivenNumber")) {
          clientGivenNumber = items[key].clientGivenNumber
        } 

        if (items[key].hasOwnProperty("collection")) {
          collection = companyCollections[items[key].collection]?.name || "-";
        } else {
          collection = "-"
        }

        if (items[key].hasOwnProperty("variations")) {
          variations = Object.keys(items[key].variations).length
        } else {
          variations = 0
        }

        rows.push(createData(key, createdAt, updatedAt, name, collection, variations, sku, clientGivenNumber))
      }

      setRows(rows)
    }
  }, [companyProducts, companyCollections])

  useEffect(()=>{
    if(permissions && permissions.includes('delete_products')) {
      setCanDeleteProduct(true)
    }
  },[permissions])

  const productDialog = (id) => {
    if (companyProducts.hasOwnProperty(id)) {
      navigate(`/size-charts/${tab}/details?id=${id}`); 
    }
  }

  const deleteProductDialog = (id) => {
    if (companyProducts.hasOwnProperty(id)) {
      navigate(`/size-charts/${tab}/delete?id=${id}`); 
    }
  }

  const handleCloseProductDialog = () => {
    navigate(`/size-charts/${tab}`);
    setOpenProductDialog(false);
    window.tidioChatApi.display(true);
  };

  const handleCloseDeleteDialog = () => {
    navigate(`/size-charts/${tab}`);
    setOpenDeleteDialog(false);
    window.tidioChatApi.display(true);
  };

  const handleDelete = async() => {
    setButtonLoader(true)
    const accessToken = await currentUser.getIdToken(true);
    let data = {}
    if (companyId) {
      data = { product_id: productId };

      fetch(database_url+"/delete_company_product?user_id=" + userId + '&company_id=' + companyId, {
          method: "DELETE",
          headers: {"Accept": "application/json", "Content-Type": 'application/json', 'x-access-token': accessToken },
          body: JSON.stringify(data)
      }).then(() => {
          handleSnackbar('success', "Product deleted");
          let promises = Promise.all([
            fetch(database_url + '/view_company_products?user_id=' + userId + '&company_id=' + companyId, {
              method: 'GET',
              headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
            }),
          ]);

          promises
          .then((results) => Promise.all(results.map((r) => r.json())))
          .then((resultsData) => {
            const [companyProducts] = resultsData;
            setCompanyProducts(companyProducts || {});
          }).then(()=>{
            setOpenDeleteDialog(false)
            setButtonLoader(false)
            navigate(`/size-charts/${tab}`);
          })
      });
    } else {
      setButtonLoader(false)
      navigate(`/size-charts/${tab}`);
    }
  }

  return (
    <>
      <TableContainer>
        <Table size='small'>
          <TableHead sx={{ display: "table-header-group", backgroundColor: theme.palette.primary.main }}>
            <TableRow>
            {canDeleteProduct && 
              <TableCell align="center" sx={{ fontWeight: 'bold', color: colors.sidenavTextSelected }}></TableCell>
            }
              <TableCell align="center" sx={{ fontWeight: 'bold', color: colors.sidenavTextSelected }}>{t(`Created`)}</TableCell>
              <TableCell align="center" sx={{ fontWeight: 'bold', color: colors.sidenavTextSelected }}>{t(`Updated`)}</TableCell>
              <TableCell align="center" sx={{ fontWeight: 'bold', color: colors.sidenavTextSelected }}>{t(`Product Name`)}</TableCell>
              <TableCell align="center" sx={{ fontWeight: 'bold', color: colors.sidenavTextSelected }}>SKU</TableCell>
              <TableCell align="center" sx={{ fontWeight: 'bold', color: colors.sidenavTextSelected }}>{t(`Collection`)}</TableCell>
              <TableCell align="center" sx={{ fontWeight: 'bold', color: colors.sidenavTextSelected }}>{t(`Size Charts`)}</TableCell>
              <TableCell align="center" sx={{ fontWeight: 'bold', color: colors.sidenavTextSelected }}>{t(`Details`)}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
              {canDeleteProduct && 
                <TableCell align="left">
                  <IconButton
                    onClick={() => { deleteProductDialog(row.id) }}
                  >
                    <Delete fontSize='small'/>
                  </IconButton>
                </TableCell>
              }
                <TableCell align="center">{row.createdAt}</TableCell>
                <TableCell align="center">{row.updatedAt}</TableCell>
                <TableCell align="center">{row.name}</TableCell>
                <TableCell align="center">{row.sku}</TableCell>
                <TableCell align="center">{row.collection}</TableCell>
                <TableCell align="center">{row.variations}</TableCell>
                <TableCell align="center">
                  <IconButton
                    onClick={() => { productDialog(row.id) }}
                    data-cy={`product-${row.name}`}
                  >
                    <ReadMore color="primary" fontSize='small'/>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        fullScreen
        maxWidth="lg"
        open={openProductDialog}
        onClose={handleCloseProductDialog}
      >
        <DialogContent>
          <ProductDetailsDialog
            product={productId}
            handleCloseDialog={handleCloseProductDialog}
          />
        </DialogContent>
      </Dialog>

      
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delete product?  
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the product ?
            This will inactivate recommendations associated with this product.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Disagree</Button>
          <LoadingButton
              onClick={()=>{handleDelete()}} 
              loading={buttonLoader}
              variant="contained"
              autoFocus
          >
            Agree
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ProductsTable;
