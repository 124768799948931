import { useState, useEffect } from 'react';
import { Grid, Typography, Checkbox, FormGroup, FormControlLabel, Button, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useTranslation } from "react-i18next";
import { useAuth } from '../../../AuthContext';

const database_url = process.env.REACT_APP_DATABASE_URL;

function ModifyClientsPermissions(props) {

  const { permissions, userId, companyClients, companyClientUsers, handleSnackbar, currentUser, companyId, setCompanyClientUsers } = useAuth();
  const { t } = useTranslation();

  const [selectedClient, setSelectedClient] = useState("");

  const [canModifyClientsPermissions, setCanModifyClientsPermissions] = useState(false);
  const [canViewMeasurements, setCanViewMeasurements] = useState(false)
  const [canViewRecommendations, setCanViewRecommendations] = useState(false);

  const [checkedMeasurements, setCheckedMeasurements] = useState(false)
  const [checkedRecommendations, setCheckedRecommendations] = useState(false)

  const [edit, setEdit] = useState(false);
  const [loaderSave, setLoaderSave] = useState(false)

  useEffect(()=>{
    if(permissions && permissions.includes('modify_clients_permissions')) {
      setCanModifyClientsPermissions(true)
    }
  },[permissions])

  useEffect(()=>{
    if(props.selectedClient) {
        setSelectedClient(props.selectedClient)
        let selectedClient = props.selectedClient
        if(companyClientUsers && companyClientUsers[selectedClient]){
            if(Object.values(companyClientUsers[selectedClient]).length > 0) {
                let currentUsers = Object.values(companyClientUsers[selectedClient])
                let firstUser = currentUsers[0]
                let userPermissions = firstUser.permissions
                if(userPermissions.includes('view_measurements')) {
                    setCanViewMeasurements(true)
                    setCheckedMeasurements(true)
                }
                if(userPermissions.includes('view_recommendations')) {
                    setCanViewRecommendations(true)
                    setCheckedRecommendations(true)
                }
            }
        }
    }
  },[props.selectedClients, companyClientUsers])

  const handleCancel = () => {
    setCheckedMeasurements(canViewMeasurements)
    setCheckedRecommendations(canViewRecommendations)
    setEdit(false)
    setLoaderSave(false)
  };

  const handleSave = async() => {
    setLoaderSave(true)

    const accessToken = await currentUser.getIdToken(true);
    if (checkedMeasurements == canViewMeasurements && checkedRecommendations == canViewRecommendations) {
      setLoaderSave(false)
      setEdit(false)
    } else {
      if (companyId && selectedClient) {
        let send_data = { client_id: selectedClient, can_view_measurements: checkedMeasurements, can_view_recommendations: checkedRecommendations  };

        fetch(database_url + "/modify_clients_permissions?user_id=" + userId + '&company_id=' + companyId, {
          method: "POST",
          headers: { "Accept": "application/json", "Content-Type": 'application/json', 'x-access-token': accessToken },
          body: JSON.stringify(send_data)
        }).then(() => {
          handleSnackbar('success', 'Permissions updated');
          setLoaderSave(false)
          let promises = Promise.all([
            fetch(database_url + '/get_client_users?user_id=' + userId + '&company_id=' + companyId, {
              method: 'GET',
              headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
            }),
          ]);

          promises
          .then((results) => Promise.all(results.map((r) => r.json())))
          .then((resultsData) => {
            setCompanyClientUsers(resultsData[0]['result'] || {});
          }).then(()=>{
            setLoaderSave(false)
            setEdit(false)
            setCanViewMeasurements(checkedMeasurements)
            setCanViewRecommendations(checkedRecommendations)
          })
        });
      } else {
        setLoaderSave(false)
        setEdit(false)
        setCheckedMeasurements(canViewMeasurements)
        setCheckedRecommendations(canViewRecommendations)
      }
    }
  };

  return (
    <>
        <Grid container mt={2} alignItems="center">
            <Grid item xs={2}>
                <Typography>Current permissions: </Typography>
            </Grid>
         
            {
                edit ? 
                <Grid item xs={6}>
                    <FormGroup row>
                        <FormControlLabel control={<Checkbox />} label="View Measurements" checked={checkedMeasurements} onChange={(event) => setCheckedMeasurements(event.target.checked)}/>
                        <FormControlLabel control={<Checkbox />} label="View Recommendations" checked={checkedRecommendations} onChange={(event) => setCheckedRecommendations(event.target.checked)}/>
                    </FormGroup>
                </Grid>
                : 
                <Grid item xs={6} sx={{width:'100%'}}>
                    <FormGroup row>
                        <FormControlLabel disabled control={<Checkbox />} label="View Measurements" checked={checkedMeasurements}/>
                        <FormControlLabel disabled control={<Checkbox />} label="View Recommendations" checked={checkedRecommendations}/>
                    </FormGroup>
                </Grid>
            }

            {!edit && canModifyClientsPermissions &&
            <Grid item xs={2}>
                <Button onClick={() => { setEdit(true) }} variant='contained' sx={{ width: '100px' }}>{t(`Edit`)}</Button>
            </Grid>
            }

            {edit &&
                <>
                <Grid item xs={2}>
                    <Box  sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={handleCancel} sx={{ width: '100px' }} variant='outlined'>{t(`Cancel`)}</Button>
                        <LoadingButton
                            onClick={() => handleSave()}
                            loading={loaderSave}
                            variant="contained"
                            sx={{ width: '100px', ml: 2 }}
                        >
                            {t(`Save`)}
                        </LoadingButton>
                    </Box>
                </Grid>
                </>
            }
        </Grid>
    </>
  );
}

export default ModifyClientsPermissions;
