import React, { useEffect, useState } from 'react';
import { Typography, Grid, TextField, Button, FormControl, InputLabel, MenuItem, Select, Checkbox, ListItemText, Box,
     Divider, IconButton, OutlinedInput, Autocomplete } from '@mui/material';
import { LoadingButton } from "@mui/lab";
import { AddPhotoAlternate, Delete, Done } from '@mui/icons-material';

import { SketchPicker } from 'react-color';

import { useAuth } from '../../../../AuthContext';
import { timezones, languages, roles, permissions, standardMeasurementsToAdd, standardPermissionsToAdd } from '../../configs';
import { toBase64 } from '../../../SizeCharts/components/utils';

const database_url = process.env.REACT_APP_DATABASE_URL;

function CompanyOnboarding() {
    const { standard, currentUser, handleSnackbar, userId } = useAuth();

    const [companyName, setCompanyName] = useState("");
    const [timezone, setTimezone] = useState("");
    const [measurementsConfig, setMeasurementsConfig] = useState([]);
    const [logo, setLogo] = useState("");
    const [primaryColor, setPrimaryColor] = useState("#27336e");
    const [sidenavBackgroundColor, setSidenavBackgroundColor] = useState("#C0DAF3");
    const [language, setLanguage] = useState("");
    const [clients, setClients] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [measurementsArray, setMeasurementsArray] = useState([]);

    const [error, setError] = useState("");
    const [buttonLoader, setButtonLoader] = useState(false);

    useEffect(()=>{
        const measurementsArray = Object.keys(standard).map(key => ({
            id: key,
            name: standard[key].name,
            definition: standard[key].definition,
            guidelines: standard[key].guidelines,
            picture: standard[key].picture,
            replaceBy: standard[key].replaceBy,
        }));
        setMeasurementsArray(measurementsArray)
    },[standard])

    const handleLogoUpload = (e) => {
        const selectedImage = e.target.files[0];
        if (selectedImage) {
          setLogo(selectedImage)
        }
    };

    const handleClientChange = (index, event) => {
        const newClients = clients.map((client, i) => {
        if (i === index) {
            return { ...client, name: event.target.value };
        }
        return client;
        });
        setClients(newClients);
    };

    const addClient = () => {
        setClients([...clients, { name: '' }]);
    };

    const removeClient = (index) => {
        setClients(clients.filter((_, i) => i !== index));
    };

    const handleAccountChange = (index, field, value) => {
        const newAccounts = accounts.map((account, i) => {
        if (i === index) {
            return { ...account, [field]: value };
        }
        return account;
        });
        setAccounts(newAccounts);
    };

    const addAccount = () => {
        setAccounts([...accounts, { firstName: '', lastName: '', email: '', clientName: '', role: '', permissions: [] }]);
    };

    const removeAccount = (index) => {
        setAccounts(accounts.filter((_, i) => i !== index));
    };

    const addStandardMeasurements = () => {
        setMeasurementsConfig([...standardMeasurementsToAdd]);
    };

    const addStandardColors = () => {
        setPrimaryColor("#27336e")
        setSidenavBackgroundColor("#C0DAF3")
    }

    const addStandardPermissions = (index) => {
        const newAccounts = accounts.map((account, i) => {
            if (i === index) {
                return { ...account, permissions: Array.from(new Set([...account.permissions, ...standardPermissionsToAdd])) };
            }
            return account;
        });
        setAccounts(newAccounts);
    };

    const validateAccounts = (accounts) => {
        return accounts.every(account => {
            const hasRequiredFields = account.firstName && account.lastName && account.email && account.role && Array.isArray(account.permissions) && account.permissions.length > 0;
            const hasClientRole = account.role === 'client';
            const hasClientName = account.clientName;
    
            if (hasClientRole) {
                return hasRequiredFields && hasClientName;
            } else {
                return hasRequiredFields;
            }
        });
    };

    const saveCompany = async () => {
        setButtonLoader(true)
        setError("");
        const accessToken = await currentUser.getIdToken(true);

        if (!validateAccounts(accounts)) {
            setError("Check accounts - missing fields");
            setButtonLoader(false);
        } else if(companyName && timezone && language && measurementsConfig.length>0) {

            const companyData = {
                name: companyName,
                timezone: timezone,
                language: language,
                measurementsConfig: measurementsConfig
            };

            let finalLogo = {}

            if (logo) {
                let currentLogo = logo ? logo : "";
                if (currentLogo) {
                    finalLogo = {
                        "name": currentLogo.name,
                        "data": await toBase64(currentLogo)
                    }
                }
            }

            if (finalLogo) {
                companyData.logo = finalLogo;
            }

            if (primaryColor !== "#27336e") {
                companyData.primaryColor = primaryColor;
            }

            if (sidenavBackgroundColor !== "#C0DAF3") {
                companyData.sidenavBackgroundColor = sidenavBackgroundColor;
            }

            if (clients.length > 0) {
                companyData.clients = clients
            }

            if (accounts.length > 0) {
                companyData.users = accounts
            }

            fetch(database_url + "/company_onboarding?user_id=" + userId, {
                method: "PUT",
                headers: { "Accept": "application/json", "Content-Type": 'application/json', 'x-access-token': accessToken },
                body: JSON.stringify(companyData)
              }).then(() => {
                handleSnackbar('success', "Company added");
                setButtonLoader(false)
                // TODO: retrieve data about this company from db and append it to company list --> just the id and config
              });
        } else {
            setButtonLoader(false)
            setError("Missing company details")
        }
     
    };

    return (
        <Grid container spacing={2} align="center">
            <Grid item xs={12} mt={2}>
                <Typography variant="h6">Details</Typography>
            </Grid>
            <Grid item xs={12} md={4} mt={2}>
                <TextField
                label="Company Name"
                variant="outlined"
                size="small"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                fullWidth
                sx={{ 
                    '& .MuiOutlinedInput-root': {
                    borderRadius: 2, 
                    }
                }}
                />
            </Grid>

            <Grid item xs={12} md={4} mt={2}>
                <Autocomplete
                    freeSolo
                    options={timezones}
                    value={timezone}
                    onChange={(event, newValue) => setTimezone(newValue)}
                    onInputChange={(event, newInputValue) => setTimezone(newInputValue)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Timezone"
                            variant="outlined"
                            size="small"
                            sx={{ 
                                '& .MuiOutlinedInput-root': {
                                borderRadius: 2, 
                                }
                            }}
                        />
                    )}
                />
            </Grid>

            <Grid item xs={12} md={4} mt={2}>
                <FormControl 
                    fullWidth 
                    variant="outlined" 
                    size="small"          
                    sx={{ 
                        '& .MuiOutlinedInput-root': {
                        borderRadius: 2, 
                    }
                }}>
                <InputLabel>Language</InputLabel>
                <Select
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                    label="Language"
                    size="small"
                    sx={{ 
                        '& .MuiOutlinedInput-root': {
                        borderRadius: 2, 
                    }
                    }}
                >
                    {languages.map((lang) => (
                    <MenuItem key={lang} value={lang}>
                        {lang}
                    </MenuItem>
                    ))}
                </Select>
                </FormControl>
            </Grid>

            <Grid item xs={8} mt={2}>
                <FormControl fullWidth variant="outlined">
                <InputLabel>Measurements Config</InputLabel>
                <Select
                    multiple
                    value={measurementsConfig}
                    onChange={(e) => setMeasurementsConfig(e.target.value)}
                    input={<OutlinedInput label="Measurements Config" />}
                    renderValue={(selected) => selected.map(id => {
                            const measurement = measurementsArray.find(measurement => measurement.id === id);
                            return measurement ? measurement.name : id;
                        }).join(', ')}                >
                    {measurementsArray.map((measurement) => (
                    <MenuItem key={measurement.id} value={measurement.id}>
                        <Checkbox checked={measurementsConfig.indexOf(measurement.id) > -1} />
                        <ListItemText primary={measurement.name} />
                    </MenuItem>
                    ))}
                </Select>
                </FormControl>
            </Grid>

            <Grid item xs={4} mt={2}>
                <Button variant="outlined" onClick={addStandardMeasurements} sx={{ mt: 2 }}>
                    Add Standard Measurements
                </Button>
            </Grid> 

            <Grid item xs={12} mt={2}>
                <input
                accept="image/*"
                id="logo-upload"
                type="file"
                style={{ display: 'none' }}
                onChange={(e) => handleLogoUpload(e)}
                />
                <label htmlFor="logo-upload">
                <Button 
                    variant="contained" 
                    component="span" 
                    startIcon={logo ? <Done /> : <AddPhotoAlternate />}
                >
                    Upload Logo
                </Button>
                </label>
                {logo && <Typography>{logo.name}</Typography>}
            </Grid>

            <Grid item xs={4} mt={2}>
                <Typography>Primary Color</Typography>
                <SketchPicker
                color={primaryColor}
                onChangeComplete={(color) => setPrimaryColor(color.hex)}
                />
            </Grid>

            <Grid item xs={4} mt={2}>
                <Typography>Sidenav Background Color</Typography>
                <SketchPicker
                color={sidenavBackgroundColor}
                onChangeComplete={(color) => setSidenavBackgroundColor(color.hex)}
                />
            </Grid>

            <Grid item xs={4} mt={2}>
                <Button variant="outlined" onClick={addStandardColors}>
                    Add Standard Colors
                </Button>
            </Grid>

            <Grid item xs={12} mt={2}>
                <Divider />
            </Grid>

            <Grid item xs={12} mt={2}>
                <Typography variant="h6">Clients</Typography>
            </Grid>
            {clients.map((client, index) => (
            <Grid container spacing={2} key={index} alignItems="center">
                <Grid item xs={12} mt={2}>
                    <Box display="flex" alignItems="center" justifyContent="center">
                    <TextField
                        label={`Client Name ${index + 1}`}
                        variant="outlined"
                        size="small"
                        value={client.name}
                        onChange={(e) => handleClientChange(index, e)}
                        sx={{ 
                        minWidth: '200px',
                        '& .MuiOutlinedInput-root': {
                            borderRadius: 2,
                        }
                        }}
                    />
                    <IconButton onClick={() => removeClient(index)} sx={{ ml: 1 }}>
                        <Delete sx={{color:"#A41818"}}/>
                    </IconButton>
                    </Box>
                </Grid>
            </Grid>
            ))}

            <Grid item xs={12} mt={2}>
                <Button variant="outlined" onClick={addClient}>
                    Add Client
                </Button>
            </Grid>

            <Grid item xs={12} mt={2}>
                <Divider />
            </Grid>

            <Grid item xs={12} mt={2}>
                <Typography variant="h6">Accounts</Typography>
            </Grid>

            {accounts.map((account, index) => (
                <Grid container spacing={2} key={index} alignItems="center">
                    <Grid item xs={12} md={3} mt={2}>
                        <TextField
                        label="First Name"
                        variant="outlined"
                        size="small"
                        value={account.firstName}
                        onChange={(e) => handleAccountChange(index, 'firstName', e.target.value)}
                        fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={3} mt={2}>
                        <TextField
                        label="Last Name"
                        variant="outlined"
                        size="small"
                        value={account.lastName}
                        onChange={(e) => handleAccountChange(index, 'lastName', e.target.value)}
                        fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={3} mt={2}>
                        <TextField
                        label="Email"
                        variant="outlined"
                        size="small"
                        value={account.email}
                        onChange={(e) => handleAccountChange(index, 'email', e.target.value)}
                        fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={3} mt={2}>
                        <FormControl 
                            fullWidth 
                            variant="outlined" 
                            size="small"
                            sx={{ 
                                '& .MuiOutlinedInput-root': {
                                borderRadius: 2, 
                            }
                            }}
                        >
                        <InputLabel>Client Name</InputLabel>
                        <Select
                            value={account.clientName}
                            onChange={(e) => handleAccountChange(index, 'clientName', e.target.value)}
                            label="Client Name"
                            size="small"
                            sx={{ 
                                '& .MuiOutlinedInput-root': {
                                borderRadius: 2, 
                            }
                            }}
                        >
                            {clients.map((client, i) => (
                            <MenuItem key={i} value={client.name}>
                                {client.name}
                            </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3} mt={2}>
                        <FormControl 
                            fullWidth 
                            variant="outlined" 
                            size="small"
                            sx={{ 
                                '& .MuiOutlinedInput-root': {
                                borderRadius: 2, 
                            }
                    }}
                        >
                        <InputLabel>Role</InputLabel>
                        <Select
                            value={account.role}
                            onChange={(e) => handleAccountChange(index, 'role', e.target.value)}
                            label="Role"
                            size="small"
                            sx={{ 
                                '& .MuiOutlinedInput-root': {
                                borderRadius: 2, 
                            }
                    }}
                        >
                            {roles.map((role) => (
                            <MenuItem key={role} value={role}>
                                {role}
                            </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3} mt={2}>
                        <FormControl 
                            fullWidth 
                            variant="outlined" 
                            size="small"
                            sx={{ 
                                '& .MuiOutlinedInput-root': {
                                borderRadius: 2, 
                            }
                    }}
                        >
                        <InputLabel>Permissions</InputLabel>
                        <Select
                            multiple
                            size="small"
                            value={account.permissions}
                            onChange={(e) => handleAccountChange(index, 'permissions', e.target.value)}
                            input={<OutlinedInput label="Permissions" />}
                            renderValue={(selected) => selected.join(', ')}
                            sx={{ 
                                '& .MuiOutlinedInput-root': {
                                borderRadius: 2, 
                                }
                            }}
                        >
                            {permissions.map((permission) => (
                            <MenuItem key={permission} value={permission}>
                                <Checkbox checked={account.permissions.indexOf(permission) > -1} />
                                <ListItemText primary={permission} />
                            </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} mt={2}>
                        <Button variant="outlined" onClick={() => addStandardPermissions(index)}>
                            Add Standard Permissions
                        </Button>
                    </Grid>
                    <Grid item xs={2} mt={2}>
                        <IconButton onClick={() => removeAccount(index)}>
                            <Delete sx={{color:"#A41818"}}/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                </Grid>
            ))}

            <Grid item xs={12} mt={2}>
                <Button variant="outlined" onClick={addAccount}>
                Add Account
                </Button>
            </Grid>

            {error !== "" &&
                <Grid item xs={12} mt={2}>
                    <Typography color="red">{error}</Typography>
                </Grid>
            }

            <Grid item xs={12} mt={2}>
                <LoadingButton
                variant="contained"
                color="primary"
                loading={buttonLoader}
                onClick={saveCompany}
                >
                Save Company
                </LoadingButton>
            </Grid>
        
        </Grid>
    );
    }

export default CompanyOnboarding;
