// import logo from './assets/logo.png'
import logo from "./assets/esenca_logo_monochrome_RGB.svg"

const defaultConfig = {
    "timezone": "Europe/Bucharest",
    "measurements": [
        "arms_aboveElbow_left_circumference_1",
        "body_chest_none_linear_1",
        "head_neck_none_linear_1",
        "legs_aboveKnee_left_linear_1",
        "legs_aboveKnee_right_linear_1"
    ],
    "logo": logo,
    "whiteLabel": {
      "primaryColor": "#27336e",
      "sidenavBackgroundColor": "#C0DAF3"
    }
    
    // "language": "english",
    // "billingPlan": "demo",
    // "3dAvatar": true,
    // "widget":{
    //     "widgetTexts": {},
    //     "widgetTutorial": {},
    //     "widgetRestrictions": {},
    // },
    // "logo": logo,
    // "colors":{
    //     "colorOne": "#6E78FE",
    //     "colorTwo": "#148bd3",
    // },
    // "customisation": true,
    // "measuringLink": "widget.esenca.app",
    // "measuringUnit": "metric"
}

export default defaultConfig;
  