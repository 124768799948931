import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, Card, Grid, AppBar, IconButton, Toolbar, List, ListItem, ListItemText, ListItemSecondaryAction, Divider } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { colors } from '../../../../defaultTheme';
import AddCollectionDialog from '../../../SizeCharts/components/Collections/AddCollectionDialog';
import AddChartFileDialog from '../../../SizeCharts/components/SizeCharts/AddChartFileDialog';
import AddProductDialog from '../../../SizeCharts/components/Products/AddProductDialog';

function AddItemsDialog(props) {

    const [addOption, setAddOption] = useState('');

    const handleAddOptionClick = (option) => {
        setAddOption(option);
    };

    const renderAddForm = () => {
        switch (addOption) {
            case 'sizeChart':
                return <AddChartFileDialog handleCloseDialog={props.handleCloseDialog}/>
            case 'product':
                return <AddProductDialog handleCloseDialog={props.handleCloseDialog}/>
            case 'collection':
                return <AddCollectionDialog handleCloseDialog={props.handleCloseDialog}/>
            default:
                return (
                    <Box display="flex" flexDirection="column" gap={2} alignItems="center">
                        <Button variant="outlined" onClick={() => handleAddOptionClick('sizeChart')} sx={{width:'200px'}} >Add Size Chart</Button>
                        <Button variant="outlined" onClick={() => handleAddOptionClick('product')} sx={{width:'200px'}}>Add Product</Button>
                        <Button variant="outlined" onClick={() => handleAddOptionClick('collection')} sx={{width:'200px'}}>Add Collection</Button>
                    </Box>
                );
        }
    };

    return (
        <>
            <AppBar
                sx={{
                    backgroundColor: colors.primary,
                    color: colors.white
                }}
            >
                <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h6" component="div">
                        Add Items
                    </Typography>
                    <IconButton
                        color="inherit"
                        onClick={props.handleCloseDialog}
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Grid container>
                <Grid item xs={12}>
                    <Toolbar />
                </Grid>
                {addOption === '' &&
                    <Grid item xs={12}>
                        <Button onClick={()=>{props.handleCloseDialog()}}>Back</Button>
                    </Grid>
                }
                {addOption !== '' &&
                    <Grid item xs={12}>
                        <Button onClick={()=>{setAddOption('')}}>Back</Button>
                    </Grid>
                }
                <Grid item xs={12}>
                    {renderAddForm()}
                </Grid>
            </Grid>
        </>
    );
}

export default AddItemsDialog;
