import React, { useEffect } from 'react';
import { Box, Toolbar, Typography } from '@mui/material';

import {
  mainComponentWidthDifference,
  pxToRem
} from "../../defaultTheme.js";
import HomeComponent from './components/HomeComponent.js';

const drawerWidth = 250;

function HomePage(props) {

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          ml: { sm: `${drawerWidth}px` },
          width: { sm: `calc(100% - ${drawerWidth}px)` }
        }}
      >
        <Toolbar />
        <HomeComponent />
      </Box>
    </>
  );
}

export default HomePage;
