import { useState } from "react";
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from "@mui/material";

import { borders, boxShadow, boxShadows, colors } from "../../../defaultTheme";
import { Add, Delete, Edit } from "@mui/icons-material";
import { useAuth } from "../../../AuthContext";
import { useTranslation } from "react-i18next";

import RecipientsListDialog from "./RecipientsList/RecipientsListDialog";
import DeleteRecipientsDialog from "./ManageRecipientsDialogs/DeleteRecipientsDialog";
import EditRecipientsDialog from "./ManageRecipientsDialogs/EditRecipientsDialog";


export default function ManageRecipientsComponent() {

  const {
    currentUser,
    companyId,
    companyRecipientsLists,
    setCompanyRecipientsLists,
    companyCollections,
    companyProducts,
    setPersons,
  } = useAuth();
  const { t } = useTranslation();

  const [openAddListDialog, setOpenAddListDialog] = useState(false);
  const [openDeleteRecipientsDialog, setOpenDeleteRecipientsDialog] = useState(false);
  const [openEditRecipientsDialog, setOpenEditRecipientsDialog] = useState(false);
  const [selectedRecipientsList, setSelectedRecipientsList] = useState("");

  const handleSelectRecipientsList = (event) => {
    setSelectedRecipientsList(event.target.value);
  };

  const handleOpenAddListDialog = () => {
    window.tidioChatApi.display(false);
    setOpenAddListDialog(true);
  };

  const handleCloseAddListDialog = () => {
    window.tidioChatApi.display(true);
    setOpenAddListDialog(false);
  };

  const handleOpenDeleteRecipientsDialog = () => {
    window.tidioChatApi.display(false);
    setOpenDeleteRecipientsDialog(true);
  };

  const handleCloseDeleteRecipientsDialog = () => {
    window.tidioChatApi.display(true);
    setOpenDeleteRecipientsDialog(false);
  };

  const handleOpenEditRecipientsDialog = () => {
    window.tidioChatApi.display(false);
    setOpenEditRecipientsDialog(true);
  };

  const handleCloseEditRecipientsDialog = async (edited) => {
    window.tidioChatApi.display(true);
    setOpenEditRecipientsDialog(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} mt={2}>
        <Typography variant="body1" fontWeight={'bold'}>
          {t(`scanContactsHeaderOne`)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" align="justify">
          {t(`scanContactsLabelOne`)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Card sx={{ padding: 2, borderRadius: borders.borderRadius.md, boxShadow: boxShadows.sm }}>
          <Typography variant="body1" fontWeight={'bold'}>
            {t(`scanContactsHeaderTwo`)}
          </Typography>
          <Box width={"100%"} display={'flex'} justifyContent={'space-between'} py={2}>
            <FormControl sx={{ m: 1, minWidth: 120, width: "35%" }}>
              <InputLabel>{t(`scanContactsLabelTwo`)}</InputLabel>
              <Select
                value={selectedRecipientsList}
                label={t(`scanContactsLabelTwo`)}
                onChange={handleSelectRecipientsList}
              >
                {
                  Object.keys(companyRecipientsLists).length > 0 && Object.keys(companyRecipientsLists).map((list, idx) => (
                    <MenuItem value={list} key={idx}>
                      {list}
                    </MenuItem>
                  ))
                }
                {
                  Object.keys(companyRecipientsLists).length == 0 && (
                    <MenuItem value="">
                      <em>{t(`scanContactsLabelThree`)}</em>
                    </MenuItem>
                  )
                }
              </Select>
            </FormControl>
            <Box height={'100%'} display={'flex'} sx={{alignSelf: 'center', marginLeft: 'auto', marginRight: 2}}>
              <Button
                startIcon={<Edit />}
                variant="contained"
                sx={{ height: '25%', alignSelf: "center", marginLeft: 'auto', marginRight: 2 }}
                onClick={handleOpenEditRecipientsDialog}
                disabled={!selectedRecipientsList}
              >
                {t(`Edit`)}
              </Button>
              <Button
                startIcon={<Delete />}
                variant="outlined"
                sx={{ height: '25%', alignSelf: "center", marginLeft: 'auto', marginRight: 2 }}
                onClick={handleOpenDeleteRecipientsDialog}
                disabled={!selectedRecipientsList}
              >
                {t(`Delete`)}
              </Button>
            </Box>
          </Box>
        </Card>
      </Grid>
      <RecipientsListDialog
        openAddListDialog={openAddListDialog}
        handleCloseAddListDialog={handleCloseAddListDialog}
      />
      <DeleteRecipientsDialog
        openDeleteRecipientsDialog={openDeleteRecipientsDialog}
        handleCloseDeleteRecipientsDialog={handleCloseDeleteRecipientsDialog}
        selectedRecipientsList={selectedRecipientsList}
        setSelectedRecipientsList={setSelectedRecipientsList}
      />
      <EditRecipientsDialog
        openEditRecipientsDialog={openEditRecipientsDialog}
        handleCloseEditRecipientsDialog={handleCloseEditRecipientsDialog}
        selectedRecipientsList={selectedRecipientsList}
      />
    </Grid>
  );
}