import React, { useEffect, useState } from 'react';
import { Typography, Grid, TextField, Button, FormControl, InputLabel, MenuItem, Select, Checkbox, ListItemText, Box,
     Divider, IconButton, OutlinedInput, Autocomplete } from '@mui/material';
import { LoadingButton } from "@mui/lab";
import { AddPhotoAlternate, Delete, Done } from '@mui/icons-material';

import { useAuth } from '../../../../AuthContext';
import { roles, permissions, standardPermissionsToAdd } from '../../configs';

const database_url = process.env.REACT_APP_DATABASE_URL;

// TODO: add custom clients 

function CreateAccounts() {
    const { currentUser, adminCompanies, userId, handleSnackbar } = useAuth();

    const [clients, setClients] = useState([]);
    const [accounts, setAccounts] = useState([]);

    const [selectedCompanyId, setSelectedCompanyId] = useState('');

    const [error, setError] = useState("");
    const [buttonLoader, setButtonLoader] = useState(false);

    const handleCompanyChange = (event) => {
        const companyId = event.target.value;
        setSelectedCompanyId(companyId);
        const companyClientsObj = adminCompanies[companyId]?.clients || {};
        const companyClientsArray = Object.entries(companyClientsObj).map(([id, details]) => ({ id, ...details })); 
        setClients(companyClientsArray);
    };

    const handleAccountChange = (index, field, value) => {
        const newAccounts = accounts.map((account, i) => {
        if (i === index) {
            return { ...account, [field]: value };
        }
        return account;
        });
        setAccounts(newAccounts);
    };

    const addAccount = () => {
        setAccounts([...accounts, { firstName: '', lastName: '', email: '', clientId: '', role: '', permissions: [] }]);
    };

    const removeAccount = (index) => {
        setAccounts(accounts.filter((_, i) => i !== index));
    };

    const addStandardPermissions = (index) => {
        const newAccounts = accounts.map((account, i) => {
            if (i === index) {
                return { ...account, permissions: Array.from(new Set([...account.permissions, ...standardPermissionsToAdd])) };
            }
            return account;
        });
        setAccounts(newAccounts);
    };

    const validateAccounts = (accounts) => {
        return accounts.every(account => {
            const hasRequiredFields = account.firstName && account.lastName && account.email && account.role && Array.isArray(account.permissions) && account.permissions.length > 0;
            const hasClientRole = account.role === 'client';
            const hasClientId = account.clientId;
    
            if (hasClientRole) {
                return hasRequiredFields && hasClientId;
            } else {
                return hasRequiredFields;
            }
        });
    };

    const saveAccounts = async () => {
        setButtonLoader(true)
        setError("");
        const accessToken = await currentUser.getIdToken(true);

        if (!validateAccounts(accounts)) {
            setError("Check accounts - missing fields");
            setButtonLoader(false);
        } else if(accounts.length>0) {
            const companyData = {
                users: accounts,
            };

            fetch(database_url + "/create_accounts?user_id=" + userId + '&company_id=' + selectedCompanyId, {
                method: "PUT",
                headers: { "Accept": "application/json", "Content-Type": 'application/json', 'x-access-token': accessToken },
                body: JSON.stringify(companyData)
              }).then(() => {
                handleSnackbar('success', "Accounts added");
                setButtonLoader(false)
                // TODO: retrieve these accounts from db
              });
        } else {
            setButtonLoader(false)
            setError("Missing accounts")
        }
     
    };

    return (
        <Grid container spacing={2} align="center">
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel id="company-select-label">Select Company</InputLabel>
                    <Select
                        labelId="company-select-label"
                        value={selectedCompanyId}
                        label="Select Company"
                        onChange={handleCompanyChange}
                    >
                        {adminCompanies && Object.keys(adminCompanies).map((companyId) => (
                            <MenuItem key={companyId} value={companyId}>
                                {adminCompanies[companyId].config.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            {selectedCompanyId &&
            <>
                <Grid item xs={12} mt={2}>
                    <Typography variant="h6">Accounts</Typography>
                </Grid>

                {accounts.map((account, index) => (
                    <Grid container spacing={2} key={index} alignItems="center">
                        <Grid item xs={12} md={3} mt={2}>
                            <TextField
                            label="First Name"
                            variant="outlined"
                            size="small"
                            value={account.firstName}
                            onChange={(e) => handleAccountChange(index, 'firstName', e.target.value)}
                            fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={3} mt={2}>
                            <TextField
                            label="Last Name"
                            variant="outlined"
                            size="small"
                            value={account.lastName}
                            onChange={(e) => handleAccountChange(index, 'lastName', e.target.value)}
                            fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={3} mt={2}>
                            <TextField
                            label="Email"
                            variant="outlined"
                            size="small"
                            value={account.email}
                            onChange={(e) => handleAccountChange(index, 'email', e.target.value)}
                            fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={3} mt={2}>
                            <FormControl 
                                fullWidth 
                                variant="outlined" 
                                size="small"
                                sx={{ 
                                    '& .MuiOutlinedInput-root': {
                                    borderRadius: 2, 
                                }
                                }}
                            >
                            <InputLabel>Client Name</InputLabel>
                            <Select
                                value={account.clientId}
                                onChange={(e) => handleAccountChange(index, 'clientId', e.target.value)}
                                label="Client Name"
                                size="small"
                                sx={{ 
                                    '& .MuiOutlinedInput-root': {
                                    borderRadius: 2, 
                                }
                                }}
                            >
                                {clients.map((client, i) => (
                                <MenuItem key={i} value={client.id}>
                                    {client.name}
                                </MenuItem>
                                ))}
                            </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3} mt={2}>
                            <FormControl 
                                fullWidth 
                                variant="outlined" 
                                size="small"
                                sx={{ 
                                    '& .MuiOutlinedInput-root': {
                                    borderRadius: 2, 
                                }
                        }}
                            >
                            <InputLabel>Role</InputLabel>
                            <Select
                                value={account.role}
                                onChange={(e) => handleAccountChange(index, 'role', e.target.value)}
                                label="Role"
                                size="small"
                                sx={{ 
                                    '& .MuiOutlinedInput-root': {
                                    borderRadius: 2, 
                                }
                        }}
                            >
                                {roles.map((role) => (
                                <MenuItem key={role} value={role}>
                                    {role}
                                </MenuItem>
                                ))}
                            </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3} mt={2}>
                            <FormControl 
                                fullWidth 
                                variant="outlined" 
                                size="small"
                                sx={{ 
                                    '& .MuiOutlinedInput-root': {
                                    borderRadius: 2, 
                                }
                        }}
                            >
                            <InputLabel>Permissions</InputLabel>
                            <Select
                                multiple
                                size="small"
                                value={account.permissions}
                                onChange={(e) => handleAccountChange(index, 'permissions', e.target.value)}
                                input={<OutlinedInput label="Permissions" />}
                                renderValue={(selected) => selected.join(', ')}
                                sx={{ 
                                    '& .MuiOutlinedInput-root': {
                                    borderRadius: 2, 
                                    }
                                }}
                            >
                                {permissions.map((permission) => (
                                <MenuItem key={permission} value={permission}>
                                    <Checkbox checked={account.permissions.indexOf(permission) > -1} />
                                    <ListItemText primary={permission} />
                                </MenuItem>
                                ))}
                            </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4} mt={2}>
                            <Button variant="outlined" onClick={() => addStandardPermissions(index)}>
                                Add Standard Permissions
                            </Button>
                        </Grid>
                        <Grid item xs={2} mt={2}>
                            <IconButton onClick={() => removeAccount(index)}>
                                <Delete sx={{color:"#A41818"}}/>
                            </IconButton>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                    </Grid>
                ))}

                <Grid item xs={12} mt={2}>
                    <Button variant="outlined" onClick={addAccount}>
                        Add Account
                    </Button>
                </Grid>

                {error !== "" &&
                    <Grid item xs={12} mt={2}>
                        <Typography color="red">{error}</Typography>
                    </Grid>
                }

                <Grid item xs={12} mt={2}>
                    <LoadingButton
                    variant="contained"
                    color="primary"
                    loading={buttonLoader}
                    onClick={saveAccounts}
                    >
                        Save Accounts
                    </LoadingButton>
                </Grid>
            </>
            }
        </Grid>
    );
    }

export default CreateAccounts;

