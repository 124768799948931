import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  FormGroup,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Switch,
  IconButton,
  TextField,
  ListItemText,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import {
  Add,
  Edit,
  Email,
  Message,
  Send,
} from "@mui/icons-material";
import { useAuth } from "../../../AuthContext";
import { useTranslation } from "react-i18next";

import template_email from "../../../assets/template_email.png";
import template_sms from "../../../assets/template_sms.png";
import { borders, boxShadow, boxShadows, colors } from "../../../defaultTheme"

import RecipientsListDialog from "./RecipientsList/RecipientsListDialog";
import DisplayGrid from "./RecipientsList/DisplayGrid";

import { useParams, useNavigate, useLocation } from 'react-router-dom';

const database_url = process.env.REACT_APP_DATABASE_URL;

export default function InviteComponent() {
  const {
    currentUser,
    companyId,
    companyRecipientsLists,
    companyCollections,
    companyProducts,
    handleSnackbar
  } = useAuth();
  const { t } = useTranslation();

  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [openAddListDialog, setOpenAddListDialog] = useState(false);
  const [selectedRecipientsList, setSelectedRecipientsList] = useState("");
  const [selectedCollections, setSelectedCollections] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);
  const [collection, setCollection] = useState(false);

  const [companyCollectionNames, setCompanyCollectionNames] = useState([]);
  const [companyProductNames, setCompanyProductNames] = useState([]);

  const [inviteType, setInviteType] = useState({
    email: false,
    sms: false,
  });
  const { email, sms } = inviteType;

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { tab, dialog } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    switch (dialog) {
      case 'add-list':
        setOpenAddListDialog(true);
        break;
      default:
        setOpenAddListDialog(false);
        break;
    }
  }, [dialog]);

  const handleSelectRecipientsList = (event) => {
    setSelectedRecipientsList(event.target.value);
  };

  const handleOpenAddListDialog = () => {
    window.tidioChatApi.display(false);
    setOpenAddListDialog(true);
    navigate(`/send-scan-link/${tab}/add-list`); 
  }

  const handleCloseAddListDialog = () => {
    window.tidioChatApi.display(true);
    setOpenAddListDialog(false);
    navigate(`/send-scan-link/${tab}`); 
  }

  const handleChangeInviteType = (event) => {
    setInviteType({
      ...inviteType,
      [event.target.name]: event.target.checked,
    });
  };

  const handleActivateCollection = (event) => {
    setCollection(event.target.checked);
  }

  const handleSelectCollections = (event) => {
    setSelectedCollections(event.target.value);
  };

  const handleSelectProducts = (event) => {
    setSelectedProducts(event.target.value);
  };

  const handleSendInvites = async () => {
    let accessPoint = [];
    Object.keys(inviteType).map((ap) => {
      if (inviteType[ap]) {
        accessPoint.push(ap);
      }
    });

    setIsLoading(true);
    const accessToken = await currentUser.getIdToken(true);
    let response = await fetch(database_url + "/send_multiple_invites?user_id=" + currentUser.uid, {
      method: 'POST',
      headers: { "Content-Type": 'application/json', "Accept": 'application/json', 'x-access-token': accessToken },
      body: JSON.stringify({
        "recipients": selectedRows,
        "recipientsListName": selectedRecipientsList,
        "maksId": currentUser.uid,
        "maksEmail": currentUser.email,
        companyId,
        accessPoint,
        "collections": selectedCollections,
        "products": selectedProducts
      })
    });

    let result = await response.json();

    setIsLoading(false);
    if (result['success']) {
      handleSnackbar('success', 'Invitations sent successfully!');
    } else{
      handleSnackbar('error', 'Error.')
    }

  }

  useEffect(() => {
    if (Object.keys(companyRecipientsLists).length > 0) {
      setSelectedRecipientsList(Object.keys(companyRecipientsLists)[0])
    }
  }, [companyRecipientsLists]);

  return (
    <Grid container mt={2}>
      <Grid item xs={12} mt={2}>
        <Grid container width={"100%"} spacing={2}>
          <Backdrop
            open={isLoading}
            sx={{
              color: colors.white,
              zIndex: (theme) => theme.zIndex.drawer + 1 
            }}
          >
            <CircularProgress size={72} sx={{ color: colors.white, }}/>
          </Backdrop>
          <Grid item xs={8}>
            <Card sx={{ padding: 2, borderRadius: borders.borderRadius.md, boxShadow: boxShadows.sm }}>
              <Typography variant="body1" fontWeight={'bold'}>
                {t(`scanHeaderSix`)}
              </Typography>
              <Box width={"100%"} display={'flex'} justifyContent={'space-between'} py={2}>
                <FormControl sx={{ m: 1, minWidth: 120, width: "35%" }}>
                  <InputLabel> {t(`scanLabelFour`)}</InputLabel>
                  <Select
                    value={selectedRecipientsList}
                    label= {t(`scanLabelFour`)}
                    onChange={handleSelectRecipientsList}
                  >
                    {
                      Object.keys(companyRecipientsLists).length > 0 && Object.keys(companyRecipientsLists).map((list, idx) => (
                        <MenuItem value={list} key={idx}>
                          {list}
                        </MenuItem>
                      ))
                    }
                    {
                      Object.keys(companyRecipientsLists).length == 0 && (
                        <MenuItem value="">
                          <em> {t(`scanLabelFive`)}</em>
                        </MenuItem>
                      )
                    }
                  </Select>
                </FormControl>
                <Button
                  startIcon={<Add />}
                  variant="contained"
                  sx={{ height: '25%', alignSelf: "center", marginLeft: 'auto', marginRight: 2 }}
                  onClick={handleOpenAddListDialog}
                >
                   {t(`scanButtonFour`)}
                </Button>
              </Box>
            </Card>
          </Grid>
          {
            selectedRecipientsList && (
              <>
                <Grid item xs={4}>
                  <Card sx={{ padding: 2, height: '100%', borderRadius: borders.borderRadius.md, boxShadow: boxShadows.sm }}>
                    <Typography variant="body1" fontWeight={'bold'}>
                      {t(`scanHeaderSeven`)}
                    </Typography>
                    <Box width={"100%"} height={'100%'} display={'flex'} justifyContent={'space-around'} px={5} py={2}>
                      <FormControlLabel control={<Checkbox checked={email} onClick={handleChangeInviteType} name="email" />} label="Email" sx={{ width: '25%' }} />
                      <FormControlLabel control={<Checkbox checked={sms} onClick={handleChangeInviteType} name="sms" />} label="SMS" sx={{ width: '25%' }} />
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card sx={{ padding: 2, height: '100%', borderRadius: borders.borderRadius.md, boxShadow: boxShadows.sm }}>
                    <Typography variant="body1" fontWeight={'bold'}>
                      {t(`scanHeaderEight`)}
                    </Typography>
                    <Typography variant="body2">
                      {t(`scanLabelSix`)}
                    </Typography>
                    <Grid container m={1} px={5}>
                      <Grid item xs={1}>
                        <FormControl component="div" sx={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
                          <FormControlLabel
                            value="start"
                            control={<Switch checked={collection} onClick={handleActivateCollection} name="collection" />}
                            label={collection ? t(`On`) : t(`Off`)}
                            labelPlacement="start"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={11}>
                        <Box width={"100%"} display={'flex'} justifyContent={'flex-end'}>
                          <FormControl sx={{ m: 1, minWidth: 60, width: "25%" }}>
                            <InputLabel>{t(`Collections`)}</InputLabel>
                            <Select
                              value={selectedCollections}
                              label={t(`Collections`)}
                              onChange={handleSelectCollections}
                              disabled={!collection}
                              multiple
                              renderValue={(selected) => {
                                let rendered = [];
                                selected.forEach((item) => {
                                  rendered.push(companyCollections[item].name);
                                })
                                return rendered.join(', ');
                              }}
                            >
                              {
                                Object.keys(companyCollections).length > 0 && Object.keys(companyCollections).map((list, idx) => (
                                  <MenuItem value={list} key={idx}>
                                    <Checkbox checked={selectedCollections.indexOf(list) > -1} />
                                    <ListItemText primary={companyCollections[list].name} />
                                  </MenuItem>
                                ))
                              }
                              {
                                Object.keys(companyCollections).length == 0 && (
                                  <MenuItem value="">
                                    <em>{t(`scanLabelSeven`)}</em>
                                  </MenuItem>
                                )
                              }
                            </Select>
                          </FormControl>
                          <FormControl sx={{ m: 1, minWidth: 60, width: "25%" }}>
                            <InputLabel>{t(`Products`)}</InputLabel>
                            <Select
                              value={selectedProducts}
                              label={t(`Products`)}
                              onChange={handleSelectProducts}
                              disabled={!collection}
                              multiple
                              renderValue={(selected) => {
                                let rendered = [];
                                selected.forEach((item) => {
                                  rendered.push(companyProducts[item].name);
                                })
                                return rendered.join(', ');
                              }}
                            >
                              {
                                Object.keys(companyProducts).length > 0 && Object.keys(companyProducts).map((list, idx) => {
                                  if (companyProducts[list]?.variations) {
                                    return (
                                      <MenuItem value={list} key={idx}>
                                        <Checkbox checked={selectedProducts.indexOf(list) > -1} />
                                        <ListItemText primary={companyProducts[list].name} />
                                      </MenuItem>
                                    )
                                  }
                                })
                              }
                              {
                                Object.keys(companyProducts).length == 0 && (
                                  <MenuItem value="">
                                    <em>{t(`scanLabelSeven`)}</em>
                                  </MenuItem>
                                )
                              }
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card sx={{ padding: 2, height: '100%', borderRadius: borders.borderRadius.md, boxShadow: boxShadows.sm }}>
                    <Typography variant="body1" fontWeight={'bold'}>
                      {t(`scanHeaderNine`)}
                    </Typography>
                    <DisplayGrid
                      rowData={companyRecipientsLists[selectedRecipientsList]}
                      selectedRows={selectedRows}
                      handleSelectionChange={setSelectedRows}
                    />
                  </Card>
                </Grid>
                <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
                  <Button
                    variant="contained"
                    sx={{ height: '25%' }}
                    startIcon={<Send />}
                    disabled={!((email || sms) && selectedRows.length > 0)}
                    onClick={handleSendInvites}
                  >
                    {t(`Send`)}
                  </Button>
                </Grid>
              </>
            )
          }
          <RecipientsListDialog
            openAddListDialog={openAddListDialog}
            handleCloseAddListDialog={handleCloseAddListDialog}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}