import { useState, useEffect } from 'react';

import { Box,  Card, Tabs, Tab,} from '@mui/material';

import CustomTabPanel from './CustomTabPanel';
import QuickScanComponent from './QuickScanComponent';
import InviteComponent from './InviteComponent';
import MeasurementLinkComponent from './MeasurementLinkComponent';
import ManageRecipientsComponent from './ManageRecipientsComponent';

import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from 'react-router-dom';

export default function OptionsComponent() {
  const { t } = useTranslation();
  const { tab } = useParams(); 
  const navigate = useNavigate();

  const [currentOption, setCurrentOption] = useState('quick-scan')

  useEffect(() => {
    if (tab) {
      setCurrentOption(tab);
    } else {
      setCurrentOption("quick-scan"); 
    }
  }, [tab]);

  const handleOptionChange = (event, newValue) => {
    setCurrentOption(newValue);
    navigate(`/send-scan-link/${newValue}`);
  }

  return (
    <Card>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={currentOption} onChange={handleOptionChange} aria-label="basic tabs example">
          <Tab label={t(`Quick Scan`)} value={'quick-scan'} />
          <Tab label={t(`Send Invites`)} value={'send-invites'} />
          <Tab label={t(`Measurement Link`)} value={'measurement-link'} />
          <Tab label={t(`Manage Contacts`)} value={'manage-contacts'} />
        </Tabs>
      </Box>
      <CustomTabPanel value={'quick-scan'} currentOption={currentOption} index={0}>
        <QuickScanComponent />
      </CustomTabPanel>
      <CustomTabPanel value={'send-invites'} currentOption={currentOption} index={1}>
        <InviteComponent />
      </CustomTabPanel>
      <CustomTabPanel value={'measurement-link'} currentOption={currentOption} index={2}>
        <MeasurementLinkComponent />
      </CustomTabPanel>
      <CustomTabPanel value={'manage-contacts'} currentOption={currentOption} index={3}>
        <ManageRecipientsComponent />
      </CustomTabPanel>
    </Card>
  )
}