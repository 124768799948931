import './App.css';

import { useState, useEffect } from 'react';

import { Route, Routes } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import defaultTheme from './defaultTheme';

import PrivateRoute from './PrivateRoute';

import Navigation from './components/Navigation';
import SignIn from './components/SignIn';
import HomePage from './components/Home';
import MeasurementsPage from './components/Measurements';
import SettingsPage from './components/Settings';
import HelpPage from './components/Help';
import SizeChartsPage from './components/SizeCharts';
import SendScanLinkPage from './components/SendScanLink';
import AdministrationPage from './components/Administration';

import AdminOnboardingPage from './components/Admin/Onboarding';
import AdminCompanyDataPage from './components/Admin/CompanyData';

import { TimeoutLogic } from './components/utils/TimeoutLogic';
import SnackbarEdw from './components/SnackbarEdw';

import { useAuth } from './AuthContext';

function App() {

  const { whiteLabel } = useAuth();

  return (
    <ThemeProvider theme={createTheme(defaultTheme(whiteLabel.primaryColor))}>
     <CssBaseline/>
      <Routes>
          <Route path="/home" exact element={
            <PrivateRoute>
              <Navigation/>
              <HomePage />
            </PrivateRoute>
          }/>
          <Route path="/administration/:tab/:dialog?" exact element={
            <PrivateRoute>
              <Navigation/>
              <AdministrationPage/>
            </PrivateRoute>
          }/>
          <Route path="/measurements/:tab/:dialog?" exact element={
            <PrivateRoute>
              <Navigation/>
              <MeasurementsPage/>
            </PrivateRoute>
          }/>
          <Route path="/settings" exact element={
            <PrivateRoute>
              <Navigation/>
              <SettingsPage/>
            </PrivateRoute>
          }/>
          <Route path="/help/:tab" exact element={
            <PrivateRoute>
              <Navigation/>
              <HelpPage />
            </PrivateRoute>
          }/>
          <Route path="/size-charts/:tab/:dialog?" exact element={
            <PrivateRoute>
              <Navigation/>
              <SizeChartsPage/>
            </PrivateRoute>
          }/>
          <Route path="/send-scan-link/:tab/:dialog?" exact element={
            <PrivateRoute>
              <Navigation/>
              <SendScanLinkPage/>
            </PrivateRoute>
          }/>
          {/* admin */}
          <Route path="/admin/onboarding/:tab" exact element={
            <PrivateRoute>
              <Navigation/>
              <AdminOnboardingPage/>
            </PrivateRoute>
          }/>
          <Route path="/admin/company-data" exact element={
            <PrivateRoute>
              <Navigation/>
              <AdminCompanyDataPage/>
            </PrivateRoute>
          }/>
          <Route path="/sign-in" exact element={<SignIn/>}/>
          <Route path="*" element={<SignIn />} />
      </Routes>
      <TimeoutLogic/>
      <SnackbarEdw />
    </ThemeProvider>
  );
}

export default App;
