import { useEffect, useState } from "react";
import {
  AppBar, Toolbar, Typography, TextField, Grid, TableContainer, TableHead, Table, TableCell, TableRow, TableBody, Paper, IconButton, Button,
  Box, InputLabel, Divider
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { Edit, ReportProblem as ReportProblemIcon } from "@mui/icons-material";

import { useAuth } from "../../../../AuthContext";
import { useTranslation } from "react-i18next";
import { colors } from "../../../../defaultTheme";

const database_url = process.env.REACT_APP_DATABASE_URL;

function CollectionDetailsDialog(props) {
  const { userId, companyId, companyProducts, currentUser, setCompanyCollections, handleSnackbar, companyCollections, setCompanyProducts } = useAuth();
  const { t } = useTranslation();

  const [edit, setEdit] = useState(false);
  const [originalCollection, setOriginalCollection] = useState({})

  const [initialProducts, setInitialProducts] = useState([]);
  const [availableProducts, setAvailableProducts] = useState([]);
  const [collectionName, setCollectionName] = useState("");
  const [editedCollectionName, setEditedCollectionName] = useState("");
  const [editedProducts, setEditedProducts] = useState([]);
  const [duplicatedNameFlag, setDuplicatedNameFlag] = useState(false)

  const [buttonLoader, setButtonLoader] = useState(false);

  useEffect(() => {
    if (props.collection) {
      if (companyCollections && companyCollections[props.collection]){
        const initialCollection = companyCollections[props.collection]
        initialCollection.id = props.collection
        setOriginalCollection(initialCollection)

        if (initialCollection.name) {
          setCollectionName(initialCollection.name);
          setEditedCollectionName(initialCollection.name)
        }
  
        for (const key in companyProducts) {
          if (companyProducts.hasOwnProperty(key)) {
            companyProducts[key].id = key;
          }
        }
  
        const initialProductsList = Object.values(companyProducts).filter(
          (product) => product.collection === props.collection
        );
  
        setInitialProducts(initialProductsList);
  
        const availableProductsList = Object.values(companyProducts).filter(
          (product) => !product.collection
        );
  
        setAvailableProducts(availableProductsList);
      }
    }
  }, [props.collection, companyProducts, companyCollections]);

  useEffect(()=>{
    if(companyCollections){
      const collectionsNames = [];
      for (const item in companyCollections) {
          if (companyCollections[item].name && item !== originalCollection.id) {
              const name = companyCollections[item]?.name
              const lowercase = name?.toLowerCase()
              if (!collectionsNames.includes(lowercase)) {
                collectionsNames.push(lowercase);
              }
          }
      }
      const isNewNameInArray = collectionsNames.includes(editedCollectionName?.toLowerCase());
      if(isNewNameInArray){
        setDuplicatedNameFlag(true)
      } else {
        setDuplicatedNameFlag(false)
      }
    }
  },[companyCollections, editedCollectionName])

  const handleEdit = () => {
    setEditedCollectionName(collectionName);
    setEditedProducts([...initialProducts]);
    setEdit(true);
  };

  const handleProductDelete = (productId) => {
    const updatedProducts = editedProducts.filter(
      (product) => product.id !== productId
    );
    setEditedProducts(updatedProducts);

    const deletedProduct = Object.values(companyProducts).find(
      (product) => product.id === productId
    );
    setAvailableProducts([...availableProducts, deletedProduct]);
  };

  const handleProductAdd = (productId) => {
    const updatedProducts = availableProducts.filter(
      (product) => product.id !== productId
    );
    setAvailableProducts(updatedProducts);

    const addedProduct = Object.values(companyProducts).find(
      (product) => product.id === productId
    );
    setEditedProducts([...editedProducts, addedProduct]);
  };

  const handleCancelEdit = () => {
    const availableProductsList = Object.values(companyProducts).filter(
      (product) => !product.collection
    );

    setAvailableProducts(availableProductsList);
    setEditedCollectionName(collectionName);
    setEditedProducts([]);
    setEdit(false);
  };

  const handleSaveEdit = async () => {
    const accessToken = await currentUser.getIdToken(true);
    setButtonLoader(true)

    const deletedIds = initialProducts
      .filter((initialProduct) => !editedProducts.find((finalProduct) => finalProduct.id === initialProduct.id))
      .map((deletedProduct) => deletedProduct.id);

    const addedIds = editedProducts
      .filter((finalProduct) => !initialProducts.find((initialProduct) => initialProduct.id === finalProduct.id))
      .map((addedProduct) => addedProduct.id);

    if(duplicatedNameFlag && editedCollectionName!==collectionName){
      handleSnackbar('error', 'Please re-name your collection.');
      setButtonLoader(false)
    } else if(editedCollectionName === ""){
      handleSnackbar('error', 'Please re-name your collection.');
      setButtonLoader(false)
    } else  if (companyId) {
      let send_data = { collection_id: props.collection, collection_name: editedCollectionName, deleted_ids: deletedIds, added_ids: addedIds };

      fetch(database_url + "/update_company_collection?user_id=" + userId + '&company_id=' + companyId, {
        method: "PUT",
        headers: { "Accept": "application/json", "Content-Type": 'application/json', 'x-access-token': accessToken },
        body: JSON.stringify(send_data)
      }).then(() => {
        setButtonLoader(false)

        let promises = Promise.all([
          fetch(database_url + '/view_company_collections?user_id=' + userId + '&company_id=' + companyId, {
            method: 'GET',
            headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
          }),
          fetch(database_url + '/view_company_products?user_id=' + userId + '&company_id=' + companyId, {
            method: 'GET',
            headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
          }),
        ]);

        promises
        .then((results) => Promise.all(results.map((r) => r.json())))
        .then((resultsData) => {
          const [companyCollections, companyProducts] = resultsData;
          setCompanyCollections(companyCollections || {});
          setCompanyProducts(companyProducts || {})
        }).then(()=>{
          setCollectionName(editedCollectionName)
          setInitialProducts(editedProducts)

          handleSnackbar('success', t(`colSnack`));
          setEdit(false)
        })
      });
    } else {
      setButtonLoader(false)
    }
  };


  return (
    <>
      <AppBar
        sx={{
          backgroundColor: colors.primary,
          color: colors.white
        }}
      >
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" component="div">
            {t(`Collection`)}: {collectionName}
          </Typography>
          <IconButton color="inherit" onClick={props.handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Grid container>
        <Grid item xs={12}>
          <Toolbar />
        </Grid>
        <Grid item container xs={12} mt={2}>
          {edit === false ? (
            <>
              <Grid item xs={4} mt={2}>
                <Box display={'flex'} sx={{ width: '100%' }} justifyContent={'space-between'}>
                  <Typography variant="body1" sx={{ alignSelf: 'center', mr: 2, fontWeight: 'bold' }}>{t(`Collection Name`)}</Typography>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    fullWidth
                    // variant="filled"
                    value={collectionName}
                    disabled
                    sx={{ width: { xs: '90%', md: '40%' } }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
              {duplicatedNameFlag && 
                  <Box display="flex" alignItems="left" mb={1}>
                      <ReportProblemIcon color="warning" />
                      <Box ml={1}>
                    <Typography variant="body2" sx={{fontWeight:'bold'}}>
                      Your company already has collection(s) that share identical name.
                    </Typography>
                    </Box>
                  </Box>
                }
              </Grid>
              <Grid item xs={12} mt={2} display={'flex'} justifyContent={'flex-end'}>
                <Button
                  onClick={handleEdit}
                  variant="contained"
                  startIcon={
                    <Edit />
                  }
                >
                  {t(`Edit`)}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} mt={2}>
                {
                  initialProducts.length > 0 ? (
                    <Typography variant="body1" fontWeight={'bold'}>
                      {t(`colLabelThree`)}
                    </Typography>
                  ) : (
                    <>
                      <Typography variant="body1" fontWeight={'bold'}>
                        {t(`colLabelFour`)}
                      </Typography>
                      <Typography variant="body2" fontWeight={'bold'}>
                        {t(`colLabelFive`)}
                      </Typography>
                    </>
                  )
                }
              </Grid>
              <Grid item xs={12} mt={2} px={10}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead sx={{ backgroundColor: colors.primary }}>
                      <TableRow>
                        <TableCell align="center" sx={{ color: colors.white, fontWeight: 'bold' }}>{t(`Product Name`)}</TableCell>
                        <TableCell align="center" sx={{ color: colors.white, fontWeight: 'bold' }}>{t(`Product Image`)}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {initialProducts.map((product) => (
                        <TableRow key={product.id}>
                          <TableCell align="center">{product.name}</TableCell>
                          <TableCell align="center">
                            {
                              product.image ? (
                                <img src={product.image} style={{width:'100px'}}/>
                              ) : (
                                t(`colLabelSix`)
                              )
                            }
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={4} mt={2}>
                <Box display={'flex'} sx={{ width: '100%' }} justifyContent={'space-between'}>
                  <Typography variant="body1" sx={{ alignSelf: 'center', mr: 2, fontWeight: 'bold' }}>{t(`Collection Name`)}</Typography>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    fullWidth
                    value={editedCollectionName}
                    onChange={(event) =>
                      setEditedCollectionName(event.target.value)
                    }
                    sx={{ width: { xs: '90%', md: '40%' } }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                {duplicatedNameFlag && 
                    <Box display="flex" alignItems="left" mb={1}>
                        <ReportProblemIcon color="warning" />
                        <Box ml={1}>
                      <Typography variant="body2" sx={{fontWeight:'bold'}}>
                        Your company already has collection(s) that share identical name.
                      </Typography>
                      </Box>
                    </Box>
                  }
              </Grid>
              <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    onClick={handleCancelEdit}
                    sx={{ margin: "0 25px", width: "150px" }}
                    variant="outlined"
                  >
                    {t(`Cancel`)}
                  </Button>
                  <LoadingButton
                      onClick={handleSaveEdit}
                      sx={{ margin: "0 25px", width: "150px" }}
                      loading={buttonLoader}
                      variant="contained"
                  >
                    {t(`Save`)}
                  </LoadingButton>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} mt={2} align="left">
                {
                  editedProducts.length > 0 ? (
                    <Typography variant="body1" fontWeight={'bold'}>{t(`colLabelSeven`)}</Typography>
                  ) : (
                    <>
                      <Typography variant="body1" fontWeight={'bold'}>{t(`colLabelEight`)}</Typography>
                    </>
                  )
                }
              </Grid>
              <Grid item xs={12} mt={2} px={10}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead sx={{ backgroundColor: colors.primary }}>
                      <TableRow>
                        <TableCell align="center" sx={{ color: colors.white, fontWeight: 'bold' }}>{t(`Product Name`)}</TableCell>
                        <TableCell align="center" sx={{ color: colors.white, fontWeight: 'bold' }}>{t(`Product Image`)}</TableCell>
                        <TableCell align="center" sx={{ color: colors.white, fontWeight: 'bold' }}>{t(`Action`)}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {editedProducts.map((product) => (
                        <TableRow key={product.id}>
                          <TableCell align="center">{product.name}</TableCell>
                          <TableCell align="center">
                            <img src={product.image ? product.image : ""} style={{width:'100px'}}/></TableCell>
                          <TableCell align="center">
                            <Button
                              onClick={() => handleProductDelete(product.id)}
                              variant="outlined"
                              sx={{ width: '150px' }}
                            >
                              {t(`Delete`)}
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} mt={2} align="left">
                {
                  availableProducts.length > 0 ? (
                    <Typography variant="body1" fontWeight={'bold'}>{t(`colLabelNine`)}</Typography>
                  ) : (
                    <>
                      <Typography variant="body1" fontWeight={'bold'}>{t(`colLabelTen`)}</Typography>
                      <Typography variant="body2">{t(`colLabelEleven`)}</Typography>
                    </>
                  )
                }
              </Grid>
              <Grid item xs={12} mt={2} px={10}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead sx={{ backgroundColor: colors.primary }}>
                      <TableRow>
                        <TableCell align="center" sx={{ color: colors.white, fontWeight: 'bold' }}>{t(`Product Name`)}</TableCell>
                        <TableCell align="center" sx={{ color: colors.white, fontWeight: 'bold' }}>{t(`Product Image`)}</TableCell>
                        <TableCell align="center" sx={{ color: colors.white, fontWeight: 'bold' }}>{t(`Action`)}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {availableProducts.map((product) => (
                        <TableRow key={product.id}>
                          <TableCell align="center">{product.name}</TableCell>
                          <TableCell align="center">
                            <img src={product.image ? product.image : ""} style={{width:'100px'}}/></TableCell>
                          <TableCell align="center">
                            <Button
                              onClick={() => handleProductAdd(product.id)}
                              variant="outlined"
                              sx={{ width: '150px' }}
                            >
                              {t(`Add`)}
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default CollectionDetailsDialog;
