import { useEffect } from "react";
// import { onTidioChatApiReady, tidioSetupVisitor } from "./functions";
import { useAuth } from "../../AuthContext";

const tidioUrl = process.env.REACT_APP_TIDIO_URL;

export default function TidioWrapper() {
  const { currentUser, chatApiObject, setChatApiObject } = useAuth();


  const chatApiReady = () => {
    deleteAllCookies();
  }

  function deleteAllCookies() {
    const pk = "hkysvq2xjvaxjvljzjj3t1eusuivbpkf";
    const stateKey = "tidio_state_" + pk;
    if (localStorage.getItem(stateKey)) {
      const state = JSON.parse(localStorage.getItem(stateKey));
      const newState = {...state, messages: []}
      localStorage.setItem(stateKey, JSON.stringify(newState));
    }

    if (localStorage.getItem(stateKey + "_cache")) {
      const state = JSON.parse(localStorage.getItem(stateKey + "_cache"));
      localStorage.removeItem(stateKey + "_cache")
    }

    localStorage.clear();
  }

  const handleSetupVisitors = (currentUser) => {
    if (currentUser) {
      window.tidioChatApi.setVisitorData({
        email: currentUser.email,
        distinct_id: currentUser.uid,
      });

      if (!chatApiObject) {
        window.tidioChatApi.show();
        window.tidioChatApi.on("ready", chatApiReady);
        setChatApiObject(window.tidioChatApi);
      } else {

        deleteAllCookies();
        chatApiObject.show();
        chatApiObject.display(true);
      }

    }
  }

  const eventListenerWrapper = () => {
    handleSetupVisitors(currentUser);
  }

  const tidioHideChat = () => {
    window.tidioChatApi.hide();
  }

  const tidioShowChat = () => {
    window.tidioChatApi.show();
  }

  useEffect(() => {
    if (currentUser) {
      let script = document.getElementById('tidioScript');
      if (!script) {
        script = document.createElement('script');

        script.src = tidioUrl;
        script.async = true;
        script.addEventListener("load", eventListenerWrapper);
        script.addEventListener("hideChat", tidioHideChat);
        script.addEventListener("showChat", tidioShowChat);
        // script.addEventListener("tidioChat-ready", onTidioChatApiReady);
        script.id = "tidioScript";

        document.body.appendChild(script);
        return () => {
          script.removeEventListener("load", eventListenerWrapper);
          document.body.removeChild(script);
        }
      }
    }

  }, [currentUser]);

  return (<></>);
}