import * as XLSX from 'xlsx';

export const areKeysUnique = (keys) => {
  const uniqueKeys = new Set(keys);
  return keys.length === uniqueKeys.size;
}

export const areCoefsDifferent = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
      return true;
  }

  function areObjectsDifferent(obj1, obj2) {
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      if (keys1.length !== keys2.length) {
          return true;
      }

      for (let key of keys1) {
          if (obj1[key] !== obj2[key]) {
              return true;
          }
      }

      return false;
  }

  for (let i = 0; i < arr1.length; i++) {
      if (areObjectsDifferent(arr1[i], arr2[i])) {
          return true;
      }
  }

  return false;
}

export const makeCSV =  (content) => {
    let csv = '';
    content.forEach(value => {
        value.forEach((item, i) => {
        let innerValue = item === null ? '' : item.toString();
        let result = innerValue.replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0) {
          result = '"' + result + '"'
        }
        if (i > 0) {csv += ','}
        csv += result;
      })
        csv += '\n';
      })
    return csv
}

export const parseLine = (line) => {
    let l = line.replaceAll(";", ",");
    let words = l.split(/[,\r]/);
    let arr = [];
    for(let i = 0; i < words.length; i++) {
      if(words[i].length > 0) {
        arr.push(words[i]);
      }
    }

    return arr;
}

export const parseCSV = (s) => {
    let lines = s.split(/[\n]/)
    let ids = [];
    let wasID = false;
    let headers = [];
    let rows = [];
    let columns = [];
    let col = [];
    
    for(let i = 0; i < lines.length; i++) {
      let line = parseLine(lines[i])

      if(line.length == 1) {
        ids.push(line[0])
        wasID = true;
        
      } else {
        if(wasID) {
          wasID = false;
          headers.push(line);
          if (col.length > 0) {
            columns.push(col);
          }
          col = []

        }
        else if (line.length > 1) {
          rows.push(line);
          col.push(line[0]);
        } 
      }
      
    }
    columns.push(col);

    var finalDict = {}
    for(let i = 0; i < ids.length; i++) {
      let header = headers[i];
      finalDict[ids[i]] = {};
      if(header === undefined) {
        return {}
      }
      for (let j = 0; j < header.length; j++){
        finalDict[ids[i]][header[j]] = [];
      }
    }

    if (columns === undefined || headers === undefined) {
      return {}
    }

    let start = 0;
    let end = 0;
    for(let i = 0; i < columns.length; i++){
      let col = columns[i];
      let li = col.length;
      if (headers[i] === undefined) {
        return {}
      }
      let lj = headers[i].length;
      let id = ids[i];

      end = start + li;

      for(let k = start; k < end; k++) {
        for(let j = 0; j < lj; j++) {
          let header = headers[i][j];
          finalDict[id][header].push(rows[k][j])
        }
      }
      start = end;

    }

    return finalDict;
  }

export const parseExcel = (contents) => {
  const workbook = XLSX.read(contents, { type: 'binary' });

      // sheetName - item name
      // row with single element - item variant name
      // rows with multiple elements:
      //                              - measurements
      //                              - actual values
      // blank rows are skipped

      function splitSizeCharts(json_object) {
        const result = {};
        const categories = [];

        let currentCategory = '';
        let currentAttributes = [];

        json_object.forEach((item) => {
          const keys = Object.keys(item);
          const values = Object.values(item);

          if (keys.includes('A') && keys.length == 1) {
            currentCategory = values[0];
            categories.push(currentCategory);
            result[currentCategory] = {};
            currentAttributes = [];
          } else {
            if (values.includes('sizes')) {
              values.forEach((value) => {
                currentAttributes.push(value);
                result[currentCategory][value] = [];
              }) 
            } else {
              values.forEach((value, index) => {
                result[currentCategory][currentAttributes[index]].push(value);
              });
            }
            
          }
        });

        return [result, categories];
      }

      let size_charts = {};

      workbook.SheetNames.forEach(function(sheetName) {
        // Here is your object
        var XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 'A', blankrows: false, skipHidden: true });

        let size_charts_from_sheet = splitSizeCharts(XL_row_object);

        let sc = size_charts_from_sheet[0];
        let categories = size_charts_from_sheet[1];

        for (let i = 0; i < categories.length; i++) {
          size_charts[categories[i]] = sc[categories[i]];
        }

      })

      return size_charts;
}

export const isObjectValid = (obj) => {
    if (!obj || typeof obj !== 'object') {
      return false; 
    }

    const sizes = obj.sizes;

    if (!Array.isArray(sizes) || sizes.some(size => size === "")) {
      return false; 
    }

    const sizeCount = sizes.length;
    let isValid = true;

    for (const key in obj) {
      if (key !== 'sizes' && key !== 'metadata' && obj.hasOwnProperty(key)) {
        const array = obj[key];

        if (!Array.isArray(array) || array.length !== sizeCount) {
          isValid = false; 
          break;
        }

        for (let i = 0; i < sizeCount; i++) {
            const value = array[i];
            if ( isNaN(value) || value === "" || value === null) {
            isValid = false; 
            break;
          }
        }
      }
    }

    return isValid;
}


export const cloneDeep = (obj) => {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
      const arrCopy = [];
      for (let i = 0; i < obj.length; i++) {
          arrCopy[i] = cloneDeep(obj[i]);
      }
      return arrCopy;
  }

  const objCopy = {};
  for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
          objCopy[key] = cloneDeep(obj[key]);
      }
  }

  return objCopy;
}

export const validateFile = (obj) => {
  if (typeof obj !== 'object') {
    return ['scFileErrorOne', 'none'];
  }

  for (const key in obj) {
    const subObj = obj[key];

    if (typeof subObj !== 'object' || Array.isArray(subObj)) {
      return ['scFileErrorTwo', key];
    }

    if (Object.keys(subObj).length === 0) {
      return ['scFileErrorThree', key];
    }
    for (const prop in subObj) {
      if (prop === 'sizes') {
        if (!Array.isArray(subObj[prop])) {
          return ['scFileErrorFour', key];
        }
        if(!areKeysUnique(Object.values(subObj[prop]))){
          return ['scFileErrorFive', key];
        }
      } else {
        if  (!Array.isArray(subObj[prop]) || subObj[prop].some(item => /^\d*\.?\d+$/.test(item) === false)) {
          return ['scFileErrorSix', key];
        }

        if (!Array.isArray(subObj[prop]) ) {
          return ['scFileErrorSeven', key];
        }

        if (subObj[prop].length !== subObj.sizes.length) {
          return ['scFileErrorEight', key];
        }
      }
    }
  }

  return true;
}

export const convertObjectToFloat = (obj) => {
  for (const key in obj) {
    if (key === 'sizes') {
      continue; 
    }
    const value = obj[key];

    if (typeof value === 'object') {
      convertObjectToFloat(value); 
    } else if (!isNaN(value) && typeof value === 'string') {
      obj[key] = parseFloat(value);
    }
  }
}

// export const isNumberKey = (evt) => {
//   for onKeyPress which is deprecated
//   const charCode = (evt.which) ? evt.which : evt.keyCode;
//   const { value } = evt.target;

//   // Disallow "-" and "e" or "E"
//   if (charCode === 45 || charCode === 101 || charCode === 69) {
//     return false;
//   }

//   if (charCode === 44) {
//     // Check if previously there was a decimal
//     if (value.indexOf('.') > 0) {
//       return false;
//     }
//     // Check if the text already contains the , character
//     if (value.indexOf(',') === -1) {
//       return true;
//     } else {
//       return false;
//     }
//   } else if (charCode === 46) {
//     // Check if previously there was a comma
//     if (value.indexOf(',') > 0) {
//       return false;
//     }
//     if (value.indexOf('.') === -1) {
//       return true;
//     } else {
//       return false;
//     }
//   } else {
//     if (charCode > 31 && (charCode < 48 || charCode > 57))
//       return false;
//   }
//   return true;
// }

export const isNumberKey = (evt) => {
  const { key } = evt;

  // Disallow "-" and "e" or "E"
  if (key === '-' || key === 'e' || key === 'E') {
    return false;
  }

  // Allow numbers, backspace, delete, arrow keys, and decimal point
  if (key === '.' || key === ',' || key === 'Backspace' || key === 'Delete' || key.startsWith('Arrow')) {
    return true;
  }

  // Disallow any other characters
  if (isNaN(key)) {
    return false;
  }

  return true;
}



export const parseData = (data) => {
  const parsedData = {
    sizes: data.map((row) => row.sizes),
  };

  Object.keys(data[0]).forEach((key) => {
    if (key !== 'sizes') {
      parsedData[key] = data.map((row) => row[key]);
    }
  });

  return parsedData;
};


export const isValidManual = (array) => {
  if (!Array.isArray(array) || array.length === 0) {
    return false;
  }

  for (const obj of array) {
    const hasKeys = Object.keys(obj).length > 1;

    const hasNoNaNOrEmpty = Object.values(obj).every((value) => {
      if (Array.isArray(value)) {
        return value.every((item) => item !== null && !isNaN(item) && item !== '');
      } else {
        return value !== null && value !== '';
      }
    });

    const hasNoNullValues = Object.values(obj).every((value) => {
      if (Array.isArray(value)) {
        return value.every((item) => item !== null);
      } else {
        return value !== null;
      }
    });

    if (!(hasKeys && hasNoNaNOrEmpty && hasNoNullValues)) {
      return false; 
    }
  }

  return true; 
};

export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
});

export const arraysAreEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  return true;
}

export const convertArraysToDictionaries = (obj) => {
  return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [
          key,
          Object.fromEntries(value.map((item, index) => [index.toString(), item]))
      ])
  );
};

export const convertDictionariesToArrays = (obj) => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
        key,
        Object.values(value)
    ])
);
};

export const isValidFirestoreFieldName = (field) => {
  if (!field[0].match(/[a-zA-Z]/)) {
      return false;
  }

  const allowedCharacters = new Set("abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789_.");
  
  for (const char of field) {
      if (!allowedCharacters.has(char)) {
          return false;
      }
  }

  return true;
}

export const transformFirestoreKey = (key) => {
  let transformedKey = '';
  let lastCharWasUnderscore = false;

  for (let i = 0; i < key.length; i++) {
    let char = key[i];
    if (char.match(/[a-zA-Z0-9_]/)) {
      transformedKey += char;
      lastCharWasUnderscore = false;
    } else if (!lastCharWasUnderscore) {
      transformedKey += '_';
      lastCharWasUnderscore = true;
    }
  }

  if (transformedKey.endsWith('_')) {
    transformedKey = transformedKey.slice(0, -1);
  }

  if (transformedKey.startsWith('_')) {
    transformedKey = transformedKey.slice(1);
  }

  return transformedKey;
};



export const validateJSON = (jsonString) => {
  // Remove whitespace to simplify parsing
  const cleanedString = jsonString.replace(/\s/g, '');

  // Check if the string starts and ends with curly braces or square brackets
  if (!((cleanedString[0] === '{' && cleanedString[cleanedString.length - 1] === '}') ||
    (cleanedString[0] === '[' && cleanedString[cleanedString.length - 1] === ']'))) {
    return { valid: false, message: "Invalid JSON format" };
  }

  // Define a regular expression to match key-value pairs
  const pairRegex = /(".*?")\s*:\s*([^,]+?)(?=,|}|])/g;

  // Find all key-value pairs in the string
  let match;
  const pairs = [];
  while ((match = pairRegex.exec(cleanedString)) !== null) {
    pairs.push({ key: match[1], value: match[2] });
  }

  const keys = new Set();

  // Check for duplicate keys
  for (let pair of pairs) {
    const cleanedKey = pair.key.replace(/"/g, '');

    if (keys.has(cleanedKey)) {
      // return { valid: false, message: `Duplicate key found: ${cleanedKey}` };
      return false
    }

    keys.add(cleanedKey);
  }

  // return { valid: true, message: "JSON is valid" };
  return true
}

export const extractLevelOneDicts = (jsonString) => {
  const levelOneDicts = [];
  const matches = jsonString.match(/\"(\w+)\":\s*\{[^{}]+\}/g);

  if (matches) {
    matches.forEach(match => {
      levelOneDicts.push('{' + match + '}');
    });
  }

  return levelOneDicts;
}

export const associationsHaveDuplicates = (obj) => {
  let seen = {};

  for (let key in obj) {
    seen[key] = {};
  }

  for (let key in obj) {
    let innerObj = obj[key];
    for (let innerKey in innerObj) {
      if (seen[key].hasOwnProperty(innerObj[innerKey])) {
        seen[key][innerObj[innerKey]] = true; 
      } else {
        seen[key][innerObj[innerKey]] = false; 
      }
    }
  }

  let hasDuplicateValue = false;
  for (let key in seen) {
    let innerObj = seen[key];
    for (let innerKey in innerObj) {
      if (innerObj[innerKey] === true) {
        hasDuplicateValue = true;
        break;
      }
    }
    if (hasDuplicateValue) {
      break;
    }
  }
 
  return {
    hasDuplicateValue: hasDuplicateValue,
    seen: seen
  };
}