import { useEffect, useState } from "react";

import {
  Box, Button, Card, CardMedia, CircularProgress, Dialog, DialogContent, Grid, Tooltip, Typography, Accordion, AccordionSummary,
  AccordionDetails, MenuItem, Select, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from "@mui/material";

import { CloudDownload, Download, ExpandMore, Visibility } from "@mui/icons-material";

import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import { parseTimestamp } from "../utils/utils";
import { useAuth } from "../../../AuthContext";

import Avatar3D from "./Avatar3D";
import { borders, boxShadows } from "../../../defaultTheme";
import { useTranslation } from "react-i18next";

const env_type = process.env.REACT_APP_ENV_TYPE;
const url_3d_mtm = process.env.REACT_APP_URL3DMTM;

// let url_3d_mtm = "https://dashboard.test.esenca.app/3dbody_mtm";


const CustomAccordion = styled(Accordion)`
  border-radius: 5px;
  margin-top: 10px;
  background-color: transparent;
`;

const getMuiTheme = () => createTheme(
  {
    components: {
      MuiCardMedia: {
        styleOverrides: {
          root: {
            height: "40vh",
            border: "none",
            overflow: "hidden",
            marginLeft: "auto",
            marginRight: "auto",
          }
        }
      },
    }
  }
);

export const MeasurementDialog = (props) => {
  const { companyConfig, companyClients, companyId } = useAuth();
  const { t } = useTranslation();

  const [person, setPerson] = useState([]);
  const [measurements, setMeasurements] = useState([]);
  const [unit, setUnit] = useState("metric");

  const [open, setOpen] = useState(false);
  const [loadedModel, setLoadedModel] = useState(false);

  const handleCloseDialog = () => {
    setOpen(false);
  }

  const handleUnitChange = (event) => {
    setUnit(event.target.value)
  }

  useEffect(() => {
    if (props.person) {
      setPerson(props.person)
      if(props.person.measurements){
        setMeasurements(props.person.measurements)
      }
    }
  }, [props.person])

  useEffect(() => {
    window.addEventListener("message", (event) => {
      if (event.data.loaded) {
        setLoadedModel(true);
      }
    });
  }, []);

  const handleDownload = () => {
    const transformedObject = {
      ...person,
      ...Object.entries(person.measurements).reduce((acc, [key, value]) => {
        const measurementKey = value.name;
        if (unit !== 'metric') {
          acc[measurementKey] = value.value / 2.54;
        } else {
          acc[measurementKey] = value.value;
        }
        return acc;
      }, {}),
    };

    if (unit !== 'metric') {
      transformedObject.height = person.height / 2.54;
      transformedObject.weight = person.weight / 0.45359237
    }

    transformedObject.createdAt = transformedObject.createdAt ? parseTimestamp(transformedObject.createdAt, props.timezone) : "";
    transformedObject.updatedAt = transformedObject.updatedAt ? parseTimestamp(transformedObject.updatedAt, props.timezone) : "";

    if(transformedObject?.clientId){
      if(transformedObject.clientId === companyId){
        transformedObject.clientId = companyConfig?.name
      } else {
        let client = companyClients[transformedObject.clientId]
        if(client?.name) {
          transformedObject.clientId  = client?.name
        }
      }
    }

    if ('measurements' in transformedObject) delete transformedObject.measurements;
    if ('betas' in transformedObject) delete transformedObject.betas;
    if ('status' in transformedObject) delete transformedObject.status;
    if ('measurementId' in transformedObject) delete transformedObject.measurementId;
    if ('results' in transformedObject) delete transformedObject.results;
    if ('maksId' in transformedObject) delete transformedObject.maksId;
    if ('companyId' in transformedObject) delete transformedObject.companyId;
    if ('personId' in transformedObject) delete transformedObject.personId;
    if ('accessPoint' in transformedObject) delete transformedObject.accessPoint;
    if ('shopid' in transformedObject) delete transformedObject.shopid;
    if ('userAgent' in transformedObject) delete transformedObject.userAgent;

    const separator = ',';

    const csvContent = [
      Object.keys(transformedObject).map(key => `"${key}"`).join(separator)
    ];

    const row = Object.values(transformedObject).map(value => `"${value}"`).join(separator);
    csvContent.push(row);

    const blob = new Blob([csvContent.join('\n')], { type: 'text/csv' });

    const filename = "person_data.csv";

    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);

  }

  const downloadAvatar = () => {

    let model_url = `https://prod.engine.esenca.app/get_model?betas=${person.betas}&gender=${person.gender}`;

    fetch(model_url)
      .then(response => response.text())
      .then(data => {
        const objContent = data;
        const blob = new Blob([objContent], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `${person.firstName}_${person.lastName}_${person.gender}.obj`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);

      })
      .catch(error => {
        console.error('Error:', error);
      });

  }

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Card sx={{ padding: "2%" }}>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Typography fontWeight="light" variant="subtitle2">{t(`Date`)}</Typography>
                <Typography fontWeight="medium" variant="subtitle2">{person.updatedAt ? parseTimestamp(person.updatedAt, props.timezone) : ""}</Typography>
              </Grid>
              <Grid item xs={4}>
                <IconButton
                  onClick={handleDownload}
                >
                  <CloudDownload />
                </IconButton>
              </Grid>
              <Grid item xs={4}>
                <Select
                  value={unit}
                  onChange={(e) => handleUnitChange(e)}
                  inputProps={{ 'aria-label': 'Without label' }}
                  sx={{ width: '100%' }}
                  size="small"
                >
                  <MenuItem value="metric">
                    <em>{t(`Metric`)}</em>
                  </MenuItem>
                  <MenuItem value="imperial">
                    <em>{t(`Imperial`)}</em>
                  </MenuItem>
                </Select>
              </Grid>
              <Grid item xs={4}>
                <Typography fontWeight="light" variant="subtitle2">{t(`First Name`)}</Typography>
                <Typography fontWeight="medium" variant="subtitle2" sx={{ fontWeight: 'bold' }}>{person.firstName}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography fontWeight="light" variant="subtitle2">{t(`Last Name`)}</Typography>
                <Typography fontWeight="medium" variant="subtitle2" sx={{ fontWeight: 'bold' }}>{person.lastName}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography fontWeight="light" variant="subtitle2">Email</Typography>
                <Typography fontWeight="medium" variant="subtitle2" sx={{ fontWeight: 'bold' }}>{person.email}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography fontWeight="light" variant="subtitle2">{t(`Gender`)}</Typography>
                <Typography fontWeight="medium" variant="subtitle2" sx={{ fontWeight: 'bold' }}>{person.gender}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography fontWeight="light" variant="subtitle2">{t(`Height`)}</Typography>
                <Typography fontWeight="medium" variant="subtitle2" sx={{ fontWeight: 'bold' }}>{unit === 'metric' ? person.height : (person.height / 2.54).toFixed(2)} {unit === 'metric' ? "cm" : "in"}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography fontWeight="light" variant="subtitle2">{t(`Weight`)}</Typography>
                <Typography fontWeight="medium" variant="subtitle2" sx={{ fontWeight: 'bold' }}>{unit === 'metric' ? person.weight : (person.weight / 0.45359237).toFixed(2)} {unit === 'metric' ? "kg" : "lbs"}</Typography>
              </Grid>
            </Grid>
          </Card>


          <Grid item xs={12} md={12} mt={2}>
          <Card sx={{ padding: "2%" }}>
            <CustomAccordion defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography fontWeight="light" variant="h6">{t(`Measurements`)}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <TableContainer>
                    <Table sx={{ minWidth: 650 }} size="small">
                      <TableHead>
                        <TableRow>
                          {/* <TableCell align="center">ID</TableCell> */}
                          <TableCell align="center">{t(`Name`)}</TableCell>
                          <TableCell align="center">{t(`Value`)}</TableCell>
                          <TableCell align="center">{t(`Type`)}</TableCell>
                          <TableCell align="center">{t(`Description`)}</TableCell>
                          <TableCell align="center">{t(`Picture`)}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {measurements && Object.values(measurements).map((row, i) => (
                          <TableRow key={row.name}>
                            {/* <TableCell align="center">{Object.keys(measurements)[i]}</TableCell> */}
                            <TableCell align="center">{row.name}</TableCell>
                            <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                              {isNaN(parseFloat(row.value)) ? row.value :
                                unit === 'metric' ? row.value.toFixed(2) + " cm" : (row.value / 2.54).toFixed(2) + " in"}
                            </TableCell>
                            <TableCell align="center">{Object.keys(measurements)[i].split('_')[3]}</TableCell>
                            <TableCell align="center">{row.definition}</TableCell>
                            <TableCell align="center"><img src={row.picture} width="100rem" /></TableCell>
                          </TableRow>

                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                </Grid>
              </AccordionDetails>
            </CustomAccordion>
            </Card>
          </Grid>
        </Grid>

        <Grid item xs={12} md={4} textAlign={"center"}>
          <Card sx={{ padding: "2%", height: "85vh" }}>
            {(person.betas && person.gender) ?
              <>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Button
                    disabled={loadedModel ? false : true}
                    onClick={() => setOpen(true)}
                    variant="contained"
                    sx={{ mt: 2, width: '50%'}}
                    startIcon={<Visibility />}
                  >
                    {t(`measurementsButtonOne`)}
                  </Button>
                  <Button 
                    onClick={downloadAvatar}
                    variant="outlined"
                    disabled={loadedModel ? false : true}
                    startIcon={<Download />}
                    sx={{ mt: 2, width: '30%'}}
                  >
                    {t(`measurementsButtonDownload`)}
                  </Button>
                </Box>
                
                <ThemeProvider theme={getMuiTheme}>
                  <Box
                    sx={{ cursor: 'pointer', mt: 5}}
                    onClick={() => setOpen(true)}
                  >
                    <CardMedia
                      component={"iframe"}
                      src={(person.betas && person.gender) ? url_3d_mtm + "?betas=" + person.betas + "&gender=" + person.gender : ""}
                      sx={{
                        pointerEvents: loadedModel && "none",
                        display: !loadedModel && "none",
                      }}
                    />
                  </Box>
                </ThemeProvider>
                <CircularProgress
                  sx={loadedModel ? { display: "none" } : { margin: "auto" }}
                  color="primary"
                />
                <Dialog
                  fullScreen
                  maxWidth="lg"
                  open={open}
                  onClose={handleCloseDialog}
                >
                  <DialogContent>
                    <Avatar3D clientInfo={person} handleCloseDialog={handleCloseDialog} />
                  </DialogContent>
                </Dialog>
              </>
              :
              <Typography variant="subtitle2">Avatar viewer not available for this person.</Typography>
            }
          </Card>
        </Grid>
      </Grid>
    </>
  )
}