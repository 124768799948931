import { useState } from "react";
import { 
  Grid, 
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import AddClientAccountForm from "./AddClientAccountForm";
import { useTranslation } from "react-i18next";

export default function AddClient() {
  const { t } = useTranslation();

  const roles = {
    "company":  t(`Company Account`),
    "client": t(`Client Account`)
  };
  const [selectedRole, setSelectedRole] = useState("");

  const handleSelectRole = (event) => {
    setSelectedRole(event.target.value);
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} mt={2}>
        <Typography variant="body1" fontWeight={'bold'}>
          {t(`newClientHeaderOne`)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" align="justify">
          {t(`newClientLabelOne`)}
        </Typography>
      </Grid>
      <Grid item xs={12} mt={2}>
        <Typography variant="body1" fontWeight={'bold'}>
          {t(`newClientHeaderTwo`)}
        </Typography>
      </Grid>
      <Grid item xs={6} mt={2}>
        <FormControl
          fullWidth
        >
          <InputLabel id="role-label">{t(`newClientLabelTwo`)}</InputLabel>
          <Select
            labelId="role-label"
            id="role"
            name="role"
            label={t(`newClientLabelTwo`)}
            value={selectedRole}
            onChange={handleSelectRole}
          >
            {Object.keys(roles).map((name) => (
              <MenuItem key={name} value={name}>
                {roles[name]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        {
          selectedRole && (
            <Typography variant="body1" fontWeight={'bold'}>
              {/* Fill in the new {roles[selectedRole]} User's details */}
              {t(`newClientHeaderThree`, { role: roles[selectedRole] })}
            </Typography>
          )
        }
      </Grid>
      <Grid item xs={6} mt={4}>
        {
          selectedRole && (
            <AddClientAccountForm selectedRole={selectedRole} setSelectedRole={setSelectedRole}/>
          )
        }
      </Grid>
    </Grid>
  )
}