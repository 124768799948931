import React, { useState, useEffect } from 'react';
import { Box, Card, Tab, Toolbar } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import AddClient from './components/AddClient';
import ViewClient from './components/ViewClients';

import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';

const drawerWidth = 245;

function AdministrationPage(props) {
  const { t } = useTranslation();
  const { tab } = useParams(); 
  const navigate = useNavigate();
  
  const [value, setValue] = useState("manage-accounts");

  useEffect(() => {
    if (tab) {
      setValue(tab);
    } else {
      setValue("manage-accounts"); 
    }
  }, [tab]);


  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`/administration/${newValue}`);
  };

  return (
    <>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, ml: { sm: `${drawerWidth}px` }, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        <Card>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label={t(`Manage Accounts`)} value="manage-accounts" />
                <Tab label={t(`Request Client Account`)} value="request-client-account" />
              </TabList>
            </Box>
            <TabPanel value="manage-accounts">
              <ViewClient />
            </TabPanel>
            <TabPanel value="request-client-account">
              <AddClient />
            </TabPanel>
          </TabContext>
        </Card>
      </Box>
    </>
  );
}

export default AdministrationPage;
