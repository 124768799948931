import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";

import {
  Close
} from "@mui/icons-material";

import CustomTabPanel from "../CustomTabPanel";

import { colors } from "../../../../defaultTheme";
import { useAuth } from "../../../../AuthContext";
import { useTranslation } from "react-i18next";

import ImportRecipientsList from "./ImportRecipientsList";
import TableRecipientsList from "./TableRecipientsList";
import SaveDialog from "../../../SaveDialog";
import PersonIdDialog from "./PersonIdDialog";
import { generateFirestoreTimeBasedId, isValidFirestoreFieldName } from "../../../utils/functions";

const database_url = process.env.REACT_APP_DATABASE_URL;

export default function RecipientsListDialog({
  openAddListDialog,
  handleCloseAddListDialog
}) {
  const {
    companyId,
    companyRecipientsList,
    setCompanyRecipientsLists,
    handleSnackbar,
    currentUser,
    role,
    clientId
  } = useAuth();
  
  const { t } = useTranslation();

  const [currentOption, setCurrentOption] = useState('import');
  const [currentImportStep, setCurrentImportStep] = useState(0);
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [openPersonIdDialog, setOpenPersonIdDialog] = useState(false);

  const [employeeList, setEmployeeList] = useState([]);
  const [employeeListName, setEmployeeListName] = useState("");
  const [listColumns, setListColumns] = useState([]);
  const [employeesMissingIds, setEmployeesMissingIds] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");

  const [errorListName, setErrorListName] = useState(false);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [hasScrolled, setHasScrolled] = useState(false);

  const [selectedOrg, setSelectedOrg] = useState(companyId);

  const steps = [
    t(`scanImportLabelOne`),
    t(`scanImportLabelTwo`),
  ];

  const handleOptionChange = (event, newValue) => {
    setCurrentOption(newValue);
  }

  const resetData = () => {
    setEmployeeList([]);
    setCurrentImportStep(0);
    setListColumns([]);
    setCurrentOption('import');
  }

  const handleExit = () => {
    // Your logic to handle exit here
    // For example, you can navigate away or perform other actions
    // Close the dialog
    setOpenSaveDialog(false);
    resetData();
    handleCloseAddListDialog();
  };

  const handleCancelExit = () => {
    // User canceled exit
    // You can choose to do nothing or perform other actions
    // Close the dialog
    setOpenSaveDialog(false);
  };

  const handleTryExit = () => {
    // User is attempting to exit, show confirmation dialog
    if (employeeList.length > 0) {
      setOpenSaveDialog(true);
    } else {
      resetData();
      handleCloseAddListDialog();
    }
  };

  const handleReUpload = () => {
    resetData();
    setOpenPersonIdDialog(false);
    handleCloseAddListDialog();
  }

  const handleUploadRecipientsList = async (recipientsList, clientId, companyId) => {
    const accessToken = await currentUser.getIdToken(true);
    let response = await fetch(database_url + "/save_recipients_list", {
      method: 'POST',
      headers: { "Content-Type": 'application/json', "Accept": 'application/json', 'x-access-token': accessToken },
      body: JSON.stringify({
        recipientsList,
        clientId,
        companyId,
      })
    });

    let result = await response.json();

    return result['success'];
  }

  const getTrackingId = async () => {

    const accessToken = await currentUser.getIdToken(true);
    let url = "https://database.esenca.app";
    // let url = "http://localhost:5001";

    let response = await fetch(url + "/generate_trackingId", { method: 'GET' });

    let result = await response.text();

    return result;
  }

  const handleAutoGenerate = async () => {
    let copy_employeeList = employeeList;
    await Promise.all(employeesMissingIds.map(async (idx) => {
      let generated_firestore_id = await getTrackingId();

      copy_employeeList[idx]['id'] = generated_firestore_id;
    }))

    setEmployeeList(copy_employeeList);
    setOpenPersonIdDialog(false);

    // acum ar trebui sa salveze in db
    let copy = JSON.parse(JSON.stringify(companyRecipientsList ? companyRecipientsList : {}));

    copy[employeeListName] = employeeList;
    setCompanyRecipientsLists(prevState => {
      return { ...prevState, [employeeListName]: copy_employeeList };
    });

    // trebuie sa se creeze cate o persoana pt fiecare si sa se puna in persoana numele listei din care face parte


    let selectedClientId = companyId; 
    if (role == 'client') {
      selectedClientId = clientId;
    }

    if (selectedOrg) {
      selectedClientId = selectedOrg;
    }

    handleUploadRecipientsList(copy, selectedClientId, companyId);

    handleSnackbar('success', 'List Created Successfully!');
    handleCloseAddListDialog();
    resetData();
  }

  const handleSaveRecipientsList = async () => {
    let employees_missing_ids = [];

    let selectedClientId = companyId; 
    if (role == 'client') {
      selectedClientId = clientId;
    }
    if (selectedOrg) {
      selectedClientId = selectedOrg;
    }

    employeeList.map((employee, idx) => {
      if (!Object.keys(employee).includes('id')) {
        employees_missing_ids.push(idx);
      }
    })

    if (employees_missing_ids.length > 0) {
      // open dialog
      setEmployeesMissingIds(employees_missing_ids);
      setOpenPersonIdDialog(true);
    } else {
      // save
      let copy = JSON.parse(JSON.stringify(companyRecipientsList ? companyRecipientsList : {}));

      copy[employeeListName] = employeeList;
      setCompanyRecipientsLists(prevState => {
        return { ...prevState, [employeeListName]: employeeList };
      });

      handleUploadRecipientsList(copy, selectedClientId, companyId);

      handleSnackbar('success', 'List Created Successfully!');
      handleCloseAddListDialog();
      resetData();
    }
  }

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const currentScrollPosition = (scrollTop / (scrollHeight - clientHeight)) * 100;

    setScrollPosition(currentScrollPosition);

    // Enable the button when the user has completely scrolled
    if (currentScrollPosition >= 100 ) {
      setHasScrolled(true);
    }
  };
  
  return (
    <Dialog
      // fullScreen
      maxWidth="lg"
      fullWidth
      open={openAddListDialog}
      onClose={handleCloseAddListDialog}
    >
      <DialogTitle
        sx={{
          padding: 0,
          paddingBottom: "16px"
        }}
      >
        <Toolbar
          sx={{
            backgroundColor: colors.primary,
          }}
        >
          <Typography variant="body1" sx={{ flexGrow: 1, color: colors.secondary, fontWeight: 'bold' }}>
            {t(`scanImportHeaderOne`)}
          </Typography>
          <IconButton onClick={handleExit} sx={{ color: colors.secondary }}>
            <Close />
          </IconButton>
        </Toolbar>
      </DialogTitle>
      <DialogContent sx={{ height: "70vh" }} 
      onScroll={handleScroll}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={currentOption} onChange={handleOptionChange} aria-label="basic tabs example">
            {/* <Tab label="Add Recipient" value={'manual'}/> */}
            <Tab label={t(`scanImportHeaderTwo`)} value={'import'} />
          </Tabs>
        </Box>
        <CustomTabPanel value={'manual'} currentOption={currentOption} index={0}>
          {t(`scanImportHeaderThree`)}
        </CustomTabPanel>
        <CustomTabPanel value={'import'} currentOption={currentOption} index={1}>
          <Stepper activeStep={currentImportStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {
            currentImportStep == 0 && (
              <Grid container width={"100%"} spacing={5} mt={2}>
                <ImportRecipientsList
                  employeeList={employeeList}
                  employeeListName={employeeListName}
                  setEmployeeList={setEmployeeList}
                  setEmployeeListName={setEmployeeListName}
                  setListColumns={setListColumns}
                  errorListName={errorListName}
                  setErrorListName={setErrorListName}
                  selectedClient={selectedOrg}
                  setSelectedClient={setSelectedOrg}
                />
              </Grid>
            )
          }
          {
            currentImportStep == 1 && (
              <Grid container width={"100%"} spacing={5} mt={2}>
                <TableRecipientsList
                  employeeList={employeeList}
                  listColumns={listColumns}
                  selectedClient={selectedClient}
                  setSelectedClient={setSelectedClient}
                />
              </Grid>
            )
          }
        </CustomTabPanel>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleTryExit}>
          {t(`Cancel`)}
        </Button>
        {
          currentOption == 'import' && currentImportStep == 0 && (
            <Button
              variant="contained"
              disabled={!(employeeList.length > 0 && employeeListName.length > 0 && !errorListName)}
              onClick={() => setCurrentImportStep(1)}
            >
              {t(`Import`)}
            </Button>
          )
        }
        {
          currentOption == 'import' && currentImportStep == 1 && (
            <Button
              variant="contained"
              disabled={!(employeeList.length > 0)}
              onClick={handleSaveRecipientsList}
            >
              {t(`Save`)}
            </Button>
          )
        }
        {
          currentOption == 'manual' && (
            <Button
              variant="contained"
              disabled
            >
             {t(`Add`)}
            </Button>
          )
        }
      </DialogActions>
      <SaveDialog
        isOpen={openSaveDialog}
        onCancel={handleCancelExit}
        onConfirm={handleExit}
      />
      <PersonIdDialog
        isOpen={openPersonIdDialog}
        onCancel={handleReUpload}
        onConfirm={handleAutoGenerate}
        employeesNumber={employeesMissingIds.length}
      />
    </Dialog>
  );

}