import React, { useEffect, useState } from 'react';
import { Box, Toolbar, Select, MenuItem, Typography, FormControl, InputLabel, Card, Grid, Button, Divider, Dialog, DialogContent } from '@mui/material';
import { useAuth } from '../../../AuthContext';
import ConfigData from './components/ConfigData';
import CompanyDetailsDialog from './components/CompanyDetailsDialog';

const drawerWidth = 250;
const database_url = process.env.REACT_APP_DATABASE_URL;

function AdminCompanyDataPage() {
    const { setCompanySizeCharts, setCompanyCollections, setCompanyProducts, setCompanyConfig, setCompanyId, setCompanyMeasurementsConfig  } = useAuth()

    const { adminCompanies, currentUser, userId } = useAuth();
    const [selectedCompanyId, setSelectedCompanyId] = useState('');
    const [companyDetails, setCompanyDetails] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [openDialogCompanyDetails, setOpenDialogCompanyDetails] = useState(false);

    const handleCompanyChange = (event) => {
        setSelectedCompanyId(event.target.value);
        setCompanyDetails(null);

        setCompanyId(event.target.value)
        setCompanyCollections(null)
        setCompanyProducts(null)
        setCompanySizeCharts(null)
        setCompanyConfig(null)
        setCompanyMeasurementsConfig(null)
    };

    const viewCompanyDetails = async () => {
        const accessToken = await currentUser.getIdToken(true);
    
        if (selectedCompanyId) {
            let promises = Promise.all([
                fetch(database_url + '/view_company_document?user_id=' + userId + '&company_id=' + selectedCompanyId, {
                    method: 'GET',
                    headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
                }),
            ]);
        
            promises
                .then((results) => Promise.all(results.map((r) => r.json())))
                .then((resultsData) => {
                    setCompanyDetails(resultsData[0]);

                    if(resultsData[0].config){
                        setCompanyConfig(resultsData[0].config)

                        if(resultsData[0].config.measurementsConfig) {
                            setCompanyMeasurementsConfig(resultsData[0].config.measurementsConfig)
                        }
                    }

                    if(resultsData[0].collections){
                        setCompanyCollections(resultsData[0].collections)
                    }
        
                    if(resultsData[0].products){
                        setCompanyProducts(resultsData[0].products)
                    }
        
                    if(resultsData[0].sizeCharts){
                        setCompanySizeCharts(resultsData[0].sizeCharts)
                    }

                    setOpenDialogCompanyDetails(true);
                });
        } 
    };

    const handleCloseDialogCompanyDetails = () => {
        setOpenDialogCompanyDetails(false);
    };

    return (
        <>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, ml: { sm: `${drawerWidth}px` }, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                <Card sx={{padding:'2%'}}>
                    <Grid container align="center">
                        {!editMode &&
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="company-select-label">Select Company</InputLabel>
                                    <Select
                                        labelId="company-select-label"
                                        value={selectedCompanyId}
                                        label="Select Company"
                                        onChange={handleCompanyChange}
                                    >
                                        {adminCompanies && Object.keys(adminCompanies).map((companyId) => (
                                            <MenuItem key={companyId} value={companyId}>
                                                {adminCompanies[companyId].config.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                        {selectedCompanyId &&
                        <>
                            <Grid item xs={12}>
                                <ConfigData companyId={selectedCompanyId} editMode={editMode} setEditMode={setEditMode}/>
                            </Grid>
                            {!editMode &&
                            <>
                                <Grid item xs={12} mt={3}>
                                    <Divider/>
                                    <Typography variant="subtitle2">Company Level</Typography>
                                    <Typography variant="body2">Size charts / products / collections</Typography>
                                    <Box display="flex" flexDirection="column" gap={2} alignItems="center">
                                        {companyDetails ? 
                                            <Button onClick={() => setOpenDialogCompanyDetails(true)}>View Details</Button>
                                            :
                                            <Button onClick={viewCompanyDetails}>Retrieve Details</Button>
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={12} mt={3}>
                                    <Divider/>
                                    <Typography variant="subtitle2">Measurements Level</Typography>
                                    <Typography variant="body2">Retrieve measurements / persons / recommendations + products</Typography>
                                    <Button>Retrieve Measurements</Button>
                                </Grid>
                                <Grid item xs={12} mt={3}>
                                    <Divider/>
                                    <Typography variant="subtitle2">Users Level</Typography>
                                    <Typography variant="body2">Retrieve users / permissions</Typography>
                                    <Button>Retrieve Users</Button>
                                </Grid>
                                <Grid item xs={12} mt={3}>
                                    <Divider/>
                                    <Typography variant="subtitle2">Billing Level</Typography>
                                    <Typography variant="body2">Retrieve billing</Typography>
                                    <Button>Retrieve Billing</Button>
                                </Grid>
                                <Grid item xs={12} mt={3}>
                                    <Divider/>
                                    <Typography variant="subtitle2">Logs Level</Typography>
                                    <Typography variant="body2">Retrieve logs</Typography>
                                    <Button>Retrieve Logs</Button>
                                </Grid>
                            </>
                            }
                        </>
                        }
                    </Grid>
                </Card>
            </Box>

            <Dialog
                fullScreen
                maxWidth="lg"
                open={openDialogCompanyDetails}
                onClose={handleCloseDialogCompanyDetails}
            >
                <DialogContent>
                    <CompanyDetailsDialog
                        handleCloseDialog={handleCloseDialogCompanyDetails} 
                        openDialog={openDialogCompanyDetails}  
                    />
                </DialogContent>
            </Dialog>
        </>
    );
}

export default AdminCompanyDataPage;
