import { Card, Grid, Typography} from "@mui/material";

import CardPageComponent from "./CardPageComponent";
import { useAuth } from "../../../AuthContext";
import { useTranslation } from 'react-i18next';

export default function HomeComponent() {

  const { role } = useAuth();
  const { t } = useTranslation();

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Card
          sx={{ px: 5, py: 2 }}
        >
          <Typography variant="h5" fontWeight={'bold'}>
            {t(`homeHeader`)}
          </Typography>
          {role !== 'esenca' &&
          <Typography variant="body1">
            {t(`homeSubHeader`)}
          </Typography>
          }
        </Card>
      </Grid>
      {role !== 'esenca' &&
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CardPageComponent
              title={t(`homeCardHeaderOne`)}
              description={t(`homeCardSubHeaderOne`)}
              buttonText={t(`View`)}
              goTo={"/measurements/measurements"}
            />
          </Grid>
          <Grid item xs={6}>
            <CardPageComponent 
              title={t(`homeCardHeaderTwo`)}
              description={t(`homeCardSubHeaderTwo`)} 
              buttonText={t(`Send`)}
              goTo={"/send-scan-link/quick-scan"}
            />          
          </Grid>
          <Grid item xs={6}>
          {
            role == 'company' && (
              <CardPageComponent 
                title={t(`homeCardHeaderThree`)}
                description={t(`homeCardSubHeaderThree`)}
                buttonText={t(`Manage`)}
                goTo={"/size-charts/size-charts"}
              />      
            )
          }    
          </Grid>
          {
            role == 'company' && (
              <Grid item xs={6}>
                <CardPageComponent
                  title={t(`homeCardHeaderFour`)}
                  description={t(`homeCardSubHeaderFour`)}
                  buttonText={t(`Go`)}
                  goTo={"/administration/manage-accounts"}
                />
              </Grid>
            )
          }
        </Grid>
      </Grid>
      }
    </Grid>
  )
}