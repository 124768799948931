import { useEffect, useRef, useState } from "react";
import {
  Button,
  Grid,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  RadioGroup,
  Radio,
  TextField,
  Typography,
  Select,
} from "@mui/material";

import { useAuth } from "../../../../AuthContext";
import { useTranslation } from "react-i18next";

import Dropzone from "./Dropzone";

import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import { ErrorOutline } from "@mui/icons-material";
import { colors } from "../../../../defaultTheme";
import template_csv_file from "../../../../assets/template.csv";
import template_xlsx_file from "../../../../assets/template.xlsx";

export default function ImportRecipientsList({
  employeeList,
  employeeListName,
  setEmployeeList,
  setEmployeeListName,
  setListColumns,
  errorListName,
  setErrorListName,
  selectedClient,
  setSelectedClient
}) {

  const {
    handleSnackbar,
    persons,
    role,
    companyClients,
    companyId,
    companyConfig
  } = useAuth();
  const { t } = useTranslation();

  const dofileDownload = useRef();

  const template_csv = template_csv_file;
  const template_xlsx = template_xlsx_file;

  const [isLoading, setIsLoading] = useState(false);
  const [fileType, setFileType] = useState('xlsx');
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [uploadSeverity, setUploadSeverity] = useState('');

  const [fileDownloadUrl, setFileDownloadUrl] = useState(null);
  const [usedListNames, setUsedListNames] = useState([]);

  const [availableOrgs, setAvailableOrgs] = useState({});

  const fileNames = {
    csv: "employee_list_template.csv",
    xlsx: "employee_list_template.xlsx",
  };

  useEffect(() => {
    if (persons) {
      const uniqueValues = new Set();
      const personsDetails = Object.values(persons);

      personsDetails.forEach(person => {
        if (Object.keys(person).includes('recipientsListName')) {
          uniqueValues.add(person['recipientsListName']);
        }
      });

      setUsedListNames(Array.from(uniqueValues));
    }


    let localOrgs = availableOrgs;
    localOrgs[companyId] = {
      "companyId": companyId,
      "name": companyConfig['name']
    }

    if (companyClients) {
      Object.keys(companyClients).forEach((key) => {
        localOrgs[key] = companyClients[key];
      })

      setAvailableOrgs(localOrgs);
    }
  }, []);

  useEffect(() => {
    if (fileDownloadUrl) {
      dofileDownload.current.click();
      URL.revokeObjectURL(fileDownloadUrl);
      setFileDownloadUrl(null);
    }
  }, [fileDownloadUrl]);

  const changeFileType = (event) => {
    const value = event.target.value;
    setFileType(value);
  }

  const download = (event) => {

    event.preventDefault();

    const fileName = 'template' + (fileType === 'xlsx' ? '.xlsx' : '.csv');
    dofileDownload.current.download = fileName;
    if (fileType === 'xlsx') {
      dofileDownload.current.href = template_xlsx;
    } else if (fileType === 'csv') {
      dofileDownload.current.href = template_csv;
    }
    dofileDownload.current.click();
  };

  const handleSelectClient = (event) => {
    setSelectedClient(event.target.value);
  }

  const handleCsv = (csvData) => {
    try {
      const parsedData = csvData;
      const requiredColumns = ['first name', 'last name', 'email', 'phone'];
      const niceToHaveColumns = ['height', 'weight', 'age', 'gender'];

      const parsedDataFields = Object.keys(parsedData[0]);
      setListColumns(parsedDataFields);

      // Check if all required columns are present in the header row
      if (!requiredColumns.every(col => parsedDataFields.includes(col))) {
        throw new Error('CSV file invalid. Missing required columns.');
      }

      const uniqueRowsSet = new Set();
      const newEmployeeList = [];

      parsedData.forEach(row => {
        try {
          const clientData = {};

          // Map data based on header names
          parsedDataFields.forEach(header => {
            // Check if the header is one of the required columns
            if (requiredColumns.includes(header.toLowerCase())) {
              clientData[header.toLowerCase()] = row[header];
            }

            if (niceToHaveColumns.includes(header.toLowerCase())) {
              clientData[header.toLowerCase()] = row[header];
            }
          });

          // Use a unique key, e.g., email, to check for duplicates
          const uniqueKey = clientData['email']; // Change 'email' to the desired key

          // Check if the row is unique before adding to the list
          if (!uniqueRowsSet.has(uniqueKey)) {
            uniqueRowsSet.add(uniqueKey);
            newEmployeeList.push(clientData);
          } else {
            // Handle duplicate row (optional)
            console.warn(`Duplicate row found for ${uniqueKey}`);
          }
        } catch (err) {
          handleSnackbar('error', err.message);
        }
      });

      handleSnackbar('success', t(`scanImportSnackOne`));

      setEmployeeList(newEmployeeList);
    } catch (err) {
      handleSnackbar('error', err.message);
    }
  };


  const handleExcel = (excelData) => {
    const requiredColumns = ['first name', 'last name', 'email', 'phone'];
    const niceToHaveColumns = ['height', 'weight', 'age', 'gender'];
    try {
      const headers = excelData[0];

      // Check if all required columns are present in the header row
      if (!requiredColumns.every(col => headers.includes(col))) {
        throw new Error('Excel file invalid. Missing required columns.');
      }
    } catch (err) {
      handleSnackbar('error', err.message);
      return;
    }

    const headers = excelData[0];
    const listColumns = headers.map(header => header.toLowerCase());
    setListColumns(listColumns);

    const uniqueRowsSet = new Set();
    const newEmployeeList = [];
    for (let i = 1; i < excelData.length; i++) {
      const row = excelData[i];

      if (row.length > 0) {
        try {
          const clientData = {};

          // Map data based on header names
          headers.forEach((header, index) => {
            // Check if the header is one of the required columns
            if (requiredColumns.includes(header.toLowerCase())) {
              clientData[header.toLowerCase()] = row[index];
            }
            if (niceToHaveColumns.includes(header.toLowerCase())) {
              clientData[header.toLowerCase()] = row[index];
            }
          });

          const uniqueKey = clientData['email']; // Change 'email' to the desired key

          // Check if the row is unique before adding to the list
          if (!uniqueRowsSet.has(uniqueKey)) {
            uniqueRowsSet.add(uniqueKey);
            newEmployeeList.push(clientData);
          }

        } catch (err) {
          handleSnackbar('error', err.message);
          return 'error';
        }
      }
    }
    handleSnackbar('success', t(`scanImportSnackOne`));
    setEmployeeList(newEmployeeList);
  };

  const handleFileUpload = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setUploadedFileName(file.name);
    try {
      const fileTypeFromName = file.name.endsWith('.xlsx') ? 'xlsx' : file.name.endsWith('.csv') ? 'csv' : '';

      if (fileTypeFromName === 'xlsx') {
        // Handle Excel file upload
        // You can use the xlsx library to process the uploaded Excel file
        const reader = new FileReader();
        reader.onload = (event) => {
          const data = new Uint8Array(event.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          // Process the workbook data
          handleExcel(sheetData);
        };
        reader.readAsArrayBuffer(file);
      } else if (fileTypeFromName === 'csv') {
        // Handle CSV file upload
        // You can use the papaparse library to process the uploaded CSV file
        Papa.parse(file, {
          complete: (result) => {
            const data = result.data;
            handleCsv(data);
          },
          header: true,
        });
      }
    } catch (err) {
      handleSnackbar('error', err.message);
      setUploadSeverity('error');
    } finally {
      setIsLoading(false);
    }

  };

  const handleChangeListName = (event) => {
    setEmployeeListName(event.target.value);
    if (usedListNames.includes(event.target.value)) {
      setErrorListName(true);
    } else {
      setErrorListName(false);
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="body1" fontWeight={'bold'}>
          {t(`scanImportHeaderFour`)}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          label={t(`scanImportLabelThree`)}
          value={employeeListName}
          onChange={handleChangeListName}
          sx={{ minWidth: 120 }}
        />
        {
          errorListName && (
            <Typography color={colors.error}>
              {t(`scanImportErrorOne`)}
            </Typography>
          )
        }
      </Grid>
      {
        ['esenca', 'company'].includes(role) && (
          <Grid item xs={6}>
            <FormControl sx={{ m: 1, minWidth: 240}}>
              <InputLabel>Assign to:</InputLabel>
              <Select
                value={selectedClient}
                label={"Select an Organization"}
                onChange={handleSelectClient}
              >
                {
                  Object.keys(availableOrgs).length > 0 && Object.keys(availableOrgs).map((list, idx) => {
                      return (
                        <MenuItem value={list} key={idx}>
                          <ListItemText primary={availableOrgs[list]?.name} />
                        </MenuItem>
                      )
                  })
                }
              </Select>
            </FormControl>
          </Grid>
        )
      }
      <Grid item xs={12} md={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" fontWeight={'bold'}>
              {t(`scanImportHeaderFive`)}
            </Typography>
          </Grid>
          <Grid item xs={12} height={'20%'}>
            <FormControl className="tour-choose-file-type">
              <RadioGroup
                value={fileType}
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={changeFileType}
              >
                <Grid item xs={12}>
                  <FormControlLabel value="xlsx" control={<Radio />} label="XLSX (Excel)" />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel value="csv" control={<Radio />} label="CSV" />
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" onClick={download}>
              {t(`Download Template`)}
            </Button>
            <a style={{ display: 'none' }} download={fileNames[fileType]} href={fileDownloadUrl} ref={dofileDownload}>download it</a>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" fontWeight={'bold'}>
              {t(`scanImportHeaderSix`)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Dropzone handleFileUpload={handleFileUpload} isLoading={isLoading} setIsLoading={setIsLoading} uploadSeverity={uploadSeverity} />
            {
              employeeList.length > 0 && uploadedFileName.length > 0 && (
                <Typography variant="body2">
                  {/* Uploaded file: {uploadedFileName}. Found {employeeList.length} recipients. */}
                  {t(`scanImportLabelSeven`, { uploadedFileName: uploadedFileName, number: employeeList.length })}
                </Typography>
              )
            }
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}