import { 
  Button,
  Drawer, 
  Typography 
} from "@mui/material";
import { borders, colors } from "../../defaultTheme";
import pdfFile from "../../assets/ESENCA TUTORIAL.pdf"
import { useTranslation } from "react-i18next";

export default function ScanGuideComponent({
  open,
  handleClose
}) {
  const { t } = useTranslation();

  const drawerWidth = 300;

  const handleDownloadGuide = () => {
    const downloadLink = document.createElement('a');
    downloadLink.href = pdfFile;
    downloadLink.download = 'esenca-scan-guide.pdf';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  return(
    <Drawer
      anchor={"right"}
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiDrawer-paper': { 
          boxSizing: 'border-box', 
          width: drawerWidth, 
          borderWidth: 0,
          backgroundColor: colors.primary,
          borderRadius: borders.borderRadius.sm,
          color: colors.sidenavTextSelected,
          py: 2,
          px: 5
        },
      }}
    >
      <Button
        variant="contained"
        sx={{
          backgroundColor: colors.secondary,
          color: colors.primary,
          mb: 5,
          "&:hover" : {
            backgroundColor: colors.dark,
            color: colors.sidenavTextSelected
          },
          "&:focus" : {
            color: colors.sidenavTextSelected
          }
        }}
        onClick={handleDownloadGuide}
      >
        {t(`scanGuideButton`)}
      </Button>
      <Typography variant="h6" fontWeight={'bold'} sx={{ textWrap: 'pretty' }}>
        {t(`scanGuideHeaderOne`)}
      </Typography>
      <Typography variant="body2" textAlign={'justify'} sx={{ textWrap: 'pretty' }} mb={2}>
        {t(`scanGuideLabelOne`)}
      </Typography>
      <Typography variant="h6" fontWeight={'bold'} sx={{ textWrap: 'pretty' }}>
        {t(`scanGuideHeaderTwo`)}
      </Typography>
      <Typography variant="body2" textAlign={'justify'} sx={{ textWrap: 'pretty' }} mb={2}>
        {t(`scanGuideLabelTwo`)}
      </Typography>
      <Typography variant="h6" fontWeight={'bold'} sx={{ textWrap: 'pretty' }} >
        {t(`scanGuideHeaderThree`)}
      </Typography>
      <Typography variant="body2" textAlign={'justify'} sx={{ textWrap: 'pretty' }}>
        {t(`scanGuideLabelThree`)}
      </Typography>
      <Typography variant="body2" textAlign={'justify'} sx={{ textWrap: 'pretty' }}>
        {t(`scanGuideLabelFour`)}
      </Typography>
    </Drawer>
  )
}