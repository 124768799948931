import { useEffect, useState } from "react";

import {
  AppBar, Box, CardMedia, CssBaseline, Drawer, FormControl, Grid, IconButton, InputLabel, List, ListItem, ListItemButton, ListItemText,
  MenuItem, Toolbar, Select, Typography, Divider
} from "@mui/material";

import { ChevronLeft, ChevronRight, Close, Menu, RotateLeft, RotateRight, SettingsBackupRestore, ZoomIn, ZoomOut, } from "@mui/icons-material";

import { createTheme, ThemeProvider, styled, useTheme } from '@mui/material/styles';

// import { convertCentimetersToInches, convertInchesToCentimeters } from "./utils";

function convertCentimetersToInches(len) {
  return parseFloat((len * 0.393700787).toFixed(5));
}

/**
 * Convert inches to centimeters
 * @param {int} len Length to be converted to centimeters
*/
function convertInchesToCentimeters(len) {
  return parseFloat((len * 2.54).toFixed(5));
}

const env_type = process.env.REACT_APP_ENV_TYPE;
const url_3d = process.env.REACT_APP_URL3D;
// const url_3d = 'https://dashboard.test.esenca.app/avatar_viewer'

const all_measurements = {
  body_chest_none_circumference_1: 'Chest Circumference',
  legs_belowKnee_right_circumference_2: 'Right Ankle Circumference',
  legs_belowKnee_left_circumference_2: 'Left Ankle Circumference',
  arms_aboveElbow_right_circumference_1: 'Right Biceps Circumference',
  arms_aboveElbow_left_circumference_1: 'Left Biceps Circumference',
  body_pelvis_none_circumference_1: 'Bottom Circumference',
  legs_belowKnee_right_circumference_1: 'Right Calf Circumference',
  legs_belowKnee_left_circumference_1: 'Left Calf Circumference',
  arms_belowElbow_right_circumference_1: 'Right Forearm Circumference',
  arms_belowElbow_left_circumference_1: 'Left Forearm Circumference',
  head_neck_none_circumference_1: 'Neck Circumference',
  legs_aboveKnee_right_circumference_5: 'Right Thigh Circumference',
  legs_aboveKnee_left_circumference_5: 'Left Thigh Circumference',
  body_diaphragm_none_circumference_1: 'Upper Waist Circumference',
  body_abs_none_circumference_1: 'Waist Circumference',
  body_abs_none_circumference_2: 'Lower Waist Circumference',
  arms_belowElbow_right_circumference_2: 'Right Wrist Circumference',
  arms_belowElbow_left_circumference_2: 'Left Wrist Circumference',
  body_whole_none_contour_1: 'Body Length',
  // arms_whole_right_contour_1: 'Right Arm Length',
  // arms_whole_left_contour_1: 'Left Arm Length',
  legs_whole_right_contour_1: 'Leg Length',
  head_neck_none_linear_1: 'Neck Height',
  height: 'Stature',
};

const getMuiTheme = (open) => createTheme(
  open ? (
    {
      components: {
        MuiCardMedia: {
          styleOverrides: {
            root: {
              width: "70vw",
              height: "90vh",
              border: "none",
              overflow: "hidden",
              marginLeft: "auto",
              marginRight: "auto",
            }
          }
        },
      }
    }
  ) : (
    {
      components: {
        MuiCardMedia: {
          styleOverrides: {
            root: {
              width: "90vw",
              height: "90vh",
              border: "none",
              overflow: "hidden",
              marginLeft: "-20vw",
              marginRight: "auto",
            }
          }
        },
      }
    }
  )
);

const drawerWidth = "20vw";

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const MUIAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

function PersistentDrawerLeft({
  linear,
  volumetric,
  setLinear,
  setVolumetric,
  currentSelected,
  setCurrentSelected,
  lastSelected,
  setLastSelected,
  handleCloseDialog,
  betas,
  gender,
}) {
  const theme = useTheme();
  const [open, setOpen] = useState(true);

  const [drawerLinear, setDrawerLinear] = useState({});
  const [drawerVolumetric, setDrawerVolumetric] = useState({});
  const [unit, setUnit] = useState("metric");

  useEffect(() => {
    setDrawerLinear(linear);
    setDrawerVolumetric(volumetric);
  }, []);

  const handleUnitChange = (event) => {
    setUnit(event.target.value);

    if (event.target.value == "metric") {
      let volumetric_arr = volumetric;

      for (let i = 0; i < Object.keys(volumetric_arr).length; i++) {
        let current = Object.keys(volumetric_arr)[i];
        volumetric_arr[current] = convertInchesToCentimeters(parseFloat(volumetric[current]));
      }

      setVolumetric(volumetric_arr);
    } else {
      let volumetric_arr = volumetric;

      for (let i = 0; i < Object.keys(volumetric_arr).length; i++) {
        let current = Object.keys(volumetric_arr)[i];
        volumetric_arr[current] = convertCentimetersToInches(parseFloat(volumetric[current]));
      }
      setVolumetric(volumetric_arr);
    }
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleSelected = (cs, text) => {
    if (cs != text) {
      setCurrentSelected(text);
    } else {
      setCurrentSelected(null);
      setLastSelected(text);
    }
  }

  const handleControls = (command) => {
    let destination = document.getElementById("destination").contentWindow;
    destination.postMessage({ control: command}, "*");
  }

  return (
    <Box sx={{ display: 'flex', width: "100%" }}>
      <CssBaseline />
      <MUIAppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="close dialog"
            onClick={() => handleCloseDialog()}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <Close />
          </IconButton>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <Menu />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Avatar Viewer
          </Typography>
          <Box display={open && "none"}>
            <IconButton title="Rotate Towards Left" onClick={() => handleControls("rotateLeft")}>
              <RotateLeft />
            </IconButton>
            <IconButton title="Rotate Towards Right" onClick={() => handleControls("rotateRight")}>
              <RotateRight />
            </IconButton>
            <IconButton title="Zoom In" onClick={() => handleControls("zoomIn")}>
              <ZoomIn />
            </IconButton>
            <IconButton title="Zoom Out" onClick={() => handleControls("zoomOut")}>
              <ZoomOut />
            </IconButton>
            <IconButton title="Reset View" onClick={() => handleControls("reset")}>
              <SettingsBackupRestore />
            </IconButton>
          </Box>
        </Toolbar>
      </MUIAppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
        >
          <DrawerHeader>
            <IconButton onClick={() => handleCloseDialog()} sx={{ marginRight: "auto" }}>
              <Close />
            </IconButton>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
            </IconButton>
          </DrawerHeader>
          <DrawerHeader>
            <Grid container spacing={2} px={2}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  Avatar Viewer
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Box>
                  <IconButton title="Rotate Towards Left" onClick={() => handleControls("rotateLeft")}>
                    <RotateLeft />
                  </IconButton>
                  <IconButton title="Rotate Towards Right" onClick={() => handleControls("rotateRight")}>
                    <RotateRight />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Box>
                  <IconButton title="Zoom In" onClick={() => handleControls("zoomIn")}>
                    <ZoomIn />
                  </IconButton>
                  <IconButton title="Zoom Out" onClick={() => handleControls("zoomOut")}>
                    <ZoomOut />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box>
                  <IconButton title="Reset View" onClick={() => handleControls("reset")}>
                    <SettingsBackupRestore />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </DrawerHeader>
          <Divider />
          <DrawerHeader>
            <Grid container>
              <Grid item xs={12}>
                <FormControl sx={{ m: 1, minWidth: 200 }}>
                  <InputLabel>Unit Select</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={unit}
                    onChange={handleUnitChange}
                    label="Unit Select"
                    fullWidth
                  >
                    <MenuItem value={"metric"}>metric</MenuItem>
                    <MenuItem value={"imperial"}>imperial</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DrawerHeader>
        </Box>
        <List>
          {Object.keys(volumetric).map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton onClick={() => handleSelected(currentSelected, text)} selected={currentSelected == text ? true : false}>
                <ListItemText
                  primary={all_measurements[text]}
                  secondary={unit == "metric" ? volumetric[text] + " cm" : volumetric[text] + " inches"}
                  primaryTypographyProps={{ variant: "subtitle2" }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <ThemeProvider theme={() => getMuiTheme(open)}>
          <CardMedia
            component={"iframe"}
            src={url_3d + "?betas=" + betas + "&gender=" + gender}
            id="destination"
          />
        </ThemeProvider>
      </Main>
    </Box>
  );
}

export default function Avatar3D({ clientInfo, handleCloseDialog }) {

  const [volumetric, setVolumetric] = useState([]);
  const [currentSelected, setCurrentSelected] = useState(null);
  const [lastSelected, setLastSelected] = useState(null);
  const [iframeDestination, setIframeDestination] = useState("");

  const dashboardToIframe = {
    body_chest_none_circumference_1: 'chest_circumference',
    legs_belowKnee_right_circumference_2: 'right_ankle_circumference',
    legs_belowKnee_left_circumference_2: 'left_ankle_circumference',
    arms_aboveElbow_right_circumference_1: 'right_biceps_circumference',
    arms_aboveElbow_left_circumference_1: 'left_biceps_circumference',
    body_pelvis_none_circumference_1: 'bottom_circumference',
    legs_belowKnee_right_circumference_1: 'right_calf_circumference',
    legs_belowKnee_left_circumference_1: 'left_calf_circumference',
    arms_belowElbow_right_circumference_1: 'right_forearm_circumference',
    arms_belowElbow_left_circumference_1: 'left_forearm_circumference',
    head_neck_none_circumference_1: 'neck_circumference',
    legs_aboveKnee_right_circumference_5: 'right_thigh_circumference',
    legs_aboveKnee_left_circumference_5: 'left_thigh_circumference',
    body_diaphragm_none_circumference_1: 'upper_waist_circumference',
    body_abs_none_circumference_1: 'waist_circumference',
    body_abs_none_circumference_2: 'lower_waist_circumference',
    arms_belowElbow_right_circumference_2: 'right_wrist_circumference',
    arms_belowElbow_left_circumference_2: 'left_wrist_circumference',
    body_whole_none_contour_1: 'shoulder_to_hip',
    // arms_whole_right_contour_1: 'right_arm_length',
    // arms_whole_left_contour_1: 'left_arm_length',
    legs_whole_right_contour_1: 'leg_length',
    head_neck_none_linear_1: 'neck_height',
    height: 'height',
  };

  useEffect(() => {

    let measurements = clientInfo.measurements;

    const newObject = {};

    for (const key in measurements) {
      if (measurements.hasOwnProperty(key) && dashboardToIframe.hasOwnProperty(key)) {
        if (typeof measurements[key].value === "number") {
          newObject[key] = measurements[key].value.toFixed(2)
        } else {
          newObject[key] = measurements[key].value
        }
      }
    }

    newObject.height = clientInfo.height

    setVolumetric(newObject);

    let destination = document.getElementById("destination").contentWindow;
    setIframeDestination(destination);
  }, []);

  useEffect(() => {
    if (iframeDestination) {
      if (currentSelected != null) {
        iframeDestination.postMessage({ measurement: dashboardToIframe[currentSelected], value: true }, "*");
      }
      
      if (currentSelected == null && lastSelected) {
        iframeDestination.postMessage({ measurement: dashboardToIframe[lastSelected], value: false }, "*");
      }
    }
  }, [currentSelected]);

  return (
    <Grid container space={3}>
      <PersistentDrawerLeft
        setVolumetric={setVolumetric}
        volumetric={volumetric}
        currentSelected={currentSelected}
        setCurrentSelected={setCurrentSelected}
        lastSelected={lastSelected}
        setLastSelected={setLastSelected}
        handleCloseDialog={handleCloseDialog}
        betas={clientInfo.betas}
        gender={clientInfo.gender}
      />
    </Grid>

  );
}