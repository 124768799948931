import {useState} from 'react';

import { Alert, CssBaseline, TextField, Box, Card, Grid, Typography, Container, Link } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { signInWithEmailAndPassword } from 'firebase/auth'
import {auth} from '../../firebase'

import { useNavigate } from 'react-router-dom';
 
import logo from '../../assets/esenca_logo_color_RGB.png'

import {
  colors,
  borders,
  boxShadows,
  pxToRem,
} from "../../defaultTheme";

export default function SignIn() {

  const navigate = useNavigate();

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  function convertErrorCodeToMessage(errorCode) {
    const parts = errorCode.split('/');
    const lastPart = parts[parts.length - 1];
    const errorMessage = lastPart.replace(/-/g, ' ');
  
    return errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1) + '.';
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true)
    const data = new FormData(event.currentTarget);
    let email = data.get('email')
    let password = data.get('password')

    signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            const user = userCredential.user;
            navigate("/home")
        })
        .catch((error) => {
            setLoading(false)
            const errorCode = error.code;
            const errorMessage = error.message;
           
            if(errorCode === "auth/user-not-found" || errorCode === "auth/wrong-password"){
              const err = "Wrong credentials"
              setError(err)
            } else {
              const err = convertErrorCodeToMessage(errorCode);
              setError(err)
            }
        });
  };

  return (
    <Grid container spacing={2}>
      <CssBaseline />
      <Grid item xs={5} pr={pxToRem(100)}>
        <Box height={'calc(100vh - 100px)'} display={'flex'} justifyContent={'center'} flexDirection={'column'}>
            {/* <Typography variant='h1' color={'grey'} textAlign={'right'}>AI Analytics</Typography>
            <Typography variant='h1' color={'grey'} textAlign={'right'}>Body Scans</Typography>
            <Typography variant='h1' color={'grey'} textAlign={'right'}>3D Avatars</Typography> */}
            <img src={logo} style={{ maxHeight: '100px', height: 'auto', width: 'auto', alignSelf: 'end' }} />

            {/*  VARIANTE CARE IMI PLAC  */}
            {/* <Typography variant='h2' fontWeight={700} sx={{ alignSelf: 'end' }}>Dashboard</Typography> */}
            {/* <Typography variant='h2' fontWeight={700} sx={{ alignSelf: 'end' }}>dashboard</Typography> */}


            {/*  VARIANTE DE LA THEO  */}
            {/* <Typography variant='h2' fontWeight={500} sx={{ alignSelf: 'end' }}>DASHBOARD</Typography> */}
            {/* <Typography variant='h2' fontWeight={500} fontStyle={'italic'} sx={{ alignSelf: 'end', letterSpacing: '.3rem' }}>Dashboard</Typography> */}
            {/* <Typography variant='h3' fontWeight={500} fontStyle={'italic'} sx={{ alignSelf: 'end', letterSpacing: '.45rem' }}>Dashboard</Typography> */}
            <Typography variant='h2' fontWeight={700} fontStyle={'italic'} sx={{ alignSelf: 'end' }}>dashboard</Typography>

            {/*  VARIANTE CARE NU IMI PLAC  */}
            {/* <Typography variant='h1' fontWeight={800} fontFamily={'Como'} sx={{ alignSelf: 'end' }}>Dashboard</Typography> */}
            {/* <Typography variant='h2' fontWeight={700} color={'#27336e'} sx={{ alignSelf: 'end' }}>dashboard</Typography> */}
            {/* <Typography fontWeight={400} fontSize={'7rem'} fontFamily={'Dongle'} color={'#27336e'} sx={{ alignSelf: 'end' }}>dashboard</Typography> */}
            {/* <Typography fontWeight={400} fontSize={'7rem'} fontFamily={'Dongle'} sx={{ alignSelf: 'end' }}>dashboard</Typography> */}
        </Box>
      </Grid>
      <Grid item xs={7} display={'flex'} justifyContent={'center'}>
        <Container component="main" maxWidth="md" height={'calc(100vh - 100px)'}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              padding: pxToRem(72),
              height: '80%',
              borderRadius: borders.borderRadius.xxl,
              boxShadow: boxShadows.xxl
            }}
          >
            <Box 
              display={'flex'}
              alignItems={'center'}
            >
              <Typography component="h1" variant="h5">Sign in</Typography>
              {/* <Link marginLeft={'auto'} sx={{ color: colors.primary, fontWeight: 'bold', '&:hover': { cursor: 'pointer' }}}>Forgot password</Link> */}
            </Box>
            {/* <Box width={"100%"}>
              <Alert variant="filled" severity="info">
                Scheduled Maintenance - From Wednesday, March 25th, 14:00 GMT to Thursday, March 28th, 14:00 GMT - You might experience interruptions.
              </Alert>
            </Box> */}
            <Typography component="h1" variant="caption" color="error" sx={{ mt: 1 }}>{error}</Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} height={'80%'} display={'flex'} flexDirection={'column'}>
              {/* <Typography variant='h2' fontWeight={700} sx={{ alignSelf: 'start' }}>dashboard</Typography> */}
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    sx={{
                      marginTop: 'auto'
                    }}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                />
                <LoadingButton 
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ 
                      marginTop: 'auto',
                      height: pxToRem(50),
                    }}
                    // loadingPosition='start'
                    loading={loading}
                    id='sign-in-button'
                >
                  {loading ? "Loading..." : "Sign In"}
                </LoadingButton>
                {/* <Typography variant='body1' mt={pxToRem(16)}> Need an account? <Link sx={{ color: colors.primary, fontWeight: 'bold', '&:hover': { cursor: 'pointer' }}}>Contact us</Link> </Typography> */}
            </Box>
            <Typography variant='caption'>
              This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
            </Typography>
            <Typography variant='caption'>
              By using this site, you agree to the ESENCA <a href="https://esenca.ai/privacy-policy">Privacy Policy</a>
            </Typography>
          </Card>
      </Container>
      </Grid>
    </Grid>
  );
}