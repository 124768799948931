import { Box, CircularProgress, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import {useDropzone} from 'react-dropzone';
import { colors } from '../../../../defaultTheme';
import { useTranslation } from "react-i18next";

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: colors.primary,
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: colors.primary,
    outline: 'none',
    transition: 'border .24s ease-in-out',
    height: '20vh',
    cursor: 'pointer'
};
  
const focusedStyle = {
    borderColor: '#2196f3'
};
  
const acceptStyle = {
    borderColor: '#00e676'
};
  
const rejectStyle = {
    borderColor: '#ff1744'
};

export default function Dropzone({ handleFileUpload, isLoading, setIsLoading, uploadSeverity }) {
    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({    
        maxFiles:1,
        accept: {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'text/csv': ['.csv']
        },
      });
    const { t } = useTranslation();


    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject,
    ]);

    useEffect(() => {
        if (acceptedFiles.length > 0) {
            setIsLoading(true);
            handleFileUpload(acceptedFiles);
        }
    }, [acceptedFiles]);
  
    return (
      <section className="container">
        <Box {...getRootProps({style})}>
            <input {...getInputProps()} />
            {
                isLoading ? (
                    <CircularProgress />
                ) : (
                    <Typography variant='body2' alignSelf={'center'}>
                        {t(`scanImportLabelFour`)}
                    </Typography>
                )
            }
        </Box>
        <Typography variant='body2'>
            {t(`scanImportLabelSix`)}
        </Typography>
      </section>
    );
  }