import { useState, useEffect } from 'react';

import { TableContainer, TableHead, Table, TableCell, TableRow, TableBody, Button, IconButton, Dialog, DialogContent,
  DialogContentText, DialogTitle, DialogActions } from '@mui/material';
import { ReadMore, Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/material/styles";

import CollectionDetailsDialog from './CollectionDetailsDialog';

import { useAuth } from '../../../../AuthContext';
import { colors } from '../../../../defaultTheme';
import { parseTimestamp } from '../../../Measurements/utils/utils';

import { useTranslation } from "react-i18next";
import { useParams, useNavigate, useLocation } from 'react-router-dom';

const database_url = process.env.REACT_APP_DATABASE_URL;

function createData(id, createdAt, updatedAt, name, products) {
  return { id, createdAt, updatedAt, name, products };
}

function CollectionsTable(props) {
  const { companyCollections, setCompanyCollections, permissions, companyId, currentUser, handleSnackbar, userId, 
    companyProducts, setCompanyProducts, companyConfig  } = useAuth()
  const { t } = useTranslation();

  const [rows, setRows] = useState([]);
  const [currentCollection, setCurrentCollection] = useState([]);
  
  const [buttonLoader, setButtonLoader] = useState(false);

  const [openCollectionDialog, setOpenCollectionDialog] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  const [canDeleteCollection, setCanDeleteCollection] = useState(false);

  const theme = useTheme();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const collectionId = params.get('id');

  const { tab, dialog } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (companyCollections) {
      let items = companyCollections
      let rows = []
      for (const key in items) {
        let products = 0
        for(const prod in companyProducts){
          if (companyProducts[prod].hasOwnProperty("collection") && companyProducts[prod]["collection"] === key) {
            products++
          }
        }
        let timezone = companyConfig.timezone
        let createdAt = items[key].createdAt ?  parseTimestamp(items[key].createdAt, timezone) : ""
        let updatedAt = items[key].updatedAt ?  parseTimestamp(items[key].updatedAt, timezone) : ""
        if (items[key].hasOwnProperty("name")) {
          let name = items[key].name
          rows.push(createData(key, createdAt, updatedAt, name, products))
        } else {
          let name = String(key).split("_").join(" ")
          rows.push(createData(key, createdAt, updatedAt, name, products))
        }
      }

      setRows(rows)
    }
  }, [companyCollections, companyProducts])


  useEffect(()=>{
    if(permissions && permissions.includes('delete_collections')) {
      setCanDeleteCollection(true)
    }
  },[permissions])

  useEffect(() => {
    switch (dialog) {
      case 'details':
        setOpenCollectionDialog(true);
        break;
      case 'delete':
        setOpenDeleteDialog(true);
        break;
      default:
        setOpenCollectionDialog(false);
        setOpenDeleteDialog(false);
        break;
    }
  }, [dialog]);

  const collectionDialog = (id) => {
    if (companyCollections.hasOwnProperty(id)) {
      navigate(`/size-charts/${tab}/details?id=${id}`); 
    }
  }

  const deleteCollectionDialog = (id) => {
    if (companyCollections.hasOwnProperty(id)) {
      navigate(`/size-charts/${tab}/delete?id=${id}`); 
    }
  }

  const handleCloseCollectionDialog = () => {
    navigate(`/size-charts/${tab}`);
    setOpenCollectionDialog(false);
    window.tidioChatApi.display(true);
  };

  const handleCloseDeleteDialog = () => {
    navigate(`/size-charts/${tab}`);
    setOpenDeleteDialog(false);
    window.tidioChatApi.display(true);
  };

  const handleDelete = async() => {
    setButtonLoader(true)
    const accessToken = await currentUser.getIdToken(true);
    let data = {}
    if (companyId) {
      data = { collection_id: collectionId };

      fetch(database_url+"/delete_company_collection?user_id=" + userId + '&company_id=' + companyId, {
          method: "DELETE",
          headers: {"Accept": "application/json", "Content-Type": 'application/json', 'x-access-token': accessToken },
          body: JSON.stringify(data)
      }).then(() => {
          handleSnackbar('success', "Collection deleted");
          let promises = Promise.all([
            fetch(database_url + '/view_company_collections?user_id=' + userId + '&company_id=' + companyId, {
              method: 'GET',
              headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
            }),
            fetch(database_url + '/view_company_products?user_id=' + userId + '&company_id=' + companyId, {
              method: 'GET',
              headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
            }),
          ]);

          promises
          .then((results) => Promise.all(results.map((r) => r.json())))
          .then((resultsData) => {
            const [companyCollections, companyProducts] = resultsData;
            setCompanyCollections(companyCollections || {});
            setCompanyProducts(companyProducts || {})
          }).then(()=>{
            setOpenDeleteDialog(false)
            setButtonLoader(false)
            navigate(`/size-charts/${tab}`);
          })
      });
    } else {
      setButtonLoader(false)
      navigate(`/size-charts/${tab}`);
    }
  }

  return (
    <>
      <TableContainer>
        <Table size='small'>
          <TableHead sx={{ display: "table-header-group", backgroundColor: theme.palette.primary.main }}>
            <TableRow>
            {canDeleteCollection && 
              <TableCell align="center" sx={{ fontWeight: 'bold', color: colors.sidenavTextSelected }}></TableCell>
            }
              <TableCell align="center" sx={{ fontWeight: 'bold', color: colors.sidenavTextSelected }}>{t(`Created`)}</TableCell>
              <TableCell align="center" sx={{ fontWeight: 'bold', color: colors.sidenavTextSelected }}>{t(`Updated`)}</TableCell>
              <TableCell align="center" sx={{ fontWeight: 'bold', color: colors.sidenavTextSelected }}>{t(`Collection Name`)}</TableCell>
              <TableCell align="center" sx={{ fontWeight: 'bold', color: colors.sidenavTextSelected }}>{t(`Products`)}</TableCell>
              <TableCell align="center" sx={{ fontWeight: 'bold', color: colors.sidenavTextSelected }}>{t(`Details`)}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
              {canDeleteCollection && 
                <TableCell align="left">
                  <IconButton
                    onClick={() => { deleteCollectionDialog(row.id) }}
                  >
                    <Delete fontSize='small'/>
                  </IconButton>
                </TableCell>
              }
                <TableCell align="center">{row.createdAt}</TableCell>
                <TableCell align="center">{row.updatedAt}</TableCell>
                <TableCell align="center">{row.name}</TableCell>
                <TableCell align="center">{row.products}</TableCell>
                <TableCell align="center">
                  <IconButton
                    onClick={() => { collectionDialog(row.id) }}
                  >
                    <ReadMore color="primary" fontSize='small'/>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        fullScreen
        maxWidth="lg"
        open={openCollectionDialog}
        onClose={handleCloseCollectionDialog}
      >
        <DialogContent>
          <CollectionDetailsDialog
            collection={collectionId}
            handleCloseDialog={handleCloseCollectionDialog}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delete collection?  
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the collection ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Disagree</Button>
          <LoadingButton
              onClick={()=>{handleDelete()}} 
              loading={buttonLoader}
              variant="contained"
              autoFocus
          >
            Agree
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CollectionsTable;
