import { useEffect, useState } from 'react';
import { CircularProgress, TextField, Select, MenuItem, FormControl, InputLabel, Button, Grid, Tooltip, ClickAwayListener } from '@mui/material';
import { Add, Check, ErrorOutline } from '@mui/icons-material';
import { useAuth } from '../../../AuthContext';
import CreateClientDialog from "./CreateClient/CreateClientDialog";

import { useTranslation } from "react-i18next";

const database_url = process.env.REACT_APP_DATABASE_URL;

const AddClientAccountForm = ({ selectedRole, setSelectedRole }) => {

  const { handleSnackbar, companyClients, currentUser, companyId } = useAuth();

  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    clientName: '',
    userFirstName: '',
    userLastName: '',
    userEmail: '',
  });
  const [formValid, setFormValid] = useState(false);
  const [openCreateClientDialog, setOpenCreateClientDialog] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [tooltipFirstName, setTooltipFirstName] = useState(true);
  const [tooltipLastName, setTooltipLastName] = useState(true);
  const [tooltipEmail, setTooltipEmail] = useState(true);

  const handleOpenCreateClientDialog = () => {
    window.tidioChatApi.display(false);
    setOpenCreateClientDialog(true);
  };

  const handleCloseCreateClientDialog = () => {
    window.tidioChatApi.display(true);
    setOpenCreateClientDialog(false);
  };

  const handleTooltipClose = (tooltipName) => {
    if (tooltipName == 'userFirstName') {
      setTooltipFirstName(false);
    }
    if (tooltipName == 'userLastName') {
      setTooltipLastName(false);
    }
    if (tooltipName == 'userEmail') {
      setTooltipEmail(false);
    }
  }

  const handleTooltipOpen = (tooltipName) => {
    if (tooltipName == 'userFirstName') {
      setTooltipFirstName(true);
    }
    if (tooltipName == 'userLastName') {
      setTooltipLastName(true);
    }
    if (tooltipName == 'userEmail') {
      setTooltipEmail(true);
    }
  }

  const onlyLettersRegex = /^[A-Za-z]+$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      if (!emailRegex.test(formData.userEmail)) {
        setTooltipEmail(true);
        return;
      }

      if (!onlyLettersRegex.test(formData.userFirstName)) {
        setTooltipFirstName(true);
        return;
      }

      if (!onlyLettersRegex.test(formData.userLastName)) {
        setTooltipLastName(true);
        return;
      }

      if (formData.clientName && formData.userEmail && formData.userFirstName && formData.userLastName) {
        const accessToken = await currentUser.getIdToken(true);
        let response = await fetch(database_url + "/send_client_account_request?user_id=" + currentUser.uid, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'x-access-token': accessToken },
          body: JSON.stringify({
            selectedRole,
            'senderId': currentUser.uid,
            'senderCompanyId': companyId,
            'clientName': formData.clientName,
            'userFirstName': formData.userFirstName,
            'userLastName': formData.userLastName,
            'userEmail': formData.userEmail
          })
        });

        let result = await response.json();

        if (result['success']) {
          handleSnackbar('success', 'Request Sent Successfully!');
        } else {
          throw new Error('Could not send request.')
        }
      } 
    } catch (err) {
      handleSnackbar('error', err.message);
    } finally {
      setIsLoading(false);
      setSelectedRole('');
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    const isValid = Object.values(formData).every((value) => value !== '') && onlyLettersRegex.test(formData.userFirstName) && onlyLettersRegex.test(formData.userLastName) && emailRegex.test(formData.userEmail);
    setFormValid(isValid);
  }, [formData]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        {
          selectedRole == 'client' && (
            <Grid item xs={8}>
              <FormControl fullWidth>
                <InputLabel id="client-name-label">{t(`Client Name`)}</InputLabel>
                <Select
                  labelId="client-name-label"
                  id="client-name"
                  name="clientName"
                  label={t(`Client Name`)}
                  value={formData.clientName}
                  onChange={handleChange}
                  required
                >
                  {Object.keys(companyClients).map((id) => (
                    <MenuItem key={id} value={id}>
                      {companyClients[id].name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )
        }
        {
          selectedRole == 'client' && (
            <Grid item xs={4} display={'flex'} justifyContent={'center'}>
              <Button
                variant='contained'
                sx={{
                  alignSelf: 'center'
                }}
                startIcon={<Add />}
                onClick={handleOpenCreateClientDialog}
              >
                {t(`Add`)}
              </Button>
            </Grid>
          )
        }

        <Grid item xs={12} display={'flex'} justifyContent={'space-between'}>
          <TextField
            sx={{
              width: '80%'
            }}
            label={t(`User First Name`)}
            id="user-name"
            name="userFirstName"
            value={formData.userFirstName}
            onChange={handleChange}
            required
          />
          {
            formData.userFirstName && (
              onlyLettersRegex.test(formData.userFirstName) ? (
                <Check sx={{ alignSelf: 'center' }}/>
              ) : (
                <ClickAwayListener onClickAway={() => handleTooltipClose('userFirstName')}>
                  <Tooltip 
                    title={t(`newClientTooltipOne`)} 
                    arrow
                    onClose={() => handleTooltipClose('userFirstName')} 
                    onOpen={() => handleTooltipOpen('userFirstName')}
                    open={tooltipFirstName}
                    onClick={() => handleTooltipOpen('userFirstName')}
                  >
                    <ErrorOutline sx={{ alignSelf: 'center' }}/>
                  </Tooltip>
                </ClickAwayListener>
              )
            )
          }
        </Grid>

        <Grid item xs={12} display={'flex'} justifyContent={'space-between'}>
          <TextField
            sx={{
              width: '80%'
            }}
            label={t(`User Last Name`)} 
            id="user-name"
            name="userLastName"
            value={formData.userLastName}
            onChange={handleChange}
            required
          />
          {
            formData.userLastName && (
              onlyLettersRegex.test(formData.userLastName) ? (
                <Check sx={{ alignSelf: 'center' }}/>
              ) : (
                <ClickAwayListener onClickAway={() => handleTooltipClose('userLastName')}>
                  <Tooltip 
                    title={t(`newClientTooltipOne`)} 
                    arrow
                    onClose={() => handleTooltipClose('userLastName')} 
                    onOpen={() => handleTooltipOpen('userLastName')}
                    open={tooltipLastName}
                  >
                    <ErrorOutline sx={{ alignSelf: 'center' }}/>
                  </Tooltip>
                </ClickAwayListener>
              )
            )
          }
        </Grid>

        <Grid item xs={12} display={'flex'} justifyContent={'space-between'}>
          <TextField
            sx={{
              width: '80%'
            }}
            label={t(`User Email`)} 
            type="email"
            id="user-email"
            name="userEmail"
            value={formData.userEmail}
            onChange={handleChange}
            required
          />
          {
            formData.userEmail && (
              emailRegex.test(formData.userEmail) ? (
                <Check sx={{ alignSelf: 'center' }}/>
              ) : (
                <ClickAwayListener onClickAway={() => handleTooltipClose('userEmail')}>
                  <Tooltip 
                    title={t(`newClientTooltipTwo`)} 
                    arrow
                    onClose={() => handleTooltipClose('userEmail')}
                    onOpen={() => handleTooltipOpen('userEmail')}
                    open={tooltipEmail}
                  >
                    <ErrorOutline sx={{ alignSelf: 'center' }}/>
                  </Tooltip>
                </ClickAwayListener>
              )
            )
          }
        </Grid>

        {/* Submit Button */}
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary" disabled={isLoading || !(onlyLettersRegex.test(formData.userFirstName) && onlyLettersRegex.test(formData.userLastName) && emailRegex.test(formData.userEmail))}>
            { isLoading ? <CircularProgress /> : t(`Submit`)}

          </Button>
        </Grid>
      </Grid>
      <CreateClientDialog
        openCreateClientDialog={openCreateClientDialog}
        handleCloseCreateClientDialog={handleCloseCreateClientDialog}
      />
    </form>
  );
};

export default AddClientAccountForm;
