import React, { useEffect, useState } from 'react';
import {
  Box, Typography, Grid, TextField, Button, IconButton, OutlinedInput, InputLabel, MenuItem, FormControl, ListItemText, Select,
  Checkbox
} from '@mui/material';
import { LoadingButton } from "@mui/lab";

import { Close, ReportProblem as ReportProblemIcon } from '@mui/icons-material';

import { useAuth } from '../../../../AuthContext';
import { colors } from '../../../../defaultTheme';
import { customRenderValues } from '../../../utils/functions';
import { useTranslation } from "react-i18next";

const database_url = process.env.REACT_APP_DATABASE_URL;

function AddCollectionDialog(props) {
  const { role, userId, companyId, companyProducts, currentUser, setCompanyCollections, handleSnackbar, companyCollections,
    setCompanyProducts } = useAuth()
  const { t } = useTranslation();

  const [collectionName, setCollectionName] = useState("");
  const [items, setItems] = useState([]);
  const [companyCollectionsNames, setCompanyCollectionsNames] = useState([])

  const [error, setError] = useState(false)
  const [isDuplicatedName, setIsDuplicatedName] = useState(false)

  const [buttonLoader, setButtonLoader] = useState(false);

  useEffect(()=>{
    if(companyCollections){
      const collectionsNames = [];
      for (const item in companyCollections) {
          if (companyCollections[item].name) {
              const name = companyCollections[item]?.name
              const lowercase = name?.toLowerCase()
              if (!collectionsNames.includes(lowercase)) {
                collectionsNames.push(lowercase);
              }
          }
      }
      setCompanyCollectionsNames(collectionsNames)
    }
  },[companyCollections])

  useEffect(()=>{
    let lowerKey = collectionName.toLowerCase(); 
    if (companyCollectionsNames.includes(lowerKey)) {
      setIsDuplicatedName(true)
    } else {
      setIsDuplicatedName(false)
    }
  },[collectionName,companyCollectionsNames])

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setItems(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const saveCollection = async () => {
    setButtonLoader(true)

    const accessToken = await currentUser.getIdToken(true);
    if (collectionName === '') {
      setError(true)
      setButtonLoader(false)
    } else {
      setError(false)

      if (companyId) {
        let send_data = { collection_name: collectionName, products: items };

        fetch(database_url + "/add_company_collection?user_id=" + userId + '&company_id=' + companyId, {
          method: "POST",
          headers: { "Accept": "application/json", "Content-Type": 'application/json', 'x-access-token': accessToken },
          body: JSON.stringify(send_data)
        }).then(() => {
          handleSnackbar('success', t(`colSnack`));
          setButtonLoader(false)
          let promises = Promise.all([
            fetch(database_url + '/view_company_collections?user_id=' + userId + '&company_id=' + companyId, {
              method: 'GET',
              headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
            }),
            fetch(database_url + '/view_company_products?user_id=' + userId + '&company_id=' + companyId, {
              method: 'GET',
              headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
            }),
          ]);

          promises
          .then((results) => Promise.all(results.map((r) => r.json())))
          .then((resultsData) => {
            const [companyCollections, companyProducts] = resultsData;
            setCompanyCollections(companyCollections || {});
            setCompanyProducts(companyProducts || {})
          }).then(()=>{
            setIsDuplicatedName(false)
            props.handleCloseDialog()
          })
        });
      } else {
        setButtonLoader(false)
      }
    }
  }

  return (
    <div>
      <Grid container align="center">
      {role!=='esenca' &&
        <Grid item xs={12} mt={2}>
          <Box display={'flex'} justifyContent={'space-between'} sx={{ backgroundColor: colors.primary, px: 2, py: 2 }}>
            <Typography variant="body1" sx={{ alignSelf: 'center', color: colors.white }}>{t(`colHeaderOne`)}</Typography>
            <IconButton
              aria-label="close"
              onClick={props.handleCloseDialog}
              sx={{
                color: (theme) => theme.palette.grey[500],
                alignSelf: 'center'
              }}
            >
              <Close />
            </IconButton>
          </Box>
        </Grid>
      }
        <Grid item xs={12}>
          <TextField
            margin="dense"
            id="name"
            label= {t(`Collection Name`)}
            sx={{width:'300px'}}
            // variant="standard"
            value={collectionName}
            onChange={(event) => setCollectionName(event.target.value)}
            error={error}
            helperText={error ? t(`colErrorOne`) : ""}
          />
        </Grid>
        {isDuplicatedName && 
          <Grid item xs={12}>
            <Box display="flex" alignItems="left" mb={1}>
                <ReportProblemIcon color="warning" />
                <Box ml={1}>
              <Typography variant="body2" sx={{fontWeight:'bold'}}>
                Your company already has collection(s) that share identical name.
              </Typography>
              </Box>
            </Box>
          </Grid>
        }
        <Grid item xs={12} mt={5}>
          <Typography variant="body2">{t(`colLabelTwo`)}</Typography>
        </Grid>
        <Grid item xs={12} mt={2}>
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-checkbox-label">{t(`Products`)}</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={items}
              onChange={handleChange}
              input={<OutlinedInput label={t(`Products`)} />}
              renderValue={(selected) => selected.map((key) => companyProducts[key]?.name).join(', ')}
              sx={{ width: '100%' }}
            >
              {Object.keys(companyProducts).map((key) => {
                if (!companyProducts[key].collection) {
                  return (
                    <MenuItem key={key} value={key}>
                      <Checkbox checked={items.indexOf(key) > -1} />
                      <ListItemText primary={companyProducts[key].name} />
                    </MenuItem>
                  );
                }
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} mt={5}>
          <LoadingButton
              onClick={saveCollection}
              loading={buttonLoader}
              variant="contained"
          >
            {t(`colButtonOne`)}
          </LoadingButton>
        </Grid>
      </Grid>
    </div>
  );
}

export default AddCollectionDialog;
