import React, { useEffect, useState } from 'react';

import { Box, Toolbar, Card, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import { useParams, useNavigate } from 'react-router-dom';
import CompanyOnboarding from './components/CompanyOnboarding';
import CreateAccounts from './components/CreateAccounts';

const drawerWidth = 250;

function AdminOnboardingPage() {

    const { tab } = useParams(); 
    const navigate = useNavigate();
    
    const [value, setValue] = useState("company-onboarding");
  
    useEffect(() => {
      if (tab) {
        setValue(tab);
      } else {
        setValue("company-onboarding"); 
      }
    }, [tab]);
  
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
      navigate(`/admin/onboarding/${newValue}`);
    };

    return (
        <>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, ml: { sm: `${drawerWidth}px` }, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                <Card>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="tabs">
                        <Tab label={'Company Onboarding'} value="company-onboarding" />
                        <Tab label={'Create Account'} value="create-account" />
                    </TabList>
                    </Box>
                    <TabPanel value="company-onboarding">
                        <CompanyOnboarding/>
                    </TabPanel>
                    <TabPanel value="create-account">
                        <CreateAccounts/>
                    </TabPanel>
                </TabContext>
                </Card>
            </Box>
        </>
    );
}

export default AdminOnboardingPage;
