import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import { Close } from "@mui/icons-material";
import { colors } from "../../../../defaultTheme";
import { useAuth } from "../../../../AuthContext";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

const database_url = process.env.REACT_APP_DATABASE_URL;

export default function DeleteRecipientsDialog({
  openDeleteRecipientsDialog,
  handleCloseDeleteRecipientsDialog,
  selectedRecipientsList,
  setSelectedRecipientsList
}) {
  const {
    companyId,
    companyRecipientsLists,
    setCompanyRecipientsLists,
    handleSnackbar,
    currentUser,
  } = useAuth();
  const { t } = useTranslation();

  const [selectedListLength, setSelectedListLength] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (selectedRecipientsList) {
      setSelectedListLength(companyRecipientsLists[selectedRecipientsList].length);
    }
  }, [selectedRecipientsList]);

  const handleDelete = async () => {
    // let url = database_url;

    setIsLoading(true);

    const accessToken = await currentUser.getIdToken(true);
    let response = await fetch(database_url + "/delete_recipients_list?user_id=" + currentUser.uid, {
      method: 'DELETE',
      headers: {'Content-Type': 'application/json', 'Accept': 'application/json', 'x-access-token': accessToken},
      body: JSON.stringify({
        companyId,
        selectedRecipientsList,
      })
    })

    let result = await response.json();

    if (result['success']) {
      handleSnackbar("success", t(`scanContactsSnackOne`));
      setIsLoading(false);

      let new_companyRecipientsLists = companyRecipientsLists;
      delete new_companyRecipientsLists[selectedRecipientsList];
      setCompanyRecipientsLists(new_companyRecipientsLists);
      setSelectedRecipientsList("");

      handleCloseDeleteRecipientsDialog();
      
    } else {
      handleSnackbar("error", t(`scanContactsSnackTwo`));
    }

  }

  return (
    <Dialog
      // fullScreen
      maxWidth="lg"
      fullWidth
      open={openDeleteRecipientsDialog}
      onClose={handleCloseDeleteRecipientsDialog}
    >
      <DialogTitle
        sx={{
          padding: 0,
          paddingBottom: "16px"
        }}
      >
        <Toolbar
          sx={{
            backgroundColor: colors.primary,
          }}
        >
          <Typography variant="body1" sx={{ flexGrow: 1, color: colors.secondary, fontWeight: 'bold' }}>
            {t(`scanContactsHeaderThree`)}
          </Typography>
          <IconButton onClick={handleCloseDeleteRecipientsDialog} sx={{ color: colors.secondary }}>
            <Close />
          </IconButton>
        </Toolbar>
      </DialogTitle>
      <DialogContent sx={{ height: "10vh" }}>
        <Typography variant="body1">
          {/* Are you sure you want to delete the recipients list <b>{selectedRecipientsList}</b>? It contains <b>{selectedListLength} person(s)</b>. <br/>All entries will be deleted. */}
          {t(`scanContactsLabelFour`, { name: selectedRecipientsList, number: selectedListLength })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleCloseDeleteRecipientsDialog}
        >
          {t(`Cancel`)}
        </Button>
        <LoadingButton
          variant="outlined"
          onClick={handleDelete}
          loading={isLoading}
        >
          {t(`Delete`)}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}