import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";

export default function SaveDialog({
    isOpen, onCancel, onConfirm
}) {

    const { t } = useTranslation();
    
    return (
        <Dialog
            open={isOpen}
            onClose={onCancel}
        >
            <DialogContent>
                <Typography variant="body1">
                    {t(`saveDialogText`)}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onCancel}>
                    {t(`Cancel`)}
                </Button>
                <Button variant="outlined" onClick={onConfirm}>
                    {t(`Exit`)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}