import { createTheme } from '@mui/material/styles';

import jsPDF from "jspdf";
import "jspdf-autotable";
import { colors } from '../../../defaultTheme';


export const getThemeDataTable = (primaryColor) => createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          cursor: "pointer",
        },
        head: {
          backgroundColor: primaryColor
        }
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: primaryColor
        }
      }
    },
    MUIDataTableSelectCell: {
      styleOverrides: {
        headerCell: {
          backgroundColor: 'transparent',
        }
      }
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          color: colors.white
        },
        sortAction: {
          color: colors.white
        },
        data: {
          color: colors.white
        },
        sortLabelRoot: {
          color: colors.white
        },
      }
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          color: colors.white
        },
        icon: {
          color: colors.white
        }
      }
    },
  }
});


export const parseTimestamp = (timestamp, timezone) => {
  const isTimestampValid = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(timestamp);
  
  if (isTimestampValid) {
    const [datePart, timePart] = timestamp.split(' ');
    const [year, month, day] = datePart.split('-').map(Number);
    const [hour, minute, second] = timePart.split(':').map(Number);
    const timestampInUTC = new Date(Date.UTC(year, month - 1, day, hour, minute, second));

    const options = {
      timeZone: timezone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false, 
    };

    const formatter = new Intl.DateTimeFormat('en-US', options);
    const formattedTime = formatter.format(timestampInUTC);

    return formattedTime;
  } else {
    return timestamp; 
  }
}

export const downloadCSV = (data, filename) => {
  const separator = ',';

  const columns = Object.keys(data[0]);

  const csvContent = [
    columns.map(column => `"${column}"`).join(separator)
  ];

  data.forEach(item => {
    const row = columns.map(column => `"${item[column]}"`).join(separator);
    csvContent.push(row);
  });

  const blob = new Blob([csvContent.join('\n')], { type: 'text/csv' });

  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  URL.revokeObjectURL(url);
}

export const isUnixTimestampMillis = (value) => {
  const regex = /^\d{13}$/;
  return regex.test(value);
}

export const generatePDF = (title, data) => {
  const doc = new jsPDF();

  doc.text(title, 14, 10);
  
  const productMap = {};
  
  Object.values(data).forEach(clientData => {
    clientData.lastMeasurementData && clientData.lastMeasurementData.results &&
      Object.values(clientData.lastMeasurementData.results).forEach(result => {
        const productId = result.productId;
        const size = result.size;
  
        if (!productMap[productId]) {
          productMap[productId] = {};
        }
  
        if (!productMap[productId][size]) {
          productMap[productId][size] = 1;
        } else {
          productMap[productId][size]++;
        }
      });
  });
  
  Object.keys(productMap).forEach(productId => {
    const tableRows = Object.entries(productMap[productId]).map(([size, count]) => [size, count]);
  
    if (tableRows.length > 0) {
      const startY = doc.lastAutoTable ? doc.lastAutoTable.finalY + 20 : 20;
      doc.text(`Product: ${productId}`, 14, startY);
  
      const tableColumns = ['Size', 'Count'];
  
      doc.autoTable(tableColumns, tableRows, {
        startY: startY + 10,
        styles: { cellPadding: 1.5, fontSize: 10 },
        columnStyles: { 0: { cellWidth: 30 }, 1: { cellWidth: 20 } },
      });
    }
  });
  

  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
  
  const date = formattedDate.replace(/[^\w\s]/gi, '').replace(/\s+/g, '_'); 
  
  doc.save(`report_${date}.pdf`);
  
};