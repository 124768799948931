import { Button } from "@mui/material";
import { useAuth } from "../../AuthContext";
import { useTranslation } from 'react-i18next';

const database_url = process.env.REACT_APP_DATABASE_URL;

export default function LogoutAllSessions() {

  const { currentUser } = useAuth();
  const { t } = useTranslation();

  const handleLogoutAllSessions = async () => {
    const accessToken = await currentUser.getIdToken(true);

    let response = await fetch(database_url + "/logout_all_sessions?user_id=" + currentUser.uid, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'x-access-token': accessToken },
      body: JSON.stringify({
        uid: currentUser.uid
      })
    });

    let result = await response.json();

    if (result.success) {
      window.location.reload();
    }
  }

  return (
    <Button
      variant="outlined"
      onClick={handleLogoutAllSessions}
      fullWidth
    >
      {t(`Log Out All Sessions`)}
    </Button>
  )
}