import {
  Box,
  Button,
  Card,
  Typography
} from "@mui/material";

import { borders, boxShadows } from "../../../defaultTheme";
import { KeyboardArrowRight } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";

export default function CardPageComponent({
  title,
  description,
  buttonText,
  goTo
}) {

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(goTo);
  }

  return (
    <Card
      sx={{ p: 2, borderRadius: borders.borderRadius.md, boxShadow: boxShadows.sm, minHeight:'200px' }}
    >
      <Box
        sx={{
          border: 1,
          borderColor: borders.borderColor,
          borderRadius: borders.borderRadius.md,
          px: 5,
          py: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="body1" fontWeight={'bold'}>
          {title}
        </Typography>
        <Typography variant="body2">
          {description}
        </Typography>
        <Button
          variant="text"
          endIcon={
            <KeyboardArrowRight />
          }
          sx={{
            alignSelf: 'flex-end'
          }}
          onClick={handleNavigate}
        >
          {buttonText}
        </Button>
      </Box>
    </Card>
  )
}