import {
  Backdrop,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  Paper,
} from "@mui/material";

import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import { DataGrid } from '@mui/x-data-grid';

import { Close, KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { colors } from "../../../../defaultTheme";
import { useAuth } from "../../../../AuthContext";
import { useTranslation } from "react-i18next";

import { useEffect, useState } from "react";

const database_url = process.env.REACT_APP_DATABASE_URL;

const RecipientsGrid = ({ rowData, selectedRows, handleSelectionChange }) => {
  // Assuming the rowData is an array of objects without "id" key
  // For example, [{ name: 'John', age: 25 }, { name: 'Jane', age: 30 }]

  // Generate unique ids for each row
  // const rowsWithIds = rowData && rowData.length > 0 ? rowData.map((row, index) => ({ index: index + 1, ...row })) : {};
  const rowsWithIds = rowData && rowData.length > 0 ? rowData : {};

  function camelCaseToUppercaseFirstLetters(input) {
    // Use regular expression to find all word boundaries in the string
    // and replace them with a space followed by the uppercase version of the letter
    return input.replace(/([A-Z])/g, ' $1')
      // Capitalize the first letter of the resulting string
      .replace(/^./, function (str) { return str.toUpperCase(); });
  }

  const columns = rowsWithIds.length > 0 ? Object.keys(rowsWithIds[0]).map((key) => ({ field: key, headerName: camelCaseToUppercaseFirstLetters(key), flex: 1 })) : [];

  return (
    <>
      {
        (rowData && rowData.length > 0) ? (
          <Paper
            sx={{
              my: 2,
              backgroundColor: 'transparent',
            }}
            elevation={0}
          >
            <DataGrid
              rows={rowsWithIds}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5, 10, 20]}
              checkboxSelection
              selectionModel={selectedRows}
              onRowSelectionModelChange={(newRowSelectionModel) => {
                handleSelectionChange(newRowSelectionModel);
              }}
              columnVisibilityModel={{ id: false }}
              getRowId={(row) => row.id}
              density='compact'
            />
          </Paper>
        ) : (
          <Paper
            sx={{
              my: 2,
              backgroundColor: 'transparent',
            }}
            elevation={0}
          >
            <Typography variant="body2">
              No data
            </Typography>
          </Paper>
        )
      }
    </>
  );
};

export default function EditRecipientsDialog({
  openEditRecipientsDialog,
  handleCloseEditRecipientsDialog,
  selectedRecipientsList
}) {
  const {
    companyRecipientsLists,
    setCompanyRecipientsLists,
    persons,
    setPersons,
    currentUser,
    handleSnackbar,
    userId,
    companyId,
  } = useAuth();
  const { t } = useTranslation();

  const [selectedListRecipients, setSelectedListRecipients] = useState([]);
  const [selectedRecipientsIds, setSelectedRecipientsIds] = useState([]);
  const [unassignedRecipients, setUnassignedRecipients] = useState([]);
  const [unassignedRecipientsIds, setUnassignedRecipientsIds] = useState([]);

  const [assignedSelectedRows, setAssignedSelectedRows] = useState([]);
  const [unassignedSelectedRows, setUnassignedSelectedRows] = useState([]);

  const [modifiedRecipients, setModifiedRecipients] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [edited, setEdited] = useState(false);

  const handleAssignedRowSelection = (selection) => {
    const selectedRowIds = selection;
    const selectedRowsData = selectedListRecipients.filter(row => selectedRowIds.includes(row.id));
    setAssignedSelectedRows(selectedRowsData);
  }

  const handleUnassignedRowSelection = (selection) => {
    const selectedRowIds = selection;
    const selectedRowsData = unassignedRecipients.filter(row => selectedRowIds.includes(row.id));
    setUnassignedSelectedRows(selectedRowsData);
  }

  useEffect(() => {
    if (selectedRecipientsList) {

      let new_selectedListRecipients = new Array();
      let new_selectedRecipientsIds = new Array();

      companyRecipientsLists[selectedRecipientsList].map((recipient) => {
        new_selectedListRecipients.push({
          'firstName': recipient['first name'],
          'lastName': recipient['last name'],
          'trackingId': recipient['id'],
          'id': recipient['id']
        });

        new_selectedRecipientsIds.push(recipient['id']);
      })
      setSelectedListRecipients(new_selectedListRecipients);
      setSelectedRecipientsIds(new_selectedRecipientsIds);
    }
  }, [selectedRecipientsList]);

  useEffect(() => {
    let unassignedPersons = new Array();
    let unassignedIds = new Array();

    Object.keys(persons).map(person_id => {
      let person_data = persons[person_id];

      if (!Object.keys(person_data).includes('recipientsListName')) {
        unassignedPersons.push({
          'firstName': Object.keys(person_data).includes('firstName') ? person_data['firstName'] : 'N/A',
          'lastName': Object.keys(person_data).includes('lastName') ? person_data['firstName'] : 'N/A',
          'trackingId': Object.keys(person_data).includes('trackingId') ? person_data['trackingId'] : person_data['personId'],
          'id': Object.keys(person_data).includes('trackingId') ? person_data['trackingId'] : person_data['personId'],
        });
        unassignedIds.push(Object.keys(person_data).includes('trackingId') ? person_data['trackingId'] : person_data['personId'])
      }
    })

    setUnassignedRecipients(unassignedPersons);
    setUnassignedRecipientsIds(unassignedIds);
  }, []);

  const moveSelectedRowsToUnassigned = () => {
    // Move selected rows from Assigned to Unassigned
    const remainingRowsAssigned = selectedListRecipients.filter(row => !assignedSelectedRows.includes(row));
    setSelectedListRecipients(remainingRowsAssigned);

    // Merge selected rows from Assigned to Unassigned
    setUnassignedRecipients(prevRows => [...prevRows, ...assignedSelectedRows]);

    let new_modified = modifiedRecipients;
    assignedSelectedRows.map((row) => new_modified[row.id] = null);
    setModifiedRecipients(new_modified);

    // Clear selected rows in Assigned
    setAssignedSelectedRows([]);
  }

  const moveSelectedRowsToAssigned = () => {
    // Move selected rows from Assigned to Unassigned
    const remainingRowsUnassigned = unassignedRecipients.filter(row => !unassignedSelectedRows.includes(row));
    setUnassignedRecipients(remainingRowsUnassigned);

    // Merge selected rows from Assigned to Unassigned
    setSelectedListRecipients(prevRows => [...prevRows, ...unassignedSelectedRows]);

    let new_modified = modifiedRecipients;
    unassignedSelectedRows.map((row) => new_modified[row.id] = selectedRecipientsList);
    setModifiedRecipients(new_modified);

    // Clear selected rows in Unassigned
    setUnassignedSelectedRows([]);
  }


  const getRecipientsLists = (persons) => {
    let recipients_lists = {};
    Object.keys(persons).map(person_id => {
      let person = persons[person_id];
      if (Object.keys(person).includes('recipientsListName')) {
        let listName = person['recipientsListName'];

        if (!Object.keys(recipients_lists).includes(listName)) {
          recipients_lists[listName] = new Array();
        }

        recipients_lists[listName].push({
          'first name': person['firstName'],
          'last name': person['lastName'],
          'email': person['email'],
          'phone': person['phone'],
          'id': person['trackingId']
        });
      }
    });

    return recipients_lists;
  }

  const handleSave = async () => {
    setIsLoading(true);

    const accessToken = await currentUser.getIdToken(true);
    let response = await fetch(database_url + '/edit_recipients_list?user_id=' + currentUser.uid, {
      'method': 'POST',
      'headers': {'Content-Type': 'application/json', 'Accept': 'application/json', 'x-access-token': accessToken},
      'body': JSON.stringify({
        modifiedRecipients
      })
    });

    let result = await response.json();
    setIsLoading(false);

    if (result['success']) {
      handleSnackbar('success', t(`scanContactsSnackThree`));
      setEdited(true);
      let response = await fetch(database_url + '/view_company_persons?user_id=' + userId + '&company_id=' + companyId, {
        method: 'GET',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
      });

      let result = await response.json();

      setCompanyRecipientsLists(getRecipientsLists(result));
      setPersons(result);
    } else {
      handleSnackbar('error', t(`scanContactsSnackTwo`));
    }
  }

  const handleClose = (edited) => {
    setAssignedSelectedRows([]);
    setUnassignedSelectedRows([]);

    handleCloseEditRecipientsDialog(edited);
  }

  useEffect(() => {

  }, []);

  return (
    <Dialog
      // fullScreen
      maxWidth="xl"
      fullWidth
      open={openEditRecipientsDialog}
      onClose={() => handleClose(edited)}
    >
      <DialogTitle
        sx={{
          padding: 0,
          paddingBottom: "16px"
        }}
      >
        <Toolbar
          sx={{
            backgroundColor: colors.primary,
          }}
        >
          <Typography variant="body1" sx={{ flexGrow: 1, color: colors.secondary, fontWeight: 'bold' }}>
            {/* Editing Recipients List - {selectedRecipientsList} */}
            {t(`scanContactsHeaderFour`,{name: selectedRecipientsList})}
          </Typography>
          <IconButton onClick={() => handleClose(edited)} sx={{ color: colors.secondary }}>
            <Close />
          </IconButton>
        </Toolbar>
      </DialogTitle>
      <DialogContent sx={{ height: "70vh" }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Card sx={{ py: 2, px: 5, minHeight: '400px' }}>
              <Typography variant="body1" fontWeight={'bold'}>
                {t(`scanContactsLabelFive`)}
              </Typography>
              <Button
                variant="contained"
                endIcon={
                  <KeyboardArrowRight />
                }
                sx={{
                  width: '25%',
                  alignSelf: 'flex-end'
                }}
                disabled={!(assignedSelectedRows.length > 0)}
                onClick={moveSelectedRowsToUnassigned}
              >
                {t(`Unassign`)}
              </Button>
              <RecipientsGrid
                rowData={selectedListRecipients}
                selectedRows={assignedSelectedRows}
                handleSelectionChange={handleAssignedRowSelection}
              />
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card sx={{ py: 2, px: 5, minHeight: '400px' }}>
              <Typography variant="body1" fontWeight={'bold'}>
                {t(`scanContactsLabelSix`)}
              </Typography>
              <Button
                variant="contained"
                startIcon={
                  <KeyboardArrowLeft />
                }
                sx={{
                  width: '25%',
                  alignSelf: 'flex-start'
                }}
                disabled={!(unassignedSelectedRows.length > 0)}
                onClick={moveSelectedRowsToAssigned}
              >
                {t(`Assign`)}
              </Button>
              <RecipientsGrid
                rowData={unassignedRecipients}
                selectedRows={unassignedSelectedRows}
                handleSelectionChange={handleUnassignedRowSelection}
              />
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => handleClose(edited)}
        >
          {t(`Cancel`)}
        </Button>
        <Button
          variant="outlined"
          onClick={handleSave}
        >
          {t(`Save`)}
        </Button>
      </DialogActions>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
}