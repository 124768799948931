import { useEffect, useRef, useState } from "react";
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  Select,
  CircularProgress,
} from "@mui/material";
import {
  FileCopy, Info, InfoOutlined
} from "@mui/icons-material";
import { useAuth } from "../../../AuthContext";
import { useTranslation } from "react-i18next";

const database_url = process.env.REACT_APP_DATABASE_URL;
const measurementLinkBase = process.env.REACT_APP_MEASUREMENT_LINK_BASE;

export default function MeasurementLinkCom() {
  const { companyId, currentUser, companyClients, role, handleSnackbar, companyMeasurementLink } = useAuth();
  const { t } = useTranslation();

  const inputRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState("");
  const [measurementLinkClient, setMeasurementLinkClient] = useState("");
  // const [measurementLinkCompany, setMeasurementLinkCompany] = useState(companyMeasurementLink);
  const acceptedRoles = ['company', 'companyMaks'];

  const handleCopyClick = () => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand('copy');
      handleSnackbar('success', t(`scanLinkSnackOne`));
    }
  };

  const handleSelectedClient = (event) => {
    setSelectedClient(event.target.value);
  };

  const shortenLink = async (companyId, clientId, maksId, type) => {
    setIsLoading(true);
    const accessToken = await currentUser.getIdToken(true);
    let response = await fetch(database_url + "/shorten?user_id=" + currentUser.uid, {
      method: "POST",
      headers: { "Content-Type": "application/json", "Accept": "application/json", 'x-access-token': accessToken },
      body: JSON.stringify({
        url: measurementLinkBase + companyId + "?clientId=" + clientId + "&maksId=" + maksId + "&isConsecutive=true"
      })
    })

    let result = await response.json();

    if (type == "client") {
      setMeasurementLinkClient(result['result']['shortURL']);
    }
    setIsLoading(false);
    return result['result']['shortURL'];
  }

  useEffect(() => {
    if (selectedClient) {
      const awaitShortenLink = async () => {
        shortenLink(companyId, selectedClient, currentUser.uid, "client");
      }
      awaitShortenLink();
    }
  }, [selectedClient]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1" fontWeight={"bold"}>
          {t(`scanLinkHeaderOne`)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" align="justify">
          {t(`scanLinkLabelOne`)}
        </Typography>
      </Grid>
      <Grid item xs={6} display={"flex"} justifyContent={"space-between"}>
        <TextField
          inputRef={inputRef}
          variant="outlined"
          sx={{
            width: '75%'
          }}
          margin="normal"
          value={companyMeasurementLink}
          readOnly
          InputProps={{
            endAdornment: <InputAdornment position="start">
              {
                isLoading ? (
                  <CircularProgress />
                ) : (
                  <IconButton onClick={handleCopyClick} color="primary">
                    <FileCopy />
                  </IconButton>
                )
              }
            </InputAdornment>
          }}
          disabled={isLoading}
          onClick={handleCopyClick}
        />
      </Grid>

      {
        acceptedRoles.includes(role) && (
          <>
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight={"bold"}>
                {t(`scanLinkHeaderTwo`)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" align="justify">
               
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                sx={{ width: '30%' }}
              >
                <InputLabel id="role-label">{t(`scanLinkLabelThree`)}</InputLabel>
                <Select
                  labelId="role-label"
                  id="role"
                  name="role"
                  label="Select a Client"
                  value={selectedClient}
                  onChange={handleSelectedClient}
                >
                  {Object.keys(companyClients).map((name) => (
                    <MenuItem key={name} value={name}>
                      {companyClients[name]['name']}
                    </MenuItem>
                  ))}
                  {
                    Object.keys(companyClients).length == 0 && (
                      <MenuItem key={"empty"} value={""}>
                        {t(`scanLinkLabelFour`)}
                      </MenuItem>
                    )
                  }
                </Select>
              </FormControl>
            </Grid>
            {
              selectedClient && (
                <Grid item xs={6} display={"flex"} justifyContent={"space-between"}>
                  <TextField
                    inputRef={inputRef}
                    variant="outlined"
                    sx={{
                      width: '75%'
                    }}
                    margin="normal"
                    value={measurementLinkClient}
                    readOnly
                    InputProps={{
                      endAdornment: <InputAdornment position="start">
                        {
                          isLoading ? (
                            <CircularProgress />
                          ) : (
                            <IconButton onClick={handleCopyClick} color="primary">
                              <FileCopy />
                            </IconButton>
                          )
                        }
                      </InputAdornment>
                    }}
                    onClick={handleCopyClick}
                    disabled={!selectedClient || isLoading}
                  />
                </Grid>
              )
            }
          </>
        )
      }

    </Grid>
  );

}