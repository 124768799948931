import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";

import { Close, ContentCopy, } from "@mui/icons-material";

import { colors } from "../../../defaultTheme";

import QRCode from "react-qr-code";
import { useAuth } from "../../../AuthContext";
import { useTranslation } from "react-i18next";

export default function QRCodeComponent({
  openQRDialog,
  handleCloseQRDialog,
  link
}) {

  const { handleSnackbar } = useAuth();
  const { t } = useTranslation();

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link);
    handleSnackbar("success", "Link Copied to Clipboard!");
  }

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={openQRDialog}
      onClose={handleCloseQRDialog}
    >
      <Box display={'flex'} justifyContent={'space-between'} sx={{ backgroundColor: colors.primary, px: 2, py: 2 }}>
        <Typography variant="body1" sx={{ alignSelf: 'center', color: colors.white }}>{t(`scanHeaderFour`)}</Typography>
        <IconButton
          aria-label="close"
          onClick={handleCloseQRDialog}
          sx={{
            color: (theme) => theme.palette.grey[500],
            alignSelf: 'center'
          }}
          className="close-dialog"
        >
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        <Box display={'flex'} justifyContent={'center'} flexDirection={'column'}>
          <Box
            sx={{
              border: 1,
              borderColor: 'divider',
              textAlign: 'center',
              my: 3,
              height: 256,
              width: 256,
              alignSelf: 'center',
              p: 2
            }}
          >
            <QRCode
              size={256}
              style={{ height: 'auto', maxWidth: "100%", width: "100%", marginBottom: 5 }}
              value={link}
              viewBox={`0 0 256 256`}
            />
          </Box>

          <Box
            sx={{
              width: '40%',
              alignSelf: 'center',
              display: 'flex',
            }}
          >
            <OutlinedInput 
              fullWidth
              value={link}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={handleCopyLink}
                  >
                    <ContentCopy />
                  </IconButton>
                </InputAdornment>
              }
              id="quickscan-copy-link"
            />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}