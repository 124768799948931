import {
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  Icon
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from "react";
import { useAuth } from "../../../../AuthContext";
import { useTranslation } from "react-i18next";

import {
  Check,
  ErrorOutline
} from "@mui/icons-material";

export default function TableRecipientsList({
  employeeList,
  listColumns,
  selectedClient,
  setSelectedClient
}) {
  const { t } = useTranslation();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { companyClients, companyId } = useAuth();
  const [clientsList, setClientsList] = useState({});

  const [matchedColumns, setMatchedColumns] = useState({
    "First Name": "",
    "Last Name": "",
    "Email": "",
    "Phone": "",
    "Height": "",
    "Weight": "",
    "Age": "",
    "Gender": ""
    // "ID": "",
  });
  const [remainingColumns, setRemainingColumns] = useState(listColumns);

  function sanitizeString(input) {
    // Convert to lowercase and remove non-letter characters using regex
    return input.toLowerCase().replace(/[^a-z]/g, '');
  }

  useEffect(() => {
    Object.keys(companyClients).map((clientName) => {
      setClientsList((prevState => {
        return { ...prevState, [companyClients[clientName]['name']]: companyClients[clientName]['companyId'] };
      }));
    });

    if (listColumns.length > 0) {
      let matchedCopy = matchedColumns;
      const idealColumns = Object.keys(matchedColumns);
      for (let i = 0; i < idealColumns.length; i++) {
        idealColumns[i] = sanitizeString(idealColumns[i]);
      }
      let remainingColumnsCopy = new Array(...listColumns);
      (listColumns).forEach(uploadedCol => {
        let index = -1;
        let sanitized = sanitizeString(uploadedCol);
        if (idealColumns.includes(sanitized)) {
          index = idealColumns.indexOf(sanitized);
          let trueKey = Object.keys(matchedColumns)[index];
          matchedCopy[trueKey] = uploadedCol;
          remainingColumnsCopy.splice(remainingColumnsCopy.indexOf(uploadedCol), 1);
        }
      });
      setMatchedColumns(matchedCopy);
      setRemainingColumns(remainingColumnsCopy);
    }
  }, []);

  const generateTable = () => {
    return (
      <TableContainer component={Paper} style={{ minHeight: fullScreen ? 500 : 300, maxHeight: fullScreen ? 500 : 300, overflow: 'auto' }} key={'table-container'}>
        <Table>
          <TableHead sx={{ display: "table-header-group" }}>
            <TableRow>
              {Object.keys(matchedColumns).map((key) => (
                <TableCell key={key}>{key}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {employeeList.map((row, index) => (
              <TableRow key={index}>
                <TableCell key={"cell_fname_" + "first_name_" + index}>{row['first name']}</TableCell>
                <TableCell key={"cell_lname_" + "last_name_" + index}>{row['last name']}</TableCell>
                <TableCell key={"cell_email_" + "email_" + index}>{row['email']}</TableCell>
                <TableCell key={"cell_phone_" + "phone_" + index}>{row['phone']}</TableCell>
                {/* <TableCell key={"cell_id_" + index}>{row['id']}</TableCell> */}
                {
                  Object.keys(row).includes('height') && (
                    <TableCell key={"cell_fname_" + "height_" + index}>{row['height']}</TableCell>
                  )
                }
                {
                  Object.keys(row).includes('weight') && (
                    <TableCell key={"cell_fname_" + "weight_" + index}>{row['weight']}</TableCell>
                  )
                }
                {
                  Object.keys(row).includes('age') && (
                    <TableCell key={"cell_fname_" + "age_" + index}>{row['age']}</TableCell>
                  )
                }
                {
                  Object.keys(row).includes('gender') && (
                    <TableCell key={"cell_fname_" + "gender_" + index}>{row['gender']}</TableCell>
                  )
                }
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  const handleMatchColumns = (event, col) => {
    let matchedColumnsCopy = new Object(matchedColumns);
    matchedColumnsCopy[col] = event.target.value;
    setMatchedColumns(prevState => ({ ...prevState, [col]: event.target.value }));
  };

  const handleSelectClient = (event) => {
    setSelectedClient(event.target.value);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="body1" fontWeight={'bold'}>
          {t(`scanImportHeaderSeven`)}
        </Typography>
        <Typography variant="body1">
          {t(`scanImportLabelEight`)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          {
            Object.keys(matchedColumns).map((col, idx) => {
              return (
                <Grid item xs={12} md={4} display={"flex"} key={idx}>
                  <FormControl sx={{ m: 1, minWidth: 120, width: "70%" }} key={idx}>
                    <InputLabel>{col}</InputLabel>
                    <Select
                      value={matchedColumns[col]}
                      label={col}
                      onChange={(event) => handleMatchColumns(event, col)}
                    >
                      {
                        matchedColumns[col].length > 0 && (
                          <MenuItem
                            key={matchedColumns[col]}
                            value={matchedColumns[col]}
                          >
                            {matchedColumns[col]}
                          </MenuItem>
                        )
                      }
                      {remainingColumns.map((name) => (
                        <MenuItem
                          key={name}
                          value={name}
                        >
                          {name}
                        </MenuItem>
                      ))}
                      {
                        matchedColumns[col] == "" && (
                          <MenuItem
                            key={"empty2"}
                            value={""}
                          >
                             {t(`scanImportLabelNine`)}
                          </MenuItem>
                        )
                      }
                    </Select>
                  </FormControl>
                  {
                    matchedColumns[col] == "" ? <ErrorOutline color="error" sx={{ alignSelf: "center" }} /> : <Check color="success" sx={{ alignSelf: "center" }} />
                  }
                </Grid>
              )
            })
          }
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" fontWeight={'bold'}>
          {/* You uploaded {employeeList.length} unique recipients. Review them below: */}
          {t(`scanImportLabelTen`, { number: employeeList.length })}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {
          employeeList.length > 0 ? generateTable() : (
            <TableContainer component={Paper} style={{ minHeight: fullScreen ? 500 : 300, maxHeight: fullScreen ? 500 : 300, overflow: 'auto' }}>
              <Typography variant="body1">
                {t(`scanImportLabelEleven`)}
              </Typography>
            </TableContainer>
          )
        }
      </Grid>
    </>

  );
}