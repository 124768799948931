import React, { useState, useEffect } from 'react';
import {
  Typography, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Accordion, AccordionSummary, AccordionDetails, 
  Card
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useTranslation } from 'react-i18next';
import { parseTimestamp } from "../utils/utils";

import { useAuth } from '../../../AuthContext';

export const HistoryComponent = (props) => {
    const { companyConfig, companyProducts, companySizeCharts, companyCollections } = useAuth();
    const { t } = useTranslation();
    const [historyData, setHistoryData] = useState({});
    const [timezone, setTimezone] = useState("")

    useEffect(() => {
        if (props.historyData) {
            setHistoryData(props.historyData);
        }
        if(companyConfig.timezone){
            setTimezone(companyConfig.timezone)
        }
    }, [props.historyData,companyConfig]);

    return (
        <>
        <Grid container spacing={4}>
            {Object.keys(historyData).map((measurementId) => (
            <Grid item xs={12} key={measurementId}>
                <Accordion disabled = {Object.values(historyData[measurementId]?.results).length > 0 ? false : true}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{historyData[measurementId].createdAt ? parseTimestamp(historyData[measurementId].createdAt, timezone) : ""}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Card sx={{ py: 2, px: 5 }}>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                <TableRow>
                                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>{t(`Product Name`)}</TableCell>
                                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>{t(`Collection`)}</TableCell>
                                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>{t(`Variation`)}</TableCell>
                                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>{t(`Size`)}</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {Object.values(historyData[measurementId]?.results).map((row, index) => (
                                    <TableRow key={index}>
                                    <TableCell align="center">{companyProducts[row.productId]? companyProducts[row.productId].name : (row.deletedProductName ? row.deletedProductName : row.productId )}</TableCell>
                                    <TableCell align="center">{companyProducts[row.productId]? companyCollections[companyProducts[row.productId]?.collection]?.name : ''}</TableCell>
                                    <TableCell align="center">{companySizeCharts[row.variationId]? companySizeCharts[row.variationId]?.metadata?.name : (row.deletedSizeChartName ? row.deletedSizeChartName : row.variationId )}</TableCell>
                                    <TableCell align="center">{row.size}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Card>
                </AccordionDetails>
                </Accordion>
            </Grid>
            ))}
        </Grid>
        </>
    );
};
