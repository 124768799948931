import { 
    Alert,
    IconButton,
    Snackbar
} from "@mui/material";

import {
    Close
} from "@mui/icons-material";

import { useAuth } from "../../AuthContext";
import { boxShadows, breakpoints } from "../../defaultTheme";

export default function SnackbarEdw() {

    const { snackbarOpen, setSnackbarOpen, snackbarMessage, setSnackbarMessage, snackbarSeverity } = useAuth();
    const vertical = 'bottom';
    const horizontal = 'right';

    const handleClose = () => {
        setSnackbarOpen(false);
        setSnackbarMessage('');
    }

    const SnackbarAction = () => {
        <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
        >
            <Close fontSize="small" color="white"/>
        </IconButton>
    }

    return (
        <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleClose}
            action={<SnackbarAction />}
            anchorOrigin={{ vertical, horizontal }}
        >
            <Alert 
                onClose={handleClose} 
                severity={snackbarSeverity} 
                variant="filled"
                sx={{ 
                    width: '20vw',
                    boxShadow: boxShadows.lg,
                }}
            >
                {snackbarMessage}
            </Alert>
        </Snackbar>
    )
}