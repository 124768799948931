import { useState } from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  Close
} from "@mui/icons-material";
import { colors } from "../../../../defaultTheme";
import SaveDialog from "../../../SaveDialog";
import { useAuth } from "../../../../AuthContext";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";

let database_url = process.env.REACT_APP_DATABASE_URL;

export default function CreateClientDialog({
  openCreateClientDialog,
  handleCloseCreateClientDialog,
}) {
  
  const { companyId, handleSnackbar, currentUser, setCompanyClients, userId } = useAuth();
  const { t } = useTranslation();

  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [clientName, setClientName] = useState('');

  const handleChange = (event) => {
    setClientName(event.target.value);
  };

  const resetData = () => {
    setClientName("");
  }

  const handleExit = () => {
    setOpenSaveDialog(false);
    resetData();
    handleCloseCreateClientDialog();
  };

  const handleCancelExit = () => {
    setOpenSaveDialog(false);
  };

  const handleTryExit = () => {
    if (clientName.length > 0) {
      setOpenSaveDialog(true);
    } else {
      resetData();
      handleCloseCreateClientDialog();
    }
  };

  const handleSaveClient = async () => {
    setIsLoading(true);
    // let url = "http://localhost:5001";

    const accessToken = await currentUser.getIdToken(true);

    let response = await fetch(database_url + "/add_company_client?user_id=" + currentUser.uid, {
      method: "POST",
      headers: { "Content-Type": 'application/json', "Accept": 'application/json', 'x-access-token': accessToken },
      body: JSON.stringify({
        clientName,
        companyId
      })
    });

    let result = await response.json();

    if (result['success']) {

      let response = await fetch(database_url + '/view_company_clients?user_id=' + userId + '&company_id=' + companyId, {
          method: 'GET',
          headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
      })

      let result = await response.json();

      setCompanyClients(result);

      handleSnackbar('success', t(`adminDialogSnackOne`));
      handleCloseCreateClientDialog();
      resetData();
    } else {
      handleSnackbar('error', 'Error');
    }
    setIsLoading(false);
  }

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={openCreateClientDialog}
      onClose={handleCloseCreateClientDialog}
    >
      <DialogTitle
        sx={{
          padding: 0,
          paddingBottom: "16px"
        }}
      >
        <Toolbar
          sx={{
            backgroundColor: colors.primary,
          }}
        >
          <Typography variant="body1" sx={{ flexGrow: 1, color: colors.secondary, fontWeight: 'bold' }}>
            {t(`Add New Client`)}
          </Typography>
          <IconButton onClick={handleExit} sx={{ color: colors.secondary }}>
            <Close />
          </IconButton>
        </Toolbar>
      </DialogTitle>
      <DialogContent sx={{ height: "30vh" }}>
        <Typography variant="body1" fontWeight={'bold'}>
          {t(`adminDialogLabelOne`)}
        </Typography>
        <TextField
          sx={{
            width: '25%'
          }}
          label={t(`adminDialogLabelTwo`)}
          variant="outlined"
          value={clientName}
          margin="normal"
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleTryExit}>
          {t(`Cancel`)}
        </Button>
        <LoadingButton
          variant="contained"
          disabled={!(clientName.length > 0)}
          loading={isLoading}
          onClick={handleSaveClient}
        >
          {t(`Save`)}
        </LoadingButton>
      </DialogActions>
      <SaveDialog
        isOpen={openSaveDialog}
        onCancel={handleCancelExit}
        onConfirm={handleExit}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
}