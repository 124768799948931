import React, { useEffect, useState } from "react";

import { Box, IconButton, Typography, Grid, LinearProgress, Dialog, AppBar, Toolbar, DialogContent, Button, Radio, Checkbox } from "@mui/material";
import { ReadMore, CloudDownload, Close, CheckCircleOutline, MailOutline, OpenInNewOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

import { ThemeProvider, useTheme } from '@mui/material/styles';

import MUIDataTable from "mui-datatables";

import { parseTimestamp, getThemeDataTable, downloadCSV } from "../utils/utils";
import { convertTZ } from "../../utils/functions";

import { MeasurementDialog } from "./MeasurementDialog";
import ScanGuideComponent from "../../utils/ScanGuideComponent";

import { useAuth } from "../../../AuthContext";
import { borders, colors, pxToRem } from "../../../defaultTheme";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate, useLocation } from 'react-router-dom';

const database_url = process.env.REACT_APP_DATABASE_URL;

const CustomCheckbox = (props) => {
  let newProps = Object.assign({}, props);

  if (props['data-description'] === 'row-select') {
    return (<Checkbox {...newProps} sx={{ color: colors.black }} />);
  } else {
    return (<Checkbox {...newProps} sx={{ color: colors.white }} />);
  }
};

export const MeasurementsTable = (props) => {

  const { companyConfig, companyMeasurementsConfig, measurements, standard, role, userId, setMeasurements, companyId, clientId,
     setPersons, handleSnackbar, lastUpdatedMeasurementsTable, setLastUpdatedMeasurementsTable, companyClients, currentUser} = useAuth();
  const { t } = useTranslation();

  const { dialog } = useParams(); 
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const measurementId = params.get('id');

  const primaryColor = "#27336e";

  const [people, setPeople] = useState([]);
  const [dialogData, setDialogData] = useState([]);

  const [foundPerson, setFoundPerson] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshLoader, setRefreshLoader] = useState(false);

  const [openScanGuide, setOpenScanGuide] = useState(false);
  const theme = useTheme();

  const [rows, setRows] = useState([]);
  const columns = [
    { name: "measurementId", label: t(`Measurement ID`), options: { display: "excluded", filter: false } },
    // {name:"uid",label:"Uid"}, 

    // { name: "id", label: "ID" },
    { name: "createdAt", label: t(`Created`)},
    { name: "updatedAt", label: t(`Updated`) },
    { name: "firstName", label: t(`First Name`) },
    { name: "lastName", label: t(`Last Name`) },
    { name: "email", label: "Email"},
    { name: "status", label: "Status", options: {
      customBodyRender: (value) => (
        value == 'done' ? 
          <Box display={'flex'}>
            <CheckCircleOutline sx={{ alignSelf: 'center' }} fontSize="small"/>
            <Typography variant="body2" sx={{ alignSelf: 'center' }} px={1} fontFamily={'Montserrat'}>
              {t(`done`)}
            </Typography>
          </Box>
        : 
        value == 'invited' ?
          <Box display={'flex'}>
            <MailOutline sx={{ alignSelf: 'center' }} fontSize="small"/>
            <Typography variant="body2" sx={{ alignSelf: 'center' }} px={1} fontFamily={'Montserrat'}>
              {t(`invited`)}
            </Typography>
          </Box>
        :
        <></>
      ),
      download: false,
    } },
    {
      name: "details", label: "Details", options: {
        customBodyRender: (value) => (
          <Button
            variant='outlined'
            disabled={!(value == 'done')}
            endIcon={
              <OpenInNewOutlined />
            }
          >
            {t(`View`)}
          </Button>
        ),
        download: false,
      }
    },
  ]

  useEffect(() => {

    let measurementsArray = []

    if (measurements) {
      const outputArray = Object.keys(measurements).map(measurementId => ({
        measurementId,
        ...measurements[measurementId]
      }));
      measurementsArray = outputArray;
    }


    let availableMeasurements = []

    if (companyMeasurementsConfig) {
      availableMeasurements = companyMeasurementsConfig
    }

    if (measurementsArray.length > 0 && availableMeasurements.length > 0 && Object.keys(standard).length > 0) {
      // varianta masuratori in cheie PENTRU DIALOG

      const processDialog = measurementsArray.map(person => {
        const processedPerson = {
          measurements: {}
        };

        let measures = person.measurements

        for (const key in measures) {
          if (!standard.hasOwnProperty(key)) {
            processedPerson[key] = measures[key];
          }
        }

        for (const key in person) {
          if (key !== 'measurements') {
            processedPerson[key] = person[key];
          }
          if(key === 'clientId'){
            if(person[key] === companyId){
              person[key] = companyConfig?.name
            } else {
              let client = companyClients[person[key]]
              if(client?.name) {
               person[key] = client?.name
              }
            }
          }
        }

        for (const measurementId of availableMeasurements) {
          if (measures && measures[measurementId] !== undefined) {
            processedPerson.measurements[measurementId] = {
              value: measures[measurementId],
              ...standard[measurementId]
            };
          } else {
            // Measurement is missing, use the "replaceBy" value
            processedPerson.measurements[measurementId] = {
              value: standard[measurementId].replaceBy,
              ...standard[measurementId]
            };
          }
        }

        return processedPerson;
      });
      setDialogData(processDialog)

      // varianta masuratori same lvl PENTRU DOWNLOAD

      const processedForDownload = measurementsArray.filter(person => person.status !== 'invited').map(person => {
        const processedPerson = {}

        let measures = person.measurements

        for (const key in measures) {
          if (!standard.hasOwnProperty(key)) {
            processedPerson[key] = measures[key];
          }
        }

        for (const key in person) {
          if (key !== 'measurements') {
            processedPerson[key] = person[key];
          }
          if(key === 'clientId'){
            if(person[key] === companyId){
              person[key] = companyConfig?.name
            } else {
              let client = companyClients[person[key]]
              if(client?.name) {
               person[key] = client?.name
              }
            }
          }
        }

        for (const measurementId of availableMeasurements) {
          if (measures && measures[measurementId] !== undefined) {
            let name = standard[measurementId].name;
            processedPerson[name] = measures[measurementId];
          } else {
            // Measurement is missing, use the "replaceBy" value
            let name = standard[measurementId].name;
            processedPerson[name] = standard[measurementId].replaceBy;
          }
        }
        return processedPerson;
      })
      setPeople(processedForDownload)

      // rows pentru tabel

      let timezone = companyConfig.timezone
      let newRows = [];
      for (var i = 0; i < measurementsArray.length; i++) {
        newRows.push({
          measurementId: measurementsArray[i].measurementId,
          // id: i + 1,
          createdAt: measurementsArray[i].createdAt ? parseTimestamp(measurementsArray[i].createdAt, timezone) : "",
          updatedAt: measurementsArray[i].updatedAt ? parseTimestamp(measurementsArray[i].updatedAt, timezone) : "",
          firstName: measurementsArray[i].firstName,
          lastName: measurementsArray[i].lastName,
          email: measurementsArray[i].email,
          status: measurementsArray[i].status,
          details: measurementsArray[i].status,
        });
      }

      setRows(newRows)
    }
  }, [companyConfig, companyMeasurementsConfig, measurements, standard])

  const options = {
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: false,
        useDisplayedRowsOnly: true,
      }
    },
    searchAlwaysOpen: true,
    searchPlaceholder: "Search here...",
    selectableRowsHeader: true,
    print: false,
    onRowClick: rowMeta => handleOpenDialog(rowMeta),
    onDownload: (buildHead, buildBody, columns, data) => {
      if (data.length === 0) {
        return false;
      }
      const printArr = data.filter(item => item.data[7] !== 'invited').map(item => {
        const measurementId = item.data[0];
        const originalEntry = people.find(person => person.measurementId === measurementId);

        const person = { ...originalEntry };
        if (person) {
          person.createdAt = person.createdAt ? parseTimestamp(person.createdAt, companyConfig.timezone) : "";
          person.updatedAt = person.updatedAt ? parseTimestamp(person.updatedAt, companyConfig.timezone) : "";
          if ('betas' in person) delete person.betas;
          if ('status' in person) delete person.status;
          if ('measurementId' in person) delete person.measurementId;
          if ('results' in person) delete person.results;
          if ('maksId' in person) delete person.maksId;
          if ('companyId' in person) delete person.companyId;
          if ('personId' in person) delete person.personId;
          if ('accessPoint' in person) delete person.accessPoint;
          if ('shopid' in person) delete person.shopid;
          if ('userAgent' in person) delete person.userAgent;
        }
        return person;
      });


      if (printArr.length === 0) {
        return false;
      }

      downloadCSV(printArr, 'measurements_data.csv');

      return false;
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      const handleClick = () => {
        const ind = [];
        for (let i = 0; i < selectedRows.data.length; i++) {
          ind.push(selectedRows.data[i].index);
        }
        let filteredIds = []
        for (var i = 0; i < ind.length; i++) {
          let x = ind[i]
          filteredIds.push(displayData[x].data[0])
        }

        const processedPersons = people;
        // const filteredPersons = ind.map(index => ({ ...processedPersons[index] }));
        const filteredPersons = processedPersons.filter(person => filteredIds.includes(person.measurementId));

        for (let i = 0; i < filteredPersons.length; i++) {
          if (filteredPersons[i].createdAt) {
            filteredPersons[i].createdAt = parseTimestamp(filteredPersons[i].createdAt, companyConfig.timezone);
          } else {
            filteredPersons[i].createdAt = "";
          }

          if (filteredPersons[i].updatedAt) {
            filteredPersons[i].updatedAt = parseTimestamp(filteredPersons[i].updatedAt, companyConfig.timezone);
          } else {
            filteredPersons[i].updatedAt = "";
          }

          if ('betas' in filteredPersons[i]) delete filteredPersons[i].betas;
          if ('status' in filteredPersons[i]) delete filteredPersons[i].status;
          if ('measurementId' in filteredPersons[i]) delete filteredPersons[i].measurementId;
          if ('results' in filteredPersons[i]) delete filteredPersons[i].results;
          if ('maksId' in filteredPersons[i]) delete filteredPersons[i].maksId;
          if ('companyId' in filteredPersons[i]) delete filteredPersons[i].companyId;
          if ('personId' in filteredPersons[i]) delete filteredPersons[i].personId;
          if ('accessPoint' in filteredPersons[i]) delete filteredPersons[i].accessPoint;
          if ('shopid' in filteredPersons[i]) delete filteredPersons[i].shopid;
          if ('userAgent' in filteredPersons[i]) delete filteredPersons[i].userAgent;
        }

        downloadCSV(filteredPersons, 'measurements_data.csv');
      };

      return (
        <Box mr={4}>
          <IconButton
            onClick={handleClick}
          >
            <CloudDownload />
          </IconButton>
        </Box>
      );
    },
    textLabels: {
      body: {
        noMatch: <div>{isLoading ? "Loading data..." : "No data available."}</div>,
      }
    },
    customToolbar: () => {
      return (
        <LoadingButton
          onClick={() => refreshMeasurements()}
          loading={refreshLoader}
          variant="contained"
          sx={{
            minWidth: 100,
            alignSelf: 'center',
            borderRadius: borders.borderRadius.md,
            padding: `${pxToRem(6.302)} ${pxToRem(16.604)}`,
            lineHeight: 1.4,
            fontWeight: 700,
            minHeight: pxToRem(37),
            color: colors.white,
            padding: `${pxToRem(9)} ${pxToRem(24)}`,
            backgroundColor: primaryColor ? primaryColor : colors.primary,
            "&:hover": {
              backgroundColor: primaryColor ? primaryColor : colors.primary,
            },
            "&:focus:not(:hover)": {
              backgroundColor: colors.dark,
            },
          }}
          id="refresh-measurements"
        >
          {t(`Refresh`)}
        </LoadingButton>
      );
    },
    // sortOrder: {
    //   name: 'createdAt',
    //   direction: 'desc'
    // },
  };

  useEffect(() => {
    switch (dialog) {
      case 'measurements-dialog':
        setOpenDialog(true);
        break;
      default:
        setOpenDialog(false);;
        break;
    }
  }, [dialog]);

  useEffect(()=>{
    if(measurementId){
      const found = dialogData.find(element => {
        if (element.status == "done" && element.measurementId === measurementId) {
          return true;
        }
      });
      if (found) {
        setFoundPerson(found);
      }
    }
  },[measurementId, dialogData])

  const handleOpenDialog = (rowMeta) => {
    const found = dialogData.find(element => {
      if (element.status == "done" && element.measurementId === rowMeta[0]) {
        return true;
      }
    });
    if (found) {
      window.tidioChatApi.display(false);
      setFoundPerson(found);
      navigate(`/measurements/measurements/measurements-dialog?id=${found.measurementId}`)
    }
  }

  const handleCloseDialog = () => {
    window.tidioChatApi.display(true);
    setFoundPerson("")
    navigate(`/measurements/measurements`);
  };

  const handleOpenScanGuide = () => {
    window.tidioChatApi.display(false);
    setOpenScanGuide(true);
  }

  const handleCloseScanGuide = () => {
    window.tidioChatApi.display(true);
    setOpenScanGuide(false);
  }

  const refreshMeasurements = async () => {
    setRefreshLoader(true);
    const accessToken = await currentUser.getIdToken(true);
    if (role === "company") {

      let promises = Promise.all([
        fetch(database_url + '/view_company_measurements_results?user_id=' + userId + '&company_id=' + companyId, {
          method: 'GET',
          headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
        }),
        fetch(database_url + '/view_company_persons?user_id=' + userId + '&company_id=' + companyId, {
          method: 'GET',
          headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
        }),
      ]);

      promises
        .then((results) => Promise.all(results.map((r) => r.json())))
        .then((resultsData) => {
          const [companyMeasurements, companyPersons] = resultsData;
          setMeasurements(companyMeasurements)
          setPersons(companyPersons)
        }).then(() => {
          setLastUpdatedMeasurementsTable(convertTZ(new Date(), companyConfig['timezone']));
          setTimeout(() => {
            setRefreshLoader(false)
          }, 5 * 1000);

          handleSnackbar("success", "Measurements Table refreshed!");
        })
    } else if (role === "client") {
      let promises = Promise.all([
        fetch(database_url + '/view_client_measurements_results?user_id=' + userId + '&company_id=' + companyId + '&client_id=' + clientId, {
          method: 'GET',
          headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
        }),
        fetch(database_url + '/view_client_persons?user_id=' + userId + '&company_id=' + companyId + '&client_id=' + clientId, {
          method: 'GET',
          headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
        }),
      ]);

      promises
        .then((results) => Promise.all(results.map((r) => r.json())))
        .then((resultsData) => {
          const [clientMeasurements, clientPersons] = resultsData;
          setMeasurements(clientMeasurements)
          setPersons(clientPersons)
        }).then(() => {
          setLastUpdatedMeasurementsTable(convertTZ(new Date(), companyConfig['timezone']));
          setTimeout(() => {
            setRefreshLoader(false)
          }, 5 * 1000);
          handleSnackbar("success", "Measurements Table refreshed!");
        })
    }

  }

  return (
    <>
      <Grid container>
        {isLoading &&
          <Grid item xs={6} alignSelf={"center"}>
            <LinearProgress
              sx={{
                alignSelf: "center",
              }}
            />
          </Grid>
        }
        <Grid item xs={8}>
          <Typography variant="body1" fontWeight={'bold'}>
            {t(`measuresHeaderOne`)}
          </Typography>
          <Typography variant="body2" align="justify">
            {t(`measuresLabelOne`)}
          </Typography>
        </Grid>
        <Grid item xs={4} display={'flex'} justifyContent={'center'} flexDirection={'column'}>
          <Button
            sx={{
              minWidth: 100,
              alignSelf: 'center'
            }}
            variant="contained"
            onClick={handleOpenScanGuide}
          >
            {t(`Scan Guide`)}
          </Button>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography variant="body2">
            <b>Last updated:</b> {lastUpdatedMeasurementsTable.toString()}
          </Typography>
        </Grid>
        <Grid item xs={12} mt={2}>
          <ThemeProvider theme={getThemeDataTable(theme.palette.primary.main)}>
            <MUIDataTable
              data={rows}
              columns={columns}
              options={options}
              sx={{ cursor: 'pointer' }}
              components={{
                Checkbox: CustomCheckbox
              }}
            />
          </ThemeProvider>
        </Grid>
      </Grid>

      <Dialog
        fullScreen
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">{t(`Measurements Details`)}</Typography>
            <IconButton color="inherit" onClick={() => handleCloseDialog()}>
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>

        <DialogContent>
          <MeasurementDialog person={foundPerson} setPerson={setFoundPerson} timezone={companyConfig.timezone} />
        </DialogContent>
      </Dialog>
      <ScanGuideComponent
        open={openScanGuide}
        handleClose={handleCloseScanGuide}
      />
    </>
  )
}