import React, { createContext, useContext, useEffect, useState } from 'react';
import { Box, CircularProgress, LinearProgress, Typography } from '@mui/material';

import { auth } from './firebase';
import { browserSessionPersistence } from 'firebase/auth';

import defaultConfig from './defaultConfig';
import logo from './assets/esenca_logo_monochrome_RGB.svg'
import { convertTZ } from './components/utils/functions';

import standard_en from '../src/components/utils/translation/standard/standard_en.json'
import standard_de from '../src/components/utils/translation/standard/standard_de.json'
import standard_ro from '../src/components/utils/translation/standard/standard_ro.json'

import { useTranslation } from 'react-i18next';
import TidioWrapper from './components/utils/TidioWrapper';

const database_url = process.env.REACT_APP_DATABASE_URL;
const measurementLinkBase = process.env.REACT_APP_MEASUREMENT_LINK_BASE;

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [authChecked, setAuthChecked] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [progress, setProgress] = useState(0);
  const [stall, setStall] = useState(false);
  const [fillId, setFillId] = useState("");

  const [currentUser, setCurrentUser] = useState(null);
  const [accessToken, setAccessToken] = useState("");
  const [role, setRole] = useState('');
  const [userId, setUserId] = useState('');
  const [permissions, setPermissions] = useState([]);
  const [companyId, setCompanyId] = useState('');
  const [clientId, setClientId] = useState('');
  const [standard, setStandard] = useState(standard_en);

  const [companyConfig, setCompanyConfig] = useState(defaultConfig);
  const [companyMeasurementsConfig, setCompanyMeasurementsConfig] = useState([]);
  const [companyClients, setCompanyClients] = useState([]);
  const [companyCollections, setCompanyCollections] = useState({});
  const [companyProducts, setCompanyProducts] = useState({});
  const [companySizeCharts, setCompanySizeCharts] = useState({});
  const [companyRecipientsLists, setCompanyRecipientsLists] = useState({});
  const [companyLanguage, setCompanyLanguage] = useState("en");
  const [companyUsers, setCompanyUsers] = useState([]);
  const [companyClientUsers, setCompanyClientUsers] = useState([]);
  const [companyMeasurementLink, setCompanyMeasurementLink] = useState("");
  const [whiteLabel, setWhiteLabel] = useState({
    primaryColor: "#27336e",
    sidenavBackgroundColor: "#C0DAF3",
    logo: logo
  });

  const [lastUpdatedMeasurementsTable, setLastUpdatedMeasurementsTable] = useState('');

  const [persons, setPersons] = useState({});
  const [measurements, setMeasurements] = useState({});

  const [adminCompanies, setAdminCompanies] = useState({});

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const { i18n } = useTranslation();

  i18n.on('languageChanged', (newLang) => {
    if (newLang === "en") {
      setStandard(standard_en)
    } else if (newLang === "de") {
      setStandard(standard_de)
    } else if (newLang === "ro") {
      setStandard(standard_ro)
    }
  });
  const [chatApiObject, setChatApiObject] = useState(null);

  const handleSnackbar = (severity, message) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  }

  const fillProgressBar = () => {
    const startTime = Date.now();
    const endTime = startTime + 3000;

    const fill = () => {
      const currentTime = Date.now();
      const percentage = ((currentTime - startTime) / (endTime - startTime)) * 100;

      if (percentage <= Math.floor(Math.random() * 21) + 60) {
        setProgress(percentage);
        requestAnimationFrame(fill);
      } else {
        setStall(true);
      }
    };

    fill();

  };

  const fillTo100 = () => {
    let id;
    if (progress < 100) {
      setProgress((prevProgress) => Math.min(prevProgress + 1, 100));
      id = requestAnimationFrame(fillTo100);
      setFillId(id);
    } else {
      cancelAnimationFrame(id);
    }
  };

  const getRecipientsLists = (persons) => {
    let recipients_lists = {};
    Object.keys(persons).map(person_id => {
      let person = persons[person_id];
      if (Object.keys(person).includes('recipientsListName')) {
        let listName = person['recipientsListName'];

        if (!Object.keys(recipients_lists).includes(listName)) {
          recipients_lists[listName] = new Array();
        }

        let listData = {
          'first name': person['firstName'],
          'last name': person['lastName'],
          'email': person['email'],
          'phone': person['phone'],
          'id': person['trackingId']
        };


        if (Object.keys(person).includes("height")) {
          listData['height'] = person['height'];
        }
        if (Object.keys(person).includes("weight")) {
          listData['weight'] = person['weight'];
        }
        if (Object.keys(person).includes("age")) {
          listData['age'] = person['age'];
        }
        if (Object.keys(person).includes("gender")) {
          listData['gender'] = person['gender'];
        }

        recipients_lists[listName].push(listData);
      }
    });

    return recipients_lists;
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {

      setCompanyConfig(defaultConfig);
      setCompanyMeasurementsConfig([]);
      setCompanyClients([]);
      setCompanyCollections({});
      setCompanyProducts({});
      setCompanySizeCharts({});
      setCompanyRecipientsLists({});

      setMeasurements({});

      setCurrentUser(user);

      if (user) {
        auth.setPersistence(browserSessionPersistence);
        setIsFetching(true);
        fillProgressBar();

        const accessToken = await user.getIdToken(true);
        setAccessToken(accessToken)

        const uid = user.uid;
        setUserId(uid)

        let promises = Promise.all([
          fetch(database_url + '/view_user_details?user_id=' + uid, {
            method: 'GET',
            headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
          }),
        ]);

        promises
          .then((results) => Promise.all(results.map((r) => r.json())))
          .then((resultsData) => {
            const data = resultsData[0];
            const role = data?.role

            const companyId = data?.companyId;
            const permissions = data?.permissions

            setCompanyId(companyId)
            setRole(role);
            setPermissions(permissions)

            if (role === "esenca") {
              let promises = Promise.all([
                fetch(database_url + '/view_companies?user_id=' + uid, {
                  method: 'GET',
                  headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
                }),
              ]);

              promises
                .then((results) => Promise.all(results.map((r) => r.json())))
                .then((resultsData) => {
                  const [adminCompanies] = resultsData;
                  setAdminCompanies(adminCompanies || {});

                  i18n.changeLanguage('en')

                  fillTo100();

                }).then(() => {
                  setAuthChecked(true);
                });
            } else if (role === "company") {
              let promises = Promise.all([
                fetch(database_url + '/view_company_config?user_id=' + uid + '&company_id=' + companyId, {
                  method: 'GET',
                  headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
                }),
                fetch(database_url + '/view_company_measurements_config?user_id=' + uid + '&company_id=' + companyId, {
                  method: 'GET',
                  headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
                }),
                fetch(database_url + '/view_company_clients?user_id=' + uid + '&company_id=' + companyId, {
                  method: 'GET',
                  headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
                }),
                fetch(database_url + '/view_company_measurements_results?user_id=' + uid + '&company_id=' + companyId, {
                  method: 'GET',
                  headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
                }),
                fetch(database_url + '/view_company_collections?user_id=' + uid + '&company_id=' + companyId, {
                  method: 'GET',
                  headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
                }),
                fetch(database_url + '/view_company_products?user_id=' + uid + '&company_id=' + companyId, {
                  method: 'GET',
                  headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
                }),
                fetch(database_url + '/view_company_size_charts?user_id=' + uid + '&company_id=' + companyId, {
                  method: 'GET',
                  headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
                }),
                fetch(database_url + '/view_company_persons?user_id=' + uid + '&company_id=' + companyId, {
                  method: 'GET',
                  headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
                }),
                fetch(database_url + '/get_company_users?user_id=' + uid + '&companyId=' + companyId, {
                  method: "GET",
                  headers: { "Content-Type": 'application/json', "Accept": 'application/json', 'x-access-token': accessToken }
                }),
                fetch(database_url + '/get_client_users?user_id=' + uid + '&company_id=' + companyId, {
                  method: "GET",
                  headers: { "Content-Type": 'application/json', "Accept": 'application/json', 'x-access-token': accessToken }
                }),
                fetch(database_url + '/view_measurement_link?user_id=' + uid+ '&companyId=' + companyId + '&clientId=' + companyId + '&measurementLinkBase=' + measurementLinkBase, {
                  method: "GET",
                  headers: { "Content-Type": 'application/json', "Accept": 'application/json', 'x-access-token': accessToken }
                }),
              ]);

              promises
                .then((results) => Promise.all(results.map((r) => r.json())))
                .then((resultsData) => {
                  const [companyConfig, companyMeasurementsConfig, companyClients, companyMeasurements, companyCollections,
                    companyProducts, companySizeCharts, companyPersons, companyUsers, companyClientUsers, companyMeasurementLink] = resultsData;

                  setCompanyConfig(companyConfig || defaultConfig);
                  setCompanyMeasurementsConfig(companyMeasurementsConfig || []);
                  setCompanyClients(companyClients || []);
                  setCompanyCollections(companyCollections || {});
                  setCompanyProducts(companyProducts || {});
                  setCompanySizeCharts(companySizeCharts || {});
                  setCompanyUsers(companyUsers['result'] || {});
                  setCompanyClientUsers(companyClientUsers['result'] || {});
                  setCompanyMeasurementLink(companyMeasurementLink || "");

                  setCompanyRecipientsLists(getRecipientsLists(companyPersons) || []);

                  setPersons(companyPersons || {});
                  setMeasurements(companyMeasurements || {});

                  setLastUpdatedMeasurementsTable(convertTZ(new Date(), companyConfig['timezone']));

                  const newWhiteLabel = {};

                  if (companyConfig.whiteLabel) {
                    if (companyConfig.whiteLabel.primaryColor) {
                      newWhiteLabel.primaryColor = companyConfig.whiteLabel.primaryColor;
                    }
              
                    if (companyConfig.whiteLabel.sidenavBackgroundColor) {
                      newWhiteLabel.sidenavBackgroundColor = companyConfig.whiteLabel.sidenavBackgroundColor;
                    }
              
                    setWhiteLabel(prevState => ({
                      ...prevState,
                      ...newWhiteLabel
                    }));
                  }

                  if(companyConfig.logo){
                    const newWhiteLabel = {};
                    newWhiteLabel.logo = companyConfig.logo

                    setWhiteLabel(prevState => ({
                      ...prevState,
                      ...newWhiteLabel
                    }));
                  }

                  let language = companyConfig['language'] || 'en'
                  setCompanyLanguage(companyConfig['language'] || 'en');
                  i18n.changeLanguage(language)

                  fillTo100();

                }).then(() => {
                  setAuthChecked(true);
                });
            } else if (role === "client") {
              const clientId = data.clientId;
              setClientId(clientId);

              let promises = Promise.all([
                fetch(database_url + '/view_company_config?user_id=' + uid + '&company_id=' + companyId, {
                  method: 'GET',
                  headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
                }),
                fetch(database_url + '/view_company_measurements_config?user_id=' + uid + '&company_id=' + companyId, {
                  method: 'GET',
                  headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
                }),
                fetch(database_url + '/view_client_measurements_results?user_id=' + uid + '&company_id=' + companyId + '&client_id=' + clientId, {
                  method: 'GET',
                  headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
                }),
                fetch(database_url + '/view_company_collections?user_id=' + uid + '&company_id=' + companyId, {
                  method: 'GET',
                  headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
                }),
                fetch(database_url + '/view_company_products?user_id=' + uid + '&company_id=' + companyId, {
                  method: 'GET',
                  headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
                }),
                fetch(database_url + '/view_company_size_charts?user_id=' + uid + '&company_id=' + companyId, {
                  method: 'GET',
                  headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
                }),
                fetch(database_url + '/view_client_persons?user_id=' + uid + '&company_id=' + companyId + '&client_id=' + clientId, {
                  method: 'GET',
                  headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
                }),
                fetch(database_url + '/view_measurement_link?user_id=' + uid + "&companyId=" + companyId + "&clientId=" + clientId + '&measurementLinkBase=' + measurementLinkBase, {
                  method: "GET",
                  headers: { "Content-Type": 'application/json', "Accept": 'application/json', 'x-access-token': accessToken }
                }),
              ]);

              promises
                .then((results) => Promise.all(results.map((r) => r.json())))
                .then((resultsData) => {
                  const [companyConfig, companyMeasurementsConfig, clientMeasurements, companyCollections,
                    companyProducts, companySizeCharts, clientPersons, companyMeasurementLink] = resultsData;

                  setCompanyConfig(companyConfig || defaultConfig)
                  setCompanyMeasurementsConfig(companyMeasurementsConfig || [])
                  setCompanyCollections(companyCollections || {})
                  setCompanyProducts(companyProducts || {})
                  setCompanySizeCharts(companySizeCharts || {})
                  setCompanyMeasurementLink(companyMeasurementLink || "");

                  setCompanyRecipientsLists(getRecipientsLists(clientPersons) || []);

                  setPersons(clientPersons || {})
                  setMeasurements(clientMeasurements || {})

                  setLastUpdatedMeasurementsTable(convertTZ(new Date(), companyConfig['timezone']));

                  const newWhiteLabel = {};

                  if (companyConfig.whiteLabel) {
                    if (companyConfig.whiteLabel.primaryColor) {
                      newWhiteLabel.primaryColor = companyConfig.whiteLabel.primaryColor;
                    }
              
                    if (companyConfig.whiteLabel.sidenavBackgroundColor) {
                      newWhiteLabel.sidenavBackgroundColor = companyConfig.whiteLabel.sidenavBackgroundColor;
                    }
              
                    setWhiteLabel(prevState => ({
                      ...prevState,
                      ...newWhiteLabel
                    }));
                  }

                  if(companyConfig.logo){
                    const newWhiteLabel = {};
                    newWhiteLabel.logo = companyConfig.logo

                    setWhiteLabel(prevState => ({
                      ...prevState,
                      ...newWhiteLabel
                    }));
                  }

                  let language = companyConfig['language'] || 'en'
                  setCompanyLanguage(companyConfig['language'] || 'en');
                  i18n.changeLanguage(language)

                  fillTo100();

                }).then(() => {
                  setAuthChecked(true);
                });
            }
          })
      } else {
        setAuthChecked(true)
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (progress >= 100) {
      setIsFetching(false);
      setProgress(0);
      setStall(false);
      cancelAnimationFrame(fillId);
    }
  }, [progress]);

  const value = {
    userId,
    currentUser,
    role,
    permissions,
    companyId,
    setCompanyId,
    clientId,
    authChecked,
    setAuthChecked,

    adminCompanies,

    standard,
    companyConfig,
    setCompanyConfig,
    whiteLabel,
    companyMeasurementsConfig,
    setCompanyMeasurementsConfig,
    companyClients,
    setCompanyClients,
    companyCollections,
    setCompanyCollections,
    companyProducts,
    setCompanyProducts,
    companySizeCharts,
    setCompanySizeCharts,
    companyRecipientsLists,
    setCompanyRecipientsLists,
    companyUsers,
    companyClientUsers,
    setCompanyClientUsers,
    companyMeasurementLink,

    companyLanguage,

    persons,
    setPersons,

    measurements,
    setMeasurements,

    snackbarOpen,
    setSnackbarOpen,
    snackbarMessage,
    setSnackbarMessage,
    snackbarSeverity,
    setSnackbarSeverity,
    handleSnackbar,

    lastUpdatedMeasurementsTable,
    setLastUpdatedMeasurementsTable,

    chatApiObject,
    setChatApiObject,
  };

  return authChecked ? (
    <AuthContext.Provider value={value}>
      {
        isFetching ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
              width: '100%'
            }}
          >
            <Box sx={{ width: '80%' }}>
              <img src={logo} style={{ maxHeight: '100px', height: 'auto', width: 'auto' }} />
              <Typography>
                Loading your dashboard...
              </Typography>
              <LinearProgress variant="determinate" value={progress} />
            </Box>
          </div>
        ) : (
          <>
            <>
              {children}
            </>
            <TidioWrapper />
          </>
        )
      }
    </AuthContext.Provider>
  ) : (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <CircularProgress />
    </div>
  );
}