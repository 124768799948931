import { useState, useRef } from 'react';

import {
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Button,
  Box,
  Divider
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { AccountCircle, Email, Badge, Phone } from '@mui/icons-material';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'

import QRCode from "react-qr-code";
import Timer from './TimerComponent';

import { useAuth } from '../../../AuthContext';
import { isValidFirestoreFieldName } from '../../utils/functions';
import QRCodeComponent from './QRCodeComponent';
import { useTranslation } from "react-i18next";

const database_url = process.env.REACT_APP_DATABASE_URL;
const measurementLinkBase = process.env.REACT_APP_MEASUREMENT_LINK_BASE;

const determineLinkTarget = (currentUserID) => {
  const demo_accounts = ['zKaCrgNpwyUYIIsHVmlTWLtOe7L2', 'iLYFenP5ClVJY0kqrn4wg7hupCJ2'];
  const hb_accounts = ['4xP2Fic8TPU7HThwDQAbnRd3yzb2'];
  // const qaja_accounts = ['Km8086141xcYFC8bOw6LPd7OQ9v1'];

  if (hb_accounts.includes(currentUserID)) {
    return 'hb_protective_wear';
  }

  // if (qaja_accounts.includes(currentUserID)) {
  //   return 'qaja';
  // }

  return 'esenca';
}

const create_UUID = () => {
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
  var type = 'edw';
  uuid = type.concat(uuid)
  return uuid;
}

const generateFirestoreTimeBasedId = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  const randomIdLength = 16; // Random characters
  const timestampLength = 4; // Timestamp characters

  let randomId = '';
  let timestamp = Date.now().toString(16);

  // Generate random characters
  for (let i = 0; i < randomIdLength; i++) {
    const randomIndex = Math.floor(Math.random() * charactersLength);
    randomId += characters.charAt(randomIndex);
  }

  return `${randomId}${timestamp}`;
}

export default function QuickScanComponent() {
  const { currentUser, companyId, clientId, handleSnackbar, role, persons } = useAuth();
  const { t } = useTranslation();

  const maksEmail = currentUser.email;
  const maksId = currentUser.uid;
  // const personInitId = create_UUID();
  const personInitId = generateFirestoreTimeBasedId();

  const client_first_name_ref = useRef("");
  const client_last_name_ref = useRef("");
  const client_mail_ref = useRef("");
  const clientID_ref = useRef("");
  const client_phone_ref = useRef("");
  const clinet_phone_country_code = useRef("");
  const link_ref_target = companyId;
  // const link_ref = useRef("https://widget.dev.esenca.app/base/" + link_ref_target);
  const link_ref = useRef(measurementLinkBase + link_ref_target);

  const [personFirstName, setPersonFirstName] = useState('');
  const [personLastName, setPersonLastName] = useState('');
  const [personEmail, setPersonEmail] = useState('');
  const [personPhone, setPersonPhone] = useState('');

  // const [measurementLink, setMeasurementLink] = useState("https://widget.dev.esenca.app/base/" + link_ref_target);
  const [measurementLink, setMeasurementLink] = useState(measurementLinkBase + link_ref_target);
  const [copied, setCopied] = useState(false);
  const [timerReset, setTimerReset] = useState(true);

  const [errorFirstName, setErrorFirstName] = useState(false)
  const [errorLastName, setErrorLastName] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);

  const [openQRDialog, setOpenQRDialog] = useState(false);
  const [qrCodeLink, setQrCodeLink] = useState(link_ref.current);

  const [loadingButtonQr, setLoadingButtonQr] = useState(false);
  const [loadingButtonEmail, setLoadingButtonEmail] = useState(false);
  const [loadingButtonSms, setLoadingButtonSms] = useState(false);


  const createLinkFromParams = ({ type = "default", value = false, newID = false } = {}) => {
    let userID = currentUser.uid;

    // let link = "https://widget.dev.esenca.app/base/" + link_ref_target;
    let link = measurementLinkBase + link_ref_target;

    switch (type) {
      case "client_first_name":
        link += "?clientFirstName=" + value;
        link += "&clientLastName=" + client_last_name_ref.current;
        link += "&clientEmail=" + client_mail_ref.current;
        break;

      case "client_last_name":
        link += "?clientFirstName=" + client_first_name_ref.current;
        link += "&clientLastName=" + value;
        link += "&clientEmail=" + client_mail_ref.current;
        break;

      case "client_email":
        link += "?clientFirstName=" + client_first_name_ref.current;
        link += "&clientLastName=" + client_last_name_ref.current;
        link += "&clientEmail=" + value;
        break;

      default:
        link += "?clientFirstName=" + client_first_name_ref.current;
        link += "&clientLastName=" + client_last_name_ref.current;
        link += "&clientEmail=" + client_mail_ref.current;
        break;
    }
    setMeasurementLink(link);
    return link;
  }

  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;

    if (name === 'client_first_name') {
      client_first_name_ref.current = value;
      setPersonFirstName(value);
      let new_link = createLinkFromParams({ type: 'client_first_name', value: value });
      link_ref.current = new_link;
    } else if (name === 'client_last_name') {
      client_last_name_ref.current = value;
      setPersonLastName(value);
      let new_link = createLinkFromParams({ type: 'client_last_name', value: value });
      link_ref.current = new_link;
    } else if (name === 'client_mail') {
      client_mail_ref.current = value;
      setPersonEmail(value);
      let new_link = createLinkFromParams({ type: 'client_mail', value: value });
      link_ref.current = new_link;
    } else if (name == 'client_tracking_id') {
      clientID_ref.current = value;
    }

    setMeasurementLink(link_ref.current);
  };

  const handleOnTimerEnd = () => {
    let new_link = createLinkFromParams({ newID: true });
    link_ref.current = new_link;
    setMeasurementLink(link_ref.current);
    setTimerReset(true);
    setTimeout(() => {
      setTimerReset(false);
    }, 100)
  }

  const copyLink = () => {
    navigator.clipboard.writeText(measurementLink);
    setCopied(true);
  };

  const handleChangePhone = (newPhone, info) => {
    setPersonPhone(newPhone);
    clinet_phone_country_code.current = "+" + info['countryCallingCode'];
    client_phone_ref.current = newPhone;
  }

  const getShortLink = async (link) => {

    const accessToken = await currentUser.getIdToken(true);
    let response = await fetch(database_url + '/shorten?user_id=' + currentUser.uid, {
      method: 'POST',
      headers: {'Content-Type': 'application/json', "Accept": 'application/json', "x-access-token": accessToken},
      body: JSON.stringify({
        'url': link
      })
    });

    let result = await response.json();

    return result['result']['shortURL'];
  }

  const getPersonId = (persons, trackingId) => {
    let foundPersonId = '';
    // console.log(persons);

    Object.keys(persons).forEach(person => {
      let currentPersonDetails = persons[person];

      if (Object.keys(currentPersonDetails).includes('trackingId')) {
        let currentPersonTrackingId = currentPersonDetails['trackingId'];

        if (currentPersonTrackingId === trackingId) {
          foundPersonId = person;
        }
      }
    });

    return foundPersonId;
  }

  const generateMeasurementId = async () => {
    let url = "https://database.esenca.app";
    // let url = "http://localhost:5001";
    let response = await fetch(url + "/generate_measurementId", {
      method: 'GET',
    });

    let result = await response.text();

    return result;
  };

  const generateMeasurementLink = async () => {
    let trackingId;
    let personIdToSend;
    if (clientID_ref.current) {
      trackingId = clientID_ref.current;
      personIdToSend = getPersonId(persons, trackingId);
    }

    let clientIdToSend;
    if (role == 'company') {
      clientIdToSend = companyId;
    } else {
      clientIdToSend = clientId;
    }

    let measurement_id = await generateMeasurementId();

    let longLink = link_ref.current + "&clientId=" + clientIdToSend + "&maksId=" + maksId + "&uid=" + measurement_id;
    if (trackingId) {
      longLink += "&trackingId=" + trackingId;
    }
    if (personIdToSend) {
      longLink += "&personId=" + personIdToSend;
    }

    let shortenedLink = await getShortLink(longLink);
    setQrCodeLink(shortenedLink);
    setLoadingButtonQr(false);

    return longLink;
  }

  const handleQRCode = async () => {
    setLoadingButtonQr(true);
    await generateMeasurementLink();
    handleOpenQRDialog();
  }

  const handleOpenQRDialog = () => {
    window.tidioChatApi.display(false);
    setOpenQRDialog(true);
  }

  const handleCloseQRDialog = () => {
    window.tidioChatApi.display(true);
    setOpenQRDialog(false);
  }

  function extractPhoneNumber(input) {
    // Regular expression to match the country code at the beginning
    const countryCodeRegex = /^\+\d{1,3}/;
    
    // Remove the country code from the input string
    const numberWithoutCountryCode = input.replace(countryCodeRegex, '');
    
    // Remove any non-digit characters to get the actual phone number
    const actualNumber = numberWithoutCountryCode.replace(/\D/g, '');
    
    return actualNumber;
  }

  const handleSendEmail = async () => {
    setLoadingButtonEmail(true);

    const accessToken = await currentUser.getIdToken(true);
    let linkToSend = await generateMeasurementLink();

    let response = await fetch(database_url + '/send_single_mail?user_id=' + currentUser.uid, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'x-access-token': accessToken },
      body: JSON.stringify({
        'link': linkToSend,
        'toEmail': client_mail_ref.current,
        'personFirstName': client_first_name_ref.current,
        'companyId': companyId,
        'fromEmail': currentUser.email
      })
    });

    let result = await response.json();

    if (result['success']) {
      handleSnackbar('success', 'Email sent successfully');
    } else {
      handleSnackbar('error', 'Error.')
    }

    setLoadingButtonEmail(false);
  }

  const handleSendSMS = async () => {
    setLoadingButtonEmail(true);
    const accessToken = await currentUser.getIdToken(true);
    let linkToSend = await generateMeasurementLink();

    let response = await fetch(database_url + '/send_single_sms?user_id=' + currentUser.uid, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'x-access-token': accessToken },
      body: JSON.stringify({
        'link': linkToSend,
        'phone': client_phone_ref.current
      })
    });

    let result = await response.json();

    if (result['success']) {
      handleSnackbar('success', 'SMS sent successfully');
    } else {
      handleSnackbar('error', 'Error.')
    }
    setLoadingButtonEmail(false);
  }

  return (
    <Grid container>
      <Grid item xs={6} sx={{ borderRight: 1, borderColor: 'divider', pr: 1 }}>
        <Grid container mt={2}>
          <Grid item xs={12}>
            <Typography variant='body1' fontWeight={'bold'}>
              {t(`scanHeaderOne`)}
            </Typography>
            <Typography variant='body2'>
              {t(`scanLabelOne`)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t(`First Name`)}
              name='client_first_name'
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                    <Divider orientation='vertical' />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              sx={{
                mt: 3,
                mb: 1,
                '& input': {
                  textAlign: 'left'
                }
              }}
              fullWidth
              value={personFirstName}
              onChange={handleChange}
              error={errorFirstName}
              helperText={errorFirstName ? t(`scanErrorOne`) : ""}
              required
              id='client_first_name'
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t(`Last Name`)}
              name='client_last_name'
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Badge />
                    <Divider orientation='vertical' />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              sx={{
                mt: 3,
                mb: 1,
                '& input': {
                  textAlign: 'left'
                }
              }}
              fullWidth
              value={personLastName}
              onChange={handleChange}
              error={errorLastName}
              helperText={errorLastName ? t(`scanErrorTwo`) : ""}
              required
              id='client_last_name'
            />
            <Box display={'flex'} justifyContent={"space-between"}>
              <TextField
                label={t(`Tracking ID`)}
                name='client_tracking_id'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Badge />
                      <Divider orientation='vertical' />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                sx={{ mt: 3, mb: 1}}
                fullWidth
                value={clientID_ref.current}
                onChange={handleChange}
              />
              {/* <Button variant='contained' sx={{ height: "50%", width: '25%', alignSelf: "center" }} onClick={generateTrackingId}>
                Generate
              </Button> */}
            </Box>
            <Typography variant='body2' alignSelf={'center'} px={2}>
              {t(`scanLabelTwo`)}
            </Typography>
          </Grid>
          <Grid item xs={12} >
            <Divider />
            <Typography variant='body1' fontWeight={'bold'}>
              {t(`scanHeaderTwo`)}
            </Typography>
          </Grid>
          <Grid item xs={12} >
            <TextField
              label="Email"
              name='client_mail'
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email />
                    <Divider orientation='vertical' />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              sx={{
                mt: 3,
                mb: 1,
                '& input': {
                  textAlign: 'left'
                }
              }}
              fullWidth
              value={personEmail}
              onChange={handleChange}
              error={errorEmail}
              helperText={errorEmail ? t(`scanLabelThree`) : ""}
              required
              id='client_mail'
            />
            <Button
              variant='contained' 
              sx={{ width: '30%' }}
              disabled={!( personEmail && personFirstName && personLastName)}
              onClick={handleSendEmail}
            >
              {t(`scanButtonOne`)}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Divider />
            <Typography variant='body1' fontWeight={'bold'}>
              {t(`scanHeaderThree`)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <MuiTelInput
              value={personPhone}
              onChange={handleChangePhone}
              sx={{
                mt: 3,
                mb: 1,
                '& input': {
                  textAlign: 'left'
                }
              }}
              fullWidth
              defaultCountry="DE"
              onlyCountries={['DE', 'RO']}
              forceCallingCode
              id='client_phone'
            />
          </Grid>
          <Grid item xs={12}>
            <Button 
              variant='contained' 
              sx={{ width: '30%' }}
              disabled={!( extractPhoneNumber(client_phone_ref.current) && personFirstName && personLastName)}
              onClick={handleSendSMS}
            >
              {t(`scanButtonTwo`)}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} pl={5}>
        <Grid container mt={2}>
          <Grid item xs={12}>
            <Typography variant='body1' fontWeight={'bold'}>
              {t(`scanHeaderFive`)}
            </Typography>
            <Typography variant='body2'>
              {t(`scanLabelThree`)}
            </Typography>
          </Grid>
          <Grid item xs={12} display={'flex'} justifyContent={'center'} mt={5}>
            <LoadingButton
              variant='contained'
              onClick={handleQRCode}
              disabled={!(personFirstName && personLastName)}
              id='button-generate-qr'
              loading={loadingButtonQr}
            >
              {t(`scanButtonThree`)}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
      <QRCodeComponent
        openQRDialog={openQRDialog}
        handleCloseQRDialog={handleCloseQRDialog}
        link={qrCodeLink}
      />
    </Grid>

  );
}