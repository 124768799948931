import React, { useEffect, useState } from 'react';

import { MenuItem, Select, FormControl, InputLabel, Typography, Grid, Chip, Box, Tooltip } from '@mui/material';
import FmdBadIcon from '@mui/icons-material/FmdBad';

import { useTranslation } from "react-i18next";
import { associationsHaveDuplicates } from '../utils';

function Associations({ data, standard, associations, setAssociations, companyMeasurementsConfig, setDuplicatesAssociationsFlag }) {
  const { t } = useTranslation();

  const [userStandard, setUserStandard] = useState({})
  const [duplicatesArray, setDuplicatesArray] = useState({})

  useEffect(()=>{
    const result = associationsHaveDuplicates(associations);
    setDuplicatesAssociationsFlag(result.hasDuplicateValue)
    setDuplicatesArray(result.seen)
  },[associations])

  useEffect(()=>{
    if(companyMeasurementsConfig.length > 0){
      const filteredStandard = companyMeasurementsConfig.reduce((acc, key) => {
        if (standard.hasOwnProperty(key)) {
          acc[key] = standard[key];
        }
        return acc;
      }, {});
    

      let dataArray = Object.entries(filteredStandard);
      dataArray.sort((a, b) => {
        let nameA = a[1].name.toLowerCase();
        let nameB = b[1].name.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
      let sortedData = {};
      for (let [key, value] of dataArray) {
        sortedData[key] = value;
      }
      setUserStandard(sortedData)
    }
  },[])
 

  const handleAssociationChange = (item, selectedItem, selectedValue) => {
    setAssociations((prevAssociations) => ({
      ...prevAssociations,
      [item]: {
        ...prevAssociations[item],
        [selectedItem]: selectedValue,
      },
    }));
  };

  return (
    <div>
      <Grid container>
        {Object.keys(data).map((item) => (
            <Grid item container key={item} xs={12} md={6} mt={2}>
              <Grid item xs={12}>
                <Chip label={item} color="primary"/>
              </Grid>
                {data[item].map((association) => (
                  association != 'sizes' && 
                  <Grid item container xs={12} key={association}>
                    <Grid item  xs={12} mt={2}>
                      <Typography variant="body2" sx={{fontWeight:'bold'}}>{association}</Typography>
                    </Grid>
                    <Grid item xs={12} mt={2}>
                      <FormControl>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <InputLabel>{t(`Choose an item`)}</InputLabel>
                        <Select
                          value={associations[item] ? associations[item][association] || '' : ''}
                          onChange={(e) => handleAssociationChange(item, association, e.target.value)}
                          sx={{width:'200px'}}
                          variant='standard'
                          size='small'
                        >
                          {Object.keys(userStandard).map((id) => (
                            <MenuItem key={id} value={id}>
                              {userStandard[id].name}
                            </MenuItem>
                          ))}
                        </Select>
                        {duplicatesArray[item] && duplicatesArray[item][associations[item][association]] && (
                          <Tooltip title="Duplicate key detected" placement="top" arrow>
                            <FmdBadIcon color="error" />
                          </Tooltip>
                        )}
                        </Box>
                      </FormControl>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default Associations;
