// import logo from './assets/logo.png'
// import logo from './assets/esenca_logo_monochrome_RGB.svg'
import chroma from "chroma-js";
import defaultConfig from "./defaultConfig";

const font =  "'Montserrat', sans-serif";

const sidenavWidth = 240;
export const mainComponentWidthDifference = sidenavWidth + 32 + 16;

export const colors = {
  gray: "#dee2e6",
  black: "#000000",
  white: "#ffffff",
  background: "#eef8fd",
  primary: defaultConfig.whiteLabel.primaryColor,
  secondary: "#C0DAF3",
  info: "#6e7ffa",
  success: "#4CAF50",
  warning: "#fb8c00",
  error: "#F44335",
  light: "#f0f2f5",
  dark: "#2f2f2f",

  text: {
    primary: defaultConfig.whiteLabel.primaryColor,
    secondary: defaultConfig.whiteLabel.primaryColor
  },

  shadowsPrimary: "#e91e62",
  shadowsSecondary: "#110e0e",
  shadowsInfo: "#00bbd4",
  shadowsSuccess: "#4caf4f",
  shadowsWarning: "#ff9900",
  shadowsError: "#f44336",
  shadowsLight: "#adb5bd",
  shadowsDark: "#404040",

  sidenavBackground: defaultConfig.whiteLabel.sidenavBackgroundColor,
  sidenavText: defaultConfig.whiteLabel.primaryColor,
  sidenavTextSelected: "#eef8fd",
  sidenavItemSelected: defaultConfig.whiteLabel.primaryColor,
  sidenavItemHover: "#eef8fd",
  
  tabs: "#ddd",
  
  inputBorderColor: "#d2d6da"
};

// red test: #ff0000
export function pxToRem(number, baseNumber = 16) {
  return `${number / baseNumber}rem`;
}

export function hexToRgb(color) {
  return chroma(color).rgb().join(", ");
}

export function rgba(color, opacity) {
  return `rgba(${hexToRgb(color)}, ${opacity})`;
}

export function boxShadow(offset = [], radius = [], color, opacity, inset = "") {
  const [x, y] = offset;
  const [blur, spread] = radius;

  return `${inset} ${pxToRem(x)} ${pxToRem(y)} ${pxToRem(blur)} ${pxToRem(spread)} ${rgba(
    color,
    opacity
  )}`;
}

export function linearGradient(color, colorState, angle = 195) {
  return `linear-gradient(${angle}deg, ${color}, ${colorState})`;
}

function gradientChartLine(chart, color, opacity = 0.2) {
  const ctx = chart.getContext("2d");
  const gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
  const primaryColor = rgba(color, opacity).toString();

  gradientStroke.addColorStop(1, primaryColor);
  gradientStroke.addColorStop(0.2, "rgba(72, 72, 176, 0.0)");
  gradientStroke.addColorStop(0, "rgba(203, 12, 159, 0)");

  return gradientStroke;
}

export const breakpoints = {
  values: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  },
}

export const borders = {
  borderColor: colors.gray,

  borderWidth: {
    0: 0,
    1: pxToRem(1),
    2: pxToRem(2),
    3: pxToRem(3),
    4: pxToRem(4),
    5: pxToRem(5),
  },

  borderRadius: {
    xs: pxToRem(1.6),
    sm: pxToRem(2),
    md: pxToRem(6),
    lg: pxToRem(8),
    xl: pxToRem(12),
    xxl: pxToRem(16),
    section: pxToRem(160),
  },
};

export const boxShadows = {
  xs: boxShadow([0, 2], [9, -5], colors.black, 0.15),
  sm: boxShadow([0, 5], [10, 0], colors.black, 0.12),
  md: `${boxShadow([0, 4], [6, -1], colors.black, 0.1)}, ${boxShadow(
    [0, 2],
    [4, -1],
    colors.black,
    0.06
  )}`,
  lg: `${boxShadow([0, 10], [15, -3], colors.black, 0.1)}, ${boxShadow(
    [0, 4],
    [6, -2],
    colors.black,
    0.05
  )}`,
  xl: `${boxShadow([0, 20], [25, -5], colors.black, 0.1)}, ${boxShadow(
    [0, 10],
    [10, -5],
    colors.black,
    0.04
  )}`,
  xxl: boxShadow([0, 20], [27, 0], colors.black, 0.05),
  inset: boxShadow([0, 1], [2, 0], colors.black, 0.075, "inset"),
  colored: {
    primary: `${boxShadow([0, 4], [20, 0], colors.black, 0.14)}, ${boxShadow(
      [0, 7],
      [10, -5],
      colors.shadowsPrimary,
      0.4
    )}`,
    secondary: `${boxShadow([0, 4], [20, 0], colors.black, 0.14)}, ${boxShadow(
      [0, 7],
      [10, -5],
      colors.shadowsSecondary,
      0.4
    )}`,
    info: `${boxShadow([0, 4], [20, 0], colors.black, 0.14)}, ${boxShadow(
      [0, 7],
      [10, -5],
      colors.shadowsInfo,
      0.4
    )}`,
    success: `${boxShadow([0, 4], [20, 0], colors.black, 0.14)}, ${boxShadow(
      [0, 7],
      [10, -5],
      colors.shadowsSuccess,
      0.4
    )}`,
    warning: `${boxShadow([0, 4], [20, 0], colors.black, 0.14)}, ${boxShadow(
      [0, 7],
      [10, -5],
      colors.shadowsWarning,
      0.4
    )}`,
    error: `${boxShadow([0, 4], [20, 0], colors.black, 0.14)}, ${boxShadow(
      [0, 7],
      [10, -5],
      colors.shadowsError,
      0.4
    )}`,
    light: `${boxShadow([0, 4], [20, 0], colors.black, 0.14)}, ${boxShadow(
      [0, 7],
      [10, -5],
      colors.shadowsLight,
      0.4
    )}`,
    dark: `${boxShadow([0, 4], [20, 0], colors.black, 0.14)}, ${boxShadow(
      [0, 7],
      [10, -5],
      colors.shadowsDark,
      0.4
    )}`,
  },

  navbarBoxShadow: `${boxShadow([0, 0], [1, 1], colors.white, 0.9, "inset")}, ${boxShadow(
    [0, 20],
    [27, 0],
    colors.black,
    0.05
  )}`,
  sliderBoxShadow: {
    thumb: boxShadow([0, 1], [13, 0], colors.black, 0.2),
  },
  tabsBoxShadow: {
    indicator: boxShadow([0, 1], [5, 1], colors.tabs, 1),
  },
};

const defaultTheme = (primaryColor) => ({
  breakpoints: {...breakpoints},
  borders: { ...borders },
  boxShadows: { ...boxShadows },
  typography: {
    fontFamily: font,
    },
    palette: {
      type: 'light',
      primary: {
        main: primaryColor ? primaryColor : colors.primary,
      },
      secondary: {
        main: colors.secondary,
      },
      // text: {
      //   primary: colors.text.primary,
      // }
  },
    components: {
      MuiAppBar: {
        defaultProps: {
          color: "transparent",
        },
      
        styleOverrides: {
          root: {
            boxShadow: "none",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: borders.borderRadius.md,
            padding: `${pxToRem(6.302)} ${pxToRem(16.604)}`,
            lineHeight: 1.4,
            fontWeight: 700,
          },
          contained: {
            minHeight: pxToRem(37),
            color: colors.white,
            padding: `${pxToRem(9)} ${pxToRem(24)}`,

            "&:hover": {
              backgroundColor: colors.white,
            },

            "&:active, &:active:focus, &:active:hover": {
              opacity: 0.85,
            },

            "& .material-icon, .material-icons-round, svg": {
              fontSize: `${pxToRem(16)} !important`,
            },
          },
          containedPrimary: {
            backgroundColor: primaryColor ? primaryColor : colors.primary,
        
            "&:hover": {
              backgroundColor: primaryColor ? primaryColor : colors.primary,
            },
        
            "&:focus:not(:hover)": {
              backgroundColor: colors.dark,
            },
          },
          containedSecondary: {
            
          }
        }
      },
      MuiCard: {
        styleOverrides: {
          root: {
            display: "flex",
            flexDirection: "column",
            position: "relative",
            minWidth: 0,
            wordWrap: "break-word",
            backgroundColor: colors.background,
            backgroundClip: "border-box",
            border: `${borders.borderWidth[1]} solid ${rgba(colors.black, 0.125)}`,
            borderRadius: borders.borderRadius.xl,
            boxShadow: boxShadows.md,
            overflow: "visible",
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          // ...container,
          html: {
            scrollBehavior: "smooth",
            backgroundColor: colors.background
          },
          body: {
            backgroundColor: colors.background
          },
          "*, *::before, *::after": {
            margin: 0,
            padding: 0,
          },
          "a, a:link, a:visited": {
            textDecoration: "none !important",
          },
          "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited": {
            color: `${colors.dark} !important`,
            transition: "color 150ms ease-in !important",
          },
          "a.link:hover, .link:hover, a.link:focus, .link:focus": {
            color: `${colors.info} !important`,
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            // backgroundColor: transparent.main,
            backgroundImage: `linear-gradient(to right, ${rgba(colors.dark, 0)}, ${rgba(
              colors.dark,
              0.4
            )}, ${rgba(colors.dark, 0)}) !important`,
            height: pxToRem(1),
            margin: `${pxToRem(16)} 0`,
            borderBottom: "none",
            // opacity: 0.25,
          },
      
          vertical: {
            backgroundColor: 'transparent',
            backgroundImage: `linear-gradient(to bottom, ${rgba(colors.dark, 0)}, ${rgba(
              colors.dark,
              0.4
            )}, ${rgba(colors.dark, 0)}) !important`,
            width: pxToRem(1),
            height: "100%",
            margin: `0 ${pxToRem(16)}`,
            borderRight: "none",
          },
      
          light: {
            backgroundColor: 'transparent',
            backgroundImage: `linear-gradient(to right, ${rgba(colors.white, 0)}, ${colors.white}, ${rgba(
              colors.white,
              0
            )}) !important`,
      
            "&.MuiDivider-vertical": {
              backgroundImage: `linear-gradient(to bottom, ${rgba(colors.white, 0)}, ${colors.white}, ${rgba(
                colors.white,
                0
              )}) !important`,
            },
          },
        },
      },
      // MuiDrawer: {
      //   styleOverrides: {
      //     root: {
      //       width: pxToRem(sidenavWidth),
      //       whiteSpace: "nowrap",
      //       border: "none",
      //     },
      
      //     paper: {
      //       width: pxToRem(sidenavWidth),
      //       backgroundColor: colors.sidenavBackground,
      //       height: `calc(100vh - ${pxToRem(32)})`,
      //       margin: pxToRem(16),
      //       borderRadius: borders.borderRadius.xl,
      //       border: "none",
      //     },
      
      //     paperAnchorDockedLeft: {
      //       borderRight: "none",
      //     },
      //   },
      // },
      MuiList: {
        styleOverrides: {
          padding: {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
      },
      MuiListItem: {
        defaultProps: {
          disableGutters: true,
        },
      
        styleOverrides: {
          root: {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
            root: {
                borderRadius: borders.borderRadius.md,
                padding: `${pxToRem(10)} ${pxToRem(10)}`,
                margin: `${pxToRem(1.5)} ${pxToRem(10)}`,
                background: 'transparent',
                '&:hover': {
                  backgroundColor: colors.sidenavItemHover
                }
            }
        }
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            marginTop: 0,
            marginBottom: 0,
          },
        },
      },
      MuiLoadingButton: {
        styleOverrides: {
          root: {
            borderRadius: borders.borderRadius.md,
            padding: `${pxToRem(6.302)} ${pxToRem(16.604)}`,
            lineHeight: 1.4,
            fontWeight: 700,
          },
          contained: {
            minHeight: pxToRem(37),
            color: colors.white,
            padding: `${pxToRem(9)} ${pxToRem(24)}`,

            "&:hover": {
              backgroundColor: colors.white,
            },

            "&:active, &:active:focus, &:active:hover": {
              opacity: 0.85,
            },

            "& .material-icon, .material-icons-round, svg": {
              fontSize: `${pxToRem(16)} !important`,
            },
          },
          containedPrimary: {
            backgroundColor: primaryColor ? primaryColor : colors.primary,
        
            "&:hover": {
              backgroundColor: primaryColor ? primaryColor : colors.primary,
            },
        
            "&:focus:not(:hover)": {
              backgroundColor: colors.dark,
            },
          },
          containedSecondary: { 
          }
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root:hover": {
              "& > fieldset": {
                borderColor: "#544695"
              },
            },
            "& .MuiOutlinedInput-root":{
              color:"#544695",
              borderRadius: 10,
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            color: colors.white,
            backgroundColor: '#43c9de'
          }
        },
        MuiDataGrid:{
          styleOverrides:{
            cell:{
              "&:focus":{
                outline:'none'
              },
              "&:focus-within":{
                outline:'none'
              },
            }
          }
        }
      }
  },
  // logo: logo,
})

export default defaultTheme;