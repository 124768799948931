import React, {useEffect, useState} from "react";
import TimeoutModal from "./TimeoutModal";

import { auth } from "../../firebase";
import { signOut } from "firebase/auth";

import { useNavigate } from 'react-router-dom';

import { useAuth } from "../../AuthContext";

const eventTypes = [
    'keypress',
    'mousemove',
    'mousedown',
    'scroll',
    'touchmove',
    'pointermove'
  ]
    
export const TimeoutLogic = () => {

    const navigate = useNavigate();
    const {currentUser} = useAuth()

    const [isWarningModalOpen, setWarningModalOpen] = useState(false);

    const addEventListeners = (listener) => {
        eventTypes.forEach((type) => {
        window.addEventListener(type, listener, false)
        })
    }

   const removeEventListeners = (listener) => {
        if (listener) {
        eventTypes.forEach((type) => {
            window.removeEventListener(type, listener, false)
        })
        }
    }

    useEffect(() => {
        if(currentUser){
            const createTimeout1 = () => setTimeout(()=>{
                signOut(auth)
                window.tidioChatApi.hide();
                setWarningModalOpen(true);
            },60*60*1000)
    
            // const createTimeout2 = () => setTimeout(() => {
            //     signOut(auth)
            //     navigate('/')
            //     setWarningModalOpen(false)
            // },5000)
    
            const listener = () => {
                if(!isWarningModalOpen){
                    clearTimeout(timeout)
                    timeout = createTimeout1();
                }
            } 
    
            // let timeout = isWarningModalOpen  ? createTimeout2() : createTimeout1()
            let timeout = createTimeout1()
            addEventListeners(listener);
    
            return () => {
                removeEventListeners(listener);
                clearTimeout(timeout);
            }
        }
    },[isWarningModalOpen,currentUser])

    return (
        <div>
        {isWarningModalOpen && (
            <TimeoutModal 
                isOpen={isWarningModalOpen}
                onRequestClose={() => setWarningModalOpen(false)}
            />
            )
        }
        </div>
    ) 
}