import { useEffect, useState } from "react";

import {
  AppBar, Toolbar, Typography, TextField, Grid, TableContainer, TableHead, Table, TableCell, TableRow, TableBody, Paper, IconButton, Button,
  Box, Divider, InputLabel
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Close as CloseIcon, ReportProblem as ReportProblemIcon } from '@mui/icons-material'

import { areKeysUnique, isObjectValid, cloneDeep, convertObjectToFloat, isNumberKey, convertDictionariesToArrays, convertArraysToDictionaries } from "../utils";

import { useAuth } from "../../../../AuthContext";
import { colors } from "../../../../defaultTheme";
import { Edit, Save } from "@mui/icons-material";

import { useTranslation } from "react-i18next";

const database_url = process.env.REACT_APP_DATABASE_URL;

function ChartDetailsDialog(props) {

  const { userId, companyId, standard, handleSnackbar, currentUser, setCompanySizeCharts, companySizeCharts } = useAuth()
  const { t } = useTranslation();

  const [originalChart, setOriginalChart] = useState({})
  const [data, setData] = useState({});
  const [sizeChartName, setSizeChartName] = useState("");
  const [editedSizeChartName, setEditedSizeChartName] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [edit, setEdit] = useState(false)
  const [buttonLoader, setButtonLoader] = useState(false);
  const [duplicatedNameFlag, setDuplicatedNameFlag] = useState(false)

  useEffect(() => {
    if (props.item) {
      const sc = companySizeCharts[props.item]
      setOriginalChart(sc)
      const chartCopy = cloneDeep(sc.data);
      const revertedObject = convertDictionariesToArrays(chartCopy);
      setData(revertedObject)
      if(sc?.metadata?.name){
        setSizeChartName(sc?.metadata?.name)
        setEditedSizeChartName(sc?.metadata?.name)
      }
    }
  }, [props.item, companySizeCharts])

  useEffect(()=>{
    const sizeChartNames = Object.entries(companySizeCharts)
      .filter(([key, value]) => key !== props.item)
      .map(([key, value]) => value.metadata?.name?.toLowerCase());
    const isNewNameInArray = sizeChartNames.includes(editedSizeChartName?.toLowerCase());
    if(isNewNameInArray){
      setDuplicatedNameFlag(true)
    } else {
      setDuplicatedNameFlag(false)
    }
  },[companySizeCharts, editedSizeChartName])

  if (!data || !data.sizes) {
    return null;
  }

  const handleCellChange = (e, key, index) => {
    const updatedData = { ...data };
    if (key === 'Sizes') {
      updatedData.sizes = [...updatedData.sizes];
      updatedData.sizes[index] = e.target.value;
    }
    setData(updatedData);
  };
  
  const handleInputChange = (e, key, size) => {
    const updatedData = { ...data };
    const sizeIndex = updatedData.sizes.indexOf(size);
    updatedData[key] = updatedData[key].map((item, index) => {
      if (index === sizeIndex) {
        return e.target.value;
      }
      return item;
    });
    setData(updatedData);
  };  
  

  const handleCancelEdit = () => {
    const chartCopy = cloneDeep(originalChart.data);
    const revertedObject = convertDictionariesToArrays(chartCopy);
    setData(revertedObject);
    setEdit(false)
    setEditedSizeChartName(originalChart?.metadata?.name)
  };


  const handleSaveUpdatedSizeChart = async () => {
    if (!isObjectValid(data)) {
      setIsValid(false)
      handleSnackbar('error', t(`scSnackOne`));
    } else if(!areKeysUnique(Object.values(data["sizes"]))){
      setIsValid(false)
      handleSnackbar('error', t(`scSnackOne`));
    } else if(duplicatedNameFlag && editedSizeChartName!==sizeChartName){
      setIsValid(false)
      handleSnackbar('error', 'Please re-name your size chart.');
    } else if(editedSizeChartName === ""){
      setIsValid(false)
      handleSnackbar('error', 'Please re-name your size chart.');
    } else {
      setButtonLoader(true)
      setIsValid(true)

      let chartId = props.item

      const accessToken = await currentUser.getIdToken(true);

      convertObjectToFloat(data)
      const convertedSizeChart = convertArraysToDictionaries(data);

      if(JSON.stringify(convertedSizeChart) === JSON.stringify(originalChart?.data) && editedSizeChartName === sizeChartName){
        setEdit(false)
        setButtonLoader(false)
      } else{
        if (companyId) {
          let sendData = {}

          sendData.chart_id = chartId
  
          if(editedSizeChartName !== sizeChartName) {
            let metadata = {}
            metadata.name = editedSizeChartName
            sendData.metadata = metadata
          }
          if(JSON.stringify(convertedSizeChart) !== JSON.stringify(originalChart.data)){
            sendData.sc_data = convertedSizeChart
          }

          fetch(database_url + "/update_company_size_chart?user_id=" + userId + '&company_id=' + companyId, {
            method: "PUT",
            headers: { "Accept": "application/json", "Content-Type": 'application/json', 'x-access-token': accessToken },
            body: JSON.stringify(sendData)
          }).then(() => {
            setEdit(false)
            setButtonLoader(false)

            let promises = Promise.all([
              fetch(database_url + '/view_company_size_charts?user_id=' + userId + '&company_id=' + companyId, {
                method: 'GET',
                headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
              }),
            ]);

            promises
            .then((results) => Promise.all(results.map((r) => r.json())))
            .then((resultsData) => {
              const [companySizeCharts] = resultsData;
              setCompanySizeCharts(companySizeCharts || {});
              setSizeChartName(editedSizeChartName)
            }).then(()=>{
              handleSnackbar('success', "Size chart saved to database.");
            })
          });
        } else {
          handleCancelEdit()
          handleSnackbar('error', "Error occured.");
        }
      }
    }
  };

  const getMeasurementNameFromId = (measurementId) => {
    if (standard && standard[measurementId]) {
      return standard[measurementId].name || "Unknown Measurement";
    }
    return "Unknown Measurement";
  };


  return (
    <>
      <AppBar
        sx={{
          backgroundColor: colors.primary,
          color: colors.white
        }}
      >
        <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6" component="div">
            {t(`Size Chart`)}: {originalChart?.metadata?.name}
          </Typography>
          <IconButton
            color="inherit"
            onClick={props.handleCloseDialog}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Toolbar />
        </Grid>
        <Grid item xs={12}>
          {
            edit === false ? (
              <Typography variant="body1">
                {t(`scLabelTwo`)}
              </Typography>
            ) : (
              <Typography variant="body1">
                {t(`scLabelThree`)}
              </Typography>
            )
          }
        </Grid>
        <Grid item container xs={12} mt={2}>
          {edit === false ?
            <>
              <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
                <Button
                  onClick={() => { setEdit(true) }}
                  variant="contained"
                  data-cy={"size-chart-edit-button"}
                  startIcon={
                    <Edit />
                  }
                >
                  {t(`Edit`)}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Box display={'flex'} sx={{ width: '100%' }}>
                    <InputLabel sx={{ alignSelf: 'center', mr: 2, fontWeight: 'bold' }}>{t(`Size Chart Name`)}</InputLabel>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      fullWidth
                      value={sizeChartName}
                      disabled
                      sx={{ width: { xs: '90%', md: '200px'} }}
                    />
                  </Box>
              </Grid>
              {duplicatedNameFlag && 
              <Grid item xs={12} md={6}>
                <Box display="flex" alignItems="left" mb={1}>
                    <ReportProblemIcon color="warning" />
                    <Box ml={1}>
                  <Typography variant="body2" sx={{fontWeight:'bold'}}>
                    Your company already has size chart(s) that share identical name(s).
                  </Typography>
                  </Box>
                </Box>
              </Grid>
            }
            </>
            :
            <>
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    onClick={handleCancelEdit}
                    sx={{ margin: '0 25px', width: '200px' }}
                    variant="outlined"
                  >
                    {t(`Cancel`)}
                  </Button>
                  <LoadingButton
                      onClick={handleSaveUpdatedSizeChart}
                      sx={{ margin: '0 25px', width: '200px' }}
                      loading={buttonLoader}
                      variant="contained"
                      data-cy={"size-chart-save-button"}
                      startIcon={
                      <Save />
                    }
                  >
                    {t(`Save`)}
                  </LoadingButton>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display={'flex'} sx={{ width: '100%' }}>
                  <InputLabel sx={{ alignSelf: 'center', mr: 2, fontWeight: 'bold' }}>{t(`Size Chart Name`)}</InputLabel>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    fullWidth
                    value={editedSizeChartName}
                    onChange={(event) =>
                      setEditedSizeChartName(event.target.value)
                    }
                    sx={{ width: { xs: '90%', md: '200px' } }}
                  />
                </Box>
              </Grid>
              {duplicatedNameFlag && 
              <Grid item xs={12} md={6}>
                <Box display="flex" alignItems="left" mb={1}>
                    <ReportProblemIcon color="warning" />
                    <Box ml={1}>
                  <Typography variant="body2" sx={{fontWeight:'bold'}}>
                    Your company already has size chart(s) that share identical name(s).
                  </Typography>
                  </Box>
                </Box>
              </Grid>
            }
            </>
          }
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} mt={2}>
          {edit === false ?
            <TableContainer component={Paper}>
              <Table>
                <TableHead sx={{ display: "table-header-group", backgroundColor: colors.primary }}>
                  <TableRow>
                    <TableCell align="center" sx={{ color: colors.white }}>Sizes</TableCell>
                    {Object.keys(data).map(key => (
                      key !== 'sizes' ? <TableCell align="center" key={key} sx={{ color: colors.white }}>{getMeasurementNameFromId(key)}</TableCell> : <></>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.sizes.map(size => (
                    <TableRow key={size}>
                      <TableCell align="center">{size}</TableCell>
                      {Object.keys(data).map(key => (
                        key !== 'sizes' ? <TableCell align="center" key={key}>{data[key][data.sizes.indexOf(size)]}</TableCell> : <></>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            :
            <TableContainer component={Paper}>
              <Table>
                <TableHead sx={{ display: "table-header-group", backgroundColor: colors.primary }}>
                  <TableRow>
                    <TableCell align="center" sx={{ color: colors.white }}>Sizes</TableCell>
                    {Object.keys(data).map((key) =>
                      key !== "sizes" ? (
                        <TableCell align="center" key={key} sx={{ color: colors.white }}>
                          {getMeasurementNameFromId(key)}
                        </TableCell>
                      ) : (
                        <></>
                      )
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.sizes.map((size, i) => (
                    <TableRow key={i}>
                      <TableCell align="center">
                        <TextField
                          value={data.sizes[i]}
                          data-cy={`sc-size-${data.sizes[i]}`}
                          onChange={(e) => handleCellChange(e, "Sizes", i)}
                        />
                      </TableCell>
                      {Object.keys(data).map((key) =>
                        key !== "sizes" ? (
                          <TableCell align="center" key={key}>
                            <TextField
                              type="number"
                              // value={data[key][data.sizes.indexOf(size)]}
                              value={data[key][i]} 
                              data-cy={`sc-value-${key}-${data[key][i]}`}
                              onKeyDown={(e) => {
                                  if (!isNumberKey(e)) {
                                    e.preventDefault();
                                  }
                              }}
                              onInput={(e) => handleInputChange(e, key, size)}
                            />
                          </TableCell>
                        ) : (
                          <></>
                        )
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </Grid>
      </Grid>
    </>
  );
}

export default ChartDetailsDialog