import React, { useEffect, useState } from 'react';
import {
    Box, Toolbar, Select, MenuItem, Typography, FormControl, InputLabel, Card, CardContent, Grid, List, ListItem,
    ListItemText, Button, TextField, Checkbox, OutlinedInput, Autocomplete, IconButton
} from '@mui/material';
import { LoadingButton } from "@mui/lab";
import { AddPhotoAlternate, Delete, Done } from '@mui/icons-material';

import { SketchPicker } from 'react-color';

import { useAuth } from '../../../../AuthContext';
import { timezones, languages, standardMeasurementsToAdd } from '../../configs';
import { toBase64 } from '../../../SizeCharts/components/utils'

const database_url = process.env.REACT_APP_DATABASE_URL;

function ConfigData({editMode, setEditMode}) {
    const { adminCompanies, standard, handleSnackbar, userId, currentUser, companyId } = useAuth();

    const [companyDetails, setCompanyDetails] = useState([]);
    const [companyClients, setCompanyClients] = useState([]);

    const [companyName, setCompanyName] = useState("");
    const [timezone, setTimezone] = useState("");
    const [language, setLanguage] = useState("");
    const [measurementsConfig, setMeasurementsConfig] = useState([]);
    const [logo, setLogo] = useState("");
    const [primaryColor, setPrimaryColor] = useState("#27336e");
    const [sidenavBackgroundColor, setSidenavBackgroundColor] = useState("#C0DAF3");
    const [clients, setClients] = useState([]);
    const [measurementsArray, setMeasurementsArray] = useState([]);
    
    const [error, setError] = useState("");
    const [buttonLoader, setButtonLoader] = useState(false);

    useEffect(() => {
        const measurementsArray = Object.keys(standard).map(key => ({
            id: key,
            name: standard[key].name,
            definition: standard[key].definition,
            guidelines: standard[key].guidelines,
            picture: standard[key].picture,
            replaceBy: standard[key].replaceBy,
        }));
        setMeasurementsArray(measurementsArray)
    }, [standard]);

    useEffect(() => {
        if (adminCompanies && companyId && adminCompanies[companyId]) {
            if (adminCompanies[companyId]['config']) {
                let companyDetails = adminCompanies[companyId]['config'];
                setCompanyDetails(companyDetails);
                setCompanyName(companyDetails.name || "");
                setTimezone(companyDetails.timezone || "");
                setLanguage(companyDetails.language || "");
                setMeasurementsConfig(companyDetails.measurementsConfig || []);
                setPrimaryColor(companyDetails.whiteLabel?.primaryColor || "#27336e");
                setSidenavBackgroundColor(companyDetails.whiteLabel?.sidenavBackgroundColor || "#C0DAF3");
            }

            if (adminCompanies[companyId]['clients']) {
                let companyClients = [];
                const clients = adminCompanies[companyId]['clients'];
                Object.keys(clients).forEach(clientId => {
                    let clientWithId = { ...clients[clientId], id: clientId };
                    companyClients.push(clientWithId);
                });
                setCompanyClients(companyClients);
                setClients(companyClients);
            } else {
                setClients([])
                setCompanyClients([])
            }
        }
    }, [companyId, adminCompanies]);

    const handleLogoUpload = (e) => {
        const selectedImage = e.target.files[0];
        if (selectedImage) {
            setLogo(selectedImage)
        }
    };

    const handleClientChange = (index, event) => {
        const newClients = clients.map((client, i) => {
            if (i === index) {
                return { ...client, name: event.target.value };
            }
            return client;
        });
        setClients(newClients);
    };

    const addClient = () => {
        setClients([...clients, { name: '' }]);
    };

    const removeClient = (index) => {
        setClients(clients.filter((_, i) => i !== index));
    };

    const addStandardMeasurements = () => {
        setMeasurementsConfig([...standardMeasurementsToAdd]);
    };

    const addStandardColors = () => {
        setPrimaryColor("#27336e")
        setSidenavBackgroundColor("#C0DAF3")
    }

    const handleCancelEdit = () => {
        setEditMode(false);
        if (adminCompanies[companyId]['config']) {
            let companyDetails = adminCompanies[companyId]['config'];
            setCompanyDetails(companyDetails);
            setCompanyName(companyDetails.name || "");
            setTimezone(companyDetails.timezone || "");
            setLanguage(companyDetails.language || "");
            setMeasurementsConfig(companyDetails.measurementsConfig || []);
            setPrimaryColor(companyDetails.whiteLabel?.primaryColor || "#27336e");
            setSidenavBackgroundColor(companyDetails.whiteLabel?.sidenavBackgroundColor || "#C0DAF3");
            setLogo("")
            setClients(companyClients);
        }
    };


    const handleSaveChanges = async () => {        
        setButtonLoader(true)
        const accessToken = await currentUser.getIdToken(true);

        if(companyId){

            const changedFields = {};

            if ((!companyDetails.name && companyName) || (companyDetails.name && companyName !== companyDetails.name)) {
                changedFields.name = companyName;
            }

            if ((!companyDetails.timezone && timezone) || (companyDetails.timezone && timezone !== companyDetails.timezone)) {
                changedFields.timezone = timezone;
            }

            if ((!companyDetails.language && language) || (companyDetails.language && language !== companyDetails.language)) {
                changedFields.language = language;
            }

            if ((!companyDetails.language && language) || (companyDetails.language && language !== companyDetails.language)) {
                changedFields.language = language;
            }

            if ((!companyDetails.measurementsConfig && measurementsConfig.length > 0) || (companyDetails.measurementsConfig && measurementsConfig !== companyDetails.measurementsConfig)) {
                changedFields.measurementsConfig = measurementsConfig;
            }

            if ((!companyDetails.whiteLabel?.primaryColor && primaryColor) || (companyDetails.whiteLabel?.primaryColor && primaryColor !== companyDetails.whiteLabel?.primaryColor)) {
                changedFields.primaryColor = primaryColor;
            }

            if ((!companyDetails.whiteLabel?.sidenavBackgroundColor && sidenavBackgroundColor) || (companyDetails.whiteLabel?.sidenavBackgroundColor && sidenavBackgroundColor !== companyDetails.whiteLabel?.sidenavBackgroundColor)) {
                changedFields.sidenavBackgroundColor = sidenavBackgroundColor;
            }

            let finalLogo = {}

            if (logo !== "") {
                let currentLogo = logo ? logo : "";
                if (currentLogo) {
                    finalLogo = {
                        "name": currentLogo.name,
                        "data": await toBase64(currentLogo)
                    }
                }
            }

            if (logo && finalLogo) {
                changedFields.logo = finalLogo;
            }

            const filteredClients = clients.filter(client => !client.hasOwnProperty('id')).filter(client => client.name !== '');
            changedFields.clients = filteredClients

            fetch(database_url + "/edit_company_config?user_id=" + userId + '&company_id=' + companyId, {
                method: "PUT",
                headers: { "Accept": "application/json", "Content-Type": 'application/json', 'x-access-token': accessToken },
                body: JSON.stringify(changedFields)
            }).then(() => {
                handleSnackbar('success', "Config edited");
                setButtonLoader(false)
                setEditMode(false)
                window.location.reload()
                // TODO: retrieve data about this company from db and append it to company list --> just the id and config
            });
        } else {
            setButtonLoader(false)
            setError("Missing company details")
        }
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="subtitle2">Database id: {companyId}</Typography>
            </Grid>
            {companyDetails && !editMode && (
                <Grid item container xs={12} mt={3} spacing={2}>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">Name: {companyDetails.name}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">Language: {companyDetails.language}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2">Timezone: {companyDetails.timezone}</Typography>
                    </Grid>
                    <Grid item xs={4} align="left" mt={3}>
                        <Typography variant="subtitle2">Measurements Config:</Typography>
                        <Card sx={{ maxHeight: '200px', overflow: 'auto', padding: '10px' }}>
                            <CardContent>
                                <List>
                                    {companyDetails.measurementsConfig && companyDetails.measurementsConfig.map((config, index) => {
                                    const measurement = measurementsArray.find(measurement => measurement.id === config);
                                    const displayName = measurement ? measurement.name : config;

                                    return (
                                        <ListItem key={index}>
                                        <ListItemText primary={displayName} />
                                        </ListItem>
                                    );
                                    })}
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4} align="left" mt={3}>
                        <Typography variant="subtitle2">White label:</Typography>
                        <Card sx={{ maxHeight: '200px', overflow: 'auto', padding: '10px' }}>
                            <CardContent>
                                {companyDetails.logo ?
                                    <img src={companyDetails.logo} style={{ maxWidth: '100px', maxHeight: '100px' }} />
                                    :
                                    <Typography variant="subtitle2">No logo</Typography>
                                }
                                <Typography>primaryColor: {companyDetails?.whiteLabel?.primaryColor}</Typography>
                                <Typography>sidenavBackgroundColor: {companyDetails?.whiteLabel?.sidenavBackgroundColor}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4} mt={3} align="left" >
                        <Typography variant="subtitle2">Clients:</Typography>
                        <Card sx={{ maxHeight: '200px', overflow: 'auto', padding: '10px' }}>
                            <CardContent>
                                {companyClients && companyClients.length > 0 ? companyClients.map((client, index) => (
                                    <ListItem key={index}>
                                        <ListItemText primary={client.name} />
                                    </ListItem>
                                )) : (
                                    <Typography variant="subtitle2">No clients available</Typography>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} mt={3}>
                        <Button variant="outlined" onClick={() => setEditMode(true)}>Edit</Button>
                    </Grid>
                </Grid>
            )}

            {editMode && (
                <Grid container spacing={2} align="center">
                    <Grid item xs={12} mt={2}>
                        <Typography variant="h6">Editing {companyDetails.name}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4} mt={2}>
                        <TextField
                            label="Company Name"
                            variant="outlined"
                            size="small"
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            fullWidth
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: 2,
                                }
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={4} mt={2}>
                        <FormControl 
                            fullWidth 
                            variant="outlined" 
                            size="small"          
                            sx={{ 
                                '& .MuiOutlinedInput-root': {
                                borderRadius: 2, 
                            }
                        }}>
                        <InputLabel>Language</InputLabel>
                        <Select
                            value={language}
                            onChange={(e) => setLanguage(e.target.value)}
                            label="Language"
                            size="small"
                            sx={{ 
                                '& .MuiOutlinedInput-root': {
                                borderRadius: 2, 
                            }
                            }}
                        >
                            {languages.map((lang) => (
                            <MenuItem key={lang} value={lang}>
                                {lang}
                            </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={4} mt={2}>
                        <Autocomplete
                            freeSolo
                            options={timezones}
                            value={timezone}
                            onChange={(event, newValue) => setTimezone(newValue)}
                            onInputChange={(event, newInputValue) => setTimezone(newInputValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Timezone"
                                    variant="outlined"
                                    size="small"
                                    sx={{ 
                                        '& .MuiOutlinedInput-root': {
                                        borderRadius: 2, 
                                        }
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={8} mt={2}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Measurements Config</InputLabel>
                            <Select
                                multiple
                                value={measurementsConfig}
                                onChange={(e) => setMeasurementsConfig(e.target.value)}
                                input={<OutlinedInput label="Measurements Config" />}
                                renderValue={(selected) => selected.map(id => {
                                    const measurement = measurementsArray.find(measurement => measurement.id === id);
                                    return measurement ? measurement.name : id;
                                }).join(', ')}    
                            >
                                {measurementsArray.map((measurement) => (
                                    <MenuItem key={measurement.id} value={measurement.id}>
                                        <Checkbox checked={measurementsConfig.indexOf(measurement.id) > -1} />
                                        <ListItemText primary={measurement.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={4} mt={2}>
                        <Button variant="outlined" onClick={addStandardMeasurements} sx={{ mt: 2 }}>
                            Add Standard Measurements
                        </Button>
                    </Grid> 

                    <Grid item xs={3} mt={2}>
                        { 
                            logo ?
                                <Typography variant="subtitle2">Uploaded</Typography>
                            :
                            companyDetails.logo ?
                                <img src={companyDetails.logo} style={{ maxWidth: '100px', maxHeight: '100px' }} />
                            :
                            <Typography variant="subtitle2">No logo</Typography>
                        }
                        <Typography variant="subtitle2">Upload Logo:</Typography>
                        <input
                            accept="image/*"
                            id="contained-button-file"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={handleLogoUpload}
                        />
                        <label htmlFor="contained-button-file">
                            <Button variant="outlined" component="span" startIcon={<AddPhotoAlternate />}>
                                {logo ? logo.name : 'Upload'}
                            </Button>
                        </label>
                    </Grid>

                    <Grid item xs={3} mt={2}>
                        <Typography variant="subtitle2">Primary Color:</Typography>
                        <SketchPicker
                            color={primaryColor}
                            onChangeComplete={(color) => setPrimaryColor(color.hex)}
                        />
                    </Grid>

                    <Grid item xs={3} mt={2}>
                        <Typography variant="subtitle2">Sidenav Background Color:</Typography>
                        <SketchPicker
                            color={sidenavBackgroundColor}
                            onChangeComplete={(color) => setSidenavBackgroundColor(color.hex)}
                        />
                    </Grid>

                    <Grid item xs={3} mt={2}>
                        <Button variant="outlined" onClick={addStandardColors}>
                            Add Standard Colors
                        </Button>
                    </Grid>

                    <Grid item xs={12} mt={2}>
                        <Typography variant="subtitle2">Clients:</Typography>
                        {clients.map((client, index) => (
                            <div key={index} style={{  alignItems: 'center', marginBottom: '8px' }}>
                                <TextField
                                    label="Client Name"
                                    variant="outlined"
                                    size="small"
                                    value={client.name}
                                    onChange={(event) => handleClientChange(index, event)}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: 2,
                                        }
                                    }}
                                />
                                {!companyClients?.find(companyClient => companyClient.name === client.name) && ( 
                                    <IconButton onClick={() => removeClient(index)}>
                                        <Delete />
                                    </IconButton>
                                )}
                            </div>
                        ))}
                        <Button variant="outlined" onClick={addClient}>Add Client</Button>
                    </Grid>

                    {error !== "" &&
                        <Grid item xs={12} mt={2}>
                            <Typography color="red">{error}</Typography>
                        </Grid>
                    }

                    <Grid item xs={12} mt={2}>
                        <Button variant="outlined" onClick={handleCancelEdit} sx={{mx:'20px'}}>Cancel</Button>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            loading={buttonLoader}
                            onClick={handleSaveChanges}
                        >
                            Save Company
                        </LoadingButton>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}

export default ConfigData;
