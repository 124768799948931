import React, { useEffect, useState } from 'react';
import {
  Typography, Grid, TextField, Button, IconButton, OutlinedInput, InputLabel, MenuItem, FormControl, ListItemText, Select,
  Checkbox, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper
} from '@mui/material';
import { LoadingButton } from "@mui/lab";

import { Add, Delete, AddPhotoAlternate, Done, Close, ReportProblem as ReportProblemIcon} from '@mui/icons-material'

import { useAuth } from '../../../../AuthContext';
import { useTranslation } from "react-i18next";

import { toBase64 } from '../utils';
import { colors } from '../../../../defaultTheme';

const database_url = process.env.REACT_APP_DATABASE_URL;

function AddProductDialog(props) {
  const { role, userId, companyId, companySizeCharts, companyCollections, currentUser, setCompanyProducts, companyProducts, 
    handleSnackbar, permissions, standard } = useAuth()
  const { t } = useTranslation();

  const [productName, setProductName] = useState("");
  const [image, setImage] = useState("");
  const [collection, setCollection] = useState("");
  const [sku, setSku] = useState("");
  const [clientGivenNumber, setClientGivenNumber] = useState("");

  const [variations, setVariations] = useState([]);
  const [companyProductsNames, setCompanyProductsNames] = useState([])
  const [isDuplicatedName, setIsDuplicatedName] = useState(false)

  const [error, setError] = useState(false)
  const [buttonLoader, setButtonLoader] = useState(false);
  const [canViewWordpressFunctionalities, setCanViewWordpressFunctionalities] = useState(false)

  const [coefficientsError, setCoefficientsError] = useState(false);
  const [coefficients, setCoefficients] = useState([]);
  const [measurementsConfig, setMeasurementsConfig] = useState([]);
  const [measurementsArray, setMeasurementsArray] = useState([]);
  const [typeOfLayer, setTypeOfLayer] = useState('None');
  const [bodyPart, setBodyPart] = useState('None');
  
  useEffect(()=>{
    if((permissions && permissions.includes('view_wordpress_functionalities')) || role=='esenca') {
      setCanViewWordpressFunctionalities(true)
    }
  },[permissions])

  useEffect(() => {
    const measurementsArray = Object.keys(standard).map(key => ({
        id: key,
        name: standard[key].name,
        definition: standard[key].definition,
        guidelines: standard[key].guidelines,
        picture: standard[key].picture,
        replaceBy: standard[key].replaceBy,
    }));
    setMeasurementsArray(measurementsArray)
}, [standard]);

  useEffect(()=>{
    if(companyProducts){
      const productsNames = [];
      for (const item in companyProducts) {
          if (companyProducts[item].name) {
              const name = companyProducts[item]?.name
              const lowercase = name?.toLowerCase()
              if (!productsNames.includes(lowercase)) {
                productsNames.push(lowercase);
              }
          }
      }
      setCompanyProductsNames(productsNames)
    }
  },[companyProducts])

  useEffect(()=>{
    let lowerKey = productName.toLowerCase(); 
    if (companyProductsNames.includes(lowerKey)) {
      setIsDuplicatedName(true)
    } else {
      setIsDuplicatedName(false)
    }
  },[productName,companyProductsNames])

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setVariations(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

    const handleImageUpload = (e) => {
        const selectedImage = e.target.files[0];
        if (selectedImage) {
        setImage(selectedImage)
        }
    };

    const handleCoefficientChange = (id, field, value) => {
        setCoefficients(prevState => 
            prevState.map(coef => 
                coef.id === id ? { ...coef, [field]: value } : coef
            )
        );
    };

    const addCoefficientRow = () => {
        setCoefficients(prevState => [
            ...prevState,
            { id: prevState.length + 1, value: '', a: '', b: '' }
        ]);
    };

    const deleteCoefficientRow = (id) => {
        setCoefficients(prevState => prevState.filter(coef => coef.id !== id));
    };

    const addCoefsForVariations = () => {
        variations.forEach(variation => {
            const sizes = Object.values(companySizeCharts[variation]?.data?.sizes) || []
            sizes.forEach(size => {
                setCoefficients(prevState => [
                    ...prevState,
                    { id: prevState.length + 1, value: size, a: '', b: '' }
                ]);
            });
        });
    }

    const validateCoefficients = () => {
        for (const coef of coefficients) {
            if (coef.value === '' || coef.a === '' || coef.b === '') {
                return false;
            }
        }
        return true;
    };

    const saveProduct = async () => {
        setError(false);
        setCoefficientsError(false);

        if (productName === '') {
            setError(true);
            setButtonLoader(false);
        } else if (role=="esenca" && !validateCoefficients()) {
            setCoefficientsError(true);
            setButtonLoader(false);
        } else {
            if (companyId && productName) {
                uploadProductInDatabase();
            } else {
                setButtonLoader(false);
            }
        }
    };

    const uploadProductInDatabase = async () => {
        setButtonLoader(true);
        const accessToken = await currentUser.getIdToken(true);
        let send_data = {};
        let finalImage = {};
    
        if (companyId) {
            send_data = {
                product_name: productName,
                variations: variations,
                collection: collection,
                sku: sku,
                clientGivenNumber: clientGivenNumber,
            };

            if (image) {
                let currentImage = image ? image : "";
                if (currentImage) {
                    finalImage = {
                        "name": currentImage.name,
                        "data": await toBase64(currentImage)
                    }
                    send_data.image = finalImage
                }
            }
    

            const coefficientsData = {};
            coefficients.forEach(coef => {
                coefficientsData[coef.value] = { a: coef.a, b: coef.b };
            });
    
            if (Object.keys(coefficientsData).length > 0) {
                send_data.coefficients = {
                    measurement_keys_to_change: measurementsConfig,
                    values: coefficientsData
                };
            }
    
            if (typeOfLayer !== 'None') {
                send_data.typeOfLayer = typeOfLayer;
            }
    
            if (bodyPart !== 'None') {
                send_data.bodyPart = bodyPart;
            }
    
            fetch(database_url + "/add_company_product?user_id=" + userId + '&company_id=' + companyId, {
                method: "POST",
                headers: { "Accept": "application/json", "Content-Type": 'application/json', 'x-access-token': accessToken },
                body: JSON.stringify(send_data)
            }).then(() => {
                handleSnackbar('success', t(`prodSnack`));
                setButtonLoader(false);
                setIsDuplicatedName(false);

                let promises = Promise.all([
                    fetch(database_url + '/view_company_products?user_id=' + userId + '&company_id=' + companyId, {
                      method: 'GET',
                      headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
                    }),
                  ]);
          
                promises
                .then((results) => Promise.all(results.map((r) => r.json())))
                .then((resultsData) => {
                    const [companyProducts] = resultsData;
                    setCompanyProducts(companyProducts || {});
                }).then(()=>{
                    setIsDuplicatedName(false)
                    setButtonLoader(false)
                    props.handleCloseDialog()
                })
            });
        } else {
            setButtonLoader(false);
        }
    };

  return (
    <div>
      <Grid container align="center">
      {role!=='esenca' &&
        <Grid item xs={12} mt={2}>
          <Box display={'flex'} justifyContent={'space-between'} sx={{ backgroundColor: colors.primary, px: 2, py: 2 }}>
            <Typography variant="body1" sx={{ alignSelf: 'center', color: colors.white }}>{t(`prodHeaderOne`)}</Typography>
            <IconButton
              aria-label="close"
              onClick={props.handleCloseDialog}
              sx={{
                color: (theme) => theme.palette.grey[500],
                alignSelf: 'center'
              }}
            >
              <Close />
            </IconButton>
          </Box>
        </Grid>
      }
      {isDuplicatedName && 
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="center" mb={1}>
                        <ReportProblemIcon color="warning" />
                        <Box ml={1}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                Your company already has product(s) that share identical name.
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                }
                <Grid item xs={12}>
                    <TextField
                        margin="dense"
                        id="name"
                        label={t(`Product Name`)}
                        fullWidth
                        value={productName}
                        onChange={(event) => setProductName(event.target.value)}
                        error={error}
                        helperText={error ? t(`prodErrorOne`) : ""}
                        sx={{width:'300px'}}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        margin="dense"
                        id="sku"
                        label='SKU'
                        fullWidth
                        value={sku}
                        onChange={(event) => setSku(event.target.value)}
                        sx={{width:'300px'}}
                    />
                </Grid>
                {canViewWordpressFunctionalities &&
                <Grid item xs={12}>
                    <TextField
                        margin="dense"
                        id="clientGivenNumber"
                        label='WooCommerce Product ID'
                        fullWidth
                        value={clientGivenNumber}
                        onChange={(event) => setClientGivenNumber(event.target.value)}
                        sx={{width:'300px'}}
                    />
                </Grid>}
                <Grid item xs={12} mt={2}>
                    <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="demo-multiple-checkbox-label">{t(`Size Charts`)}</InputLabel>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={variations}
                            onChange={handleChange}
                            input={<OutlinedInput label={t(`Size Charts`)} />}
                            renderValue={(selected) => selected.map((key) => companySizeCharts[key]?.metadata?.name).join(', ')}
                            sx={{ width: '100%' }}
                        >
                            {Object.keys(companySizeCharts).map((key) => {
                                return (
                                    <MenuItem key={key} value={key}>
                                        <Checkbox checked={variations.indexOf(key) > -1} />
                                        <ListItemText primary={companySizeCharts[key]?.metadata?.name} />
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} mt={2}>
                    <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="demo-multiple-checkbox-label">{t(`Collection`)}</InputLabel>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            value={collection}
                            onChange={(e) => { setCollection(e.target.value) }}
                            input={<OutlinedInput label={t(`Collection`)} />}
                            sx={{ width: '100%' }}
                        >
                            {Object.keys(companyCollections).map((key) => {
                                return (
                                    <MenuItem key={key} value={key}>
                                        <ListItemText primary={companyCollections[key]?.name} />
                                    </MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} alignItems="center" justifyContent={"center"} display={"flex"} sx={{ mt: 2 }}>
                    <input
                        accept="image/*"
                        id={`image-upload-button`}
                        type="file"
                        style={{ display: 'none' }}
                        onChange={(e) => handleImageUpload(e)}
                    />
                    <label htmlFor={`image-upload-button`} >
                        <Button
                            variant="outlined"
                            size='small'
                            sx={{ width: '200px' }}
                            component="span"
                            startIcon={image ? <Done /> : <AddPhotoAlternate />}
                            justify="center"
                        >
                            {t(`Upload Image`)}
                        </Button>
                    </label>
                </Grid>
                {role=='esenca' &&
                <>
                <Grid item xs={4} mt={2}>
                    <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="type-of-layer-label">Type of Layer</InputLabel>
                        <Select
                            labelId="type-of-layer-label"
                            id="type-of-layer"
                            value={typeOfLayer}
                            onChange={(e) => setTypeOfLayer(e.target.value)}
                            input={<OutlinedInput label="Type of Layer" />}
                            sx={{ width: '100%' }}
                        >
                            {["None", 0, 1, 2, 3, 4].map((value) => (
                                <MenuItem key={value} value={value}>
                                    <ListItemText primary={value} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4} mt={2}>
                    <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="body-part-label">Body Part</InputLabel>
                        <Select
                            labelId="body-part-label"
                            id="body-part"
                            value={bodyPart}
                            onChange={(e) => setBodyPart(e.target.value)}
                            input={<OutlinedInput label="Body Part" />}
                            sx={{ width: '100%' }}
                        >
                            {['None','lower', 'upper', 'both'].map((value) => (
                                <MenuItem key={value} value={value}>
                                    <ListItemText primary={value} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4} mt={2}>
                        <FormControl sx={{mt: 1, width:'300px'}} variant="outlined">
                            <InputLabel>Measurements Keys To Change</InputLabel>
                            <Select
                                multiple
                                value={measurementsConfig}
                                onChange={(e) => setMeasurementsConfig(e.target.value)}
                                input={<OutlinedInput label="Measurements Keys To Change" />}
                                renderValue={(selected) => selected.map(id => {
                                    const measurement = measurementsArray.find(measurement => measurement.id === id);
                                    return measurement ? measurement.name : id;
                                }).join(', ')}    
                                sx={{width:'300px'}}
                            >
                                {measurementsArray.map((measurement) => (
                                    <MenuItem key={measurement.id} value={measurement.id}>
                                        <Checkbox checked={measurementsConfig.indexOf(measurement.id) > -1} />
                                        <ListItemText primary={measurement.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                <Grid item xs={12} mt={5}>
                    <Typography variant="body2">Coefficients</Typography>
                    <Button onClick={()=>{addCoefsForVariations()}}>Add Coefficients for Associated Variations</Button>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Value</TableCell>
                                    <TableCell>a</TableCell>
                                    <TableCell>b</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {coefficients.map((coef) => (
                                    <TableRow key={coef.id}>
                                        <TableCell>
                                            <TextField
                                                value={coef.value}
                                                onChange={(e) => handleCoefficientChange(coef.id, 'value', e.target.value)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                value={coef.a}
                                                onChange={(e) => handleCoefficientChange(coef.id, 'a', e.target.value)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                value={coef.b}
                                                onChange={(e) => handleCoefficientChange(coef.id, 'b', e.target.value)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => deleteCoefficientRow(coef.id)}>
                                                <Delete />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {coefficientsError && (
                        <Typography color="error">All coefficient fields must be filled out.</Typography>
                    )}
                </Grid>
                <Grid item xs={12} mt={2}>
                    <Button variant="standard" onClick={()=>{addCoefficientRow()}} startIcon={<Add />}>
                        Add Row
                    </Button>
                </Grid>
                </>
                }

                <Grid item xs={12} mt={5}>
                    <LoadingButton
                        onClick={saveProduct}
                        loading={buttonLoader}
                        variant="contained"
                    >
                        {t(`prodButtonOne`)}
                    </LoadingButton>
                </Grid>
      </Grid>
    </div>
  );
}

export default AddProductDialog;
