import React from 'react';

import { Box, Toolbar } from '@mui/material';

import OptionsComponent from './components/OptionsComponent'

const drawerWidth = 245;

function SendScanLinkPage(props) {
  return (
    <>
        <Box
            component="main"
            sx={{ 
              flexGrow: 1, 
              p: 3, 
              ml: { sm: `${drawerWidth}px` },
              width: { sm: `calc(100% - ${drawerWidth}px)` } 
            }}
        >
            <Toolbar />
            <OptionsComponent />
        </Box>
    </>
  );
}

export default SendScanLinkPage;
