import { useEffect, useState } from 'react';

import { Typography, Grid, TextField, Button, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { AccountCircle, Email as EmailIcon, Notes } from '@mui/icons-material';

import { useAuth } from '../../../AuthContext';
import { useTranslation } from "react-i18next";

const database_url = process.env.REACT_APP_DATABASE_URL;

function Email(props) {

  const { currentUser, handleSnackbar } = useAuth()
  const { t } = useTranslation();

  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [errorName, setErrorName] = useState(false)
  const [errorSubject, setErrorSubject] = useState(false)
  const [errorDescription, setErrorDescription] = useState(false);

  useEffect(() => {
    if (currentUser) {
      setEmail(currentUser.email)
    }
  }, [])

  const validateFields = () => {
    let check = true

    if (name.trim() === '') {
      setErrorName(true)
      check = false
    } else {
      setErrorName(false)
    }

    if (subject.trim() === '') {
      setErrorSubject(true)
      check = false
    } else {
      setErrorSubject(false)
    }

    if (description.trim() === '') {
      setErrorDescription(true)
      check = false
    } else {
      setErrorDescription(false)
    }

    return check
  }

  const handleSendEmail = async () => {
    let url = database_url;
    if (validateFields()) {
      setIsLoading(true)

      const accessToken = await currentUser.getIdToken(true);
      let promises = Promise.all([
        fetch(url + "/send_support_email_test?user_id=" + currentUser.uid, {
          method: "POST",
          headers: { Accept: "application/json", "Content-Type": "application/json", 'x-access-token': accessToken },
          body: JSON.stringify({ name: name, email: email, subject: subject, body: description }),
        }),
      ]);

      promises.then((results) =>
        Promise.all(results.map(r => r.json()))).then(r => {
          if (r[0].success = true) {
            setIsLoading(false);
            handleSnackbar('success', t(`helpSnackOne`));
            setName("")
            setSubject("")
            setDescription("")
          }
        });
    }
  }

  return (
    <>
      <Typography variant="body1" fontWeight={'bold'}>
        {t(`helpHeaderFour`)}
      </Typography>
      <Grid container align="center">
        <Grid item xs={12}>
          <TextField
            label={t(`helpLabelOne`)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            variant="standard"
            sx={{ mt: 3, width: { xs: '90%', md: '50%' } }}
            value={name}
            onChange={(event) => setName(event.target.value)}
            error={errorName}
            helperText={errorName ? t(`helpErrorOne`) : ""}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={t(`helpLabelTwo`)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
            sx={{ mt: 3, width: { xs: '90%', md: '50%' } }}
            value={subject}
            onChange={(event) => setSubject(event.target.value)}
            error={errorSubject}
            helperText={errorSubject ?  t(`helpErrorTwo`) : ""}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={t(`helpLabelThree`)}
            multiline
            rows={4}
            sx={{ mt: 3, width: { xs: '90%', md: '50%' } }}
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            error={errorDescription}
            helperText={errorDescription ? t(`helpErrorThree`) : ""}
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            type='submit'
            onClick={handleSendEmail}
            loading={isLoading}
            // loadingPosition="end"
            variant='contained'
            disableElevation={true}
            sx={{
              width: { xs: '90%', md: '50%' },
              mt: 5,
            }}
          >
            {isLoading ? t(`Loading...`) : t(`Send Email`)}
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
}

export default Email;