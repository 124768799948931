export const generateFirestoreTimeBasedId = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    const randomIdLength = 16; // Random characters
    const timestampLength = 4; // Timestamp characters
  
    let randomId = '';
    let timestamp = Date.now().toString(16);
  
    // Generate random characters
    for (let i = 0; i < randomIdLength; i++) {
      const randomIndex = Math.floor(Math.random() * charactersLength);
      randomId += characters.charAt(randomIndex);
    }
  
    return `${randomId}${timestamp}`;
}

export const isValidFirestoreFieldName = (field) => {
  if (!field[0].match(/[a-zA-Z]/)) {
      return false;
  }

  const allowedCharacters = new Set("abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789_.");
  
  for (const char of field) {
      if (!allowedCharacters.has(char)) {
          return false;
      }
  }

  return true;
}

export const customRenderValues = (start, values) => {
  let toRender = '';

  values.forEach((value) => {
    toRender += value.replace(start, '') + ", ";
  })

  return toRender;
}

export function convertTZ(date, tzString) {
  return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
}