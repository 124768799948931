import { useEffect, useState } from 'react';

import { Box, Card, Toolbar, Button, Dialog, DialogContent, Grid, Typography, Divider, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Add, UploadFile } from '@mui/icons-material';

import { borders, boxShadows } from '../../defaultTheme';

import ChartsTable from './components/SizeCharts/ChartsTable';
import AddChartFileDialog from './components/SizeCharts/AddChartFileDialog';
import AddChartManualDialog from './components/SizeCharts/AddChartManualDialog';
import AddCollectionDialog from './components/Collections/AddCollectionDialog';
import CollectionsTable from './components/Collections/CollectionsTable';
import AddProductDialog from './components/Products/AddProductDialog';
import ProductsTable from './components/Products/ProductsTable';

import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from 'react-router-dom';

const drawerWidth = 245;

function SizeChartsPage(props) {
  const { t } = useTranslation();
  const { tab, dialog } = useParams(); 
  const navigate = useNavigate();

  const [openDialogFile, setOpenDialogFile] = useState(false);
  const [openDialogManual, setOpenDialogManual] = useState(false);
  const [openDialogCollections, setOpenDialogCollections] = useState(false);
  const [openDialogProduct, setOpenDialogProduct] = useState(false);
  const [value, setValue] = useState("size-charts");

  useEffect(() => {
    if (tab) {
      setValue(tab);
    } else {
      setValue("size-charts"); 
    }

    switch (dialog) {
      case 'import-file':
        setOpenDialogFile(true);
        break;
      case 'add-manual':
        setOpenDialogManual(true);
        break;
      case 'add-collection':
        setOpenDialogCollections(true);
        break;
      case 'add-product':
        setOpenDialogProduct(true);
        break;
      default:
        setOpenDialogFile(false);
        setOpenDialogManual(false);
        setOpenDialogCollections(false);
        setOpenDialogProduct(false);
        break;
    }
  }, [tab, dialog]);

  useEffect(()=>{
    if(openDialogFile || openDialogManual || openDialogProduct || openDialogCollections){
      window?.tidioChatApi?.display(false);
    } else {
      window?.tidioChatApi?.display(true);
    }
  },[openDialogFile,openDialogManual,openDialogProduct,openDialogCollections])

  const handleChangeTabs = (event, newValue) => {
    navigate(`/size-charts/${newValue}`);
  };

  const handleCloseDialog = () => {
    navigate(`/size-charts/${tab}`);
  };

  return (
    <>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, ml: { sm: `${drawerWidth}px` }, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        <Card>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChangeTabs} aria-label="lab API tabs example">
                <Tab label={t(`Size Charts`)} value="size-charts" data-cy={"tab-size-charts"}/>
                <Tab label={t(`Products`)} value="products" data-cy={"tab-products"}/>
                <Tab label={t(`Collections`)} value="collections" data-cy={"tab-collections"}/>
              </TabList>
            </Box>
            <TabPanel value="size-charts">
              <Grid container>
                <Grid item xs={12} align="left">
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {t(`Size Charts`)}
                  </Typography>
                  <Typography variant="body2" align="justify">
                    {t(`scLabelOne`)}
                  </Typography>
                </Grid>
                <Grid item xs={12} align="right">
                  <Button
                    size='small'
                    variant="contained"
                    sx={{ my: 2, mx: 5, width: {xs: '80%',md:'20%'}}}
                    onClick={() => navigate(`/size-charts/${value}/import-file`)}
                    startIcon={<UploadFile />}
                  >
                    {t(`Import From File`)}
                  </Button>
                  {/* <Button
                    size='small'
                    variant="contained"
                    sx={{ my: 2, mx: 5, width: {xs: '80%',md:'20%'}}}
                    onClick={() => { setOpenDialogManual(true) }}
                    startIcon={
                      <Add />
                    }
                  >
                    Add Manually
                  </Button> */}
                </Grid>
                <Grid item xs={12} my={5} mx={2} border={1} borderRadius={borders.borderRadius.sm} boxShadow={boxShadows.sm}>
                  <ChartsTable />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="products">
              <Grid container>
                <Grid item xs={12} align="left">
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {t(`Products`)}
                  </Typography>
                  <Typography variant="body2" align="justify">
                    {t(`prodLabelOne`)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} display={'flex'} justifyContent={'flex-end'}>
                  <Button
                    size='small'
                    variant="contained"
                    sx={{ mt: 2, mb: 2, width: '200px' }}
                    onClick={() => navigate(`/size-charts/${value}/add-product`)}
                    startIcon={<Add />}
                  >
                    {t(`Add`)}
                  </Button>
                </Grid>
                <Grid item xs={12} my={5} mx={2} border={1} borderRadius={borders.borderRadius.sm} boxShadow={boxShadows.sm}>
                  <ProductsTable />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="collections">
              <Grid container>
                <Grid item xs={12} align="left">
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {t(`Collections`)}
                  </Typography>
                  <Typography variant="body2" align="justify">
                    {t(`colLabelOne`)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} display={'flex'} justifyContent={'flex-end'}>
                  <Button
                    size='small'
                    variant="contained"
                    sx={{ mt: 2, mb: 2, width: '200px' }}
                    onClick={() => navigate(`/size-charts/${value}/add-collection`)}
                    startIcon={<Add />}
                  >
                    {t(`Add`)}
                  </Button>
                </Grid>
                <Grid item xs={12} my={5} mx={2} border={1} borderRadius={borders.borderRadius.sm} boxShadow={boxShadows.sm}>
                  <CollectionsTable />
                </Grid>
              </Grid>
            </TabPanel>
          </TabContext>
        </Card>
      </Box >

      <Dialog
        fullScreen
        maxWidth="lg"
        open={openDialogFile}
        onClose={handleCloseDialog}
      >
        <DialogContent>
          <AddChartFileDialog handleCloseDialog={handleCloseDialog} openDialog={openDialogFile} />
        </DialogContent>
      </Dialog>

      <Dialog
        fullScreen
        maxWidth="lg"
        open={openDialogManual}
        onClose={handleCloseDialog}
      >
        <DialogContent>
          <AddChartManualDialog handleCloseDialog={handleCloseDialog} openDialog={openDialogManual} />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openDialogCollections}
        onClose={handleCloseDialog}
      >
        <DialogContent>
          <AddCollectionDialog handleCloseDialog={handleCloseDialog} openDialog={openDialogCollections} />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openDialogProduct}
        onClose={handleCloseDialog}
      >
        <DialogContent>
          <AddProductDialog handleCloseDialog={handleCloseDialog} openDialog={openDialogProduct} />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default SizeChartsPage;
