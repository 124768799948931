export const timezones = ['Europe/Berlin', 'Europe/Bucharest'];
export const languages = ['en', 'de', 'ro'];
export const roles = ['client', 'company'];
export const permissions = [ 
    'delete_recommendations', 
    'view_history', 
    'delete_size_charts', 
    'delete_products', 
    'delete_collections',
    'modify_clients_permissions', 
    'view_measurements', 
    'view_recommendations', 
    'can_recommend'
];

export const standardPermissionsToAdd = [
    'view_measurements',
    'view_recommendations',
    'can_recommend'
]

export const standardMeasurementsToAdd = [
    "arms_aboveElbow_left_circumference_1",
    "arms_aboveElbow_right_circumference_1",
    "arms_belowElbow_left_circumference_1",
    "arms_belowElbow_left_circumference_2",
    "arms_belowElbow_right_circumference_1",
    "arms_belowElbow_right_circumference_2",
    "arms_whole_left_contour_1",
    "arms_whole_right_contour_1",
    "body_abs_none_circumference_1",
    "body_abs_none_circumference_2",
    "body_abs_none_linear_1",
    "body_abs_none_linear_2",
    "body_chest_none_circumference_1",
    "body_chest_none_circumference_2",
    "body_chest_none_linear_1",
    "body_chest_none_linear_2",
    "body_diaphragm_none_circumference_1",
    "body_diaphragm_none_linear_1",
    "body_pelvis_none_circumference_1",
    "body_pelvis_none_linear_1",
    "body_whole_none_contour_1",
    "head_neck_none_circumference_1",
    "head_neck_none_circumference_2",
    "head_neck_none_linear_1",
    "legs_aboveKnee_left_circumference_1",
    "legs_aboveKnee_left_circumference_5",
    "legs_aboveKnee_left_linear_1",
    "legs_aboveKnee_right_circumference_1",
    "legs_aboveKnee_right_circumference_5",
    "legs_aboveKnee_right_linear_1",
    "legs_belowKnee_left_circumference_1",
    "legs_belowKnee_left_circumference_2",
    "legs_belowKnee_right_circumference_1",
    "legs_belowKnee_right_circumference_2",
    "legs_whole_left_linear_1",
    "legs_whole_none_linear_2",
    "legs_whole_none_linear_3",
    "legs_whole_right_linear_1",
    "whole_none_left_contour_1",
    "whole_none_none_linear_1",
    "whole_none_right_contour_1"
]