import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, Grid, AppBar, IconButton, Toolbar, List, ListItem, ListItemText, ListItemSecondaryAction, Divider, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Close as CloseIcon, Add, ExpandMore as ExpandMoreIcon, Delete } from '@mui/icons-material';

import { colors } from '../../../../defaultTheme';
import { useAuth } from '../../../../AuthContext';

import AddItemsDialog from './AddItemsDialog';
import ChartDetailsDialog from '../../../SizeCharts/components/SizeCharts/ChartDetailsDialog';
import CollectionDetailsDialog from '../../../SizeCharts/components/Collections/CollectionDetailsDialog';
import ProductDetailsDialog from '../../../SizeCharts/components/Products/ProductDetailsDialog';

const database_url = process.env.REACT_APP_DATABASE_URL;

function CompanyDetailsDialog(props) {

    const { handleSnackbar, userId, currentUser, companyId, companyCollections, companyProducts, companySizeCharts, setCompanyCollections, setCompanyProducts, setCompanySizeCharts } = useAuth();

    const [edit, setEdit] = useState(false);
    const [section, setSection] = useState(null);
    const [editId, setEditId] = useState(null);
    const [deleteId, setDeleteId] = useState(null);
    const [deleteSection, setDeleteSection] = useState(null);
    const [deleteButtonLoader, setDeleteButtonLoader] = useState(false);
    const [companyDetails, setCompanyDetails] = useState(null);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    useEffect(() => {
        if (companyId) {
            setCompanyDetails(companyId);
        }
    }, [companyId]);

    const handleEdit = (section, id) => {
        setEditId(id);
        setSection(section);
        setEdit(true);
    };

    const renderEditForm = () => {
        switch (section) {
            case 'sizeChart':
                return <ChartDetailsDialog item={editId} handleCloseDialog={() => { setEdit(false) }}/>
            case 'product':
                return <ProductDetailsDialog product={editId} handleCloseDialog={() => { setEdit(false) }} />;
            case 'collection':
                return <CollectionDetailsDialog collection={editId} handleCloseDialog={() => { setEdit(false) }} />
            default:
                return (
                    <div>Edit</div>
                );
        }
    };

    const handleOpenAddDialog = () => {
        setOpenAddDialog(true);
    };

    const handleCloseAddDialog = () => {
        setOpenAddDialog(false);
    };

    const handleOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setDeleteId(null)
        setDeleteSection(null)
        setOpenDeleteDialog(false);
    };

    const triggerDeleteButton = (key,section) => {
        setDeleteSection(section)
        setDeleteId(key)
        setOpenDeleteDialog(true)
    };

    const handleDelete = async () => {
        setDeleteButtonLoader(true)
        const accessToken = await currentUser.getIdToken(true);
        let data = {}
        if (companyId && deleteId && deleteSection) {
            if(deleteSection === 'collection') {
                data = { collection_id: deleteId };
                fetch(database_url+"/delete_company_collection?user_id=" + userId + '&company_id=' + companyId, {
                    method: "DELETE",
                    headers: {"Accept": "application/json", "Content-Type": 'application/json', 'x-access-token': accessToken },
                    body: JSON.stringify(data)
                }).then(() => {
                    handleSnackbar('success', "Collection deleted");
                    let promises = Promise.all([
                      fetch(database_url + '/view_company_collections?user_id=' + userId + '&company_id=' + companyId, {
                        method: 'GET',
                        headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
                      }),
                      fetch(database_url + '/view_company_products?user_id=' + userId + '&company_id=' + companyId, {
                        method: 'GET',
                        headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
                      }),
                    ]);
          
                    promises
                    .then((results) => Promise.all(results.map((r) => r.json())))
                    .then((resultsData) => {
                        const [companyCollections, companyProducts] = resultsData;
                        setCompanyCollections(companyCollections || {});
                        setCompanyProducts(companyProducts || {})
                    }).then(()=>{
                        setDeleteButtonLoader(false)
                        handleCloseDeleteDialog()
                    })
                });
            }
        }
    }

    return (
        <>
            <AppBar
                sx={{
                    backgroundColor: colors.primary,
                    color: colors.white
                }}
            >
                <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h6" component="div">
                        Company Details
                    </Typography>
                    <IconButton
                        color="inherit"
                        onClick={props.handleCloseDialog}
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Grid container>
                <Grid item xs={12}>
                    <Toolbar />
                </Grid>
                {companyDetails && !edit && (
                    <>
                        <Grid item xs={12}>
                            <Button variant="outlined" startIcon={<Add />} sx={{ width: '200px' }} onClick={handleOpenAddDialog}>
                                Add
                            </Button>
                        </Grid>
                        <Grid item xs={12} mt={3}>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6" color="red">Collections</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <List>
                                        {companyCollections && Object.entries(companyCollections).map(([key, value]) => (
                                            <React.Fragment key={key}>
                                                <ListItem>
                                                    <IconButton
                                                        onClick={() => { triggerDeleteButton(key, 'collection') }}
                                                        sx={{m:2}}
                                                    >
                                                        <Delete fontSize='small'/>
                                                    </IconButton>
                                                    <ListItemText primary={value.name} secondary={key} />
                                                    <ListItemSecondaryAction>
                                                        <Button onClick={() => handleEdit('collection', key)}>Edit</Button>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                <Divider />
                                            </React.Fragment>
                                        ))}
                                    </List>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6" color="red">Products</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <List>
                                        {companyProducts && Object.entries(companyProducts).map(([key, value]) => (
                                            <React.Fragment key={key}>
                                                <ListItem>
                                                    <ListItemText primary={value.name} secondary={key} />
                                                    <ListItemSecondaryAction>
                                                        <Button onClick={() => handleEdit('product', key)}>Edit</Button>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                <Divider />
                                            </React.Fragment>
                                        ))}
                                    </List>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6" color="red">Size Charts</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <List>
                                        {companySizeCharts && Object.entries(companySizeCharts).map(([key, value]) => (
                                            <React.Fragment key={key}>
                                                <ListItem>
                                                    <ListItemText primary={value.metadata.name} secondary={key} />
                                                    <ListItemSecondaryAction>
                                                        <Button onClick={() => handleEdit('sizeChart', key)}>Edit</Button>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                <Divider />
                                            </React.Fragment>
                                        ))}
                                    </List>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </>
                )}
            </Grid>

            {edit && (
                <>
                    <Grid item xs={12}>
                        <Button onClick={() => { setEdit(false) }}>Back</Button>
                    </Grid>
                    <Grid item xs={12}>
                        {renderEditForm()}
                    </Grid>
                </>
            )}

            <Dialog
                open={openAddDialog}
                onClose={handleCloseAddDialog}
                fullScreen
                maxWidth="lg"
            >
                <DialogContent>
                    <AddItemsDialog
                        handleCloseDialog={handleCloseAddDialog}
                    />
                </DialogContent>
            </Dialog>

            <Dialog
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Delete item?
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete the item ?
                    This will potentially trigger recommendations.
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseDeleteDialog}>Disagree</Button>
                <LoadingButton
                    onClick={()=>{handleDelete()}} 
                    loading={deleteButtonLoader}
                    variant="contained"
                    autoFocus
                >
                    Agree
                </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default CompanyDetailsDialog;
