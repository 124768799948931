import React, { useEffect, useState } from 'react';
import { Box, Card, Tab, Toolbar } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import About from './components/About';
import Email from './components/Email';
import Faq from './components/Faq';

import { useAuth } from '../../AuthContext';
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from 'react-router-dom';

const drawerWidth = 250;

function HelpPage(props) {
  const { t } = useTranslation();
  const { tab } = useParams(); 
  const navigate = useNavigate();

  const [value, setValue] = useState("faq");

  useEffect(() => {
    if (tab) {
      setValue(tab);
    } else {
      setValue("faq"); 
    }
  }, [tab]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`/help/${newValue}`);
  };

  return (
    <>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, ml: { sm: `${drawerWidth}px` }, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
          <Card>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label={t(`helpHeaderOne`)} value="faq" />
                  <Tab label={t(`helpHeaderTwo`)} value="contact-us" />
                  <Tab label={t(`helpHeaderThree`)} value="about" />
                </TabList>
              </Box>
              <TabPanel value="faq">
                <Faq />
              </TabPanel>
              <TabPanel value="contact-us">
                <Email />
              </TabPanel>
              <TabPanel value="about">
                <About />
              </TabPanel>
            </TabContext>
          </Card>
      </Box>
    </>
  );
}

export default HelpPage;
