import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";

export default function PersonIdDialog({
    isOpen, onCancel, onConfirm, employeesNumber
}) {
    const { t } = useTranslation();
    return (
        <Dialog
            open={isOpen}
            onClose={onCancel}
        >
            <DialogContent>
                <Typography variant="body1">
                    {/* It looks like {employeesNumber} of your contacts are missing an ID. Do you want to auto-generate or re-upload template? */}
                    {t(`scanImportLabelTwelve`, { number: employeesNumber })}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onCancel}>
                    {t(`Re-Upload`)}
                </Button>
                <Button variant="contained" onClick={onConfirm}>
                    {t(`Auto-Generate`)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}