import { useState, useEffect, useMemo } from 'react';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Box, Button, AppBar, Grid, Toolbar, Typography, IconButton, DialogContent, Dialog, Divider, Card, Paper } from '@mui/material';
import { OpenInNewOutlined, Close } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

import { ResultsComponent } from './ResultsComponent';

import { useAuth } from '../../../AuthContext';
import { parseTimestamp, generatePDF } from '../utils/utils';
import { useTranslation } from "react-i18next";
import { useParams, useNavigate, useLocation} from 'react-router-dom';

import { colors } from '../../../defaultTheme';

const env_type = process.env.REACT_APP_ENV_TYPE

export const RecommendationTable = () => {

  const { measurements, persons, companyClients, companyCollections, companyProducts, companyConfig, companyId } = useAuth();
  
  const { t } = useTranslation();

  const { dialog } = useParams(); 
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const personId = params.get('id');

  const [columns, setColumns] = useState([])
  const [rows, setRows] = useState([])
  const [fullPersons, setFullPersons] = useState({})

  const [foundPerson, setFoundPerson] = useState();
  const [openDialog, setOpenDialog] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    switch (dialog) {
      case 'recommendations-dialog':
        setOpenDialog(true);
        break;
      default:
        setOpenDialog(false);;
        break;
    }
  }, [dialog]);

  useEffect(() => {
    const updatedFullPersons = { ...persons };

    for (const key in updatedFullPersons) {
      updatedFullPersons[key].personId = key
      const lastMeasurementId = updatedFullPersons[key].lastMeasurementId;
      if (lastMeasurementId && measurements[lastMeasurementId]) {
        updatedFullPersons[key].lastMeasurementData = measurements[lastMeasurementId];
      }
    }

    for (const trackingId in updatedFullPersons) {
      if (updatedFullPersons.hasOwnProperty(trackingId)) {
        const personData = updatedFullPersons[trackingId];

        const collectionFlags = {};
        const productFlags = {};

        if (personData.lastMeasurementData) {
          const results = personData.lastMeasurementData.results || {};

          for (const collectionId in companyCollections) {
            if (companyCollections.hasOwnProperty(collectionId)) {
              collectionFlags[collectionId] = Object.values(results)
                .some(result => {
                  const productId = result.productId;
                  return companyProducts[productId] && companyProducts[productId].collection === collectionId;
                });
            }
          }

          for (const productId in companyProducts) {
            if (companyProducts.hasOwnProperty(productId)) {
              productFlags[productId] = Object.values(results)
                .some(result => result.productId === productId) ||
                Object.values(companyCollections)
                  .some(collection => collection.id === companyProducts[productId].collection);
            }
          }
        }

        personData.collectionFlags = collectionFlags;
        personData.productFlags = productFlags;
      }
    }
    setFullPersons(updatedFullPersons);
  }, [persons, companyCollections, companyProducts, measurements])

  useEffect(()=>{
    if(personId){
      if (fullPersons[personId]) {
        setFoundPerson(fullPersons[personId])
      }
    }
  },[personId, fullPersons])

  useEffect(() => {

    const dynamicColumns = [
      { field: 'trackingId', headerName: t(`Tracking ID`), flex: 1, minWidth: 150, headerAlign: 'center', align: 'center', show: true },
      { field: 'firstName', headerName: t(`First Name`), flex: 1, minWidth: 150, headerAlign: 'center', align: 'center', show: true },
      { field: 'lastName', headerName: t(`Last Name`), flex: 1, minWidth: 150, headerAlign: 'center', align: 'center', show: true },
      { field: 'clientId', headerName: t(`Client`), flex: 1, minWidth: 150, headerAlign: 'center', align: 'center', show: true },
      { field: 'lastMeasurementDate', headerName: t(`Last Measurement Date`), flex: 1, minWidth: 150, headerAlign: 'center', align: 'center', show: true },
      {
        field: "details",
        headerName: t(`Recommendations`),
        sortable: false,
        flex: 1,
        minWidth: 150,
        disableExport: true,
        headerAlign: 'center',
        align: 'center',
        show: true,
        filterable: false,
        renderCell: (params) => {
          const onClick = (e) => {
            e.stopPropagation();
            if (fullPersons[params.id]) {
              setFoundPerson(fullPersons[params.id]);
              if (fullPersons[params.id].lastMeasurementData && companyProducts) {
                window.tidioChatApi.display(false);
                navigate(`/measurements/recommendations/recommendations-dialog?id=${params.id}`)
              }
            }
          };

          return (
            <Button
              onClick={onClick}
              variant='outlined'
              disabled={!(fullPersons[params.id] && Object.keys(fullPersons[params.id]).includes('lastMeasurementData'))}
              endIcon={
                <OpenInNewOutlined />
              }
              data-cy={params.id}
            >
              {t(`View`)}
            </Button>)
            ;
        }
      },
    ];

    Object.keys(companyCollections).forEach((colId) => {
      const col = companyCollections[colId];
      let str = col.name
      dynamicColumns.push({
        field: colId, headerName: t(`Collection`)+": "+str, flex: 1, headerAlign: 'center', align: 'center', show: false, filterOperators: [
          {
            label: 'True',
            value: 'true',
            getApplyFilterFn: (filterItem) => (params) => params.value === true,
          },
          {
            label: 'False',
            value: 'false',
            getApplyFilterFn: (filterItem) => (params) => params.value === false,
          },
        ],
        getFilterValue: (params) => params.value === true ? 'true' : 'false',
      });
    });

    Object.keys(companyProducts).forEach((productId) => {
      const product = companyProducts[productId];
      let str = product.name
      dynamicColumns.push({
        field: productId, headerName: t(`Product`)+": "+str, flex: 1, headerAlign: 'center', align: 'center', show: false, filterOperators: [
          {
            label: 'True',
            value: 'true',
            getApplyFilterFn: (filterItem) => (params) => params.value === true,
          },
          {
            label: 'False',
            value: 'false',
            getApplyFilterFn: (filterItem) => (params) => params.value === false,
          },

        ], getFilterValue: (params) => params.value === true ? 'true' : 'false'
      });
    });

    setColumns(dynamicColumns);
  }, [companyCollections, companyProducts, fullPersons,t]);

  useEffect(() => {
    const updatedRows = Object.keys(fullPersons).filter(key => {
      const item = fullPersons[key];
      return item.lastMeasurementData && Object.keys(item.lastMeasurementData).length > 0;
      }).map((key) => {
        const personData = fullPersons[key];

        let clientName = "";
        if (personData.clientId == companyId) {
          clientName = companyConfig['name'];
        } else if (companyClients[personData.clientId] && companyClients[personData.clientId]['name']){
          clientName = companyClients[personData.clientId]['name'];
        }

        const row = {
          id: key,
          trackingId: personData.trackingId,
          firstName: personData?.firstName,
          lastName: personData?.lastName,
          clientId: clientName,
          lastMeasurementDate: personData.lastMeasurementData ? parseTimestamp(personData.lastMeasurementData.updatedAt, companyConfig.timezone) : "Unavailable",
        };

        Object.keys(companyCollections).forEach((colId) => {
          row[colId] = fullPersons[key].collectionFlags[colId];
        });

        Object.keys(companyProducts).forEach((productId) => {
          row[productId] = fullPersons[key].productFlags[productId];
        });

        return row;
    });

    setRows(updatedRows);
  }, [fullPersons, companyClients]);

  const handleCloseDialog = () => {
    window.tidioChatApi.display(true);
    setFoundPerson("")
    navigate(`/measurements/recommendations`)

  }

  const generateReport = () => {
    generatePDF("Sizes Breakdown Report", fullPersons)
  }

  return (
    <>
      <Grid container>
        <Grid item xs={8}>
          <Typography variant="body1" fontWeight={'bold'}>
            {t(`recomHeaderOne`)}
          </Typography>
          <Typography variant="body2" align="justify">
            {t(`recomLabelOne`)}
          </Typography>
        </Grid>
        {/* <Grid item xs={4} display={'flex'} justifyContent={'center'} flexDirection={'column'}>
          <Button 
            onClick={()=>{generateReport()}} 
            variant='contained'
            sx={{
              minWidth: 100,
              alignSelf: 'center'
            }}
          >
            {t(`recomButtonOne`)}
          </Button>
        </Grid> */}
        <Grid item xs={12} mt={2}>
          <Paper sx={{ height: 600, width: 1 }}>
            <DataGrid
              disableVirtualization={env_type == 'production' ? false : true}
              rows={rows}
              columns={columns}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  printOptions: { disableToolbarButton: true },
                },
                baseButton: {
                  variant: 'contained'
                }
              }}
              columnVisibilityModel={columns.reduce((acc, column) => ({ ...acc, [column.field]: column.show }), {})}
              disableColumnSelector
              disableDensitySelector
              disableRowSelectionOnClick
              // density='compact'
              ignoreDiacritics
              sx={{
                p: 2
              }}
              data-cy={'recommendations-data-grid'}
            />
          </Paper>
        </Grid>
      </Grid>

      <Dialog
        fullScreen
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <AppBar sx={{ position: 'relative', backgroundColor: theme.palette.primary.main, color: colors.white }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">{t(`Recommendations`)}</Typography>
            <IconButton color="inherit" onClick={() => handleCloseDialog()} data-cy="close-dialog">
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>

        <DialogContent>
          <ResultsComponent person={foundPerson} />
        </DialogContent>
      </Dialog>
    </>
  );
}
