import React from 'react';

import { Typography, Box, Grid, Button } from '@mui/material';
import { useTranslation } from "react-i18next";

function Faq(props) {
  const { t } = useTranslation();

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box mt={3}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              {t(`helpFaqQOne`)}
            </Typography>
            <Typography variant="body2" pt={1}>
              {t(`helpFaqAOne`)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mt={3}>
            <Typography variant="body1" pt={1} sx={{ fontWeight: 'bold' }}>
              {t(`helpFaqQTwo`)}
            </Typography>
            <Typography variant="body2">
              {t(`helpFaqATwo`)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mt={3}>
            <Typography variant="body1" pt={1} sx={{ fontWeight: 'bold' }}>
              {t(`helpFaqQThree`)}
            </Typography>
            <Typography variant="body2">
              {t(`helpFaqAThree`)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Faq;
