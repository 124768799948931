import { useState, useEffect } from 'react';

import { AppBar, Box, Button, CssBaseline, Divider, Drawer, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText,
  Menu, MenuItem, Toolbar, TextField, Typography, Icon } from '@mui/material';
import { useTheme, styled } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { Menu as MenuIcon, Home, People, Settings, Info, TableView, Send, Logout, AdminPanelSettings, HowToReg, Description } from '@mui/icons-material';

import { Link as RouterLink, useLocation } from 'react-router-dom';

import { signOut } from 'firebase/auth';
import { auth } from '../../firebase';
import { useAuth } from '../../AuthContext';

import measurementsGuidePdf from '../../assets/measurements-guide.pdf';
import userGuidePdf from '../../assets/Dashboard User Guide.pdf';

import { colors, boxShadows, pxToRem, borders, mainComponentWidthDifference, rgba } from '../../defaultTheme.js';

import ScanGuideComponent from '../utils/ScanGuideComponent.js';
import LogoutAllSessions from '../utils/LogoutAllSessions.js';
import defaultConfig from '../../defaultConfig.js';

import { useTranslation } from 'react-i18next';
import { US, DE, RO } from 'country-flag-icons/react/3x2';
import { useParams } from 'react-router-dom';

const drawerWidth = 240;

function Scroll(props) {
  useEffect(function mount() {
    function onScroll() {
      props.setTransparentNavbar((props.fixedNavbar && window.scrollY === 0) || !props.fixedNavbar);
    }

    window.addEventListener("scroll", onScroll);

    return function unMount() {
      window.removeEventListener("scroll", onScroll);
    };
  });

  return null;
}
const getPagesByRole = (userRole) => {

  const administrationURL = generateURL('/administration', 'manage-accounts');
  const sizeChartsURL = generateURL('/size-charts', 'size-charts');
  const measurementsURL = generateURL('/measurements', 'measurements');
  const scanURL = generateURL ('/send-scan-link', 'quick-scan')
  const helpURL = generateURL('/help', 'faq');
  const adminOnboardingURL = generateURL('/admin/onboarding', 'company-onboarding');

  switch (userRole) {
    case 'esenca':
      return [
        { label: 'Home', icon: <Home />, url : '/home' },
        { label: 'Onboarding', icon: <HowToReg />, url : adminOnboardingURL },
        { label: 'Company Data', icon: <Description />, url : '/admin/company-data' }
      ];
    case 'company':
      return [
        { label: 'Home', icon: <Home />, url : '/home' },
        { label: 'Administration', icon: <AdminPanelSettings />, url : administrationURL },
        { label: 'Size Charts', icon: <TableView />, url: sizeChartsURL },
        { label: 'Measurements', icon: <People />, url : measurementsURL },
        { label: 'Send Scan Link', icon: <Send />, url : scanURL },
        { label: 'Help', icon: <Info />, url : helpURL },
      ];
    case 'companyMaks':
      return [
        { label: 'Home', icon: <Home />, url : '/home'  },
        { label: 'Measurements', icon: <People />,url : measurementsURL },
        { label: 'Send Scan Link', icon: <Send />, url : scanURL },
        { label: 'Help', icon: <Info />, url : helpURL },
      ];
    case 'client':
      return [
        { label: 'Home', icon: <Home />, url : '/home'  },
        { label: 'Measurements', icon: <People />,url : measurementsURL },
        { label: 'Send Scan Link', icon: <Send />, url : scanURL },
        { label: 'Help', icon: <Info />, url : helpURL },
      ];
    case 'clientMaks':
      return [
        { label: 'Home', icon: <Home />, url : '/home'  },
        { label: 'Measurements', icon: <People />,url : measurementsURL },
        { label: 'Send Scan Link', icon: <Send />, url : scanURL },
        { label: 'Help', icon: <Info />, url : helpURL },
      ];
    case 'person':
      return [
        { label: 'Home', icon: <Home />, url : '/home'  },
        { label: 'Measurements', icon: <People />,url : measurementsURL },
        { label: 'Help', icon: <Info />, url : helpURL },
      ];
    default:
      return [
        { label: 'Home', icon: <Home />, url : '/home'  },
      ];
  }
};

const generateURL = (basePath, dynamicSegment) => {
  const currentLocation = window.location.pathname;
  const regex = new RegExp(`${basePath}/([^/]+)`);
  const match = currentLocation.match(regex);
  if (match && match[1]) {
    return `${basePath}/${match[1]}`;
  }
  return `${basePath}/${dynamicSegment}`;
};

export const languagesArr = [
  { label: "English", code: "en", flag: <US /> },
  { label: "Deutsch", code: "de", flag: <DE /> },
  { label: "Română", code: "ro", flag: <RO /> },
];

function Navigation(props) {
  const { role, companyLanguage, chatApiObject, whiteLabel } = useAuth();
  const { window } = props;
 
  const location = useLocation();
  const theme = useTheme();
  const { i18n, t } = useTranslation();

  const [mobileOpen, setMobileOpen] = useState(false);
  const [transparentNavbar, setTransparentNavbar] = useState(true);
  const [fixedNavbar, setFixedNavbar] = useState(true);
  const [pagesArr, setPagesArr] = useState(getPagesByRole(role));
  const [language, setLanguage] = useState(companyLanguage)

  const [sidenavBackgroundColor, setSidenavBackgroundColor] = useState(whiteLabel.sidenavBackgroundColor);
  const [logo, setLogo] = useState(whiteLabel.logo)

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [openScanGuide, setOpenScanGuide] = useState(false);

  const { tab } = useParams(); 

  useEffect(()=>{
    setPagesArr(getPagesByRole(role))
  },[tab])

  const handleOpenScanGuide = () => {
    setOpenScanGuide(true);
    setAnchorEl(null);
  }

  const handleCloseScanGuide = () => {
    setOpenScanGuide(false);
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClickSettings = (event) => {
    setAnchorEl(!anchorEl ? event.currentTarget : null);
  };

  const handleCloseSettings = () => {
    setAnchorEl(null);
  };

  const resetChat = () => {
    const pk = "hkysvq2xjvaxjvljzjj3t1eusuivbpkf";
    const stateKey = "tidio_state_" + pk;
    if (localStorage.getItem(stateKey)) {
      localStorage.removeItem(stateKey);
    }
    if (localStorage.getItem(stateKey + "_cache")) {
      localStorage.removeItem(stateKey + "_cache")
    }
    document.location.reload();
  }

  useEffect(() => {
    setPagesArr(getPagesByRole(role));
  }, [role]);

  useEffect(() => {
    setLanguage(companyLanguage)
  }, [companyLanguage]);

  function deleteAllCookies() {
    const pk = "hkysvq2xjvaxjvljzjj3t1eusuivbpkf";
    const stateKey = "tidio_state_" + pk;
    const state = JSON.parse(localStorage.getItem(stateKey));
    const newState = {...state, messages: []}
    localStorage.setItem(stateKey, JSON.stringify(newState));
    localStorage.clear();
  }

  const handleLogout = (event) => {
    event.preventDefault()
    signOut(auth).then(() => {
      chatApiObject.hide();
      window.tidioChatApi.hide();
      deleteAllCookies();
    }).catch((error) => {
      // console.log(error)
    });
  }

  const onChangeLang = (e) => {
    setLanguage(e.target.value.code)
    const lang_code = e.target.value;
    i18n.changeLanguage(lang_code.code);
  };

  const handleDownloadMeasurementsGuide = () => {
    const downloadLink = document.createElement('a');
    downloadLink.href = measurementsGuidePdf;
    downloadLink.download = 'esenca-measurements-guide.pdf';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  const handleDownloadUserGuide = () => {
    const downloadLink = document.createElement('a');
    downloadLink.href = userGuidePdf;
    downloadLink.download = 'esenca-user-guide.pdf';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  const drawer = (
    <div>
      <Scroll setTransparentNavbar={setTransparentNavbar} fixedNavbar={fixedNavbar} />
      <Toolbar
        sx={{
          justifyContent: 'center',
          paddingTop: pxToRem(16)
        }}>
        <img
          src={logo}
          style={{
            maxWidth: 220, height: 'auto', width: 'auto'
          }}
        />
      </Toolbar>
      <Divider />
      <List>
      {pagesArr.map(({ label, icon, url }) => {
        const targetURL = url ? url : `/${label.toString().toLowerCase().replace(/\s+/g, '-')}`;
        let basePath;
        const currentLocation = location.pathname;
        if (currentLocation.startsWith('/admin')) {
          const splitPath = currentLocation.split('/admin');
          basePath = splitPath.length > 1 ? `/admin${splitPath[1]}` : '/admin';
        } else {
          basePath = currentLocation.split('/', 2).join('/');
        }
        
        return (
          <ListItem
            key={label}
            disablePadding
            component={RouterLink}
            to={targetURL}
            onClick={() => { setMobileOpen(false) }}
            sx={{ padding: '5px' }}
            id={`${label.toString().toLowerCase().replace(/\s+/g, '-')}`}
          >
            <ListItemButton
              sx={{
                background: location.pathname.startsWith(basePath) && targetURL.startsWith(basePath) ? theme.palette.primary.main : '',
                color: location.pathname.startsWith(basePath) && targetURL.startsWith(basePath) ? colors.sidenavTextSelected : colors.sidenavText,
                marginTop: '10px',
                boxShadow: location.pathname.startsWith(basePath) && targetURL.startsWith(basePath) && boxShadows.colored.dark,
                "&:hover": {
                  backgroundColor: location.pathname.startsWith(basePath) && targetURL.startsWith(basePath) ? theme.palette.primary.main : colors.sidenavItemHover,
                }
              }}
            >
              <ListItemIcon sx={{
                color: location.pathname.startsWith(basePath) && targetURL.startsWith(basePath) ? colors.white : colors.sidenavText
              }}>
                {icon}
              </ListItemIcon>
              <ListItemText primary={t(`${label}`)} sx={{ '& .MuiTypography-root': { fontWeight: 500 } }} />
            </ListItemButton>
          </ListItem>
        );
      })}
      </List>
      <List style={{ position: "absolute", bottom: "0", width: '100%'}}>
        <ListItem
          key={"sign-out"}
          disablePadding
          sx={{ padding: '5px' }}
        >
          <ListItemButton
            onClick={(e) => { handleLogout(e) }}
          >
            <ListItemIcon sx={{
              color: colors.sidenavText,
            }}>
              <Logout />
            </ListItemIcon>
            <ListItemText primary={t(`Sign Out`)} sx={{ '& .MuiTypography-root': { fontWeight: 500 } }} />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  const sidenavWidth = 240;

  const overrides = {
    MuiDrawer: {
      styleOverrides: {
        root: {
          width: pxToRem(sidenavWidth),
          whiteSpace: "nowrap",
          border: "none",
        },

        paper: {
          width: pxToRem(sidenavWidth),
          backgroundColor: sidenavBackgroundColor,
          height: `calc(100vh - ${pxToRem(32)})`,
          margin: pxToRem(16),
          borderRadius: borders.borderRadius.xl,
          border: "none",
        },

        paperAnchorDockedLeft: {
          borderRight: "none",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
    MuiListItem: {
      defaultProps: {
        disableGutters: true,
      },

      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: borders.borderRadius.md,
          padding: `${pxToRem(10)} ${pxToRem(8)}`,
          margin: `${pxToRem(1.5)} ${pxToRem(8)}`,
          background: 'transparent',
          '&:hover': {
            backgroundColor: colors.sidenavItemHover
          }
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          marginTop: 0,
          marginBottom: 0,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundImage: `linear-gradient(to right, ${rgba(colors.dark, 0)}, ${rgba(
            colors.dark,
            0.4
          )}, ${rgba(colors.dark, 0)}) !important`,
          height: pxToRem(1),
          margin: `${pxToRem(16)} 0`,
          borderBottom: "none",
        },

        vertical: {
          backgroundColor: 'transparent',
          backgroundImage: `linear-gradient(to bottom, ${rgba(colors.dark, 0)}, ${rgba(
            colors.dark,
            0.4
          )}, ${rgba(colors.dark, 0)}) !important`,
          width: pxToRem(1),
          height: "100%",
          margin: `0 ${pxToRem(16)}`,
          borderRight: "none",
        },

        light: {
          backgroundColor: 'transparent',
          backgroundImage: `linear-gradient(to right, ${rgba(colors.white, 0)}, ${colors.white}, ${rgba(
            colors.white,
            0
          )}) !important`,

          "&.MuiDivider-vertical": {
            backgroundImage: `linear-gradient(to bottom, ${rgba(colors.white, 0)}, ${colors.white}, ${rgba(
              colors.white,
              0
            )}) !important`,
          },
        },
      },
    },
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position={fixedNavbar ? 'fixed' : 'static'}
        sx={{
          width: { sm: `calc(100% - ${mainComponentWidthDifference}px)` },
          margin: pxToRem(16),
          minHeight: pxToRem(75),
          borderRadius: borders.borderRadius.xl,
          boxShadow: transparentNavbar ? "none" : boxShadows.navbarBoxShadow,
          backdropFilter: transparentNavbar ? "none" : `saturate(200%) blur(${pxToRem(30)})`,
        }}
        elevation={0}
      >
        <Toolbar>
          <Grid container spacing={2} p={pxToRem(2)}>
            <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
              <TextField
                  select
                  size="small"
                  value={languagesArr.find((option) => option.code === language)}
                  variant='standard'
                  onChange={(e) => { onChangeLang(e) }}
                  sx={{ minWidth: '5%', marginRight: 10 }}
              >
                  {languagesArr.map((option) => (
                      <MenuItem key={option.code} value={option} color="secondary">
                          <Icon>
                            {option.flag}
                          </Icon>
                      </MenuItem>
                  ))}
              </TextField>
              <Typography variant='caption' sx={{ alignSelf: 'center', mr: 2 }}>
                service provided by <a href='https://esenca.ai' target='_blank'><b>esenca</b></a>
              </Typography>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleClickSettings}
              >
                <Settings />
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleCloseSettings}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={handleDownloadUserGuide}>
                    <Button
                      variant='outlined'
                      fullWidth
                    >
                      {t(`User Guide`)}
                    </Button>
                  </MenuItem>
                  <MenuItem onClick={handleOpenScanGuide}>
                    <Button
                      variant='outlined'
                      onClick={handleOpenScanGuide}
                      fullWidth
                    >
                       {t(`Scan Guide`)}
                    </Button>
                  </MenuItem>
                  <MenuItem onClick={handleDownloadMeasurementsGuide}>
                    <Button
                      variant='outlined'
                      fullWidth
                    >
                      {t(`Measurements Guide`)}
                    </Button>
                  </MenuItem>
                  <MenuItem>
                    <LogoutAllSessions />
                  </MenuItem>
                  <MenuItem onClick={resetChat} >
                    <Button 
                      variant='outlined'
                      fullWidth
                    >
                      {t(`Reset Chat`)}
                    </Button>
                  </MenuItem>
                </Menu>
              </IconButton>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ display: { md: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          </Grid>
          <ScanGuideComponent
            open={openScanGuide}
            handleClose={handleCloseScanGuide}
          />

        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <ThemeProvider theme={(theme) =>
          createTheme({
            ...theme,
            components: overrides
          })
        }>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, borderWidth: 0 },
              border: 'none'
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, borderWidth: 0 },
              border: 'none',
            }}
            PaperProps={{
              elevation: 3
            }}
            open
          >
            {drawer}
          </Drawer>
        </ThemeProvider>

      </Box>
    </Box>
  );
}

export default Navigation;
