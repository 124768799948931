import { useEffect, useState } from "react";
import {
  AppBar, Toolbar, FormControlLabel, Typography, TextField, Grid, TableContainer, TableHead, Table, TableCell, TableRow, TableBody, Paper, IconButton, Button,
  Box, FormControl, InputLabel, Select, OutlinedInput, MenuItem, ListItemText, Divider, Checkbox,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Add, AddPhotoAlternate, Done, Close as CloseIcon, ReportProblem as ReportProblemIcon, Delete as DeleteIcon } from '@mui/icons-material';

import { useAuth } from "../../../../AuthContext";
import { useTranslation } from "react-i18next";

import { toBase64, arraysAreEqual, areCoefsDifferent } from "../utils";
import { colors } from "../../../../defaultTheme";

const database_url = process.env.REACT_APP_DATABASE_URL;

function ProductDetailsDialogTest(props) {
    const { userId, permissions, role, currentUser, handleSnackbar, standard, companyId, companySizeCharts, companyProducts, companyCollections, setCompanyProducts  } = useAuth();
    const { t } = useTranslation();

    const [measurementsArray, setMeasurementsArray] = useState([]);

    const [edit, setEdit] = useState(false);
    const [originalProduct, setOriginalProduct] = useState({ variations: [] });

    const [availableSizeCharts, setAvailableSizeCharts] = useState([]);
    const [editedSizeCharts, setEditedSizeCharts] = useState([]);

    const [editedProductName, setEditedProductName] = useState("");
    const [duplicatedNameFlag, setDuplicatedNameFlag] = useState(false)

    const [editedCollection, setEditedCollection] = useState("")

    const [productImage, setProductImage] = useState("")
    const [newImage, setNewImage] = useState("");

    const [editedSku, setEditedSku] = useState("");
    const [editedClientGivenNumber, setEditedClientGivenNumber] = useState("");
    const [editedBodyPart, setEditedBodyPart] = useState("");
    const [editedTypeOfLayer, setEditedTypeOfLayer] = useState("");
    const [editedMeasurementKeysToChange, setEditedMeasurementKeysToChange] = useState([]);
    const [editedCoefValues, setEditedCoefValues] = useState([]);

    const [initialCoefsValues, setInitialCoefsValues] = useState([])
    const [changedCoefsFlag, setChangedCoefsFlag] = useState(false)
    const [recommendFlag, setRecommendFlag] = useState(false)

    const [canViewWordpressFunctionalities, setCanViewWordpressFunctionalities] = useState(false)

    const [buttonLoader, setButtonLoader] = useState(false);

    useEffect(()=>{
        if((permissions && permissions.includes('view_wordpress_functionalities')) || role == 'esenca') {
          setCanViewWordpressFunctionalities(true)
        }
      },[permissions])

    useEffect(() => {
        const measurementsArray = Object.keys(standard).map(key => ({
            id: key,
            name: standard[key].name,
            definition: standard[key].definition,
            guidelines: standard[key].guidelines,
            picture: standard[key].picture,
            replaceBy: standard[key].replaceBy,
        }));
        setMeasurementsArray(measurementsArray)
    }, [standard]);

    useEffect(() => {
        if (props.product) {
            if(companyProducts && companyProducts[props.product]){
                const initialProduct = companyProducts[props.product]

                initialProduct.id = props.product
                if(!initialProduct.variations){
                initialProduct.variations = []
                } else {
                setEditedSizeCharts(initialProduct.variations)
                }
                setOriginalProduct(initialProduct)

                if (initialProduct.name) {
                setEditedProductName(initialProduct.name)
                }

                if (initialProduct.sku) {
                setEditedSku(initialProduct.sku)
                }

                if (initialProduct.clientGivenNumber) {
                setEditedClientGivenNumber(initialProduct.clientGivenNumber)
                }
        
                if (initialProduct.collection) {
                setEditedCollection(initialProduct.collection)
                }
        
                if (initialProduct.image) {
                setProductImage(initialProduct.image);
                }
        
                if (initialProduct.variations) {

                const unassociatedVariations = Object.keys(companySizeCharts).filter(variation => !initialProduct.variations.includes(variation));
                    setAvailableSizeCharts(unassociatedVariations)
                } else {
                const unassociatedVariations = Object.keys(companySizeCharts)
                    setAvailableSizeCharts(unassociatedVariations)
                }

                if (initialProduct.bodyPart) {
                    setEditedBodyPart(initialProduct.bodyPart);
                }
          
                if (initialProduct.typeOfLayer) {
                    setEditedTypeOfLayer(initialProduct.typeOfLayer);
                }
        
                if (initialProduct.coefficients) {
                    setEditedMeasurementKeysToChange(initialProduct.coefficients.measurement_keys_to_change || []);

                    const transformedCoefs = [];
    
                    Object.keys(initialProduct.coefficients.values).forEach((key, index) => {
                        const newObject = {
                            value: key,
                            ...initialProduct.coefficients.values[key]
                        };
                        transformedCoefs[index] = newObject;
                    });

                    setEditedCoefValues(transformedCoefs || []);
                    setInitialCoefsValues(transformedCoefs)
                }
            }
        }
    }, [props.product, companyProducts, props]);

    useEffect(()=>{
        if(companyProducts){
        const productsNames = [];
        for (const item in companyProducts) {
            if (companyProducts[item].name && item !== originalProduct.id) {
                const name = companyProducts[item]?.name
                const lowercase = name?.toLowerCase()
                if (!productsNames.includes(lowercase)) {
                    productsNames.push(lowercase);
                }
            }
        }
        const isNewNameInArray = productsNames.includes(editedProductName?.toLowerCase());
        if(isNewNameInArray){
            setDuplicatedNameFlag(true)
        } else {
            setDuplicatedNameFlag(false)
        }
        }
    },[companyProducts, editedProductName])

    useEffect(()=>{
        if(areCoefsDifferent(initialCoefsValues, editedCoefValues)){
            setChangedCoefsFlag(true)
        }
    },[editedCoefValues])

    const handleEdit = () => {
        setEdit(true);
    };

    const handleScDelete = (scId) => {
        const updatedSc = editedSizeCharts.filter(
        (sc) => sc !== scId
        );
        setEditedSizeCharts(updatedSc);

        const deletedSc = Object.keys(companySizeCharts).find(
        (sc) => sc === scId
        );
        setAvailableSizeCharts([...availableSizeCharts, deletedSc]);
    };

    const handleScAdd = (scId) => {
        const updatedSc = availableSizeCharts.filter(
        (sc) => sc !== scId
        );
        setAvailableSizeCharts(updatedSc);

        const addedSc = Object.keys(companySizeCharts).find(
        (sc) => sc === scId
        );
        setEditedSizeCharts([...editedSizeCharts, addedSc]);
    };

    const handleCancelEdit = () => {
        if (originalProduct.variations) {
        const unassociatedVariations = Object.keys(companySizeCharts).filter(variation => !originalProduct.variations.includes(variation));
        setAvailableSizeCharts(unassociatedVariations)
        } else {
        const unassociatedVariations = Object.keys(companySizeCharts)
        setAvailableSizeCharts(unassociatedVariations)
        }
        setNewImage("")
        setEditedCollection(originalProduct.collection)
        setEditedProductName(originalProduct.name);
        setEditedSizeCharts([...originalProduct.variations]);
        setEditedBodyPart(originalProduct.bodyPart);
        setEditedTypeOfLayer(originalProduct.typeOfLayer);
        setEditedMeasurementKeysToChange(originalProduct?.coefficients?.measurement_keys_to_change || []);
        setEditedCoefValues(initialCoefsValues || []);

        setEdit(false);
    };

    const handleImageUpload = (e) => {
        const selectedImage = e.target.files[0];
        if (selectedImage) {
        setNewImage(selectedImage)
        }
    };

    const handleCoefChange = (index, field, value) => {
        const updatedCoefValues = [...editedCoefValues];
        updatedCoefValues[index] = { ...updatedCoefValues[index], [field]: value };
        setEditedCoefValues(updatedCoefValues);
      };
    
    const handleDeleteCoefRow = (index) => {
        const updatedCoefValues = [...editedCoefValues];
        updatedCoefValues.splice(index, 1);
        setEditedCoefValues(updatedCoefValues);
    };

    const addCoefficientRow = () => {
        setEditedCoefValues(prevState => [
            ...prevState,
            { value: '', a: '', b: '' }
        ]);
    };

    const addCoefsForVariations = () => {
        editedSizeCharts.forEach(variation => {
            const sizes = Object.values(companySizeCharts[variation]?.data?.sizes) || []
            sizes.forEach(size => {
                setEditedCoefValues(prevState => [
                    ...prevState,
                    { value: size, a: '', b: '' }
                ]);
            });
        });
    };

    const handleSaveEdit = async () => {
        const accessToken = await currentUser.getIdToken(true);
        setButtonLoader(true)

        if(duplicatedNameFlag && editedProductName!==originalProduct.name){
            handleSnackbar('error', 'Please re-name your product.');
            setButtonLoader(false)
        } else if(editedProductName === ""){
            handleSnackbar('error', 'Please re-name your product.');
            setButtonLoader(false)
        } else if (companyId) {
            let send_data = {};
            let finalImage = {}

            send_data = {
                product_id: props.product,
                product_name: editedProductName,
                variations: editedSizeCharts,
                collection: editedCollection,
                sku: editedSku,
                clientGivenNumber: editedClientGivenNumber,
                recommend_flag: recommendFlag
            };

            if (editedTypeOfLayer !== 'None') {
                send_data.typeOfLayer = editedTypeOfLayer;
            }
    
            if (editedBodyPart !== 'None') {
                send_data.bodyPart = editedBodyPart;
            }

            let editedVariations = ""

            if (arraysAreEqual(originalProduct.variations, editedSizeCharts)) {
                editedVariations = "false"
            } else {
                editedVariations = "true"
            }

            send_data.edited_variations = editedVariations;

            if (newImage != "") {
                let currentImage = newImage ? newImage : "";
                if (currentImage) {
                finalImage = {
                    "name": currentImage.name,
                    "data": await toBase64(currentImage)
                }
                }
            }

            if (finalImage != {}) {
                send_data.image = finalImage;
            }

            const coefficientsData = {};
            editedCoefValues.forEach(coef => {
                coefficientsData[coef.value] = { a: coef.a, b: coef.b };
            });

            if (Object.keys(coefficientsData).length > 0) {
                send_data.coefficients = {
                    measurement_keys_to_change: editedMeasurementKeysToChange,
                    values: coefficientsData
                };
            }

            fetch(database_url + "/update_company_product?user_id=" + userId + '&company_id=' + companyId, {
                method: "PUT",
                headers: { "Accept": "application/json", "Content-Type": 'application/json', 'x-access-token': accessToken },
                body: JSON.stringify(send_data)
            }).then(() => {
                setButtonLoader(false)

                let promises = Promise.all([
                  fetch(database_url + '/view_company_products?user_id=' + userId + '&company_id=' + companyId, {
                    method: 'GET',
                    headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
                  }),
                ]);
        
                promises
                .then((results) => Promise.all(results.map((r) => r.json())))
                .then((resultsData) => {
                  const [companyProducts] = resultsData;
                  setCompanyProducts(companyProducts || {});
        
                  const initialProduct = companyProducts[props.product]
                  initialProduct.id = props.product
                  setOriginalProduct(initialProduct)
        
                }).then(()=>{
                  setNewImage("")
                  handleSnackbar('success', t(`prodSnack`));
                  setEdit(false)
                })

            });
            setButtonLoader(false)
        } else {
            setButtonLoader(false)
        }
    };


    return (
        <>
            <AppBar
                sx={{
                backgroundColor: colors.primary,
                color: colors.white
                }}
            >
                <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h6" component="div">
                    {t(`Product`)}: {originalProduct.name}
                </Typography>
                <IconButton color="inherit" onClick={props.handleCloseDialog}>
                    <CloseIcon />
                </IconButton>
                </Toolbar>
            </AppBar>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Toolbar />
                </Grid>
                <Grid item xs={12} align="right">
                    {edit ? (
                        <>
                            <LoadingButton
                              variant="contained"
                              color="primary"
                              loading={buttonLoader}
                              onClick={handleSaveEdit}
                            >
                            {t('Save')}
                            </LoadingButton>
                            <Button onClick={handleCancelEdit}>
                            {t('Cancel')}
                            </Button>
                        </>
                    ) : (
                        <Button variant="contained" color="primary" onClick={handleEdit}>
                            {t('Edit')}
                        </Button>
                    )}
                </Grid>
                {!edit ? (
                    productImage &&
                    <Grid item xs={12} mt={2} align="center">
                        <Box
                            sx={{
                            width: '300px',
                            // height: '300px',
                            overflow: 'hidden'
                            }}
                        >
                            <img src={productImage} style={{ maxWidth: '300px', heigth: 'auto', width: 'auto', alignSelf: 'center' }} />
                        </Box>
                    </Grid>
                ) : (
                    <>
                        <Grid item xs={12} mt={2} align="center">
                            {
                                productImage && (
                                    <Box
                                        sx={{
                                            width: '300px',
                                            // height: '300px',
                                            overflow: 'hidden', 
                                            objectFit: 'cover', 
                                            alignSelf: 'center'
                                        }}
                                    >
                                        <img src={productImage} style={{ maxWidth: '300px', maxHeight: '300px', heigth: 'auto', width: 'auto', alignSelf: 'center' }} />
                                    </Box>
                                )
                            }
                        </Grid>
                        <Grid item xs={12} mt={2} align="center">
                            <input
                                accept="image/*"
                                id={`image-upload-button`}
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => handleImageUpload(e)}
                            />
                            <label htmlFor={`image-upload-button`} style={{ alignSelf: 'center' }}>
                            <Button
                                variant="contained"
                                size='small'
                                sx={{ width: '200px' }}
                                component="span"
                                startIcon={newImage ? <Done /> : <AddPhotoAlternate />}
                            >
                                {t(`Upload Image`)}
                            </Button>
                            </label>
                        </Grid>
                    </>
                )}
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12}>
                        <TextField
                            label={t('Product Name')}
                            fullWidth
                            value={editedProductName}
                            onChange={(e) => setEditedProductName(e.target.value)}
                            disabled={!edit}
                            error={duplicatedNameFlag}
                            helperText={duplicatedNameFlag ? 'Duplicate name' : ''}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label='SKU'
                            fullWidth
                            value={editedSku}
                            onChange={(e) => setEditedSku(e.target.value)}
                            disabled={!edit}
                        />
                    </Grid>
                    {canViewWordpressFunctionalities &&
                    <Grid item xs={12}>
                        <TextField
                            label='WooCommerce Product ID'
                            fullWidth
                            value={editedClientGivenNumber}
                            onChange={(e) => setEditedClientGivenNumber(e.target.value)}
                            disabled={!edit}
                        />
                    </Grid>
                    }
                    <Grid item xs={12}>
                        <FormControl sx={{ my: 1 }} fullWidth>
                            <InputLabel id="demo-multiple-checkbox-label">{t(`Collection`)}</InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                value={editedCollection}
                                onChange={(e) => { setEditedCollection(e.target.value) }}
                                input={<OutlinedInput label="Collection" />}
                                fullWidth
                                disabled={!edit}
                            >
                            {Object.keys(companyCollections).map((key) => {
                                return (
                                <MenuItem key={key} value={key}>
                                    <ListItemText primary={companyCollections[key].name} />
                                </MenuItem>)
                            })}
                            </Select>
                        </FormControl>
                    </Grid>
                    {role == 'esenca' &&
                    <>
                    <Grid item xs={4} mt={2}>
                        <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="type-of-layer-label">Type of Layer</InputLabel>
                        <Select
                            labelId="type-of-layer-label"
                            id="type-of-layer"
                            value={editedTypeOfLayer}
                            onChange={(e) => setEditedTypeOfLayer(e.target.value)}
                            input={<OutlinedInput label="Type of Layer" />}
                            sx={{ width: '100%' }}
                            disabled={!edit}
                        >
                            {["None", 0, 1, 2, 3, 4].map((value) => (
                            <MenuItem key={value} value={value}>
                                <ListItemText primary={value} />
                            </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} mt={2}>
                        <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="body-part-label">Body Part</InputLabel>
                        <Select
                            labelId="body-part-label"
                            id="body-part"
                            value={editedBodyPart}
                            onChange={(e) => setEditedBodyPart(e.target.value)}
                            input={<OutlinedInput label="Body Part" />}
                            sx={{ width: '100%' }}
                            disabled={!edit}
                        >
                            {['None', 'lower', 'upper', 'both'].map((value) => (
                            <MenuItem key={value} value={value}>
                                <ListItemText primary={value} />
                            </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} mt={2}>
                        <FormControl sx={{ mt: 1, width: '300px' }} variant="outlined">
                        <InputLabel>Measurements Keys To Change</InputLabel>
                        <Select
                            multiple
                            value={editedMeasurementKeysToChange}
                            onChange={(e) => setEditedMeasurementKeysToChange(e.target.value)}
                            input={<OutlinedInput label="Measurements Keys To Change" />}
                            renderValue={(selected) => selected.map(id => {
                                const measurement = measurementsArray.find(measurement => measurement.id === id);
                                return measurement ? measurement.name : id;
                            }).join(', ')}
                            sx={{ width: '300px' }}
                            disabled={!edit}
                        >
                            {measurementsArray.map((measurement) => (
                            <MenuItem key={measurement.id} value={measurement.id}>
                                <Checkbox checked={editedMeasurementKeysToChange.indexOf(measurement.id) > -1} />
                                <ListItemText primary={measurement.name} />
                            </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} mt={5}>
                        <Typography variant="body2" fontWeight={"bold"}>Coefficients</Typography>
                        <Button onClick={addCoefsForVariations} disabled={!edit} align="right">Add Coefficients for Associated Variations</Button>
                        <FormControlLabel
                            label="Re-Recommend"
                            control={
                                <Checkbox
                                    disabled={!edit} 
                                    checked={recommendFlag}
                                    onChange={()=>{setRecommendFlag(!recommendFlag)}}
                                />
                            }
                        />
                        <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                            <TableRow>
                                <TableCell>Value</TableCell>
                                <TableCell>a</TableCell>
                                <TableCell>b</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {Array.isArray(editedCoefValues) && editedCoefValues.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <TextField
                                            value={row.value}
                                            onChange={(e) => handleCoefChange(index, 'value', e.target.value)}
                                            disabled={!edit}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            value={row.a}
                                            onChange={(e) => handleCoefChange(index, 'a', e.target.value)}
                                            disabled={!edit}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            value={row.b}
                                            onChange={(e) => handleCoefChange(index, 'b', e.target.value)}
                                            disabled={!edit}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {edit && (
                                        <IconButton onClick={() => handleDeleteCoefRow(index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12} mt={2}>
                        <Button variant="standard" disabled={!edit} onClick={()=>{addCoefficientRow()}} startIcon={<Add />}>
                            Add Row
                        </Button>
                    </Grid>
                    </>
                    }

                    {!edit ? 
                    <>
                        <Grid item xs={12} mt={2}>
                            <Divider />
                            {
                                originalProduct?.variations?.length > 0 ? (
                                    <Typography variant="body1" fontWeight={'bold'}>{t(`prodLabelTwo`)}</Typography>
                                ) : (
                                <>
                                    <Typography variant="body1" fontWeight={'bold'}>{t(`prodLabelThree`)}</Typography>
                                    <Typography variant="body2">{t(`prodLabelFour`)}</Typography>
                                </>
                                )
                        }
                        </Grid>
                        <Grid item xs={12} mt={2} px={10}>
                            {
                            originalProduct?.variations?.length > 0 && (
                                <TableContainer component={Paper}>
                            <Table>
                                    <TableHead sx={{ backgroundColor: colors.primary }}>
                                    <TableRow>
                                        <TableCell align="center" sx={{ color: colors.white, fontWeight: 'bold' }}>{t(`Size Chart Name`)}</TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {originalProduct.variations.map((sc) => (
                                        <TableRow key={sc}>
                                        <TableCell align="center">{companySizeCharts[sc]?.metadata?.name}</TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                                </TableContainer>
                            )
                            }
                        </Grid>
                    </>
                    :
                    <>
                        <Grid item xs={12} mt={2} align="left">
                        {
                            editedSizeCharts.length > 0 ? (
                                <Typography variant="body1" fontWeight={'bold'}>{t(`prodLabelTwo`)}</Typography>
                            ) : (
                            <>
                                <Typography variant="body1" fontWeight={'bold'}>{t(`prodLabelThree`)}</Typography>
                                <Typography variant="body2">{t(`prodLabelFour`)}</Typography>
                            </>
                            )
                        }
                    </Grid>
                    <Grid item xs={12} mt={2} px={10}>
                        {
                        editedSizeCharts.length > 0 && (
                            <TableContainer component={Paper}>
                            <Table>
                                <TableHead sx={{ backgroundColor: colors.primary }}>
                                <TableRow>
                                    <TableCell align="center" sx={{ color: colors.white, fontWeight: 'bold' }}>{t(`Size Chart Name`)}</TableCell>
                                    <TableCell align="center" sx={{ color: colors.white, fontWeight: 'bold' }}>{t(`Action`)}</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {editedSizeCharts.map((sc) => (
                                    <TableRow key={sc}>
                                    <TableCell align="center">{companySizeCharts[sc]?.metadata?.name}</TableCell>
                                    <TableCell align="center">
                                        <Button
                                            onClick={() => handleScDelete(sc)}
                                            variant="outlined"
                                            sx={{ width: '150px' }}
                                            data-cy = {`delete-size-chart-${sc}`}
                                            >
                                            {t(`Delete`)}
                                        </Button>
                                    </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        )
                        }
                    </Grid>
                    <Grid item xs={12} mt={2} align="left">
                        {
                        availableSizeCharts.length > 0 ? (
                            <Typography variant="body1" fontWeight={'bold'}>{t(`prodLabelFive`)}</Typography>
                        ) : (
                            <>
                            <Typography variant="body1" fontWeight={'bold'}>{t(`prodLabelSix`)}</Typography>
                            <Typography variant="body2">{t(`prodLabelSeven`)}</Typography>
                            </>
                        )
                        }
                    </Grid>
                    <Grid item xs={12} mt={2}>
                    {
                        availableSizeCharts.length > 0 && (
                            <TableContainer component={Paper}>
                            <Table>
                                <TableHead sx={{ backgroundColor: colors.primary }}>
                                <TableRow>
                                    <TableCell align="center" sx={{ color: colors.white, fontWeight: 'bold' }}>{t(`Size Chart Name`)}</TableCell>
                                    <TableCell align="center" sx={{ color: colors.white, fontWeight: 'bold' }}>{t(`Action`)}</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {availableSizeCharts.map((sc) => (
                                    <TableRow key={sc}>
                                    <TableCell align="center">{companySizeCharts[sc]?.metadata?.name}</TableCell>
                                    <TableCell align="center">
                                        <Button
                                            onClick={() => handleScAdd(sc)}
                                            variant="outlined"
                                            sx={{ width: '150px' }}
                                            data-cy = {`add-size-chart-${sc}`}
                                            >
                                            {t(`Add`)}
                                        </Button>
                                    </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                            </TableContainer>
                        )
                        }
                    </Grid>
                    </>
                    }
                </Grid>
            </Grid>
        </>
    );
}

export default ProductDetailsDialogTest;
