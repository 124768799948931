import { useState, useEffect } from 'react';

import {
  Typography, Grid, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Button, Box,
  Backdrop, CircularProgress, Card
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Close, Add, Check, Delete, HorizontalRule } from '@mui/icons-material';

import { useAuth } from '../../../AuthContext';
import { useTranslation } from "react-i18next";
import { HistoryComponent } from './HistoryComponent';

const database_url = process.env.REACT_APP_DATABASE_URL;

export const ResultsComponent = (props) => {

  const { currentUser, companyId, companyProducts, measurements, setMeasurements, companySizeCharts, companyConfig, 
    companyClients, permissions, userId, companyCollections } = useAuth();
  const { t } = useTranslation();

  const [edit, setEdit] = useState(false);
  const [person, setPerson] = useState('')
  const [showHistory, setShowHistory] = useState(false)
  const [loaderSave, setLoaderSave] = useState(false);
  const [loaderHistory, setLoaderHistory] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canViewHistory, setCanViewHistory] = useState(false)
  const [canRecommend, setCanRecommend] = useState(false);

  const [personId, setPersonId] = useState("");
  const [trackingId, setTrackingId] = useState("");
  const [measurementId, setMeasurementId] = useState("");
  const [results, setResults] = useState([]);

  const [productNames, setProductNames] = useState({});
  const [unselectedProducts, setUnselectedProducts] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState("");

  const [associatedProducts, setAssociatedProducts] = useState([]);
  const [deletedProducts, setDeletedProducts] = useState([])

  const [clientName, setClientName] = useState("");
  const [historyData, setHistoryData] = useState({});

  useEffect(()=>{
    if(permissions && permissions.includes('delete_recommendations')) {
      setCanDelete(true)
    }
    if(permissions && permissions.includes('view_history')) {
      setCanViewHistory(true)
    }
    if(permissions && permissions.includes('can_recommend')) {
      setCanRecommend(true)
    }
  },[permissions])

  useEffect(() => {
    if (props.person) {
      setPerson(props.person)
      if (props.person.personId) {
        setPersonId(props.person.personId)
      }
      if (props.person.trackingId) {
        setTrackingId(props.person.trackingId)
      }
      if (props.person.lastMeasurementId) {
        setMeasurementId(props.person.lastMeasurementId)
      }
      let clientName = "";
      if (props.person.clientId == companyId) {
        clientName = companyConfig['name'];
      } else if (companyClients[props.person.clientId] && companyClients[props.person.clientId]['name']){
        clientName = companyClients[props.person.clientId]['name'];
      }

      setClientName(clientName);

      if(props.person.lastMeasurementData){
        let personData = props.person.lastMeasurementData
        if (companyProducts && personData.results) {
          const updatedResults = {};
          Object.keys(personData.results).forEach((resultId) => {
            const result = personData.results[resultId];
            updatedResults[resultId] = { ...result, resultId: resultId };
          });
          setResults(updatedResults);

          const productNamesMapping = {};
          Object.keys(companyProducts).forEach((productId) => {
            if(companyProducts.variations){
              productNamesMapping[productId] = companyProducts[productId].name;
            }
          });
          setProductNames(productNamesMapping);
          const unselectedProductsArray = Object.entries(companyProducts)
          .filter(([productId, product]) => 
              product.variations && 
              !Object.values(personData.results).some(result => result.productId === productId)
          )
          .map(([productId, product]) => ({
              ...product,
              productId: productId,
              associated: false
          }));

          setUnselectedProducts(unselectedProductsArray);
        }
      }
    }
  }, [props.person])

  const handleDelete = (productId) => {
    const deletedProduct = deletedProducts.find((p) => p.productId === productId);

    if (deletedProduct) {
      setDeletedProducts((prev) =>
        prev.filter((p) => p.productId !== productId)
      );
    } else {
      const productToDelete = results && results[Object.keys(results).find(key => results[key].productId === productId)];
      if (productToDelete) {
        setDeletedProducts((prev) => [...prev, productToDelete]);
      }
    }
  };

  const handleAdd = (productId) => {
    const product = unselectedProducts.find(
      (p) => p.productId === productId
    );

    if (product) {
      const isAssociated = product.associated;

      if (!isAssociated) {
        const updatedUnselected = unselectedProducts.map((p) =>
          p.productId === productId ? { ...p, associated: true } : p
        );
        setUnselectedProducts(updatedUnselected);

        setAssociatedProducts((prev) => [...prev, product]);
      } else {
        const updatedUnselected = unselectedProducts.map((p) =>
          p.productId === productId ? { ...p, associated: false } : p
        );
        setUnselectedProducts(updatedUnselected);

        setAssociatedProducts((prev) =>
          prev.filter((p) => p.productId !== productId)
        );
      }
    }
  };

  const handleCancel = () => {
    setEdit(false)
    setAssociatedProducts([])
    setDeletedProducts([])
    const updatedUnselected = unselectedProducts.map((product) => ({
      ...product,
      associated: false
    }));
    setUnselectedProducts(updatedUnselected);
    setSelectedCollection("")
  };

  const handleSelectCollection = (key) => {
    if(key === selectedCollection){
      setSelectedCollection("")
    } else {
      setSelectedCollection(key)
    }
  };

  const handleSave = async () => {
    const accessToken = await currentUser.getIdToken(true);

    let selectedCollectionIds = []
    if(selectedCollection !== "") {
      selectedCollectionIds = unselectedProducts.filter(product => product.collection === selectedCollection).map(product => product.productId);
    }

    if (companyId && (associatedProducts.length > 0 || deletedProducts.length > 0 || selectedCollectionIds.length > 0)) {
      setLoaderSave(true)

      let addedIds = new Set();
      if (associatedProducts.length > 0) {
        for (let i = 0; i < associatedProducts.length; i++) {
          addedIds.add(associatedProducts[i].productId)
        }
      } 
      if (selectedCollectionIds.length > 0) {
        for (let i = 0; i < selectedCollectionIds.length; i++) {
          addedIds.add(selectedCollectionIds[i])
        }
      } 

      addedIds = Array.from(addedIds);

      let deletedIds = []
      if (deletedProducts.length > 0) {
        for (let i = 0; i < deletedProducts.length; i++) {
          deletedIds.push(deletedProducts[i].resultId)
        }
      }

      let fetchPromises = [];

      if (addedIds.length > 0) {
        let send_data = { person_id: personId, measurement_id: measurementId, products_added: addedIds };
        fetchPromises.push(fetch(database_url + "/add_person_results?user_id=" + userId + '&company_id=' + companyId, {
          method: "PUT",
          headers: { "Accept": "application/json", "Content-Type": 'application/json', 'x-access-token': accessToken },
          body: JSON.stringify(send_data)
        }));
      }

      if (deletedIds.length > 0) {
        let send_data = { person_id: personId, measurement_id: measurementId, results_deleted: deletedIds };
        fetchPromises.push(fetch(database_url + "/delete_person_results?user_id=" + userId + '&user_id=' + userId + '&company_id=' + companyId, {
          method: "DELETE",
          headers: { "Accept": "application/json", "Content-Type": 'application/json', 'x-access-token': accessToken },
          body: JSON.stringify(send_data)
        }));
      }

      if (fetchPromises.length > 0) {
        Promise.all(fetchPromises)
          .then(()=>{
            setAssociatedProducts([])
            setDeletedProducts([])
            setEdit(false)
            setSelectedCollection("")

            let promises = Promise.all([
              fetch(database_url + '/view_single_measurement_results?user_id=' + userId + '&measurement_id=' + measurementId, {
                method: 'GET',
                headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
              }),
            ])
            promises
              .then((results) => Promise.all(results.map((r) => r.json())))
              .then((resultsData) => {
                const [new_results] = resultsData;

                const updatedResults = {};
                Object.keys(new_results).forEach((resultId) => {
                  const result = new_results[resultId];
                  updatedResults[resultId] = { ...result, resultId: resultId };
                });
                setResults(updatedResults);

                const unselectedProductsArray = Object.entries(companyProducts)
                .filter(([productId, product]) => 
                    product.variations && 
                    !Object.values(updatedResults).some(result => result.productId === productId)
                )
                .map(([productId, product]) => ({
                    ...product,
                    productId: productId,
                    associated: false
                }));

                setUnselectedProducts(unselectedProductsArray);

                if (measurements.hasOwnProperty(measurementId)) {
                  const updatedObject = { ...measurements };
                  const entry = updatedObject[measurementId];
                  entry.results = updatedResults;
                  setMeasurements(updatedObject);
                }

              })
              .then(() => {
                setLoaderSave(false)
              });
        })
        .then(() => {
          setLoaderSave(false)
        });
      } else {
        setAssociatedProducts([])
        setDeletedProducts([])
        setLoaderSave(false)
        setEdit(false)
        setSelectedCollection("")
      }
    } else {
      setLoaderSave(false)
      setEdit(false)
      setSelectedCollection("")
    }
  };

  const handleCloseBackdrop = () => {
    setLoaderSave(false);
  };

  const handleOpenBackdrop = () => {
    setLoaderSave(true);
  };

  const viewHistory = async() => {
    const accessToken = await currentUser.getIdToken(true);
    setLoaderHistory(true)

    if(companyId && personId && accessToken){
      let promises = Promise.all([
        fetch(database_url + '/view_person_measurements_results?user_id=' + userId + '&company_id=' + companyId + '&person_id=' + personId, {
          method: 'GET',
          headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
        }),
      ])
      promises
        .then((results) => Promise.all(results.map((r) => r.json())))
        .then((resultsData) => {
          setHistoryData(resultsData[0])
        }).then(() => {
          setLoaderHistory(false)
        });

    } else {
      setLoaderHistory(false)
    }
    
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight={'bold'}>
            {t(`recomHeaderTwo`)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ py: 2, px: 5 }}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Typography fontWeight="light" variant="subtitle2">{t(`First Name`)}</Typography>
                <Typography fontWeight="medium" variant="subtitle2" sx={{ fontWeight: 'bold' }}>{person?.lastMeasurementData?.firstName}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontWeight="light" variant="subtitle2">{t(`Last Name`)}</Typography>
                <Typography fontWeight="medium" variant="subtitle2" sx={{ fontWeight: 'bold' }}>{person?.lastMeasurementData?.lastName}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontWeight="light" variant="subtitle2">{t(`Client`)}</Typography>
                <Typography fontWeight="medium" variant="subtitle2" sx={{ fontWeight: 'bold' }}>{clientName}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontWeight="light" variant="subtitle2">{t(`Tracking ID`)}</Typography>
                <Typography fontWeight="medium" variant="subtitle2" sx={{ fontWeight: 'bold' }}>{person?.trackingId}</Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" fontWeight={'bold'}>
            {t(`recomHeaderThree`)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ py: 2, px: 5 }}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>{t(`Product Name`)}</TableCell>
                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>{t(`Collection`)}</TableCell>
                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>{t(`Variation`)}</TableCell>
                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>{t(`Size`)}</TableCell>
                    {edit && canDelete && <TableCell align="center" sx={{ fontWeight: 'bold' }}>Delete</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {results && Object.values(results).map((row, i) => (
                    <TableRow key={i}>
                        <TableCell align="center">{companyProducts[row.productId]? companyProducts[row.productId].name : (row.deletedProductName ? row.deletedProductName : row.productId )}</TableCell>
                        <TableCell align="center">{companyProducts[row.productId]? companyCollections[companyProducts[row.productId]?.collection]?.name : ''}</TableCell>
                        <TableCell align="center">{companySizeCharts[row.variationId]? companySizeCharts[row.variationId]?.metadata?.name : (row.deletedSizeChartName ? row.deletedSizeChartName : row.variationId )}</TableCell>
                      <TableCell align="center" data-cy={`size-${row.productId}`}>{row.size}</TableCell>

                      {edit && canDelete &&
                        <TableCell align="center">
                          {deletedProducts.some((deletedProduct) => deletedProduct.productId === row.productId) ? (
                            <IconButton
                              color="error"
                              onClick={() => handleDelete(row.productId)}
                            >
                              <Add />
                            </IconButton>
                          ) : (
                            <IconButton
                              color="error"
                              onClick={() => handleDelete(row.productId)}
                              data-cy={`delete-recommendation-${row.productId}`}
                            >
                              <Delete />
                            </IconButton>
                          )}
                        </TableCell>
                      }
                    </TableRow>

                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
        {!edit && canRecommend &&
          <Grid item xs={12} align="right">
            <Button onClick={() => { setEdit(true) }} variant='contained' data-cy={'edit-recommendation-button'} >{t(`Edit`)}</Button>
          </Grid>
        }
        {edit &&
          <>
          <Grid item xs={12} mt={2}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={handleCancel} sx={{ margin: '0 25px', width: '100px' }} variant='outlined'>{t(`Cancel`)}</Button>
              <Button onClick={handleSave} sx={{ margin: '0 25px', width: '100px' }} variant='contained' data-cy={'save-recommendations-button'} >{t(`Save`)}</Button>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight={'bold'}>
                Collections 
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Card sx={{ py: 2, px: 5 }}>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>{t(`Collection Name`)}</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>{t(`Add`)}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {companyCollections && Object.entries(companyCollections).map(([key, row], i) => (
                        <TableRow key={i}>
                          <TableCell align="center">{row.name}</TableCell>
                          <TableCell align="center">
                            <IconButton
                              onClick={() => handleSelectCollection(key)}
                              disabled = {(selectedCollection !== "" && key !== selectedCollection)? true : false}
                            >
                              { key === selectedCollection ?
                                <Check style={{ color: 'green' }}/>
                                :
                                selectedCollection !== "" || associatedProducts.length > 0 ?
                                <HorizontalRule style={{ color: 'gray' }} />
                                :
                                <Add style={{ color: 'green' }} />
                              }
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight={'bold'}>
                {t(`recomHeaderFour`)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Card sx={{ py: 2, px: 5 }}>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>{t(`Product Name`)}</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>{t(`Add`)}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {unselectedProducts && Object.values(unselectedProducts).map((row, i) => (
                        <TableRow key={i}>
                          <TableCell align="center">{row.name}</TableCell>
                          <TableCell align="center">
                            <IconButton
                              color="error"
                              onClick={() => handleAdd(row.productId)}
                              data-cy={`add-recommendation-${row.productId}`}
                            >
                              {
                                selectedCollection !== "" ?
                                <HorizontalRule style={{ color: 'gray' }} />
                                :
                                row.associated === false ?
                                <Add style={{ color: 'green' }} />
                                :
                                <Check style={{ color: 'green' }} />
                              }
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
          </Grid>
          </>
        }
        {!edit && canViewHistory &&
          <>
            <Grid item xs={12} align="right">
              <LoadingButton
                onClick={() => viewHistory()}
                loading={loaderHistory}
                variant="contained"
              >
                {t(`recomButtonTwo`)}
              </LoadingButton>
            </Grid>
            <Grid item xs={12}>
              <HistoryComponent historyData={historyData}/>
            </Grid>
          </>
        }
      </Grid>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loaderSave}
        onClick={handleCloseBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}
