import {
    Box,
    Typography
} from "@mui/material";

export default function CustomTabPanel(props) {
    const { children, value, currentOption, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== currentOption}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        style={{
          height: "100%"
        }}
      >
        {value === currentOption && (
          <Box sx={{ p: 3, height: '100%' }}>
            {children}
          </Box>
        )}
      </div>
    );
}