import { useState, useEffect } from "react";

import { FormControl, Grid, InputLabel, MenuItem, Typography, Select, Button, List, ListItem, ListItemIcon, ListItemText,
  CircularProgress
} from "@mui/material";
import { Add, Person } from "@mui/icons-material";

import CreateClientDialog from "./CreateClient/CreateClientDialog";
import ModifyClientsPermissions from "./ModifyClientsPermissions"

import { useTranslation } from "react-i18next";
import { useAuth } from "../../../AuthContext";
import { useParams, useNavigate, useLocation } from 'react-router-dom';

export default function ViewClient() {
  const { t } = useTranslation();

  const { companyClients, companyUsers, companyClientUsers, permissions } = useAuth();
  const [selectedClient, setSelectedClient] = useState("");
  const [clientUsers, setClientUsers] = useState([]);
  const [openCreateClientDialog, setOpenCreateClientDialog] = useState(false);
  const [canModifyClientsPermissions, setCanModifyClientsPermissions] = useState(false);

  const [isCompanyUsersLoading, setIsCompanyUsersLoading] = useState(false);
  const [isClientUsersLoading, setIsClientUsersLoading] = useState(false);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { tab, dialog } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    switch (dialog) {
      case 'add-client':
        setOpenCreateClientDialog(true);
        break;
      default:
        setOpenCreateClientDialog(false);
        break;
    }
  }, [dialog]);

  useEffect(()=>{
    if(permissions && permissions.includes('modify_clients_permissions')) {
      setCanModifyClientsPermissions(true)
    }
  },[permissions])

  const handleSelectedClient = (event) => {
    setSelectedClient(event.target.value);
  };

  const handleOpenCreateClientDialog = () => {
    window.tidioChatApi.display(false);
    navigate(`/administration/${tab}/add-client`); 
  };

  const handleCloseCreateClientDialog = () => {
    navigate(`/administration/${tab}`);
    window.tidioChatApi.display(true);
    setOpenCreateClientDialog(false);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} mt={2}>
        <Typography variant="body1" fontWeight={'bold'}>
          {t(`adminHeaderOne`)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {
          isCompanyUsersLoading ? (
            <CircularProgress />
          ) : (
            companyUsers && (
              Object.keys(companyUsers).map((clt, index) => (
                <ListItem key={index} sx={{ py: 2 }}>
                  <ListItemIcon>
                    <Person />
                  </ListItemIcon>
                  <ListItemText
                    primary={companyUsers[clt]['email']}
                    secondary={"role: " + companyUsers[clt]['role']}
                  />
                </ListItem>
              ))
            )
          )
        }
      </Grid>
      <Grid item xs={12} mt={2}>
        <Typography variant="body1" fontWeight={'bold'}>
          {t(`adminHeaderTwo`)}
        </Typography>
      </Grid>
      <Grid item xs={6} mt={2} display={'flex'} justifyContent={'space-between'}>
        <FormControl
          sx={{ width: '60%' }}
        >
          <InputLabel id="role-label">{t(`adminLabelOne`)}</InputLabel>
          <Select
            labelId="role-label"
            id="role"
            name="role"
            label={t(`adminLabelOne`)}
            value={selectedClient}
            onChange={handleSelectedClient}
          >
            {Object.keys(companyClients).map((name) => (
              <MenuItem key={name} value={name}>
                {companyClients[name]['name']}
              </MenuItem>
            ))}
            {
              Object.keys(companyClients).length == 0 && (
                <MenuItem key={"empty"} value={""}>
                  {t(`adminLabelTwo`)}
                </MenuItem>
              )
            }
          </Select>
        </FormControl>
        <Button
          variant="contained"
          startIcon={
            <Add />
          }
          onClick={handleOpenCreateClientDialog}
        >
          {t(`Add New Client`)}
        </Button>
      </Grid>
      {
        selectedClient && canModifyClientsPermissions && (
          <>
            <Grid item xs={12}>
                {
                  Object.keys(companyClientUsers[selectedClient]).length > 0 ? (
                    <>
                      <Typography variant="body1" fontWeight={'bold'}>{t(`adminLabelThree`)}</Typography>
                      <ModifyClientsPermissions selectedClient={selectedClient}/>
                    </>
                  ) : (
                    <Typography variant="body1" fontWeight={'bold'}>{t(`adminLabelFour`)}</Typography>
                  )
                }
            </Grid>
          </>
        )
      }
      <Grid item xs={12}>
        <List>
          {
            isClientUsersLoading ? (
              <CircularProgress />
            ) : (
              selectedClient && Object.keys(companyClientUsers[selectedClient]).length > 0 && (
                Object.keys(companyClientUsers[selectedClient]).map((clt, index) => (
                  <ListItem key={index} sx={{ py: 2 }}>
                    <ListItemIcon>
                      <Person />
                    </ListItemIcon>
                    <ListItemText
                      primary={companyClientUsers[selectedClient][clt]['email']}
                      secondary={"role: " + companyClientUsers[selectedClient][clt]['role']}
                    />
                  </ListItem>
                ))
              )
            )
          }
        </List>
      </Grid>
      <CreateClientDialog
        openCreateClientDialog={openCreateClientDialog}
        handleCloseCreateClientDialog={handleCloseCreateClientDialog}
      />
    </Grid>
  );
}