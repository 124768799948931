import React, { useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Card, Paper } from '@mui/material';
import { boxShadows } from '../../../../defaultTheme';

const DisplayGrid = ({ rowData, selectedRows, handleSelectionChange }) => {
  // Assuming the rowData is an array of objects without "id" key
  // For example, [{ name: 'John', age: 25 }, { name: 'Jane', age: 30 }]

  // Generate unique ids for each row
  const rowsWithIds = rowData && rowData.length > 0 ? rowData : {};

  const columns = rowsWithIds.length > 0 ? Object.keys(rowsWithIds[0]).map((key) => ({ field: key, headerName: key, flex: 1})) : [];

  useEffect(() => {
    console.log(rowData)
  }, [rowData]);

  return (
    <>
      {
        rowData && rowData.length > 0 && (
          <Paper
            sx={{
              my: 2,
              backgroundColor: 'transparent',
            }}
            elevation={0}
          >
            <DataGrid
              rows={rowsWithIds}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5, 10, 20]}
              checkboxSelection
              selectionModel={selectedRows}
              onRowSelectionModelChange={(newRowSelectionModel) => {
                handleSelectionChange(newRowSelectionModel);
              }}
              // columnVisibilityModel={{id: false}}
              getRowId={(row) => row.id}
              density='compact'
            />
          </Paper>
        )
      }
    </>
  );
};

export default DisplayGrid;
