import * as React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';

import { Close } from '@mui/icons-material';

import { auth } from "../../firebase";
import { signOut } from 'firebase/auth';

import { useNavigate } from 'react-router-dom';

export default function TimeoutModal({isOpen, onRequestClose}) {

    const navigate = useNavigate();
    // const auth = useAuth()

    const onLogOffCall = () => {
        signOut(auth)
        navigate('/')
        onRequestClose()
    }

    return (
        <div>
        <Dialog
            open={isOpen}
            onClose={onRequestClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {"Session Timeout"}
            <IconButton
                aria-label="close"
                onClick={onRequestClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
                >
                <Close />
            </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    You're being timed out due to inactivity. Please log in. 
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {/* <Button onClick={onLogOffCall}>Log in</Button> */}
                {/* <Button onClick={onRequestClose} autoFocus>Stay logged in</Button> */}
            </DialogActions>
        </Dialog>
        </div>
    );
}