import { useEffect, useState, useRef } from "react";

import {
  AppBar, Toolbar, Typography, Grid, IconButton, Card, FormControl, RadioGroup, FormControlLabel, Radio, Button, Table, TableCell,
  TableRow, TableBody, TableContainer, TableHead, Input, InputLabel, Select, Skeleton, Paper, MenuItem,
  Box
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {Close as CloseIcon, ReportProblem as ReportProblemIcon} from '@mui/icons-material'

import { makeCSV, parseCSV, parseExcel, validateFile, convertArraysToDictionaries, isValidFirestoreFieldName, transformFirestoreKey,
  extractLevelOneDicts, validateJSON } from "../utils";

import { useAuth } from "../../../../AuthContext";
import { useTranslation } from "react-i18next";

import template from '../../../../assets/size_chart_template.json'
import excel_template from '../../../../assets/size_chart_template.xlsx'

import Associations from "./Associations";
import { colors } from "../../../../defaultTheme";
import { ReportProblemOutlined } from "@mui/icons-material";

const database_url = process.env.REACT_APP_DATABASE_URL;

function AddChartFileDialog(props) {

  const { role, userId, companyId, standard, handleSnackbar, currentUser, companyMeasurementsConfig, companySizeCharts, setCompanySizeCharts } = useAuth()
  const { t } = useTranslation();

  const [correlations, setCorrelations] = useState({})
  const [associations, setAssociations] = useState({});

  const [data, setData] = useState(template);
  const [companySizeChartsNames, setCompanySizeChartsNames] = useState([]);

  const [showSaveButton, setShowSaveButton] = useState(false);
  const [fileType, setFileType] = useState("json");
  const [uploadedFile, setUploadedFile] = useState("");

  const [fileError, setFileError] = useState(false);
  const [saveError, setSaveError] = useState(false);

  const [duplicatesNameFlag, setDuplicatesNameFlag] = useState(false);
  const [duplicatesAssociationsFlag, setDuplicatesAssociationsFlag] = useState(false)

  const [columns, setColumns] = useState([])
  const [rows, setRows] = useState([])
  const [listForHeader, setListForHeader] = useState({});
  const [selectedItem, setSelectedItem] = useState("");
  const [upperColumns, setUpperColumns] = useState([]);
  const [rowsCsv, setRowsCsv] = useState([]);
  const [newRows, setNewRows] = useState([]);
  const [newColumns, setNewColumns] = useState([]);
  const [newRowCountArray, setNewRowCountArray] = useState([]);
  const [newColCountArray, setNewColCountArray] = useState([]);

  const [finalObject, setFinalObject] =  useState({})

  const [buttonLoader, setButtonLoader] = useState(false);

  const downloadLinkRef = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(()=>{
    if(companySizeCharts){
      const sizeChartNames = [];
      for (const item in companySizeCharts) {
          if (companySizeCharts.hasOwnProperty(item)) {
              const name = companySizeCharts[item].metadata?.name
              const lowercase = name?.toLowerCase()
              if (!sizeChartNames.includes(lowercase)) {
                  sizeChartNames.push(lowercase);
              }
          }
      }
      setCompanySizeChartsNames(sizeChartNames)
    }
  },[companySizeCharts])

  useEffect(() => {
    var futureCSV = []
    for (var i = 0; i < Object.keys(data).length; i++) {
      for (var k = 0; k < Object.values(data)[i].sizes.length; k++) {
        var tempObj = []
        tempObj = Object.values(Object.values(data)[i])
        var temp = []
        for (var l = 0; l < tempObj.length; l++) {
          temp.push(tempObj[l][k])
        }
        futureCSV.push(temp)
      }
    }
    setRowsCsv(futureCSV)
  }, [fileType])

  const changeFileType = (event) => {
    const value = event.target.value;
    setFileType(value)
  }

  const download = (event) => {
    event.preventDefault();

    let output;
    let mimeType;

    if (fileType === "json") {
      output = JSON.stringify(data, null, 2);
      mimeType = "application/json";
    } else if (fileType === "csv") {
      let contents = [];
      let h = 0
      for (var i = 0; i < Object.keys(data).length; i++) {
        let temp = []
        temp.push(Object.keys(data)[i])
        contents.push(temp);
        contents.push(Object.keys(Object.values(data)[i]))
        for (var j = 0; j < Object.values(data)[i].sizes.length; j++) {
          contents.push(rowsCsv[h])
          h++
        }
      }
      output = makeCSV(contents);
      mimeType = "text/csv";
    } else if (fileType === "text") {
      output = JSON.stringify(data, null, " ")
      mimeType = "text/plain";
    }

    if (fileType === "xlsx") {
      downloadLinkRef.current.href = excel_template;
      downloadLinkRef.current.download = 'esenca';
      downloadLinkRef.current.click();
    } else {
      const blob = new Blob([output], { type: mimeType });
      const fileDownloadUrl = URL.createObjectURL(blob);

      downloadLinkRef.current.href = fileDownloadUrl;
      downloadLinkRef.current.download = 'esenca';
      downloadLinkRef.current.click();
    }
  }

  const handleUpload = () => {
    fileInputRef.current.click();
  }

  const resetValues = () => {
    setUploadedFile("")
    setFileError(false)
    setColumns([])
    setRows([])
    setListForHeader({})
    setSelectedItem("")
    setUpperColumns([])
    setRowsCsv([])
    setNewRows([])
    setNewColumns([])
    setNewRowCountArray([])
    setNewColCountArray([])
    setCorrelations({})
    setAssociations({})
    setShowSaveButton(false)
  }

  const handleFileUpload = (event) => {
    event.preventDefault()
    if (fileInputRef.current.files[0]) {
      resetValues()
      setFileError(false)

      const fileObj = fileInputRef.current.files[0];
      const reader = new FileReader();
      var last = fileObj.name.substr(fileObj.name.length - 3);

      let fileloaded = e => {
        const fileContents = e.target.result;
        if (last === 'csv') {
          let parsed = parseCSV(fileContents)
          if (fileContents.includes("sizes")) {
            if (Object.keys(parsed).length === 0) {
              setFileError(true)
              handleSnackbar('error', t(`scSnackFour`));
              resetValues()
            } else {
              setUploadedFile(parsed)
              const validationResult = validateFile(parsed);
              if (validationResult === true) {
                showTable(parsed)
              } else {
                const [errorCode, key] = validationResult;
                setFileError(true)
                if(key === "none"){
                  handleSnackbar('error', t(`${errorCode}`));
                } else {
                  handleSnackbar('error', t(`${errorCode}`, { key: key }))
                }
                resetValues()
              }
            }
          } else {
            setFileError(true)
            handleSnackbar('error', t(`scSnackThree`));
            resetValues()
          }
        } else if (last === 'lsx') {
          let parsed = parseExcel(fileContents);

          let hasSizes = false;
          for (const key in parsed) {
            if (parsed[key].hasOwnProperty("sizes")) {
              hasSizes = true;
              break;
            }
          }

          if (hasSizes) {
            if (Object.keys(parsed).length === 0) {
              setFileError(true)
              handleSnackbar('error', t(`scSnackFour`));
            } else {
              setUploadedFile(parsed)
              const validationResult = validateFile(parsed);
              if (validationResult === true) {
                showTable(parsed)
              } else {
                const [errorCode, key] = validationResult;
                setFileError(true)
                if(key === "none"){
                  handleSnackbar('error', t(`${errorCode}`));
                } else {
                  handleSnackbar('error', t(`${errorCode}`, { key: key }))
                }
                resetValues()
              }
            }
          } else {
            setFileError(true)
            handleSnackbar('error', t(`scSnackThree`));
            resetValues()
          }
        } else {
          try {
            const fileContent = e.target.result;
            const parsedData = JSON.parse(fileContent);
            setUploadedFile(parsedData)

            let keysValidator = true
            let extracted = extractLevelOneDicts(fileContent.toString())
            for(let i = 0; i < extracted.length; i++) {
             if(!validateJSON(extracted[i])){
              keysValidator = false
             }
            }
            if (!keysValidator) {
              setFileError(true)
              handleSnackbar('error', t(`scSnackFour`));
            } else {
              const validationResult = validateFile(parsedData);
              if (validationResult === true) {
                showTable(parsedData)
              } else {
                const [errorCode, key] = validationResult;
                setFileError(true)
                if(key === "none"){
                  handleSnackbar('error', t(`${errorCode}`));
                } else {
                  handleSnackbar('error', t(`${errorCode}`, { key: key }))
                }
                resetValues()
              }
            }
          } catch (error) {
            setFileError(true)
            handleSnackbar('error', t(`scSnackTwo`));
            resetValues()
          }
        }
      }
      fileloaded = fileloaded.bind(this);
      reader.onload = fileloaded;
      if (last === 'lsx') {
        reader.readAsBinaryString(fileObj)
      } else {
        reader.readAsText(fileObj);
      }
    }
  };

  const showTable = (file) => {
    setShowSaveButton(true)
    setUpperColumns(Object.keys(file))

    var correlations = {}

    var columns = []
    var rows = []
    let headerList = {}

    for (var i = 0; i < Object.keys(file).length; i++) {
      for (var j = 0; j < Object.keys(Object.values(file)[i]).length; j++) {
        correlations[Object.keys(file)[i]] = Object.keys(Object.values(file)[i])

        var temp = []
        temp = Object.keys(Object.values(file)[i])
        columns.push(temp[j])
        headerList[Object.keys(file)[i]] = Object.keys(Object.values(file)[i]).length
      }

      for (var k = 0; k < Object.values(Object.values(file)[i]).length; k++) {
        var temp = []
        temp = Object.values(Object.values(file)[i])
        rows.push(temp[k])
      }
    }

    setCorrelations(correlations)

    setListForHeader(headerList)
    setSelectedItem(Object.keys(headerList)[0])
    setColumns(columns)
    setRows(rows)

    let headerKeys = Object.keys(headerList)
    for (const name of headerKeys) {
      if (companySizeChartsNames.includes(name?.toLowerCase())) {
          setDuplicatesNameFlag(true)
      }
    }

    var a = []
    var maxRow = 0
    for (let i = 0; i < rows.length; i++) {
      var d = rows[i].length
      if (d > maxRow) {
        maxRow = d
      }
    }
    for (let i = 1; i <= maxRow; i++) {
      a.push(i);
    }

    var b = []
    for (let i = 1; i <= columns.length; i++) {
      b.push(i);
    }

    //!!!

    var newColumns = [];
    var newRows = [];
    var newInput = file[Object.keys(headerList)[0]];
    for (var i = 0; i < Object.keys(newInput).length; i++) {
      newColumns.push(Object.keys(newInput)[i]);
    }
    for (var k = 0; k < Object.values(newInput).length; k++) {
      var temp = [];
      temp = Object.values(newInput)[k];
      newRows.push(temp);
    }
    setNewColumns(newColumns)
    setNewRows(newRows)

    var newA = [];
    var newMaxRow = 0;
    for (let i = 0; i < newColumns.length; i++) {
      if (newRows[i] != undefined) {
        var d = newRows[i].length;
      }
      if (d > newMaxRow) {
        newMaxRow = d;
      }
    }
    for (let i = 1; i <= newMaxRow; i++) {
      newA.push(i);
    }
    setNewRowCountArray(newA)

    var newB = [];
    for (let i = 1; i <= newColumns.length; i++) {
      newB.push(i);
    }
    setNewColCountArray(newB)
  }

  const makeTable = (file) => {
    var newColumns = []
    var newRows = []

    var newInput = file

    for (var i = 0; i < Object.keys(newInput).length; i++) {
      newColumns.push(Object.keys(newInput)[i])
    }

    for (var k = 0; k < Object.values(newInput)[0].length; k++) {
      var temp = []
      temp = Object.values(newInput)[k]
      newRows.push(temp)
    }

    setNewColumns(newColumns)
    setNewRows(newRows)

    var newA = []

    var newMaxRow = 0
    for (let i = 0; i < newColumns.length; i++) {
      var d = newRows[i].length
      if (d > newMaxRow) {
        newMaxRow = d
      }
    }

    for (let i = 1; i <= newMaxRow; i++) {
      newA.push(i);
    }

    setNewRowCountArray(newA)

    var newB = []

    for (let i = 1; i <= newColumns.length; i++) {
      newB.push(i);
    }

    setNewColCountArray(newB)
  }

  const handleChangeSelector = (event) => {
    setSelectedItem(event.target.value);
    makeTable(uploadedFile[event.target.value])
  }

  const handleInputChange = (event, idRow, idCol) => {
    const newData = [...newRows];
    newData[idRow][idCol] = event.target.value;
    setNewRows(newData);
  }

  const saveSizeChart = async () => {
    setSaveError(false)

    if(duplicatesAssociationsFlag){
      handleSnackbar('error', "You have duplicated associations.");
    }

    if (!fileError && !duplicatesAssociationsFlag) {
      var noCols = Object.values(listForHeader)
      let finalDict = {}
      var error = false

      var h = 0
      for (var i = 0; i < upperColumns.length; i++) {
        let dict = {}
        var j = 0
        while (j < noCols[i]) {
          dict[columns[h]] = rows[h]
          h++
          j++
        }
        finalDict[upperColumns[i]] = dict
      }

      const result = {};

      for (const key in finalDict) {
        result[key] = {};
        if (key in associations) {
          for (const subKey in finalDict[key]) {
            if (subKey in associations[key]) {
              result[key][associations[key][subKey]] = finalDict[key][subKey];

              if (Array.isArray(result[key][associations[key][subKey]])) {
                for (let i = 0; i < result[key][associations[key][subKey]].length; i++) {
                  if (typeof result[key][associations[key][subKey]][i] === 'string' &&
                      !isNaN(parseFloat(result[key][associations[key][subKey]][i]))) {
                        result[key][associations[key][subKey]][i] = parseFloat(result[key][associations[key][subKey]][i].replace(',', '.'))
                  } else if (result[key][associations[key][subKey]][i] === "") {
                    error = true
                  }
                }
              }

            } else if (subKey === "sizes") {
              result[key][subKey] = finalDict[key][subKey]
            } else {
              error = true
            }
          }
        } else {
          error = true
        }
      }

      const outputObject = Object.fromEntries(
        Object.entries(result).map(([key, value]) => [
          key,
          convertArraysToDictionaries(value)
        ])
      );


      setFinalObject(outputObject) 

      if (!error) {
        updateSizeChartsInDatabase(outputObject)
      } else {
        setSaveError(true)
        handleSnackbar('error', t(`scAsocErrorOne`));
      }
    }
  }

  const updateSizeChartsInDatabase = async (sizeCharts) => {
    setButtonLoader(true)

    const accessToken = await currentUser.getIdToken(true);
    let data = "";

    if (companyId) {
      data = { size_charts: sizeCharts };

      fetch(database_url+"/add_company_size_chart?user_id=" + userId + '&company_id=' + companyId, {
          method: "POST",
          headers: {"Accept": "application/json", "Content-Type": 'application/json', 'x-access-token': accessToken },
          body: JSON.stringify(data)
      }).then(() => {
          handleSnackbar('success', t(`scSnack`));
          setButtonLoader(false)
          let promises = Promise.all([
            fetch(database_url + '/view_company_size_charts?user_id=' + userId + '&company_id=' + companyId, {
              method: 'GET',
              headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-access-token': accessToken },
            }),
          ]);

          promises
          .then((results) => Promise.all(results.map((r) => r.json())))
          .then((resultsData) => {
            const [companySizeCharts] = resultsData;
            setCompanySizeCharts(companySizeCharts || {});
          }).then(()=>{
            props.handleCloseDialog()
          })
      });
    } else {
      setButtonLoader(false)
    }
  }

  return (
    <>
      {role!=='esenca' &&
        <AppBar
          sx={{
            backgroundColor: colors.primary,
            color: colors.white
          }}
        >
          <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6" component="div">
              {t(`scHeaderOne`)}
            </Typography>
            <IconButton
              color="inherit"
              onClick={props.handleCloseDialog}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      }
      <Grid container>
      {role!=='esenca' &&
        <Grid item xs={12}>
          <Toolbar />
        </Grid>
      }
        <Grid item container xs={12} padding={2} spacing={2} sx={{ maxHeight: '500px' }}>
          <Grid item xs={6}>
            <Card sx={{ px: 5, py: 2, minHeight: '300px' }}>
              <Typography variant="body1" fontWeight={"bold"}>
                {t(`scHeaderTwo`)}
              </Typography>
              <FormControl>
                <RadioGroup
                  defaultValue={"json"}
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={changeFileType}
                >
                  <Grid item xs={12}><FormControlLabel value="xlsx" control={<Radio />} label={<Typography variant="subtitle2">XLSX</Typography>} /></Grid>
                  <Grid item xs={12}><FormControlLabel value="csv" control={<Radio />} label={<Typography variant="subtitle2">CSV</Typography>} /></Grid>
                  <Grid item xs={12}><FormControlLabel value="json" control={<Radio />} label={<Typography variant="subtitle2">JSON</Typography>} /></Grid>
                  <Grid item xs={12}><FormControlLabel value="text" control={<Radio />} label={<Typography variant="subtitle2">Text</Typography>} /></Grid>
                </RadioGroup>
              </FormControl>
              <Button variant="contained" sx={{ width: "50%", mt: 2 }} onClick={download}>{t(`Download File`)}</Button>
              <a
                ref={downloadLinkRef}
                style={{ display: 'none' }}
                href=""
                download
              />
            </Card>
          </Grid>
          <Grid item xs={6} sx={{ maxHeight: '500px'}}>
            <Card sx={{ px: 5, py: 2, minHeight: '300px' }}>
              <Typography variant="body1" fontWeight={"bold"}>
                {t(`scHeaderThree`)}
              </Typography>
              <Button variant="contained" sx={{ width: "50%", mt: 2 }} onClick={handleUpload}>{t(`Choose File`)}</Button>
              <Typography variant="body2">{t(`scSubHeaderOne`)}</Typography>
              <br />
              <input
                type="file"
                accept=".json,.csv,.txt,.text,application/json,text/csv,text/plain,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ref={fileInputRef}
                onChange={handleFileUpload}
                multiple={false}
                style={{ display: 'none' }}
              />
              <Typography variant="body1">
                <ReportProblemOutlined /> {t(`scSubHeaderTwo`)}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} >
            <Card sx={{ px: 5, py: 2 }}>
              <Typography variant="body1" fontWeight={'bold'} mb={2}>
                {t(`scHeaderFour`)} 
              </Typography>
              <Grid container spacing={2}>
                {showSaveButton ?
                  <>
                    <Grid item xs={12} md={6}>
                      <FormControl>
                        <InputLabel id="demo-simple-select-label">{t(`Size Chart`)} </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedItem}
                          label={t(`scHeaderFour`)} 
                          onChange={handleChangeSelector}
                        >
                          {Object.keys(listForHeader).map(name => <MenuItem value={name}>{name}</MenuItem>)}
                        </Select>
                      </FormControl>
                    </Grid>
                    {duplicatesNameFlag && 
                      <Grid item xs={12} md={6}>
                        <Box display="flex" alignItems="left" mb={1}>
                            <ReportProblemIcon color="warning" />
                            <Box ml={1}>
                          <Typography variant="body2" sx={{fontWeight:'bold'}}>
                            Your company already has size chart(s) that share identical name(s).
                          </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    }
                    <Grid item xs={12}>
                      <Paper>
                        <TableContainer sx={{ mt: { xs: 1, md: 1 } }}>
                          <Table size="small">
                            <TableHead sx={{ display: 'contents' }}>
                              <TableRow>
                                {newColumns.map(name => <TableCell align="center">{name}</TableCell>)}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {newRowCountArray.map((row, indexRow) => (
                                <TableRow>
                                  {newColCountArray.map((col, index) => (
                                    <TableCell align="center">
                                      {
                                        <Input
                                          align="center"
                                          sx={{
                                            '& .MuiInput-input': {
                                              textAlign: 'center',
                                            },
                                          }}
                                          variant="standard"
                                          value={
                                            newRows &&
                                            newRows[col - 1] &&
                                            newRows[col - 1][row - 1]
                                          }
                                          onChange={(e) => handleInputChange(e, col - 1, row - 1)}
                                        />
                                      }
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={12} mt={5}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{t(`Correlations`)}</Typography>
                      <Associations 
                        data={correlations} 
                        standard={standard} 
                        associations={associations} 
                        setAssociations={setAssociations} 
                        companyMeasurementsConfig={companyMeasurementsConfig}
                        setDuplicatesAssociationsFlag={setDuplicatesAssociationsFlag}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ textAlign: { xs: 'center', md: 'right' }, width: { xs: '100%', md: '50%' }, mt: { xs: 2, md: 0 } }}>
                      <LoadingButton
                          onClick={saveSizeChart}
                          loading={buttonLoader}
                          variant="contained"
                      >
                        {t(`Save to database`)}
                      </LoadingButton>
                    </Grid>
                  </>
                  :
                  <>
                    <Skeleton variant="rounded" width={"100vw"} height={"80vh"} />
                  </>
                }
              </Grid>
            </Card>

          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default AddChartFileDialog
